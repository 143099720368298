const ClockIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 -2 29 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.3035 28.6069C22.2031 28.6069 28.6069 22.2031 28.6069 14.3035C28.6069 6.40388 22.2031 0 14.3035 0C6.40388 0 0 6.40388 0 14.3035C0 22.2031 6.40388 28.6069 14.3035 28.6069Z"
        fill="#3C3C3C"
      />
      <path
        d="M14.4189 25.6562C20.6266 25.6562 25.659 20.6244 25.659 14.4173C25.659 8.21027 20.6266 3.17847 14.4189 3.17847C8.2111 3.17847 3.17871 8.21027 3.17871 14.4173C3.17871 20.6244 8.2111 25.6562 14.4189 25.6562Z"
        fill="#FFFBF0"
      />
      <path
        d="M14.1969 7.064C13.9108 7.064 13.679 6.83209 13.679 6.54601V5.81376C13.679 5.52762 13.9109 5.29578 14.1969 5.29578C14.4831 5.29578 14.7149 5.52768 14.7149 5.81376V6.54601C14.7149 6.83209 14.4831 7.064 14.1969 7.064Z"
        fill="#3C3C3C"
      />
      <path
        d="M19.8847 9.42022C19.7521 9.42022 19.6196 9.3696 19.5184 9.26843C19.3161 9.06616 19.3161 8.73818 19.5185 8.53584L20.0362 8.01813C20.2385 7.81585 20.5665 7.81592 20.7688 8.01819C20.9711 8.22046 20.9711 8.54844 20.7688 8.75078L20.251 9.2685C20.1499 9.3696 20.0173 9.42022 19.8847 9.42022Z"
        fill="#3C3C3C"
      />
      <path
        d="M22.9736 15.1082H22.2414C21.9552 15.1082 21.7234 14.8763 21.7234 14.5902C21.7234 14.3042 21.9553 14.0723 22.2414 14.0723H22.9737C23.2598 14.0723 23.4917 14.3042 23.4917 14.5902C23.4917 14.8763 23.2598 15.1082 22.9736 15.1082Z"
        fill="#3C3C3C"
      />
      <path
        d="M14.4096 24.0976C14.1234 24.0976 13.8916 23.8657 13.8916 23.5796V22.8473C13.8916 22.5612 14.1235 22.3293 14.4096 22.3293C14.6957 22.3293 14.9276 22.5612 14.9276 22.8473V23.5796C14.9276 23.8658 14.6957 24.0976 14.4096 24.0976Z"
        fill="#3C3C3C"
      />
      <path
        d="M7.9902 21.3139C7.85765 21.3139 7.72504 21.2633 7.62387 21.1622C7.4216 20.9598 7.4216 20.6319 7.62387 20.4295L8.14165 19.9117C8.34399 19.7095 8.6719 19.7095 8.87431 19.9117C9.07658 20.1141 9.07658 20.442 8.87431 20.6444L8.35653 21.1622C8.25529 21.2633 8.12268 21.3139 7.9902 21.3139Z"
        fill="#3C3C3C"
      />
      <path
        d="M6.1516 15.1082H5.41935C5.13321 15.1082 4.90137 14.8763 4.90137 14.5902C4.90137 14.3042 5.13327 14.0723 5.41935 14.0723H6.15167C6.43782 14.0723 6.66965 14.3042 6.66965 14.5902C6.66965 14.8763 6.43775 15.1082 6.1516 15.1082Z"
        fill="#3C3C3C"
      />
      <path
        d="M8.50797 9.42022C8.37543 9.42022 8.24281 9.3696 8.14171 9.2685L7.62393 8.75078C7.42159 8.54851 7.42159 8.22053 7.62386 8.01819C7.8262 7.81585 8.15418 7.81592 8.35645 8.01813L8.87423 8.53584C9.07657 8.73811 9.07657 9.06609 8.8743 9.26843C8.77313 9.3696 8.64052 9.42022 8.50797 9.42022Z"
        fill="#3C3C3C"
      />
      <path
        d="M14.1974 15.3212C14.0649 15.3212 13.9345 15.2703 13.8359 15.1743L12.014 13.4002C11.8091 13.2006 11.8047 12.8727 12.0043 12.6677C12.2038 12.4627 12.5318 12.4583 12.7368 12.658L13.6793 13.5757V9.90092C13.6793 9.61477 13.9112 9.38293 14.1973 9.38293C14.4834 9.38293 14.7152 9.61484 14.7152 9.90092V14.8031C14.7152 15.0114 14.5905 15.1993 14.3987 15.2804C14.3336 15.3078 14.2652 15.3212 14.1974 15.3212Z"
        fill="#3C3C3C"
      />
      <g filter="url(#filter0_d_4054_5540)">
        <path
          d="M22.5076 32C26.4439 32 29.6349 28.8091 29.6349 24.8728C29.6349 20.9365 26.4439 17.7455 22.5076 17.7455C18.5714 17.7455 15.3804 20.9365 15.3804 24.8728C15.3804 28.8091 18.5714 32 22.5076 32Z"
          fill="#FF3600"
        />
      </g>
      <path
        d="M22.3756 26.4072C21.919 26.4072 21.5488 26.037 21.5488 25.5803V20.6441C21.5488 20.1875 21.919 19.8173 22.3756 19.8173C22.8323 19.8173 23.2025 20.1875 23.2025 20.6441V25.5804C23.2024 26.037 22.8323 26.4072 22.3756 26.4072Z"
        fill="#E7E7E7"
      />
      <path
        d="M22.3756 29.6041C21.919 29.6041 21.5488 29.2339 21.5488 28.7772V28.2506C21.5488 27.794 21.919 27.4238 22.3756 27.4238C22.8323 27.4238 23.2025 27.794 23.2025 28.2506V28.7772C23.2024 29.2339 22.8323 29.6041 22.3756 29.6041Z"
        fill="#E7E7E7"
      />
      <path
        d="M22.3758 19.8173C22.225 19.8173 22.0841 19.8583 21.9624 19.9288C22.2093 20.0718 22.3758 20.3382 22.3758 20.6441V25.5804C22.3758 25.8862 22.2093 26.1527 21.9624 26.2957C22.0841 26.3662 22.225 26.4072 22.3758 26.4072C22.8324 26.4072 23.2026 26.037 23.2026 25.5804V20.6441C23.2026 20.1875 22.8324 19.8173 22.3758 19.8173Z"
        fill="white"
      />
      <path
        d="M22.3758 27.4238C22.225 27.4238 22.0841 27.4648 21.9624 27.5353C22.2093 27.6783 22.3758 27.9447 22.3758 28.2506V28.7772C22.3758 29.0831 22.2093 29.3495 21.9624 29.4926C22.0841 29.563 22.225 29.6041 22.3758 29.6041C22.8324 29.6041 23.2026 29.2339 23.2026 28.7772V28.2506C23.2026 27.794 22.8324 27.4238 22.3758 27.4238Z"
        fill="white"
      />
      <defs>
        <filter
          id="filter0_d_4054_5540"
          x="4.15015"
          y="9.32288"
          width="36.7148"
          height="36.715"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="2.80755" />
          <feGaussianBlur stdDeviation="5.61511" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 1 0 0 0 0 0.211765 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4054_5540"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4054_5540"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default ClockIcon;
