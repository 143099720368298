import React, { useEffect, useState } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModelService from "views/models/services/ModelService";
import ModelPageLayout from "../../layouts/ModelPageLayout/ModelPageLayout";
import {
  AnalyticEventCategory,
  AnalyticService,
  EventActionType,
} from "services/AnalyticService";
import { MessageService } from "services/MessageService";
import {
  MODEL_PAGE_VIEW_TYPE_PARAM_NAME,
  ModelPageViewTypes,
  ModelDetailsPageTabKeys,
} from "const/global";
import { WindowUtils } from "utils/windowUtils";
import ModelDetailsPageStore from "stores/ModelDetailsPageStore";
import { IGlobalStore } from "stores/GlobalStore";
import { inject, observer } from "mobx-react";
import { Model } from "../../../../models/Model";
import withViewerProviders from "../../../viewer/hoc/withViewerProviders";
import ModelMapView from "../../components/ModelMapView";
import SidebarComponent from "./Sidebar/Sidebar.component";
import ContentSectionComponent from "../components/ContentSection.component";
import ModelSharedDetailsPageViewer from "./Viewer/Viewer.component";
import ModelDetailsRow from "../../layouts/ModelPageLayout/components/ModelDetailsRow";
import ModelCommentsContainer from "../../containers/ModelCommentsContainer";
import Dialog from "../../../shared/components/Dialog/Dialog";
import SharedModelDetailsPageDownloadSectionComponent from "./DownloadSection/SharedModelDetailsPageDownloadSection.component";
import ModelOrthophotoViewer from "../components/ModelOrthophotoViewer";

interface IMatchParams {
  id: string;
}

interface ISharedModelDetailsPagePropTypes
  extends RouteComponentProps<IMatchParams> {
  GlobalStore?: IGlobalStore;
}

const SharedModelDetailsPage = ({
  GlobalStore,
  history,
  match,
}: ISharedModelDetailsPagePropTypes) => {
  const { t } = useTranslation();
  const [model, setModel] = useState<null | Model>(null);
  const [, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const [tags, setTags] = useState<string[]>([]);
  const [currentTabKey, setCurrentTabKey] = useState(
    ModelDetailsPageTabKeys.General
  );
  const [downloadModalOpen, setDownloadModalOpen] = useState(false);
  const [viewType, setViewType] = useState(
    WindowUtils.getQueryParam(
      MODEL_PAGE_VIEW_TYPE_PARAM_NAME,
      ModelPageViewTypes.Map
    )
  );

  const getPublicModel = (publicKey: string) => {
    setLoading(true);
    ModelService.getSharedModel(publicKey)
      .then((res) => {
        AnalyticService.event(
          AnalyticEventCategory.PublicModelDetailsPage,
          "model_loaded",
          EventActionType.Load
        );
        setModel(res.data);
        setTags(res.data.tags || []);
      })
      .catch(() => {
        AnalyticService.event(
          AnalyticEventCategory.PublicModelDetailsPage,
          "model_not_loaded",
          EventActionType.LoadError
        );
        MessageService.error(t("toastPublicModelNotAvailable"));
      })
      .finally(() => {
        setLoading(false);
        setLoaded(true);
      });
  };

  const getUser = async () => {
    await GlobalStore.getUser();
  };

  useEffect(() => {
    const { id } = match.params;
    getPublicModel(id);
    getUser();
    ModelDetailsPageStore.resetStore();
    ModelDetailsPageStore.loadComments(id);

    return () => {
      ModelDetailsPageStore.resetStore();
    };
  }, [history, match.params.id, match.params]);

  if (!(loaded && model)) {
    return null;
  }

  const downloadSection = (
    <SharedModelDetailsPageDownloadSectionComponent model={model} />
  );
  return (
    <>
      <Dialog
        size="medium"
        open={downloadModalOpen}
        title={t("download")}
        onClose={() => setDownloadModalOpen(false)}
        hideCancelButton
        hideConfirmButton
      >
        {downloadSection}
      </Dialog>
      <ModelPageLayout
        orthophotoViewerComponent={
          <ModelOrthophotoViewer showDownload={false} model={model} />
        }
        view={viewType}
        viewerComponent={<ModelSharedDetailsPageViewer model={model} />}
        commentsComponent={<ModelCommentsContainer model={model} />}
        mapComponent={<ModelMapView model={model} points={[]} />}
        headerRowComponent={
          <ModelDetailsRow
            showCommentsModule={true}
            model={model}
            view={viewType}
            onViewChange={(viewType) => setViewType(viewType)}
          />
        }
        sidebarComponent={
          <SidebarComponent
            currentTabKey={currentTabKey}
            setCurrentTabKey={setCurrentTabKey}
          />
        }
        contentSectionComponent={
          <ContentSectionComponent
            downloadSectionComponent={downloadSection}
            editable={false}
            currentTabKey={currentTabKey}
            setCurrentTabKey={(key) =>
              setCurrentTabKey(key as ModelDetailsPageTabKeys)
            }
            model={model}
            onModelChange={() => {}}
          />
        }
      ></ModelPageLayout>
    </>
  );
};

export default inject("GlobalStore")(
  withRouter(observer(withViewerProviders(SharedModelDetailsPage)))
);
