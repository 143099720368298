import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { trimStringIfToLong } from "../../../utils/stringUtils";
import { IAppContext, VideoUploadErrorType } from "../../interfaces";
import Button from "../buttons/Button";
import AlertIcon from "../Icons/AlertIcon";
import CameraIcon from "../Icons/CameraIcon";
import CheckCircleIcon from "../Icons/CheckCircleIcon";
import CircleLoaderIcon from "../Icons/CircleLoaderIcon";
import TrashIcon from "../Icons/TrashIcon";
import Alert from "./Alert";
import Box from "./Box";

const LOADING_ICON_SIZE = 16;

export enum UploadStatus {
  Uploading = "uploading",
  Failed = "failed",
  Uploaded = "uploaded",
  InQueue = "inQueue",
}

interface IFileUploadIndicatorPropTypes {
  errorType?: VideoUploadErrorType;
  fileName: string;
  status: UploadStatus;
  warningMessage?: string;
  loadingProgressLabel?: string;
  showWarning?: boolean;
  onDelete?: () => void;
  onUploadStart?: () => void;
  onRefresh?: () => void;
}

const Wrapper = styled.div`
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  padding: ${(p: IAppContext) => p.theme.skye.sizes.average};
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};

  .skye-alert {
    margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
  }
`;

const FileName = styled.h6`
  margin: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
`;

const IconWrapper = styled(Box)`
  width: ${LOADING_ICON_SIZE}px;
  height: ${LOADING_ICON_SIZE}px;
  justify-content: center;
  align-items: center;

  &.end-icon {
    margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
  }

  &.start-icon {
    margin-left: ${(p: IAppContext) => p.theme.skye.sizes.small};
  }

  &.loading {
    svg {
      width: ${LOADING_ICON_SIZE}px;
      height: ${LOADING_ICON_SIZE}px;
      position: absolute;
      top: 50%;
      left: 50%;
      margin: -${LOADING_ICON_SIZE}px 0 0 -${LOADING_ICON_SIZE}px;
      position: relative;
      -webkit-animation: spin 2s linear infinite;
      -moz-animation: spin 2s linear infinite;
      animation: spin 2s linear infinite;

      @-moz-keyframes spin {
        100% {
          -moz-transform: rotate(360deg);
        }
      }
      @-webkit-keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
        }
      }
      @keyframes spin {
        100% {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }
  }
`;

const BoxAlignedToCenter = styled(Box)`
  align-items: center;
  justify-content: center;
`;

const FileDetailsRow = styled(Box)`
  align-items: center;
  justify-content: space-between;
`;
const UploadFailedLabel = styled(FileName)`
  color: ${(p: IAppContext) => p.theme.skye.colors.redOrange};
`;
const UploadingLabel = styled(FileName)`
  color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
`;
const FailedUploadWrapper = styled(Box)`
  align-items: center;
`;
const DetailsLeftSide = styled(BoxAlignedToCenter)``;
const DetailsRightSide = styled(BoxAlignedToCenter)``;

const FileUploadIndicator = ({
  errorType,
  fileName,
  onDelete = () => {},
  onUploadStart = () => {},
  onRefresh = () => {},
  warningMessage,
  showWarning,
  status,
  loadingProgressLabel,
}: IFileUploadIndicatorPropTypes) => {
  const { t } = useTranslation();

  const getFailedTranslation = (): string => {
    switch (errorType) {
      case VideoUploadErrorType.Cancel:
        return t("requestCanceled");
      case VideoUploadErrorType.Network:
        return t("requestNetworkError");
      default:
        return t("uploadFailed");
    }
  };

  const getRightContent = () => {
    switch (status) {
      case UploadStatus.Uploading:
        return (
          <FailedUploadWrapper>
            <UploadingLabel>{loadingProgressLabel}</UploadingLabel>
            <IconWrapper className="start-icon loading">
              <CircleLoaderIcon />
            </IconWrapper>
          </FailedUploadWrapper>
        );

      case UploadStatus.Failed:
        return (
          <FailedUploadWrapper>
            {/* <IconWrapper className="end-icon btn" onClick={onRefresh}>
              <RefreshIcon />
            </IconWrapper> */}
            <UploadFailedLabel>{getFailedTranslation()}</UploadFailedLabel>
            <IconWrapper className="start-icon">
              <AlertIcon />
            </IconWrapper>
          </FailedUploadWrapper>
        );

      case UploadStatus.Uploaded:
        return (
          <IconWrapper>
            <CheckCircleIcon />
          </IconWrapper>
        );

      case UploadStatus.InQueue:
      default:
        return (
          <FailedUploadWrapper>
            <Button
              type="solid"
              color="primary"
              size="very-small"
              onClick={onUploadStart}
            >
              {t("startUpload")}
            </Button>
            <IconWrapper className="start-icon btn" onClick={onDelete}>
              <TrashIcon />
            </IconWrapper>
          </FailedUploadWrapper>
        );
    }
  };
  return (
    <Wrapper className="file-upload-indicator">
      {showWarning &&
        Boolean(warningMessage) &&
        status === UploadStatus.InQueue && (
          <Alert type="danger" icon={<AlertIcon />}>
            {t(warningMessage)}
          </Alert>
        )}
      <FileDetailsRow>
        <DetailsLeftSide>
          <IconWrapper className="end-icon">
            <CameraIcon />
          </IconWrapper>
          <FileName>{trimStringIfToLong(fileName, 40)}</FileName>
        </DetailsLeftSide>
        <DetailsRightSide>{getRightContent()}</DetailsRightSide>
      </FileDetailsRow>
    </Wrapper>
  );
};

export default FileUploadIndicator;
