import { memo } from "react";
import SidebarTab from "../../../components/SidebarTab";
import { ModelDetailsPageTabKeys } from "../../../../../const/global";
import { useModelDetailsPage } from "../useModelDetailsPage";
import {
  AnalyticEventCategory,
  AnalyticService,
} from "../../../../../services/AnalyticService";
import { MODEL_DETAILS_PAGE_TABS_KEYS } from "../../pages.const";

const ModelDetailsPageSidebar = () => {
  const { currentTabKey, setCurrentTabKey } = useModelDetailsPage();

  const changeTab = (id: ModelDetailsPageTabKeys) => {
    if (id === ModelDetailsPageTabKeys.Download) {
      AnalyticService.event(
        AnalyticEventCategory.ModelDetailsPage,
        "download_section_click"
      );
    }
    setCurrentTabKey(id);
  };

  return (
    <>
      {MODEL_DETAILS_PAGE_TABS_KEYS.map((tab) => {
        return (
          <SidebarTab
            key={tab.key}
            active={tab.key === currentTabKey}
            onClick={changeTab}
            tab={tab}
          />
        );
      })}
    </>
  );
};

export default memo(ModelDetailsPageSidebar);
