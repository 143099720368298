import styled from 'styled-components';
import { IAppContext } from "../../interfaces";

const UserName = styled.h4`
  margin-bottom: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
`;

export default UserName;
