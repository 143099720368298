export class TopographyMap {
  static isOpen(): boolean {
    return !!window.viewer?.scene.topographyMap;
  }

  static toggle(): void {
    window.viewer?.scene.toggleTopographyMap();
  }

  static open(): void {
    window.viewer?.scene.openTopographyMap();
  }

  static close(): void {
    window.viewer?.scene.closeTopographyMap();
  }
}
