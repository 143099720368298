import React from 'react'

interface IAreaIconPropTypes {
  
}

const AreaIcon = ({}: IAreaIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M4.1665 4.66666C4.1665 4.39051 4.39036 4.16666 4.6665 4.16666H7.0665C7.34265 4.16666 7.5665 4.39051 7.5665 4.66666V5.36666H24.5665V4.66666C24.5665 4.39051 24.7904 4.16666 25.0665 4.16666H27.4665C27.7426 4.16666 27.9665 4.39051 27.9665 4.66666V7.06666C27.9665 7.3428 27.7426 7.56666 27.4665 7.56666H26.4544L17.398 15.491L26.4736 24.5667H27.4665C27.7426 24.5667 27.9665 24.7905 27.9665 25.0667V27.4667C27.9665 27.7428 27.7426 27.9667 27.4665 27.9667H25.0665C24.7904 27.9667 24.5665 27.7428 24.5665 27.4667V26.7667H7.5665V27.4667C7.5665 27.7428 7.34265 27.9667 7.0665 27.9667H4.6665C4.39036 27.9667 4.1665 27.7428 4.1665 27.4667V25.0667C4.1665 24.7905 4.39036 24.5667 4.6665 24.5667H5.3665V7.56666H4.6665C4.39036 7.56666 4.1665 7.3428 4.1665 7.06666V4.66666ZM6.3665 7.56666V24.5667H7.0665C7.34265 24.5667 7.5665 24.7905 7.5665 25.0667V25.7667H24.5665V25.0667C24.5665 24.7929 24.7866 24.5705 25.0594 24.5667L16.313 15.8202C16.2151 15.7224 16.1622 15.5883 16.1668 15.45C16.1714 15.3117 16.2331 15.1815 16.3373 15.0904L24.9511 7.55327C24.7306 7.50116 24.5665 7.30307 24.5665 7.06666V6.36666H7.5665V7.06666C7.5665 7.3428 7.34265 7.56666 7.0665 7.56666H6.3665ZM25.5665 6.56666H26.2665H26.9665V5.16666H25.5665V5.86666V6.56666ZM25.5665 25.5667V26.2667V26.9667H26.9665V25.5667H26.2665H25.5665ZM5.1665 5.16666V6.56666H5.8665H6.5665V5.86666V5.16666H5.1665ZM5.1665 25.5667V26.9667H6.5665V26.2667V25.5667H5.8665H5.1665Z" fill="#1A1A1A"/>
      <mask id="path-2-inside-1_179_2291" fill="white">
      <rect x="16" y="13.4" width="4" height="4" rx="0.5"/>
      </mask>
      <rect x="16" y="13.4" width="4" height="4" rx="0.5" fill="#F3F3F3" stroke="#1A1A1A" strokeWidth="2" mask="url(#path-2-inside-1_179_2291)"/>
    </svg>
  )
}

export default AreaIcon;

