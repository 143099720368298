import classNames from "classnames";
import React, { ReactNode } from "react";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import Label from "../typography/Label";
import { SmallDescription } from "../typography/typography";
import Box, { BoxCentered } from "./Box";
import breakpoint from "styled-components-breakpoint";

interface ISelectRadioButtonGroup {
  children: any;
}

const GroupWrapper = styled(Box)`
  flex-direction: column;
  margin: 0 -16px;
  padding: 8px;

  ${breakpoint("md")`
    align-items: flex-start;
    flex-direction: row;
  `}
`;

export const SelectRadioButtonGroup = ({
  children,
}: ISelectRadioButtonGroup) => {
  return <GroupWrapper>{children}</GroupWrapper>;
};

interface ISelectRadioButtonPropTypes {
  icon?: ReactNode;
  noMargin?: boolean;
  selected: boolean;
  label: any;
  description?: any;
  id: string;
  onClick: (id: string) => void;
}

const RadioOutter = styled(BoxCentered)`
  border: ${(p: IAppContext) => p.theme.skye.borders.primary};
  width: 20px;
  height: 20px;
  border-radius: 20px;
  position: absolute;
  right: 16px;
  top: 14px;
`;
const RadioInner = styled(Box)``;
const Wrapper = styled(Box)`
  margin: 0 8px;
  padding: 16px 52px 16px 20px;
  margin-bottom: 8px;
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  border: ${(p: IAppContext) => p.theme.skye.borders.primary};
  min-height: 96px;
  position: relative;
  flex-direction: column;
  width: 100%;

  .label {
    font-weight: 600;
  }

  &.selected {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.lavender};
    border: ${(p: IAppContext) => p.theme.skye.borders.active};

    ${RadioOutter} {
      border: 2px solid
        ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    }

    ${RadioInner} {
      width: 10px;
      height: 10px;
      border-radius: 10px;
      background-color: ${(p: IAppContext) =>
        p.theme.skye.colors.electricViolet};
    }
  }

  &.noMargin {
    margin: 0;
  }
`;

const SelectRadioButton = ({
  id,
  icon,
  selected,
  label,
  description,
  onClick,
  noMargin,
}: ISelectRadioButtonPropTypes) => {
  return (
    <Wrapper
      onClick={() => onClick(id)}
      className={classNames(["select-radio-btn btn", { selected, noMargin }])}
    >
      <div className="flex items-start justify-start">
        {icon && <div className="icon-wrapper mr-3">{icon}</div>}
        <div>
          <Label>{label}</Label>
          <SmallDescription>{description}</SmallDescription>
        </div>
      </div>
      <RadioOutter>
        <RadioInner />
      </RadioOutter>
    </Wrapper>
  );
};

export default SelectRadioButton;
