import { useTranslation } from "react-i18next";
import {
  IPublicModel,
  ITypedModelType,
  ModelStatus,
} from "../../../../models/Model";
import OrthophotoViewer from "../../components/OrthophotoViewer/OrthophotoViewer";
import { useDownload2DAssets } from "../../hooks/download/useDownload2DAssets";

interface IModelOrthophotoViewerPropTypes {
  model: IPublicModel;
  showDownload?: boolean;
}

const ModelOrthophotoViewer = ({
  model,
  showDownload = true,
}: IModelOrthophotoViewerPropTypes) => {
  const { t } = useTranslation();
  const orthoDownload = useDownload2DAssets(model, true);

  return (
    <OrthophotoViewer
      sketchProps={{
        showDownload,
        name: t("models.2DGeotiff"),
        exists: !!(
          model.assets.orthophotoUrl &&
          model.typedModels.find(
            (typed) =>
              typed.type === ITypedModelType.Orthophoto &&
              typed.status === ModelStatus.Finished
          )
        ),
        imgSrc: model.assets.orthophotoUrl,
        onDownload: () => {
          window.open(model.assets.orthophotoUrl, "_blank");
        },
        loading: orthoDownload.loading,
      }}
      orthophotoProps={{
        showDownload,
        name: t("models.sketch"),
        exists: !!(
          model.assets.orthophotoSketchUrl &&
          model.typedModels.find(
            (typed) =>
              typed.type === ITypedModelType.OrthophotoSketch &&
              typed.status === ModelStatus.Finished
          )
        ),
        imgSrc: model.assets.orthophotoSketchUrl,
        onDownload: () => {
          window.open(model.assets.orthophotoSketchUrl, "_blank");
        },
        loading: orthoDownload.loading,
      }}
    />
  );
};

export default ModelOrthophotoViewer;
