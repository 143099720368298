import { EnvVariables } from "./";

const devEnv: EnvVariables = {
  APP_DOMAIN: "skyebrowse.com",
  API_URL: "http://localhost:3100/",
  API_V3_URL: "http://localhost:3100/",
  MAP_URL: "http://localhost:8080/",
  UPLOAD_BUCKET_URL:
    "https://skyebrowse-dev-uploads.s3.us-east-2.amazonaws.com",
  GOOGLE_MAPS_API_KEY: "AIzaSyCeDFfruBiTe73MQiiYDdHYA4N3DDFSxMw",
  GOOGLE_ANALYTIC_API_KEY: "",
  STRIPE_PRICING_TABLE_ID: "prctbl_1MM6RTFCf9LMLUSNRNaSJHVV",
  STRIPE_PUBLISHABLE_KEY: "pk_test_hLTYLk4SUWhXIKVJcUza0MyJ00PUHAZSwn",
  STRIPE_CUSTOMER_PORTAL_ID: "test_9AQdSe9CV8X4fmgeUU",
  FACEBOOK_CONVERSION_API_KEY: "",
  FACEBOOK_PIXEL_ID: "",
};

export default devEnv;
