import React from "react";
import styled from "styled-components";
import { IAppContext } from "../../../../interfaces";
import breakpoint from "styled-components-breakpoint";
import Box, { BoxAlignedCenter } from "../../../../shared/components/Box";
import useCurrentUser from "../../../../account/hooks/useCurrentUser";
import UserAvatar from "../../../../shared/components/UserAvatar";
import { UserHelpers } from "../../../../../models/User";
import OrganizationUsersContainer from "./OrganizationUsersContainer";

const TabContentWrapper = styled(Box)`
  flex-direction: column;
  padding-top: ${(p: IAppContext) => p.theme.skye.sizes.xLarge};
`;
const OrganizationTabContentWrapper = styled(TabContentWrapper)`
  flex-direction: column;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.xLarge};

  ${breakpoint("md")`
    flex-direction: row;
  `}
`;
const AvatarColumn = styled(BoxAlignedCenter)`
  flex-direction: column;
  min-width: 142px;
  align-items: flex-start;
`;
const ContentColumn = styled(Box)`
  width: 100%;
  flex-direction: column;
`;
const OrganizationName = styled.h3`
  font-style: normal;
  font-weight: 400;
  font-size: 21px;
  line-height: 25px;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;
interface OrganizationUsersTabProps {}

const OrganizationUsersTab = ({}: OrganizationUsersTabProps) => {
  const { user } = useCurrentUser();

  return (
    <div className="">
      <OrganizationTabContentWrapper className="tab-content-wrapper">
        <AvatarColumn>
          <UserAvatar
            userName={UserHelpers.getOrganizationName(user)}
            size={102}
          />
        </AvatarColumn>
        <ContentColumn>
          <OrganizationName>
            {UserHelpers.getOrganizationName(user)}
          </OrganizationName>
        </ContentColumn>
      </OrganizationTabContentWrapper>
      <OrganizationUsersContainer />
    </div>
  );
};

export default OrganizationUsersTab;
