import React, { memo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Title from "../../shared/typography/Title";
import Description from "../../shared/typography/Description";
import Alert from "../../shared/components/Alert";
import Box from "../../shared/components/Box";
import { IPublicModel, ModelStatus } from "../../../models/Model";

interface IDownloadButtonWrapperProps {
  titleDictionary: string;
  descriptionDictionary: string;
  alertDictionary: string;
  showAlert: boolean;
  model: IPublicModel;
  children: any;
}

const Wrapper = styled.div`
  .description {
    margin-top: 0;
  }
`;

const ModelDownloadButtonWrapper = ({
  titleDictionary,
  descriptionDictionary,
  alertDictionary,
  showAlert,
  model,
  children,
}: IDownloadButtonWrapperProps) => {
  const { t } = useTranslation();

  return (
    <Wrapper className="download-file-section">
      <Title>{t(titleDictionary)}</Title>
      <Description>{t(descriptionDictionary)}</Description>
      {showAlert && <Alert type="warning">{t(alertDictionary)}</Alert>}
      {model.status === ModelStatus.Finished && <Box>{children}</Box>}
    </Wrapper>
  );
};

export default memo(ModelDownloadButtonWrapper);
