import httpService from "services/HttpService";
import modelEnv from "../model.env";

export class ModelDownloadService {
  static async geoTiffPresignedUrl(modelId: string): Promise<string> {
    const url = `${modelEnv.apiUrl}/${modelId}/downloads/ortho`;
    const res = await httpService.get(url);
    return res.data as string;
  }
}
