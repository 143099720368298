import { ViewerAnnotationsProvider } from "../context/ViewerAnnotationsContext";

const withViewerProviders = (WrappedComponent) => {
  return (props) => (
    <ViewerAnnotationsProvider>
      <WrappedComponent {...props} />
    </ViewerAnnotationsProvider>
  );
};

export default withViewerProviders;
