import { createContext, useContext } from "react";

interface IContext {
  blocked: boolean;
}

interface IInitialState {
  children: React.ReactNode;
  blocked?: boolean;
}

export const ViewerMeasuringToolsContext = createContext<IContext>(null);

export const ViewerScreenshotToolProvider = ({
  children,
  blocked,
}: IInitialState) => {
  return (
    <ViewerMeasuringToolsContext.Provider value={{ blocked }}>
      {children}
    </ViewerMeasuringToolsContext.Provider>
  );
};

export const useScreenshotTool = () => {
  const ctx = useContext(ViewerMeasuringToolsContext);

  if (!ctx) {
    throw new Error(
      "useScreenshotTool must be used within a ViewerScreenshotToolProvider"
    );
  }

  return { blocked: ctx.blocked };
};
