import React from 'react'

interface ICopyIconPropTypes {
  
}

const CopyIcon = ({
  
}: ICopyIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M8.99805 4.49902C8.7219 4.49902 8.49805 4.72288 8.49805 4.99902C8.49805 5.27517 8.7219 5.49902 8.99805 5.49902H26.4989V22.9991C26.4989 23.2753 26.7228 23.4991 26.9989 23.4991C27.2751 23.4991 27.4989 23.2753 27.4989 22.9991V4.99902C27.4989 4.72288 27.2751 4.49902 26.9989 4.49902H8.99805ZM4.99805 8.49951C4.7219 8.49951 4.49805 8.72337 4.49805 8.99951V26.9995C4.49805 27.2757 4.7219 27.4995 4.99805 27.4995H22.9988C23.2749 27.4995 23.4988 27.2757 23.4988 26.9995V8.99951C23.4988 8.72337 23.2749 8.49951 22.9988 8.49951H4.99805ZM5.49805 26.4995V9.49951H22.4988V26.4995H5.49805Z" fill="#1A1A1A"/>
    </svg>
  )
}

export default CopyIcon;
