import React from 'react'

interface IPublicIconPropTypes {
  
}

const PublicIcon = ({
  
}: IPublicIconPropTypes) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 11.5C8.76143 11.5 11 9.26143 11 6.5C11 3.73857 8.76143 1.5 6 1.5C3.23857 1.5 1 3.73857 1 6.5C1 9.26143 3.23857 11.5 6 11.5Z" stroke="#5A11FF" strokeWidth="0.8"/>
      <path d="M1 5.70998C1.75371 5.35408 2.31116 5.3045 2.67235 5.56123C3.21415 5.94635 3.28208 7.51588 4.51438 6.76575C5.74668 6.01563 3.98668 5.70998 4.36875 4.62335C4.75083 3.53673 6.00207 4.3808 6.12647 3.19055C6.20937 2.39703 5.2602 2.09341 3.2789 2.27968" stroke="#5A11FF" strokeWidth="0.8"/>
      <path d="M9.00033 2.5C7.57173 3.74715 7.07278 4.50028 7.50348 4.75933C8.1495 5.14793 8.42363 4.60083 9.21198 4.75933C10.0003 4.91785 9.82963 5.98642 9.41498 5.98642C9.00033 5.98642 6.8528 5.71295 6.96098 6.96625C7.06913 8.21952 8.36085 8.40508 8.36085 9.10528C8.36085 9.57208 8.07505 10.2865 7.50348 11.2486" stroke="#5A11FF" strokeWidth="0.8" strokeLinecap="round"/>
      <path d="M1.52637 8.73173C1.75424 8.63233 1.92553 8.55948 2.04024 8.51313C3.00201 8.12458 3.71566 8.03298 4.18114 8.23838C5.00426 8.6016 4.68781 9.33515 4.94564 9.6054C5.20344 9.87565 5.84724 9.79703 5.84724 10.3111C5.84724 10.6539 5.73211 11.0411 5.50186 11.4727" stroke="#5A11FF" strokeWidth="0.8" strokeLinecap="round"/>
    </svg>
  )
}

export default PublicIcon;
