import React from "react";
import { STORE_NAMES } from "const/global";
import { inject, observer } from "mobx-react";
import { ISessionViewerPageStore } from "stores/SessionViewerPageStore";
import { ViewerService } from "views/viewer/services/ViewerService";
import Viewer from "views/viewer/Viewer";
import styled from "styled-components";
import SessionUserViewerLocatorsContainer from "./SessionUserViewerLocatorsContainer";
import SessionFindingViewerLocatorsContainer from "./SessionFindingViewerLocatorsContainer";
import ViewerViewControls from "../../viewer/components/ViewerViewControls";
import SessionViewerToolsPanelContainer from "./SessionViewerToolsPanelContainer";
import SessionViewerStatusTopBarContainer from "./SessionViewerStatusTopBarContainer/SessionViewerStatusTopBarContainer";
import SessionStreamContainer from "./SessionStreamContainer/SessionStreamContainer";
import SessionMembersPanelContainer from "./SessionMembersPanelContainer";
import {
  AbsoluteBottomBox,
  AbsoluteBox,
  AbsoluteCenterBox,
  AbsoluteTopBox,
} from "../../shared/components/Box";
import ViewerObjectTree from "../../viewer/containers/ViewerObjectTree";
import { IAppContext } from "../../interfaces";
import ViewerCompanyIcon from "../../viewer/components/ViewerCompanyIcon";
import ViewerTexturePanel from "../../viewer/components/ViewerTexturePanel";
import ViewerMeasurementsUnitPanel from "../../viewer/components/ViewerMeasurementsUnitPanel";
import {
  ViewerBottomRow,
  ViewerTopRow,
} from "../../models/components/viewer-grid";
import sessionViewerUIHook from "../hook/sessionViewerBottomUIHook";
import { ISessionStreamStore } from "../../../stores/SessionStreamStore";
import useModelViewer from "../../models/containers/ModelViewer/hooks/useModelViewer";
import { IViewerStore } from "../../../stores/ViewerStore";

interface ISessionViewerPropTypes {
  SessionViewerPageStore?: ISessionViewerPageStore;
  SessionStreamStore?: ISessionStreamStore;
  ViewerStore?: IViewerStore;
}

const viewerService = new ViewerService();

const TopCenterBarWrapper = styled(AbsoluteCenterBox)``;
const ViewControlsWrapper = styled(AbsoluteBottomBox)`
  left: 0;
`;
const ToolsWrapper = styled(AbsoluteBox)`
  bottom: 0;
  left: calc(50% - 220px);
`;
const ObjectTreeWrapper = styled(AbsoluteBottomBox)`
  right: 0;
`;
const RightSidePanelWrapper = styled(AbsoluteBox)`
  right: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  height: 100%;
`;
const StreamingWrapper = styled(AbsoluteBox)``;
const TopLeftBarWrapper = styled(AbsoluteTopBox)`
  left: 0;
`;
const TopRightBarWrapper = styled(AbsoluteTopBox)`
  right: 0;
`;

const fullScreenHandler = () => {};

const SessionViewer = ({
  SessionViewerPageStore,
  SessionStreamStore,
  ViewerStore,
}: ISessionViewerPropTypes) => {
  const { sessionModel } = SessionViewerPageStore;
  const [state, handlers] = useModelViewer({
    ViewerStore,
    model: sessionModel,
  });
  const [controlStyles, streamStyles, rightSidePanelsStyles] =
    sessionViewerUIHook({
      streamsOpen: SessionStreamStore.streamPanelOpen,
    });
  const handleModelLoaded = () => {
    viewerService.addEventListener("locator_click", (data: any) => {
      SessionViewerPageStore.selectedMemberId = data.userId;
      SessionViewerPageStore.sessionMembersPanelOpen = true;
    });
    handlers.initialize();
  };

  return (
    <Viewer
      model={sessionModel}
      onLoad={handleModelLoaded}
      onMeasurementsSave={() => {}}
      modelLoadedContent={
        <>
          <ViewerTopRow>
            <TopLeftBarWrapper>
              <ViewerCompanyIcon />
            </TopLeftBarWrapper>
            <TopCenterBarWrapper>
              <SessionViewerStatusTopBarContainer />
            </TopCenterBarWrapper>
            <TopRightBarWrapper>
              <ViewerTexturePanel model={sessionModel} />
              <ViewerMeasurementsUnitPanel />
            </TopRightBarWrapper>
          </ViewerTopRow>
          <RightSidePanelWrapper
            style={rightSidePanelsStyles}
            className="right-side-panel"
          >
            <SessionMembersPanelContainer />
          </RightSidePanelWrapper>
          <ViewerBottomRow
            className="session-viewer-bottom-left"
            style={controlStyles}
          >
            <ViewControlsWrapper>
              <ViewerViewControls
                vertical
                fullScreen={false}
                onFullscreen={fullScreenHandler}
              />
            </ViewControlsWrapper>
            <ToolsWrapper>
              <SessionViewerToolsPanelContainer />
            </ToolsWrapper>
            <ObjectTreeWrapper>
              <ViewerObjectTree model={sessionModel} />
            </ObjectTreeWrapper>
          </ViewerBottomRow>
          <StreamingWrapper style={streamStyles}>
            <SessionStreamContainer />
          </StreamingWrapper>
          <SessionUserViewerLocatorsContainer />
          <SessionFindingViewerLocatorsContainer type="viewer" />
        </>
      }
    />
  );
};

export default inject(
  STORE_NAMES.SessionViewerPageStore,
  STORE_NAMES.SessionStreamStore,
  STORE_NAMES.ViewerStore
)(observer(SessionViewer));
