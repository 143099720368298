export default class Perspective {
  static add() {
    window.viewer?.measuringTool.startInsertion({
      showDistances: false,
      showAngles: false,
      showCoordinates: false,
      showLabel: false,
      showArea: false,
      closed: true,
      maxMarkers: 3,
      name: "Perspective",
    });
  }

  static alignToMeasurement(perspectiveMeasurement) {
    if (!perspectiveMeasurement) return;
    // TODO: Uncomment when feature is ready
    // window.viewer?.perspectiveAligner?.alignToMeasure(perspectiveMeasurement);
  }

  static alignFromRotation(rotationX, rotationY, rotationZ) {
    // window.viewer?.perspectiveAligner?.alignFromRotation(
    //   rotationX,
    //   rotationY,
    //   rotationZ
    // );
  }

  static getRotation() {
    return window.viewer?.perspectiveAligner.getRotation();
  }

  static isChanged() {
    // TODO: Uncomment when feature is ready
    // return window.viewer?.perspectiveAligner.perspectiveChanged();
  }

  static reset() {
    // TODO: Uncomment when feature is ready
    // return window.viewer?.perspectiveAligner.resetPerspective();
  }
}
