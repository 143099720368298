import React from 'react'
import moment from "moment";

interface IDatePropTypes {
  children: Date | string | number,
}

const DateComponent = ({
  children,
}: IDatePropTypes) => {
  return (
    <>
      {moment(children).format("LLL")}
    </>
  )
}

export default DateComponent;
