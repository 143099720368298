import styled from 'styled-components';
import { Tabs } from 'antd';
import { IAppContext } from "../../interfaces";

const { TabPane } = Tabs;

export const SkyeTab = styled(TabPane)``;
const SkyeTabs = styled(Tabs)`
  // Fixing issue with invisible tab content for safari
  &.ant-tabs .ant-tabs-bottom-content > .ant-tabs-tabpane,
  &.ant-tabs .ant-tabs-top-content > .ant-tabs-tabpane {
    -webkit-transition: none !important;
    transition: none !important;
  }

  &.ant-tabs .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
  }

  &.ant-tabs .ant-tabs-ink-bar {
    background: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
  }
`;

export default SkyeTabs;
