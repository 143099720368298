export default class LocationPoint {
  static add() {
    window.viewer?.measuringTool.startInsertion({
      showDistances: false,
      showAngles: false,
      showCoordinates: true,
      showLabel: true,
      showArea: false,
      closed: true,
      name: "CoordinatePoint",
      maxMarkers: 1,
    });
  }
}
