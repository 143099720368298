import { memo } from "react";
import PaidAccessOverlay from "../../components/PaidAccessOverlay";
import { useTranslation } from "react-i18next";

const SharedModelDetailsPagePaidAccessOverlay = () => {
  const { t } = useTranslation();

  return (
    <PaidAccessOverlay
      title={t("unlockModelTitle")}
      description={t("unlockModelDescription")}
      showPayPerModelButton={false}
    />
  );
};

export default memo(SharedModelDetailsPagePaidAccessOverlay);
