import React, { memo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { StatusLabel } from "../../../../shared/typography/typography";
import { BoxAlignedCenter } from "../../../../shared/components/Box";
import { IAppContext } from "../../../../interfaces";
import VideoOff from "../../../../shared/Icons/VideoOff";

interface IStreamNotAvailableInfoPropTypes {}

const Wrapper = styled(BoxAlignedCenter)``;
const Text = styled(StatusLabel)`
  padding-left: ${(p: IAppContext) => p.theme.skye.sizes.average};
`;

const StreamStatusInfo = ({}: IStreamNotAvailableInfoPropTypes) => {
  const { t } = useTranslation();

  return (
    <Wrapper className="stream-not-available-info">
      <VideoOff />
      <Text>{t("streamNotAvailable")}</Text>
    </Wrapper>
  );
};

export default memo(StreamStatusInfo);
