import React from 'react'
import styled from 'styled-components';

interface IEyeClosedIconPropTypes {
  
}

const EyeClosedIcon = ({
  
}: IEyeClosedIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.40269 10.6858C4.22915 10.471 3.91434 10.4375 3.69954 10.6111C3.48474 10.7846 3.45129 11.0994 3.62482 11.3142C4.30332 12.1541 5.16245 13.0669 6.21604 13.9236L3.56707 18.5118C3.429 18.7509 3.51094 19.0567 3.75008 19.1948C3.98923 19.3329 4.29503 19.2509 4.4331 19.0118L7.01748 14.5355C8.42484 15.5425 10.1291 16.4184 12.1556 16.9258L11.3485 21.5032C11.3005 21.7752 11.4821 22.0345 11.7541 22.0824C12.026 22.1304 12.2854 21.9488 12.3333 21.6769L13.1339 17.1366C14.0348 17.3 14.9941 17.3906 16.0138 17.3906C17.0293 17.3906 17.985 17.3007 18.8826 17.1386L19.683 21.678C19.731 21.9499 19.9903 22.1315 20.2623 22.0836C20.5342 22.0356 20.7158 21.7763 20.6678 21.5043L19.861 16.9285C21.8898 16.4222 23.5959 15.5465 25.0048 14.5393L27.5755 18.9919C27.7136 19.2311 28.0194 19.313 28.2586 19.1749C28.4977 19.0369 28.5796 18.7311 28.4416 18.4919L25.8065 13.9278C26.8624 13.0699 27.7232 12.1555 28.4028 11.3143C28.5763 11.0995 28.5429 10.7846 28.3281 10.6111C28.1133 10.4376 27.7985 10.471 27.6249 10.6858C26.9157 11.5637 26.005 12.5183 24.8746 13.391C24.859 13.4016 24.8443 13.413 24.8303 13.425C23.3426 14.5649 21.4777 15.5608 19.1944 16.0567C19.1849 16.0584 19.1755 16.0605 19.1663 16.0628C18.1924 16.2722 17.1427 16.3906 16.0138 16.3906C12.2193 16.3906 9.31843 15.0521 7.19042 13.4197C7.17757 13.4088 7.16405 13.3983 7.14989 13.3885C6.02091 12.5165 5.11126 11.5629 4.40269 10.6858Z" fill="#1A1A1A"/>
    </svg>
  )
}

export default EyeClosedIcon;
