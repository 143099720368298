const currentYear = new Date().getFullYear();

const LOGO_WIDTH = 78;
const LOGO_HEIGHT = 78;
const FOOTER_HEIGHT = 60;
const BORDER_SIZE = 5;
const INFORMATION_TEXT_FONT_SIZE = 16;
const CASE_NUMBER_FONT_SIZE = 20;
const INFORMATION_TEXT_FONT_TYPE = 'Arial';
const WATERMARK_SRC = '/img/watermark_logo_white.svg';
const FOOTER_PADDING = 10;
const WATERMARK_MADE_IN_TEXT = 'Made in USA';
const ALL_RIGHTS_RESERVED_TEXT = `SkyeBrowse ${currentYear} All Rights Reserved`;
const FOOTER_BACKGROUND_COLOR = '#ffffff';
const INFORMATION_TEXT_C0LOR = '#000000';
const IMAGE_BACKGROUND_COLOR = '#ffffff';
const CASE_NUMBER_LABEL = `Case number:`;

export class ImageService {
  static async createImage(src: string): Promise<any> {
    return new Promise((res, rej) => {
      const image = new Image();
      image.onerror = (error) => { rej(error) };
      image.onload = () => { res(image) };
      image.src = src;
    });
  }

  static async createWatermark(base64Image: any, strMime = 'image/jpg') {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    const img = await this.createImage(base64Image);
    const logoWatermark = await this.createImage(WATERMARK_SRC);
    const canvasHeight = img.height + FOOTER_HEIGHT + BORDER_SIZE;
    const canvasWidth = img.width + (2 * BORDER_SIZE);

    logoWatermark.width = String(LOGO_WIDTH);
    logoWatermark.height = String(LOGO_HEIGHT);  
    canvas.height = canvasHeight;
    canvas.width = canvasWidth;

    if (context) {
      const watermarkXPosition = canvasWidth - (logoWatermark.width + FOOTER_PADDING + (2 * BORDER_SIZE));
      const watermarkYPosition = canvasHeight - FOOTER_HEIGHT - FOOTER_PADDING - LOGO_HEIGHT - BORDER_SIZE;
      // Filling image backgrround
      context.fillStyle = IMAGE_BACKGROUND_COLOR;
      context.fillRect(0, 0, canvasWidth, canvasHeight);
      context.drawImage(img, BORDER_SIZE, BORDER_SIZE, img.width, img.height);
      // Footer rectangle
      context.fillStyle = FOOTER_BACKGROUND_COLOR;
      context.fillRect(0, canvasHeight - FOOTER_HEIGHT, canvasWidth, FOOTER_HEIGHT);
      // Draw company logo
      context.drawImage(logoWatermark, watermarkXPosition, watermarkYPosition, logoWatermark.width, logoWatermark.height);
      // Draw information text
      context.font = `${INFORMATION_TEXT_FONT_SIZE}px ${INFORMATION_TEXT_FONT_TYPE}`;
      context.fillStyle = INFORMATION_TEXT_C0LOR;
      context.textAlign = 'center';
      // Draw all rights reserver text
      context.fillText(
        ALL_RIGHTS_RESERVED_TEXT,
        canvasWidth / 2,
        canvasHeight - (FOOTER_PADDING / 2) - INFORMATION_TEXT_FONT_SIZE,
      )
      context.font = `${CASE_NUMBER_FONT_SIZE}px ${INFORMATION_TEXT_FONT_TYPE}`;
      context.textAlign = 'right';
      // Draw made in USA text
      context.fillText(
        WATERMARK_MADE_IN_TEXT,
        canvasWidth - FOOTER_PADDING - BORDER_SIZE,
        canvasHeight - (FOOTER_HEIGHT / 2) + FOOTER_PADDING
      );
      const watermarkedScreenshot = canvas.toDataURL(strMime).replace('image/png', strMime);
      return watermarkedScreenshot;
    }
    return img;
  }

  static async addReportNumber(base64Image: string, reportNumber, strMime = 'image/png') {
    var canvas = document.createElement('canvas');
    var context = canvas.getContext('2d');
    const img = await this.createImage(base64Image);
    const txt = `${CASE_NUMBER_LABEL} ${reportNumber}`;
    const footerCenterYPosition = img.height - (FOOTER_HEIGHT / 2) + FOOTER_PADDING;

    if (context) {
      canvas.width = img.width;
      canvas.height = img.height;
      context.drawImage(img, 0, 0, img.width, img.height);
      context.font = `${CASE_NUMBER_FONT_SIZE}px ${INFORMATION_TEXT_FONT_TYPE}`;
      context.fillStyle = INFORMATION_TEXT_C0LOR;
      context.textAlign = 'left';
      context.fillText(txt, FOOTER_PADDING + BORDER_SIZE, footerCenterYPosition);

      const watermarkedScreenshot = canvas.toDataURL(strMime);
      return watermarkedScreenshot;
    }
    return base64Image;
  }
}