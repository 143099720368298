import { IPublicModel, ModelHelpers } from "../../../../../models/Model";
import { IViewerStore, TexturesMode } from "../../../../../stores/ViewerStore";
import {
  ViewerEventName,
  useViewerEventEmitter,
} from "../../../../viewer/context/ViewerEventEmitter";
import { ViewerService } from "../../../../viewer/services/ViewerService";

interface useModelViewerPropTypes {
  ViewerStore: IViewerStore;
}

interface useModelViewerHandlers {
  loadTextures: (model: IPublicModel, onLoad: () => void) => void;
  setLastTexturesMode: (textureMode: TexturesMode) => void;
  hideTextures: () => void;
  showTextures: () => void;
}

type IUseTextureManager = (
  props: useModelViewerPropTypes
) => [useModelViewerHandlers];

const useTextureManager: IUseTextureManager = ({ ViewerStore }) => {
  const eventEmitter = useViewerEventEmitter();
  const vs = new ViewerService();

  const loadTextures = async (model, onLoad: () => void) => {
    const chunks = ModelHelpers.getTextureModelChunks(model);

    for await (const [index, { mapUrl, objUrl, mtlUrl }] of chunks.entries()) {
      ViewerStore.setTexturesLoading(true);

      try {
        await vs.loadTextures(mtlUrl, objUrl, mapUrl);
        vs.setLowPointBudget();
        vs.disablePointCloudVisibility();
        vs.toggleTextures(true);
        setLastTexturesMode(TexturesMode.TEXTURES);

        // Hidding low quality textures when better quality available
        if (index > 0 && vs.getTextures().length >= 2) vs.removeFirstTexture();

        eventEmitter.trigger(ViewerEventName.TexturesLoaded, true);
        ViewerStore.setTexturesLoaded(true);
        onLoad();
      } catch (error) {
        console.error(error);
      }
      ViewerStore.setTexturesLoading(false);
    }
  };

  /**
   * Method used to save the last texture mode when using tools and want a persistent
   * texture visibility model choosen by user
   */
  const setLastTexturesMode = (textureMode: TexturesMode) => {
    ViewerStore.lastTexturesMode = textureMode;
  };

  const hideTextures = () => {
    if (!ViewerStore.texturesVisible) {
      return;
    }
    ViewerStore.setTexturesVisible(false);
    vs.setMediumPointBudget();
    vs.enablePointCloudVisibility();
    vs.toggleTextures(false);
  };

  const showTextures = () => {
    if (
      !ViewerStore.viewerInitialized ||
      !ViewerStore.texturesLoaded ||
      ViewerStore.texturesVisible
    ) {
      return;
    }

    ViewerStore.setTexturesVisible(true);
    vs.setLowPointBudget();
    vs.disablePointCloudVisibility();
    vs.removeAllClipVolumes();
    vs.closeProfileWindow();
    vs.toggleTextures(true);
  };

  const handlers: useModelViewerHandlers = {
    loadTextures,
    hideTextures,
    showTextures,
    setLastTexturesMode,
  };

  return [handlers];
};

export default useTextureManager;
