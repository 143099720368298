import React, { memo } from "react";

const VideoOff = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_2877_8875)">
        <path
          d="M8.88341 4.99999H11.6667C12.1088 4.99999 12.5327 5.17558 12.8453 5.48814C13.1578 5.80071 13.3334 6.22463 13.3334 6.66666V8.61666L14.1667 9.44999L18.3334 6.66666V13.3333M1.66675 1.66666L18.3334 18.3333M13.3334 13.3333C13.3334 13.7753 13.1578 14.1993 12.8453 14.5118C12.5327 14.8244 12.1088 15 11.6667 15H3.33341C2.89139 15 2.46746 14.8244 2.1549 14.5118C1.84234 14.1993 1.66675 13.7753 1.66675 13.3333V6.66666C1.66675 6.22463 1.84234 5.80071 2.1549 5.48814C2.46746 5.17558 2.89139 4.99999 3.33341 4.99999H5.00008L13.3334 13.3333Z"
          stroke="#9E9E9E"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_2877_8875">
          <rect width="20" height="20" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(VideoOff);
