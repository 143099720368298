import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import { AbsoluteBox } from "../../shared/components/Box";

export const ViewerRow = styled(AbsoluteBox)`
  width: calc(100% - 40px);
  left: 20px;
`;
export const ViewerBottomRow = styled(ViewerRow)`
  bottom: 20px;
`;
export const ViewerTopRow = styled(ViewerRow)`
  top: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;
