import React, { ReactNode, useEffect } from "react";
import classNames from "classnames";
import styled from "styled-components";
import Box, { BoxAlignedCenter, BoxCentered } from "../Box";
import { IAppContext } from "views/interfaces";
import ProgressLoader from "../ProgressLoader";
import Label from "../../typography/Label";
import CancelIcon from "../../Icons/CancelIcon";
import RefreshIcon from "../../Icons/RefreshIcon";
import { trimStringIfToLong } from "utils/stringUtils";
import ApprovedIcon from "../../Icons/ApprovedIcon";
import CircleLoader from "../CircleLoader";

export enum UploadStatus {
  InProgress = "inprogress",
  Failed = "failed",
  Uploaded = "uploaded",
  Pending = "pending",
}

export interface IUploadProgress {
  id: string;
  name: string;
  status: UploadStatus;
  progress: number; // 0 - 100
}

interface IUploadIndicatorToastPropTypes {
  upload?: IUploadProgress;
  onCancel?: (id: string) => void;
  onDelete?: (id: string) => void;
  onRefresh?: (id: string) => void;
  customIcons?: ReactNode;
  hideCancelButton?: boolean;
  hideRefreshButton?: boolean;
  hideSuccessButton?: boolean;
}

const TIME_BEFORE_OUT_ANIMATION = 3000;
const OUT_ANIMATION_TIME = 1000;

const ContentWrapper = styled(BoxAlignedCenter)`
  margin-bottom: 5px;
  min-height: 22px;
  justify-content: space-between;
`;
const StyledLabel = styled(Label)`
  margin-bottom: 0;
`;
const ButtonsWrapper = styled(Box)`
  & > * {
    cursor: pointer;
  }
`;

const Wrapper = styled(Box)`
  padding: ${(p: IAppContext) => p.theme.skye.sizes.small};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  box-shadow: ${(p: IAppContext) => p.theme.skye.shadows.primary};
  width: 250px;
  height: 50px;

  &.failed {
    .label {
      color: ${(p: IAppContext) => p.theme.skye.colors.danger};
    }

    .progress-content {
      background-color: ${(p: IAppContext) => p.theme.skye.colors.danger};
    }
  }
`;

const IconWrapper = styled(BoxCentered)`
  width: 20px;
  height: 20px;
`;

const UploadIndicatorToast = ({
  upload,
  customIcons,
  onCancel = () => {},
  onDelete = () => {},
  onRefresh = () => {},
  hideCancelButton,
  hideRefreshButton,
  hideSuccessButton,
}: IUploadIndicatorToastPropTypes) => {
  const classes = classNames([
    "upload-indicator-toast",
    { failed: upload.status === UploadStatus.Failed },
  ]);
  const showCancelButton =
    (!hideCancelButton && upload.status === UploadStatus.InProgress) ||
    upload.status === UploadStatus.Failed;

  useEffect(() => {
    if (
      upload?.status === UploadStatus.Failed ||
      upload?.status === UploadStatus.Uploaded
    ) {
      setTimeout(() => {
        setTimeout(() => {
          onDelete(upload.id);
        }, OUT_ANIMATION_TIME);
      }, TIME_BEFORE_OUT_ANIMATION);
    }
  }, [upload.status]);

  return (
    <Wrapper flexDirection="column" className={classes}>
      <ContentWrapper>
        <Box alignItems="center" justifyContent="flex-start">
          {upload.status === UploadStatus.InProgress && (
            <CircleLoader color="primary" />
          )}
          <StyledLabel>{trimStringIfToLong(upload.name, 18)}</StyledLabel>
        </Box>
        <ButtonsWrapper>
          {!!customIcons && customIcons}
          {!hideRefreshButton && (
            <IconWrapper onClick={() => onRefresh(upload.id)}>
              <RefreshIcon />
            </IconWrapper>
          )}
          {showCancelButton && (
            <IconWrapper onClick={() => onCancel(upload.id)}>
              <CancelIcon />
            </IconWrapper>
          )}
          {!hideSuccessButton && upload.status === UploadStatus.Uploaded && (
            <IconWrapper onClick={() => onCancel(upload.id)}>
              <ApprovedIcon />
            </IconWrapper>
          )}
        </ButtonsWrapper>
      </ContentWrapper>
      <ProgressLoader percent={upload.progress} />
    </Wrapper>
  );
};

export default UploadIndicatorToast;
