import React, { ReactNode } from "react";
import styled from "styled-components";
import { IAppContext } from "../../../../views/interfaces";
import Button, {
  IButtonPropTypes,
} from "../../../../views/shared/buttons/Button";
import Title from "../../../../views/shared/typography/Title";
import Description from "../../../../views/shared/typography/Description";
import { useTranslation } from "react-i18next";
import CloseIcon from "../../../../views/shared/Icons/CloseIcon";
import Box from "../../../../views/shared/components/Box";
import Modal, { IModalSize } from "./Modal";
import styles from "./Dialog.module.css";
import classNames from "classnames";

export interface IDialogRequiredPropTypes {
  title: string | ReactNode;
  open: boolean;
  onClose: () => void;
}

export interface IDialogPropTypes extends IDialogRequiredPropTypes {
  description?: string;
  children?: ReactNode;
  confirmButtonProps?: IButtonPropTypes;
  cancelButtonProps?: IButtonPropTypes;
  hideConfirmButton?: boolean;
  hideCancelButton?: boolean;
  size?: IModalSize;
}

const ContentWrapper = styled.div`
  width: 100%;

  .cancel-btn {
    margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
  }

  .input-group {
    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const Dialog = ({
  title,
  description,
  children,
  open,
  onClose,
  confirmButtonProps,
  cancelButtonProps,
  hideCancelButton,
  hideConfirmButton,
  size = "small",
}: IDialogPropTypes) => {
  const { t } = useTranslation();

  return (
    <Modal size={size} open={open}>
      <ContentWrapper className="dialog-content">
        <div className={styles["close-btn-wrapper"]} onClick={onClose}>
          <CloseIcon />
        </div>
        <div
          className={classNames([styles.dialogRow, styles["dialog-header"]])}
        >
          <Title className={styles["dialog-header-title"]}>{title}</Title>
          {!!description && <Description>{description}</Description>}
        </div>
        <div
          className={classNames([styles.dialogRow, styles["dialog-content"]])}
        >
          {children}
        </div>
        {!(hideConfirmButton && hideCancelButton) && (
          <div
            className={classNames([styles.dialogRow, styles["dialog-footer"]])}
          >
            {!hideConfirmButton && (
              <Button className="mr-3" {...confirmButtonProps}>
                {confirmButtonProps?.children || t("confirm")}
              </Button>
            )}
            {!hideCancelButton && (
              <Button
                type="transparent"
                onClick={onClose}
                className="cancel-btn"
                {...cancelButtonProps}
              >
                {cancelButtonProps?.children || t("cancel")}
              </Button>
            )}
          </div>
        )}
      </ContentWrapper>
    </Modal>
  );
};

export default Dialog;
