const getLocalFileUrl = (file: File | undefined): string => {
  if (file && window.URL && window.URL.createObjectURL) {
    return window.URL.createObjectURL(file)
  }
  return '';
}

const getBlobFromObjectUrl = async (url: string) => {
  const blob = await fetch(url).then(r => r.blob());
  return blob;
}

export const blobToFile = (theBlob: Blob, fileName:string): File => {
  var b: any = theBlob;
  //A Blob() is almost a File() - it's just missing the two properties below which we will add
  b.lastModifiedDate = new Date();
  b.name = fileName;

  //Cast to a File() type
  return <File>theBlob;
}

const getFileFromObjectUrl = async (url: string, fileName: string) => {
  const blob = await getBlobFromObjectUrl(url);
  const file = new File([blob], fileName);
  return file;
}

const getExtensionFromFileType = (fileType: string) => {
  const mimes = fileType.split('/');
  return mimes[1] ? mimes[1] : '';
}

const convertBlobToBase64 = (blob: Blob): Promise<string> => new Promise((resolve, reject) => {
  const reader = new FileReader();
  reader.onerror = reject;
  reader.onload = () => {
    if (typeof reader.result === 'string') {
      return resolve(reader.result);
    }
    reject('');
  };
  reader.readAsDataURL(blob);
});

const humanFileSize = (bytes: number, si = true): string => {
  const thresh = si ? 1000 : 1024;
  if(Math.abs(bytes) < thresh) {
      return bytes + ' B';
  }
  const units = si
      ? ['KB','MB','GB','TB','PB','EB','ZB','YB']
      : ['KiB','MiB','GiB','TiB','PiB','EiB','ZiB','YiB'];
  let u = -1;
  do {
      bytes /= thresh;
      ++u;
  } while(Math.abs(bytes) >= thresh && u < units.length - 1);
  return bytes.toFixed(1)+' '+units[u];
}

const validateExtension = (file: File, fileTypes: string[]) => {
  return !!fileTypes.find(
    (type) => type === file.type || file.name.endsWith(type)
  );
}

const fileUtils = {
  getLocalFileUrl,
  getBlobFromObjectUrl,
  getFileFromObjectUrl,
  getExtensionFromFileType,
  humanFileSize,
  convertBlobToBase64,
  validateExtension,
};

export default fileUtils;
