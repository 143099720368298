import React from "react";
import AuthorizedDesktopNavbarContent from "./content";
import NavbarBase from "../../components/NavbarBase";
import NavbarUserDetailsDropdown from "../../components/NavbarUserDetailsDropdown";
import UploadLimitInfo from "./upload-limit-info";

interface DesktopAuthorizedNavbarProps {}

const DesktopAuthorizedNavbar = ({}: DesktopAuthorizedNavbarProps) => {
  return (
    <div className="desktop-authorized-navbar">
      <NavbarBase
        rightSide={
          <div className="flex items-center gap-x-2">
            <UploadLimitInfo />
            <NavbarUserDetailsDropdown />
          </div>
        }
      >
        <AuthorizedDesktopNavbarContent />
      </NavbarBase>
    </div>
  );
};

export default DesktopAuthorizedNavbar;
