import React from 'react'

interface IHandPointingIconPropTypes {
  
}

const HandPointingIcon = ({
  
}: IHandPointingIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0003 13V4.5C16.0003 3.83696 15.7369 3.20107 15.2681 2.73223C14.7992 2.26339 14.1634 2 13.5003 2C12.8373 2 12.2014 2.26339 11.7325 2.73223C11.2637 3.20107 11.0003 3.83696 11.0003 4.5V19.6583L8.25937 14.9108C8.09537 14.6262 7.87688 14.3767 7.61641 14.1766C7.35593 13.9764 7.05857 13.8296 6.74132 13.7445C6.42407 13.6593 6.09314 13.6375 5.76746 13.6803C5.44178 13.7231 5.12773 13.8297 4.84326 13.9939C4.55879 14.1582 4.30947 14.3769 4.10956 14.6375C3.90965 14.8982 3.76306 15.1956 3.67818 15.513C3.59329 15.8303 3.57178 16.1612 3.61485 16.4869C3.65793 16.8125 3.76477 17.1265 3.92924 17.4108C8.00031 26 10.4775 29 16.0003 29C17.3135 29 18.6139 28.7413 19.8271 28.2388C21.0404 27.7362 22.1428 26.9997 23.0714 26.0711C24 25.1425 24.7366 24.0401 25.2391 22.8268C25.7417 21.6136 26.0003 20.3132 26.0003 19V14C26.0003 13.337 25.7369 12.7011 25.2681 12.2322C24.7992 11.7634 24.1634 11.5 23.5003 11.5C22.8373 11.5 22.2014 11.7634 21.7325 12.2322C21.2637 12.7011 21.0003 13.337 21.0003 14" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.0003 14L20.9999 12.5C20.9999 11.837 20.7366 11.2011 20.2677 10.7322C19.7989 10.2634 19.163 10 18.4999 10C17.8369 10 17.201 10.2634 16.7322 10.7322C16.2633 11.2011 15.9999 11.837 15.9999 12.5L16.0003 13" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default HandPointingIcon;
