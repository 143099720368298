import {
  GPS_LOGS_FILE_EXTENSIONS,
  MODEL_VIDEO_FILE_EXTENSIONS,
  UPLOAD_SIZE_PER_SUBSCRIPTION,
} from "../../../../../const/global";
import { SubscriptionType } from "../../../../payments/payments.model";
import fileUtils from "../../../../shared/utils/file";

interface IUploadValidationResult {
  videoFiles: File[];
  logsFiles: File[];
  unknownFiles: File[];
  sizeExceededFiles: File[];
}

export const ModelUploadFileValidator = (
  files: File[],
  subscriptionType: SubscriptionType
): IUploadValidationResult => {
  const validVideoFiles = [];
  const validLogsFiles = [];
  const unknownFiles = [];
  const sizeExceededFiles = [];

  const videoFileValidation = (file: File) => {
    const maxUploadSize = UPLOAD_SIZE_PER_SUBSCRIPTION[subscriptionType];

    if (file.size > maxUploadSize) {
      sizeExceededFiles.push(file);
      return;
    }

    validVideoFiles.push(file);
  };

  files.forEach((file) => {
    if (fileUtils.validateExtension(file, MODEL_VIDEO_FILE_EXTENSIONS)) {
      videoFileValidation(file);
    } else if (fileUtils.validateExtension(file, GPS_LOGS_FILE_EXTENSIONS)) {
      validLogsFiles.push(file);
    } else {
      unknownFiles.push(file);
    }
  });

  return {
    videoFiles: validVideoFiles,
    logsFiles: validLogsFiles,
    unknownFiles,
    sizeExceededFiles,
  };
};
