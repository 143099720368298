import { useTranslation } from "react-i18next";
import { ModelStatus } from "../../../../../../../../models/Model";
import AccessBadge from "../../../../../../../shared/components/badges/AccessBadge/AccessBadge";
import ArchiveIcon from "../../../../../../../shared/Icons/ArchivedIcon";

interface IModelStatusBadgePropTypes {
  status: ModelStatus;
}

const ModelStatusBadge = ({ status }: IModelStatusBadgePropTypes) => {
  const { t } = useTranslation();

  switch (status) {
    case ModelStatus.RestoringArchive:
      return (
        <AccessBadge color="danger" startIcon={<ArchiveIcon />}>
          {t("status.restoringArchive")}
        </AccessBadge>
      );
    case ModelStatus.Archiving:
      return (
        <AccessBadge color="danger" startIcon={<ArchiveIcon />}>
          {t("status.archiving")}
        </AccessBadge>
      );
    default:
      return null;
  }
};

export default ModelStatusBadge;
