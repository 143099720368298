import classNames from "classnames";
import Button from "../../../shared/buttons/Button";
import { SubscriptionPeriod, SubscriptionType } from "../../payments.model";
import LiteSubscriptionPaymentOption from "../ModelPaymentDialog/components/LiteSubscriptionPaymentOption";
import PaymentOption from "../PaymentOption/PaymentOption";
import styles from "./index.module.css";
import { PremiumStarIcon } from "../../../shared/Icons/PremiumStarIcon";
import { PREMIUM_PRICING_QUOTE_SUVERY_URL } from "../PricingTable/PricingTable.const";

interface ISimplifiedPricingPropTypes {
  subscriptionPeriod: SubscriptionPeriod;
  handleBuySubscription: (type: SubscriptionType) => void;
  onSelectSubscriptionPeriod: (period: SubscriptionPeriod) => void;
}

const SimplifiedPricing = ({
  subscriptionPeriod,
  handleBuySubscription,
  onSelectSubscriptionPeriod,
}: ISimplifiedPricingPropTypes) => {
  const redirectToPremiumQoteSurvey = () => {
    window.location.href = PREMIUM_PRICING_QUOTE_SUVERY_URL;
  };

  const handleSubPeriodChange = (newSubPeriod: SubscriptionPeriod) => {
    onSelectSubscriptionPeriod(newSubPeriod);
  };

  return (
    <div className="simplified-pricing w-full max-w-3xl block md:grid grid-cols-2 gap-4">
      <div className={classNames([styles.boxLarge, styles.boxFeatured])}>
        <LiteSubscriptionPaymentOption
          subscriptionPeriod={subscriptionPeriod}
          onBuy={() => handleBuySubscription(SubscriptionType.Lite)}
          onPeriodChange={handleSubPeriodChange}
        ></LiteSubscriptionPaymentOption>
      </div>
      <div className={styles.boxLarge}>
        <PaymentOption
          priceLabel="Premium"
          description="Designed for organizations that require the highest level of clarity, feature sets, and security."
          buttonsPlacement={
            <div className="w-full block">
              <Button
                type="bordered"
                color="dark"
                fullSize
                onClick={redirectToPremiumQoteSurvey}
              >
                Request Quote
              </Button>
            </div>
          }
          icon={
            <div className="w-12 h-12 rounded-md bg-primary-500 flex justify-center items-center p-3">
              <PremiumStarIcon />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default SimplifiedPricing;
