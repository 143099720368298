import styled from "styled-components";

interface IBoxPropTypes extends HTMLDivElement {
  display?: string;
  alignItems?: string;
  flexWrap?: string;
  justifyContent?: string;
  flexDirection?: string;
}

const Box = styled.div<any>`
  box-sizing: border-box;
  display: ${(p: IBoxPropTypes) => (p.display ? p.display : "flex")};
  ${(p: IBoxPropTypes) => (p.alignItems ? `align-items: ${p.alignItems}` : "")}
  ${(p: IBoxPropTypes) => (p.flexWrap ? `flex-wrap: ${p.flexWrap}` : "")}
  ${(p: IBoxPropTypes) =>
    p.justifyContent ? `justify-content: ${p.justifyContent}` : ""}
  ${(p: IBoxPropTypes) =>
    p.flexDirection ? `flex-direction: ${p.flexDirection}` : ""}
`;

export const BoxAlignedCenter = styled(Box)`
  align-items: center;
`;

export const BoxCentered = styled(Box)`
  align-items: center;
  justify-content: center;
`;

export const AbsoluteBox = styled(Box)`
  position: absolute;
`;

export const AbsoluteBottomBox = styled(AbsoluteBox)`
  bottom: 0;
`;

export const AbsoluteTopBox = styled(AbsoluteBox)`
  top: 0;
`;
export const AbsoluteCenterBox = styled(AbsoluteBox)`
  left: 50%;
  transform: translateX(-50%);
`;

export default Box;
