import React, { DOMAttributes } from 'react'
import styled from "styled-components";
import classNames from 'classnames';

interface ICollapseIcon extends DOMAttributes<HTMLDivElement> {
  open?: boolean;
}

const StyledIcon = styled.div`
  transform: rotate(0deg);

  &.open {
    transform: rotate(180deg);
  }
`;

const CollapseIcon = ({
  open,
  ...otherProps
}: ICollapseIcon) => {
  return (
    <StyledIcon className={classNames([{ open }])} {...otherProps}>
      <svg
        width="32"
        height="32"
        viewBox="0 0 32 32"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M8 12L16 20L24 12"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </StyledIcon>
  );
}

export default CollapseIcon;
