import React from 'react'
import styled from 'styled-components';
import classNames from "classnames";
import { Pagination } from "antd";
import { IAppContext } from "../../interfaces";
import { useTranslation } from "react-i18next";
import useBreakpoint from "../hook/useBreakpointHook";

interface ISkyePaginationPropTypes {
  loading: boolean;
  total: number;
  currentPage: number;
  onChange: (page: number) => void;
}

const PaginationContainer = styled.div`
  position: relative;
  width: 100%;
  text-align: center;
  margin-bottom: 24px;

  &.loading {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.6;
  }

  .ant-pagination {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-item {
    background: transparent;
    font-weight: 300;
    border-radius: 0;
    border: none;
    border-bottom: 2px solid transparent;

    &:hover {
      transition: none;
      border-bottom: 2px solid
        ${(p: IAppContext) => p.theme.skye.colors.electricViolet};

      a {
        border: none;
      }
    }
  }
  .ant-pagination-item:focus,
  .ant-pagination-item-active {
    background-color: transparent;
    color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
    border-bottom: 2px solid
      ${(p: IAppContext) => p.theme.skye.colors.electricViolet};

    a {
      color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
      font-weight: 500;
    }
  }

  .pagination-container .ant-pagination-item:hover {
    color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
  }

  .ant-pagination-item:focus a,
  .ant-pagination-item:hover a {
    color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
  }

  a.ant-pagination-item-link {
    border-radius: 50%;
    background-color: #dadada;
    color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
    a {
      color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
    }

    &:hover {
      border-color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
      color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};

      a {
        color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
      }
    }
  }

  .ant-pagination-jump-next,
  .ant-pagination-jump-prev {
    color: red;

    .ant-pagination-item-link {
      background-color: transparent;
      font-size: 12px;
    }
  }

  li {
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 22px;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      min-width: 30px;
      min-height: 30px;
    }
  }
`;

const PaginationEndElement = styled.a`
  display: block;
  margin: 0 36px;
`;

const SkyePagination = ({
  loading,
  total,
  currentPage,
  onChange,
}: ISkyePaginationPropTypes) => {
  const { t } = useTranslation();
  const { isDesktop } = useBreakpoint();

  const itemRender = (current, type, originalElement) => {
    if (!isDesktop() && (type === "prev" || type === "next")) {
      return null;
    }

    if (type === "prev") {
      return (
        <PaginationEndElement className="pagination-end-element">
          {t("previous")}
        </PaginationEndElement>
      );
    }

    if (type === "next") {
      return (
        <PaginationEndElement className="pagination-end-element">
          {t("next")}
        </PaginationEndElement>
      );
    }

    return originalElement;
  };

  return (
    <PaginationContainer
      className={classNames(["pagination-container", { loading }])}
    >
      <Pagination
        itemRender={itemRender}
        defaultPageSize={10}
        current={currentPage}
        total={total}
        onChange={onChange}
        showLessItems
        showSizeChanger={false}
      />
    </PaginationContainer>
  );
};

export default SkyePagination;
