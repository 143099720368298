import React from "react";
import Component from "../component";
import NavbarMobileUserDetails from "../user-details";

interface MobileAuthorizedNavbarProps {}

const MobileAuthorizedNavbar = ({}: MobileAuthorizedNavbarProps) => {
  return <Component showButtons topSection={<NavbarMobileUserDetails />} />;
};

export default MobileAuthorizedNavbar;
