import React from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";
import Box, { BoxAlignedCenter } from './Box';
import moment from 'moment';
import UserAvatar from './UserAvatar';
import UserName from '../typography/UserName';

interface ICommentPropTypes {
  userName: string,
  text: string,
  createdAt: Date | string,
}

const CommentBox = styled(Box)`
  align-items: flex-start;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;
const CreatedDateLabel = styled.h6`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
`;
const CommentText = styled.p`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 140%;
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
  margin-bottom: 6px;
`;
const AvatarColumn = styled(Box)`
  min-width: 44px;
`;
const ContentColumn = styled(Box)`
  flex-direction: column;
`;
const TopRow = styled(BoxAlignedCenter)`
  min-height: 32px;
`;
const CommentUserName = styled(UserName)`
  font-weight: 600;
`;


const CommentComponent = ({
  userName,
  createdAt,
  text,
}: ICommentPropTypes) => {
  return (
    <CommentBox>
      <AvatarColumn>
        <UserAvatar userName={userName} />
      </AvatarColumn>
      <ContentColumn>
        <TopRow>
          <CommentUserName>{userName}</CommentUserName>
        </TopRow>
        <CommentText>{text}</CommentText>
        <CreatedDateLabel>{moment(createdAt).fromNow()}</CreatedDateLabel>
      </ContentColumn>
    </CommentBox>
  )
}

export default CommentComponent;
