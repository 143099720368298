// When adding new platform, remember to add translation to dictionary
export enum ModelIssueType {
  ProcessingStuck = "processing_stuck",
  ModelNotVisible = "model_not_visible",
  Other = "other",
}

// When adding new platform, remember to add translation to dictionary
export enum Platform {
  IOS = "ios",
  Android = "android",
  Web = "web",
  Unknown = "unknown",
}

export interface IModelIssueReq extends IModelIssueForm {
  userAgent?: string;
}

export interface IModelIssueForm {
  description: string;
  reason: ModelIssueType;
  platform: Platform;
  shareWithSupport: boolean;
}
