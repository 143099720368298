import {
  AnalyticEventCategory,
  AnalyticService,
} from "../../../../services/AnalyticService";
import { IPotreeMeasurement, MeasurementTypesNames } from "../../interfaces";
import BaseMeasure from "./BaseMeasure";

export default class TextLabel {
  static isTextLabel(measurement: any): boolean {
    return (
      measurement &&
      measurement.name === MeasurementTypesNames.Point &&
      measurement.points.length === 1
    );
  }

  static add(annotationName) {
    window.viewer?.measuringTool.startInsertion({
      showDistances: false,
      showAngles: false,
      showCoordinates: false,
      showLabel: true,
      showArea: false,
      closed: true,
      maxMarkers: 1,
      name: "Point",
      annotation: annotationName,
    });
    AnalyticService.event(
      AnalyticEventCategory.ViewerMeasurements,
      `measurement_nav_annotation`
    );
  }

  static getAnnotations(): IPotreeMeasurement[] {
    return BaseMeasure.getAll().filter(this.isTextLabel);
  }
}
