import React, { ReactNode, useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import { IGlobalStore } from "../../../stores/GlobalStore";
import { RouteComponentProps, withRouter } from "react-router";
import { REDIRECT_URL_KEY } from "../../../utils/windowUtils";
import { AUTH_ROUTES } from "../../routes";
import AuthService from "../../../services/AuthService";
import useCurrentUser from "../hooks/useCurrentUser";

interface IAuthGuardPropTypes extends RouteComponentProps {
  children: ReactNode;
  GlobalStore?: IGlobalStore;
}

let tokenCheckInterval = null;

const AuthGuard = ({
  GlobalStore,
  children,
  history,
  location,
}: IAuthGuardPropTypes) => {
  const [loading, setLoading] = useState(false);
  const messagesSubscription = useRef(null);
  const currentUser = useCurrentUser();

  const moveToLogin = () => {
    history.push({
      pathname: AUTH_ROUTES.login,
      search: `?${REDIRECT_URL_KEY}=${location.pathname}`,
    });
  };

  const loadProfile = async () => {
    if (!AuthService.hasToken()) return moveToLogin();
    if (loading) return;

    setLoading(true);
    let user = AuthService.getStoredUser() || (await GlobalStore.getUser());
    GlobalStore.setUser(user);
    currentUser.setUser(user);
    setLoading(false);

    if (!user) moveToLogin();
  };

  const checkToken = () => {
    if (tokenCheckInterval) {
      clearTimeout(tokenCheckInterval);
    }

    if (!AuthService.hasToken()) {
      AuthService.signOut();
      currentUser.logout();
      moveToLogin();
    }

    tokenCheckInterval = setTimeout(() => {
      checkToken();
    }, 1000);
  };

  const initialize = () => {
    loadProfile();
    checkToken();

    return () => {
      if (tokenCheckInterval) {
        clearTimeout(tokenCheckInterval);
      }

      if (messagesSubscription.current) {
        messagesSubscription.current.unsubscribe();
      }
    };
  };

  useEffect(initialize, [initialize]);

  useEffect(() => {}, [GlobalStore.user]);

  return <>{!!GlobalStore.user && !!currentUser.user && children}</>;
};

export default inject("GlobalStore")(withRouter(observer(AuthGuard)));
