import React from "react";
import styled from "styled-components";
import { ModelHelpers } from "models/Model";
import {
  EmailShareButton,
  FacebookShareButton,
  TwitterShareButton,
} from "react-share";
import { IAppContext } from "../../interfaces";
import Label from "../../shared/typography/Label";
import Box from "../../shared/components/Box";
import {
  AnalyticEventCategory,
  AnalyticService,
} from "services/AnalyticService";
import { useTranslation } from "react-i18next";
import FacebookIcon from "../../shared/Icons/FacebookIcon";
import TwitterIcon from "../../shared/Icons/TwitterIcon";
import LinkedInIcon from "../../shared/Icons/LinkedInIcon";
import EmailIcon from "../../shared/Icons/EmailIcon";

interface ISocialIconsPropTypes {
  modelPublicKey: string;
  modelName: string;
}

const SocialMediaIconsWrapper = styled<any>(Box)`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.small};
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};

  & > button {
    margin-right: 5px;
  }
`;

const LinkedInShareButton = styled.a`
  margin-right: 5px;
`;

const IconButtonCircle = styled<any>(Box)`
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin: 0 6px;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.lavender};

  svg {
    path {
      stroke: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    }
  }

  &:hover {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};

    svg {
      path {
        stroke: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
      }
    }
  }

  // Fixing issue with icon 'bold' effect

  &.twitter-icon {
    svg {
      path {
        stroke: none;
        fill: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
      }
    }

    &:hover {
      background-color: ${(p: IAppContext) =>
        p.theme.skye.colors.electricViolet};

      svg {
        path {
          stroke: none;
          fill: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
        }
      }
    }
  }
`;

const IconWrapper = styled<any>(Box)`
  width: 20px;
`;

const handleShareToSocial = (socialMedia: string) => () => {
  AnalyticService.event(
    AnalyticEventCategory.ModelDetailsPage,
    `Share to ${socialMedia}`
  );
};

const SocialIcons = ({ modelPublicKey, modelName }: ISocialIconsPropTypes) => {
  const { t } = useTranslation();
  const shareTitle = `Skyebrowse model - ${modelName}`;
  const translate = shareTitle;
  const ogTitle = `Check out this SkyeBrowse reality capture model I made!`;
  const publicUrl = ModelHelpers.getPublicUrl(modelPublicKey);
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  return (
    <div>
      <Label>{t("shareToSocialMedia")}</Label>
      <SocialMediaIconsWrapper>
        <FacebookShareButton
          windowWidth={windowWidth}
          windowHeight={windowHeight}
          url={publicUrl}
          quote={ogTitle}
          hashtag="#skyebrowse"
          onClick={handleShareToSocial("facebook")}
        >
          <IconButtonCircle>
            <IconWrapper>
              <FacebookIcon />
            </IconWrapper>
          </IconButtonCircle>
        </FacebookShareButton>
        <TwitterShareButton
          windowWidth={windowWidth}
          windowHeight={windowHeight}
          url={publicUrl}
          title={ogTitle}
          onClick={handleShareToSocial("twitter")}
        >
          <IconButtonCircle className="twitter-icon">
            <IconWrapper>
              <TwitterIcon />
            </IconWrapper>
          </IconButtonCircle>
        </TwitterShareButton>
        <LinkedInShareButton
          onClick={handleShareToSocial("linkedin")}
          href={`https://www.linkedin.com/shareArticle?mini=true&url=${publicUrl}`}
          target="_blank"
        >
          <IconButtonCircle>
            <IconWrapper>
              <LinkedInIcon />
            </IconWrapper>
          </IconButtonCircle>
        </LinkedInShareButton>
        <EmailShareButton
          windowWidth={windowWidth}
          windowHeight={windowHeight}
          url={publicUrl}
          subject={ogTitle}
          openShareDialogOnClick
          onClick={handleShareToSocial("email")}
        >
          <IconButtonCircle>
            <IconWrapper>
              <EmailIcon />
            </IconWrapper>
          </IconButtonCircle>
        </EmailShareButton>
      </SocialMediaIconsWrapper>
    </div>
  );
};

export default SocialIcons;
