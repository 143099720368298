import { memo } from "react";
import OrthophotoPreview, {
  IOrthophotoPreviewPropTypes,
} from "./OrthophotoPreview";
import InformationBox from "../../../shared/components/InformationBox/InformationBox";
import { useTranslation } from "react-i18next";

interface IOrthophotoViewerPropTypes {
  sketchProps: IOrthophotoPreviewPropTypes;
  orthophotoProps: IOrthophotoPreviewPropTypes;
}

const OrthophotosNotAvailable = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center w-full h-full bg-gray-50 rounded-md">
      <InformationBox
        icon="error_2"
        transparent
        title={t("models.orthophotosNotAvailable")}
        description={t("models.orthophotosNotAvailableDescription")}
      />
    </div>
  );
};

const OrthophotoViewer = ({
  orthophotoProps,
  sketchProps,
}: IOrthophotoViewerPropTypes) => {
  if (!orthophotoProps.exists && !sketchProps.exists) {
    return <OrthophotosNotAvailable />;
  }

  return (
    <div className="orthophoto-viewer w-full h-full block md:grid grid-cols-2 gap-4">
      <OrthophotoPreview {...orthophotoProps} />
      <OrthophotoPreview {...sketchProps} />
    </div>
  );
};

export default memo(OrthophotoViewer);
