import React, { useEffect, useState } from "react";
import { Row } from "styled-bootstrap-grid";
import { useTranslation } from "react-i18next";
import InputGroup from "../../shared/form/InputGroup";
import Label from "../../shared/typography/Label";
import Button from "../../shared/buttons/Button";
import styled from "styled-components";
import AddIcon from "../../shared/Icons/AddIcon";
import { IAppContext } from "../../interfaces";
import Heading from "../../shared/typography/Heading";
import { Model } from "models/Model";
import ModelChooseModalContainer from "views/models/containers/ModelChooseModalContainer";
import ModelRadioButton from "views/models/components/ModelRadioButton";
import UserChooseModalContainer from "views/users/containers/UserChooseModalContainer";
import { SessionService } from "services/SessionService";
import { MessageService } from "services/MessageService";
import ModelService from "views/models/services/ModelService";
import _ from "lodash";
import { OrganizationService } from "services/OrganizationService";
import { LoggedUser } from "models/User";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { SESSION_ROUTES } from "views/routes";
import { WindowUtils } from "utils/windowUtils";
import { IModelListItem } from "../../models/pages/ModelListPage.tsx/ModelList/interface";

interface ISessionCreateContainerPropTypes extends RouteComponentProps {
  sessionAddress?: string;
}

const Wrapper = styled.div`
  width: 100%;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;
const AddModelWrapper = styled(Wrapper)`
  .start-icon {
    margin-right: 0;
  }

  .model-radio-button {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.lavender};
  }

  svg {
    path {
      fill: ${(p: IAppContext) => p.theme.skye.colors.primary};
    }
  }
`;

enum SessionVisibility {
  Public = "public",
  Private = "private",
}

const SessionCreateContainer = ({
  sessionAddress,
  history,
}: ISessionCreateContainerPropTypes) => {
  const { t } = useTranslation();
  const [previousSessionVisibility, setPreviousSessionVisibility] = useState(
    SessionVisibility.Private
  );
  const [sessionVisibility, setSessionVisibility] = useState(
    SessionVisibility.Private
  );
  const [previousSessionName, setPreviousSessionName] = useState("");
  const [sessionName, setSessionName] = useState("");
  const [modelModalOpen, setModelModalOpen] = useState(false);
  const [usersModalOpen, setUsersModalOpen] = useState(false);
  const [previousModel, setPreviousModel] = useState<IModelListItem | null>(
    null
  );
  const [currentModel, setCurrentModel] = useState<IModelListItem | null>(null);
  const [selectedUsers, setSelectedUsers] = useState<LoggedUser[]>([]);
  const [loading, setLoading] = useState(false);
  const isEditMode = Boolean(sessionAddress);

  const handleSessionNameChange = (e: any) => {
    setPreviousSessionName(sessionName);
    setSessionName(e.target.value);
  };

  const openModelModal = () => {
    setModelModalOpen(true);
  };

  const handleModelChange = (model: IModelListItem) => {
    setPreviousModel(currentModel);
    setCurrentModel(model);
    setModelModalOpen(false);
  };

  const handleInviteUsers = (users: LoggedUser[]) => {
    setSelectedUsers(users);
    setUsersModalOpen(false);
  };

  const isSessionPublic = (): boolean => {
    return sessionVisibility === SessionVisibility.Public;
  };

  const getModelId = (): string => {
    return currentModel?.id;
  };

  const getUsersIds = (): string[] => {
    return selectedUsers?.map((u) => String(u.id));
  };

  const redirect = () => {
    if (history.action === "PUSH") {
      history.goBack();
      return;
    }

    history.push(SESSION_ROUTES.sessionList);
  };

  const handleSessionCreate = () => {
    const ts = new SessionService();
    setLoading(true);

    ts.createSession(
      sessionName,
      getModelId(),
      getUsersIds(),
      [],
      isSessionPublic()
    )
      .then(() => {
        MessageService.success(t("sessionCreated"));
        redirect();
      })
      .catch(() => {
        MessageService.error(t("sessionCreationFailed"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const getCurrentSessionDetails = async () => {
    const ts = new SessionService();
    const session = await ts.getSession(sessionAddress);

    if (session) {
      setSessionName(session.name);
      setPreviousSessionVisibility(sessionVisibility);
      setSessionVisibility(
        session.open ? SessionVisibility.Public : SessionVisibility.Private
      );

      const usersIds = (_.get(session, "invited.users", []) as string[]).filter(
        (id) => !Number.isNaN(Number(id))
      );
      const users = await Promise.all(
        usersIds.map((userId) => OrganizationService.getUser(userId))
      );
      setSelectedUsers(users.filter((u) => Boolean(u)));

      if (session.modelId) {
        ModelService.getModelDetails(session.modelId).then((res) => {
          setCurrentModel(res.data);
          setPreviousModel(res.data);
        });
      }
    }
  };

  const handleSessionEdit = () => {
    const ts = new SessionService();
    setLoading(true);
    const updateObj = {
      name: sessionName !== previousSessionName ? sessionName : undefined,
      modelId: getModelId() !== previousModel?.id ? getModelId() : undefined,
      open:
        sessionVisibility !== previousSessionVisibility
          ? isSessionPublic()
          : undefined,
    };
    ts.updateSession(sessionAddress, updateObj)
      .then(() => {
        MessageService.success(t("toastSessionUpdated"));
        redirect();
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const handleSubmit = () => {
    isEditMode ? handleSessionEdit() : handleSessionCreate();
  };

  const setInitialModel = async () => {
    const modelId = WindowUtils.getQueryParam("modelId", null);

    if (modelId) {
      ModelService.getModelDetails(modelId).then((res) =>
        setCurrentModel(res.data)
      );
    }
  };

  useEffect(() => {
    if (sessionAddress) {
      getCurrentSessionDetails();
    } else {
      setInitialModel();
    }
  }, [sessionAddress]);

  return (
    <div>
      <Row>
        <Wrapper>
          <Heading>{t("createSession")}</Heading>
        </Wrapper>
        <Wrapper>
          <InputGroup
            bordered
            label="nameLabel"
            inputProps={{
              value: sessionName,
              placeholder: "sessionNamePlaceholder",
              onChange: handleSessionNameChange,
            }}
          />
        </Wrapper>
      </Row>
      <Row>
        <AddModelWrapper>
          <Label>{t("model")}</Label>
          {currentModel ? (
            <ModelRadioButton
              showRadioIcon={false}
              showCloseIcon={true}
              modelId={currentModel.id}
              modelName={currentModel.name}
              thumbnailsUrls={currentModel.assets.thumbnailsUrls}
              selected={false}
              onClick={openModelModal}
              onClose={() => setCurrentModel(null)}
            />
          ) : (
            <Button
              fullSize
              startIcon={<AddIcon />}
              type="light"
              onClick={openModelModal}
            >
              {t("addModel")}
            </Button>
          )}
        </AddModelWrapper>
      </Row>
      <Row>
        <Button loading={loading} fullSize={false} onClick={handleSubmit}>
          {t(isEditMode ? "save" : "create")}
        </Button>
      </Row>
      <ModelChooseModalContainer
        onClose={() => setModelModalOpen(false)}
        onChange={handleModelChange}
        selectedModel={currentModel}
        open={modelModalOpen}
      />
      <UserChooseModalContainer
        currentSelectedUsers={selectedUsers}
        onClose={() => setUsersModalOpen(false)}
        onInvite={handleInviteUsers}
        open={usersModalOpen}
      />
    </div>
  );
};

export default withRouter(SessionCreateContainer);
