import React from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";

const UserEmail = styled.h6`
  margin-bottom: 0;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: ${(p: IAppContext) => p.theme.skye.colors.silverSand};
`;

export default UserEmail;
