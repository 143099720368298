import React from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { STORE_NAMES } from "../../../const/global";
import { ISessionViewerPageStore } from "stores/SessionViewerPageStore";
import SkyeSwitch from "../../shared/form/SkyeSwitch";

interface ISessionFindingsLiveUpdateSwitchPropTypes {
  SessionViewerPageStore?: ISessionViewerPageStore;
}

const Wrapper = styled.div``;

const SessionFindingsLiveUpdateSwitch = ({
  SessionViewerPageStore,
}: ISessionFindingsLiveUpdateSwitchPropTypes) => {
  const { t } = useTranslation();
  const handleChange = (newChecked: boolean) => {
    return newChecked
      ? SessionViewerPageStore.toggleLive(true)
      : SessionViewerPageStore.toggleLive(false);
  };

  return (
    <Wrapper className="">
      <SkyeSwitch
        reverse
        bottomPadding={false}
        checked={SessionViewerPageStore.liveResults}
        onChange={handleChange}
      >
        {t("liveResults")}
      </SkyeSwitch>
    </Wrapper>
  );
};

export default inject(STORE_NAMES.SessionViewerPageStore)(
  observer(SessionFindingsLiveUpdateSwitch)
);
