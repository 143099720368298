/**
 * Formatting tag phrase, deleting all non alphanumeric characters and
 * making phrase lowercase
 * 
 * @param phrase string
 */
export const formatTag = (phrase: string) => {
  return phrase
    .toLocaleLowerCase()
    .replace(/\W/g, '');
}