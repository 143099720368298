import React from 'react'

interface IModelIconPropTypes {
  
}

const ModelIcon = ({
  
}: IModelIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4 9.33333L16 4L28 9.33333M4 9.33333L16 14.6667M4 9.33333V22.6667L16 28M28 9.33333L16 14.6667M28 9.33333V22.6667L16 28M16 14.6667V28" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}

export default ModelIcon;
