import React, { useEffect, useState } from "react";
import { Breakpoints, BREAKPOINTS_GRID } from "theme";

const useBreakpoint = () => {
  const [width, setWidth] = useState<number>(window.innerWidth);

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  const isMobile = () => width < BREAKPOINTS_GRID.breakpoints.md;
  const isTablet = () =>
    width >= BREAKPOINTS_GRID.breakpoints.md &&
    width < BREAKPOINTS_GRID.breakpoints.lg;
  const isTabletLg = () =>
    width >= BREAKPOINTS_GRID.breakpoints.lg &&
    width < BREAKPOINTS_GRID.breakpoints.xl;
  const isDesktop = () => width >= BREAKPOINTS_GRID.breakpoints.xl;

  const breakpoint = (point: Breakpoints) => {
    return width > BREAKPOINTS_GRID.breakpoints[point];
  };

  const hook = {
    isMobile,
    isTablet,
    isTabletLg,
    isDesktop,
    breakpoint,
  };

  return hook;
};

export default useBreakpoint;
