import { SessionPageViewTypes, STORE_NAMES } from "const/global";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ISessionViewerPageStore } from "stores/SessionViewerPageStore";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import Dialog from "views/shared/components/Dialog/Dialog";
import { SESSION_ROUTES } from "views/routes";
import Box, { BoxAlignedCenter } from "../../shared/components/Box";
import ToolIcon from "../../shared/components/ToolIcon";
import InputGroup from "../../shared/form/InputGroup";
import SettingsIcon from "../../shared/Icons/SettingsIcon";
import UsersIcon from "../../shared/Icons/UsersIcon";
import {
  TEXT_LABEL_ID,
  ANNOTATION_TOOL,
  AREA_TOOL,
  FIELD_OF_VIEW_TOOL,
  DELETE_ALL_TOOL,
  DISTANCE_TOOL,
  OPEN_PROFILE_PANEL,
  SWITCH_TOPOGRAPHY_MAP,
} from "views/viewer/const";
import { ViewerService } from "views/viewer/services/ViewerService";
import SessionViewerToolIcon, {
  SessionViewerIconColorMethodType,
} from "../components/SessionViewerToolIcon";
import breakpoint from "styled-components-breakpoint";
import SessionViewerStreamToolIcon from "./SessionViewerStreamToolIcon";
import TextLabel from "../../viewer/services/tools/TextLabel";
import Distance from "../../viewer/services/tools/Distance";
import Area from "../../viewer/services/tools/Area";
import BaseMeasure from "../../viewer/services/tools/BaseMeasure";
import { TopographyMap } from "../../viewer/services/TopographyMap";

interface ISessionViewerToolsPanelContainerPropTypes
  extends RouteComponentProps {
  SessionViewerPageStore?: ISessionViewerPageStore;
}

const Panel = styled(Box)`
  display: none;
  background: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border: ${(p: IAppContext) => p.theme.skye.borders.primary};
  box-sizing: border-box;
  box-shadow: ${(p: IAppContext) => p.theme.skye.shadows.light};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  height: 56px;
  justify-content: center;
  flex-direction: row;
  padding: 0 12px;

  ${breakpoint("md")`
    display: flex;
  `}

  .tool-icon {
    margin: 0 ${(p: IAppContext) => p.theme.skye.sizes.radius};
  }
`;
const Section = styled(BoxAlignedCenter)``;

const viewerService = new ViewerService();
const VIEWER_TOOLS = [
  ANNOTATION_TOOL,
  DISTANCE_TOOL,
  SWITCH_TOPOGRAPHY_MAP,
  OPEN_PROFILE_PANEL,
  FIELD_OF_VIEW_TOOL,
  DELETE_ALL_TOOL,
];

const getColorMethod = (toolId): SessionViewerIconColorMethodType => {
  if (toolId === DISTANCE_TOOL.id || toolId === FIELD_OF_VIEW_TOOL.id) {
    return "stroke";
  }
  return "fill";
};

const SessionViewerToolsPanelContainer = ({
  history,
  SessionViewerPageStore,
}: ISessionViewerToolsPanelContainerPropTypes) => {
  const { t } = useTranslation();
  const viewerViewEnabled =
    SessionViewerPageStore.viewType === SessionPageViewTypes.Model;
  const [annotationDialogOpen, setAnnotationDialogOpen] = useState(false);
  const [annotationName, setAnnotationName] = useState("");

  const handleUsersClick = () => {
    if (SessionViewerPageStore.sessionMembersPanelOpen) {
      SessionViewerPageStore.setSessionMembersPanelOpen(false);
      return;
    }
    SessionViewerPageStore.setSessionMembersPanelOpen(true);
  };

  const handleSettingsClick = () => {
    history.push(
      SESSION_ROUTES.sessionDetails.replace(
        ":id",
        SessionViewerPageStore.session.publicId
      )
    );
  };

  const onAnnotationDialogClose = () => {
    setAnnotationDialogOpen(false);
  };

  const onAnnotationDialogAdd = () => {
    TextLabel.add(annotationName);
    setAnnotationDialogOpen(false);
    setAnnotationName("");
  };

  const openAnnotationDialog = () => {
    setAnnotationDialogOpen(true);
    setTimeout(() => {
      const annotationInputRef = document.getElementById("annotationInput");
      if (annotationInputRef) {
        annotationInputRef.focus();
      }
    }, 200);
  };

  const handleMeasureClick = (id: string) => () => {
    switch (id) {
      case TEXT_LABEL_ID:
        openAnnotationDialog();
        break;

      case DISTANCE_TOOL.id:
        Distance.add();
        break;

      case AREA_TOOL.id:
        Area.add();
        break;

      case DELETE_ALL_TOOL.id:
        BaseMeasure.deleteAll();
        break;

      case SWITCH_TOPOGRAPHY_MAP.id:
        TopographyMap.toggle();
        break;

      case OPEN_PROFILE_PANEL.id:
        viewerService.openProfile();
        break;

      case FIELD_OF_VIEW_TOOL.id:
        viewerService.changePerspectiveToPoint();
        break;

      default:
        return;
    }
  };

  const handleAnnotationKeyUp = (e: any) => {
    if (e.key === "Enter") {
      onAnnotationDialogAdd();
    }
  };

  const onProfileCanceled = () => {
    viewerService.handleProfileToolCancel();
  };

  useEffect(() => {
    const sceneExist = viewerViewEnabled && window.viewer.scene;

    if (sceneExist) {
      window.viewer.scene.addEventListener(
        "profile_cancel_insertion",
        onProfileCanceled
      );
    }

    return () => {
      if (sceneExist) {
        window.viewer.scene.removeEventListener(
          "profile_cancel_insertion",
          onProfileCanceled
        );
      }
    };
  }, [viewerViewEnabled]);

  return (
    <Panel>
      <Dialog
        title={t("addAnnotation")}
        open={annotationDialogOpen}
        onClose={onAnnotationDialogClose}
        confirmButtonProps={{
          onClick: onAnnotationDialogAdd,
        }}
        cancelButtonProps={{
          onClick: onAnnotationDialogClose,
        }}
      >
        <InputGroup
          label={"annotationName"}
          inputProps={{
            id: "annotationInput",
            placeholder: "annotationNamePlaceholder",
            value: annotationName,
            onChange: (e: any) => setAnnotationName(e.target.value),
            onKeyUp: handleAnnotationKeyUp,
          }}
        />
      </Dialog>
      <Section>
        <SessionViewerToolIcon
          active={SessionViewerPageStore.sessionMembersPanelOpen}
          icon={<UsersIcon />}
          name={t("members")}
          onClick={handleUsersClick}
        />
        <SessionViewerToolIcon
          icon={<SettingsIcon />}
          name={t("sessionSettings")}
          onClick={handleSettingsClick}
        />
      </Section>
      {viewerViewEnabled && (
        <Section>
          {VIEWER_TOOLS.map((tool) => (
            <SessionViewerToolIcon
              key={tool.id}
              icon={<ToolIcon toolId={tool.id} />}
              name={t(tool.tooltip)}
              onClick={handleMeasureClick(tool.id)}
              colorMethod={getColorMethod(tool.id)}
            />
          ))}
        </Section>
      )}
      <Section>
        <SessionViewerStreamToolIcon />
      </Section>
    </Panel>
  );
};

export default inject(STORE_NAMES.SessionViewerPageStore)(
  observer(withRouter(SessionViewerToolsPanelContainer))
);
