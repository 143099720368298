import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import Button from "../../../../shared/buttons/Button";
import { BoxCentered } from "../../../../shared/components/Box";
import SkyeTooltip from "../../../../shared/components/SkyeTooltip";
import ReportIcon from "../../../../shared/Icons/ReportIcon";

const ReportIconWrapper = styled(BoxCentered)`
  width: 22px;
`;

const ReportIssueButton = (props) => {
  const { t } = useTranslation();

  return (
    <SkyeTooltip title={t("reportIssue")}>
      <Button className="view-btn" type="light" color="primary" {...props}>
        <ReportIconWrapper>
          <ReportIcon />
        </ReportIconWrapper>
      </Button>
    </SkyeTooltip>
  );
};

export default ReportIssueButton;
