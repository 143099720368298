import { useCallback, useEffect } from "react";
import {
  IVideoUploadStore,
  UploadQueueStatus,
} from "../../../../../stores/VideoUploadStore";
import { UploadStatus } from "../service/MultipartUploadService";
import useUploadModelVideo from "./useUploadModelVideo";

interface HookProps {
  VideoUploadStore: IVideoUploadStore;
}

interface IHandlers {
  cancelUpload: (id: string) => void;
}

type HookResult = [IHandlers];

const MAX_UPLOADS = 1;
const DELAY_BEFORE_REMOVING_UPLOAD_IN_MS = 2000;

const useManageUploads = ({ VideoUploadStore }: HookProps): HookResult => {
  const { getQueued: inQueueArray, inProgressArray } = VideoUploadStore;

  const onProgress = (id: string, progress: number, status: UploadStatus) => {
    VideoUploadStore.updateProgress(id, progress);

    switch (status) {
      case UploadStatus.Failed:
        VideoUploadStore.changeStatus(id, UploadQueueStatus.FAILED);
        return;
      case UploadStatus.Canceled:
        VideoUploadStore.changeStatus(id, UploadQueueStatus.CANCELED);
        return;
    }
  };

  const onUploadComplete = (id: string) => {
    setTimeout(() => {
      VideoUploadStore.changeStatus(id, UploadQueueStatus.SUCCESS);
    }, DELAY_BEFORE_REMOVING_UPLOAD_IN_MS);
  };

  /**
   * Initiate the upload of the next file in the queue.
   */
  const [initUpload, cancelUpload] = useUploadModelVideo({
    onProgress,
    onUploadComplete,
  });

  const onStoreChange = useCallback(() => {
    if (inQueueArray.length && inProgressArray.length < MAX_UPLOADS) {
      const nextUpload = inQueueArray[0];

      if (nextUpload) {
        VideoUploadStore.changeStatus(
          nextUpload.id,
          UploadQueueStatus.IN_PROGRESS
        );
        initUpload(nextUpload);
      }
    }
  }, [VideoUploadStore, initUpload, inProgressArray, inQueueArray]);

  const handleCancel = (id: string) => {
    cancelUpload(id);
    VideoUploadStore.changeStatus(id, UploadQueueStatus.CANCELED);
  };

  useEffect(onStoreChange, [inQueueArray, onStoreChange]);

  return [{ cancelUpload: handleCancel }];
};

export default useManageUploads;
