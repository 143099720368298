import classNames from "classnames";
import { PremiumStarIcon } from "../../../../../shared/Icons/PremiumStarIcon";
import Button from "../../../../../shared/buttons/Button";
import CircleLoader from "../../../../../shared/components/CircleLoader";
import {
  SubscriptionPeriod,
  SubscriptionType,
} from "../../../../payments.model";
import { AccessPeriod } from "../../../../service/QuoteService";
import PaymentOption from "../../../PaymentOption/PaymentOption";
import { PREMIUM_PRICING_QUOTE_SUVERY_URL } from "../../../PricingTable/PricingTable.const";
import { IPaymentDialogPropTypes } from "../../model";
import styles from "./SimplifiedPricing.module.css";
import LiteSubscriptionPaymentOption from "../LiteSubscriptionPaymentOption";

interface ISimplifiedPricingPropTypes extends IPaymentDialogPropTypes {}

const ModelSimplifiedPricing = ({
  quote,
  subscriptionPeriod,
  onBuyAccess,
  handleBuySubscription,
  onSelectSubscriptionPeriod,
}: ISimplifiedPricingPropTypes) => {
  const PPM_LABEL = "Get access to this model and standard tools.";
  const PPM_UNLOCK_LABEL = "Unlock this model";

  if (!quote)
    return (
      <div className={styles.loaderWrapper}>
        <CircleLoader color="primary" size={80} />
      </div>
    );

  const redirectToPremiumQoteSurvey = () => {
    window.location.href = PREMIUM_PRICING_QUOTE_SUVERY_URL;
  };

  const handleSubPeriodChange = (newSubPeriod: SubscriptionPeriod) => {
    onSelectSubscriptionPeriod(newSubPeriod);
  };

  return (
    <div className="simplified-pricing w-full max-w-3xl block md:grid grid-cols-3 gap-4">
      <div className="block md:grid grid-span-2 gap-4">
        {!!quote?.data?.prices?.month && (
          <div className={styles.boxSmall}>
            <PaymentOption
              periodLabel="1 month"
              priceLabel={`$${quote?.data?.prices?.month}`}
              description={PPM_LABEL}
              buttonsPlacement={
                <Button
                  type="bordered"
                  fullSize
                  onClick={() => onBuyAccess(AccessPeriod.MONTH)}
                >
                  {PPM_UNLOCK_LABEL}
                </Button>
              }
            />
          </div>
        )}
        {!!!!quote?.data?.prices?.six_months && (
          <div className={styles.boxSmall}>
            <PaymentOption
              periodLabel="6 months"
              priceLabel={`$${quote?.data?.prices?.six_months}`}
              description={PPM_LABEL}
              buttonsPlacement={
                <Button
                  type="bordered"
                  fullSize
                  onClick={() => onBuyAccess(AccessPeriod.SIX_MONTHS)}
                >
                  {PPM_UNLOCK_LABEL}
                </Button>
              }
            />
          </div>
        )}
      </div>
      <div className={classNames([styles.boxLarge, styles.boxFeatured])}>
        <LiteSubscriptionPaymentOption
          subscriptionPeriod={subscriptionPeriod}
          onBuy={() => handleBuySubscription(SubscriptionType.Lite)}
          onPeriodChange={handleSubPeriodChange}
        ></LiteSubscriptionPaymentOption>
      </div>
      <div className={styles.boxLarge}>
        <PaymentOption
          priceLabel="Premium"
          description="Designed for organizations that require the highest level of clarity, feature sets, and security."
          buttonsPlacement={
            <div className="w-full block">
              <Button
                type="bordered"
                color="dark"
                fullSize
                onClick={redirectToPremiumQoteSurvey}
              >
                Request Quote
              </Button>
            </div>
          }
          icon={
            <div className="w-12 h-12 rounded-md bg-primary-500 flex justify-center items-center p-3">
              <PremiumStarIcon />
            </div>
          }
        />
      </div>
    </div>
  );
};

export default ModelSimplifiedPricing;
