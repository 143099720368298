import React from 'react'

interface ITacbrowseIconPropTypes {
  
}

const TacbrowseIcon = ({
  
}: ITacbrowseIconPropTypes) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="24" height="24" />
    <path d="M14.5213 4.06898C14.5213 5.4846 13.3679 6.63795 11.9523 6.63795C10.5367 6.63795 9.3833 5.4846 9.3833 4.06898C9.3833 2.65335 10.5367 1.5 11.9523 1.5C13.3679 1.5 14.5213 2.65335 14.5213 4.06898Z" stroke="black"/>
    <path d="M14.5905 13.5431H14.1207L14.0914 14.0119L13.7371 19.681H10.1661L9.81156 14.0118L9.78224 13.5431H9.31253H7.84839V10.5906C7.84839 9.38582 8.82922 8.4051 10.0339 8.4051H13.8701C15.0748 8.4051 16.0556 9.38593 16.0556 10.5906V13.5431H14.5905Z" stroke="black"/>
    <path d="M15.7579 16.0054C18.4594 16.6585 20.0084 17.9015 20.0084 19.4139C20.0084 21.9055 15.8576 23.2501 11.9524 23.2501C8.04721 23.2501 3.89648 21.9054 3.89648 19.4139C3.89648 17.9015 5.44521 16.6585 8.14689 16.0064" stroke="black"/>
    </svg>
  )
}

export default TacbrowseIcon;
