import React from "react";

interface ICloseIconPropTypes {
  size?: number;
}

const CloseIcon = ({ size = 32 }: ICloseIconPropTypes) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16.7075 15.9999L23.6873 9.02016L22.9801 8.31305L16.0004 15.2928L9.02058 8.31305L8.31348 9.02016L15.2933 15.9999L8.31348 22.9797L9.02058 23.6868L16.0004 16.707L22.9801 23.6868L23.6873 22.9797L16.7075 15.9999Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default CloseIcon;
