import React from 'react'

interface IArrowDownIconPropTypes {
  
}

const ArrowDownIcon = ({
  
}: IArrowDownIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 12L16 20L24 12" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default ArrowDownIcon;
