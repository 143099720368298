import { makeAutoObservable } from "mobx";
import validate from "mobx-form-validate";
import {
  PASSWORD_PATTERN,
  minLength,
  EMAIL_PATTERN,
  maxLength,
  isPhoneNumber,
} from "../../shared/utils/validationUtils";

export default class RegisterForm {
  constructor() {
    makeAutoObservable(this);
  }

  @validate(PASSWORD_PATTERN, "validationErrorPassword")
  password = "";

  @validate((value, that) => {
    if (value !== that.password)
      return "validationErrorRepeatPasswordDifferent";
    if (!PASSWORD_PATTERN.test(value)) return "validationErrorPassword";
    return undefined;
  })
  passwordRepeat = "";

  @validate(minLength(2), maxLength(30))
  firstName = "";

  @validate(minLength(2), maxLength(30))
  lastName = "";

  @validate(minLength(2), maxLength(50))
  organization = "";

  jobTitle = "";

  @validate((value) => {
    if (!value) {
      return undefined;
    }

    return isPhoneNumber(value);
  })
  phone = "";

  @validate(EMAIL_PATTERN, "validateErrorEmail")
  email = "";

  couponCode = "";

  submitted: boolean = false;

  // Bind this for future use.
  submit = () => {
    this.submitted = true;
  };
}
