import classNames from 'classnames';
import React from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";
import { UserHelpers } from '../../../models/User';
import { BoxCentered } from './Box';

interface IUserAvatarPropTypes {
  userName: string,
  size?: number,
  selected?: boolean,
}

const AVATAR_DEAULT_SIZE = 32;

const Avatar = styled(BoxCentered)`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  color: ${(p: IAppContext) => p.theme.skye.colors.silverSand};
  line-height: 116%;

  svg {
    width: 18px;
    height: 18px;
  }
`;
const AvatarContainer = styled(BoxCentered)`
  &.selected {
    .avatar {
      background-color: ${(p: IAppContext) =>
        p.theme.skye.colors.electricViolet};
      color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
    }
  }
`;

const UserAvatar = ({
  userName,
  selected,
  size = AVATAR_DEAULT_SIZE
}: IUserAvatarPropTypes) => {
  return (
    <AvatarContainer
      className={classNames(["skye-avatar", { selected }])}
      style={{
        width: size,
        // Preventing ellipsing of avatar when squeezed by near other elements
        minWidth: size,
        height: size,
        fontSize: size * 0.375,
      }}
    >
      <Avatar className="avatar">
        {UserHelpers.getUserNameShortcut(userName)}
      </Avatar>
    </AvatarContainer>
  );
}

export default UserAvatar;
