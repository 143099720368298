import styled from "styled-components";
import { IAppContext } from "../../interfaces";

export const SmallDescription = styled.p`
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  margin-bottom: 0;
  color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
`;

export const StatusLabel = styled.span`
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
`;