import { memo } from "react";
import PaidAccessOverlay from "../../components/PaidAccessOverlay";
import { IPublicModel } from "../../../../../models/Model";
import { useTranslation } from "react-i18next";
import { useModelPayment } from "../../../payments/useModelPayment";
import { useSubscriptionPayment } from "../../../payments/useSubscriptionPayment";
import { SubscriptionType } from "../../../../payments/payments.model";

interface IModelDetailsPagePaidAccessOverlayPropTypes {
  model: IPublicModel;
}

const ModelDetailsPagePaidAccessOverlay = ({
  model,
}: IModelDetailsPagePaidAccessOverlayPropTypes) => {
  const { t } = useTranslation();
  const subscription = useSubscriptionPayment();
  const { openWidget } = useModelPayment();

  const handleClick = () => openWidget(model.id, model.accessType);

  const handleSubscribeClick = () => {
    subscription.redirect(SubscriptionType.Lite);
  };

  return (
    <PaidAccessOverlay
      title={t("unlockModelTitle")}
      description={t("unlockModelDescription")}
      showPayPerModelButton
      payPerModelButtonProps={{
        children: t("purchase"),
        onClick: handleClick,
        type: "light",
      }}
      showSubscriptionButton
      subscriptionModelButtonProps={{
        children: t("subscribe"),
        onClick: handleSubscribeClick,
        type: "solid",
      }}
      subscriptionButtonDescription={
        <div className="text-xs mt-2">
          {t("payment.model.subscribeDescription")}
        </div>
      }
    />
  );
};

export default memo(ModelDetailsPagePaidAccessOverlay);
