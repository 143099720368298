import React, { useState } from 'react'
import styled from 'styled-components';
import ToolIconSection from './ToolIconSection';
import { useTranslation } from 'react-i18next';
import InputGroup from "../../shared/form/InputGroup";
import Button from "../../shared/buttons/Button";
import { AnalyticService, AnalyticEventCategory } from 'services/AnalyticService';
import { ViewerService } from '../services/ViewerService';
import { ImageService } from '../../../services/ImageService';
import { SCREENSHOT_FILENAME } from 'const/global';
import { FileService } from 'services/FileService';
import { MessageService } from 'services/MessageService';
import { IAppContext } from "../../interfaces";
import { useScreenshotTool } from "../context/ViewerScreenshotToolContext";
import ToolAvailabilityHoc from "../hoc/ToolAvailabilityHoc";

interface IViewerScreenshotPanelPropTypes {}

const GenerateButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

const ToolIconSectionHoc = ToolAvailabilityHoc(ToolIconSection);

const ViewerScreenshotPanel = ({}: IViewerScreenshotPanelPropTypes) => {
  const screenshotCtx = useScreenshotTool();
  const { blocked } = screenshotCtx;

  const { t } = useTranslation();
  const [screenshotName, setScreenshotName] = useState("");
  const [creatingScreenshot, setCreatingScreenshot] = useState(false);

  const handleGenerate = async () => {
    if (!creatingScreenshot) {
      setCreatingScreenshot(true);

      try {
        const viewerService = new ViewerService();
        AnalyticService.event(
          AnalyticEventCategory.ViewerScreenshot,
          "create_screenshot_button"
        );
        const screenshot = viewerService.getScreenshot();
        let watermarkedScreenshot = await ImageService.createWatermark(
          screenshot
        );

        if (screenshotName && screenshotName.length) {
          watermarkedScreenshot = await ImageService.addReportNumber(
            watermarkedScreenshot,
            screenshotName
          );
        }
        FileService.downloadJpgFile(watermarkedScreenshot, SCREENSHOT_FILENAME);
        setCreatingScreenshot(false);
        MessageService.success(t("screenshot_created"));
      } catch (error) {
        setCreatingScreenshot(false);
        MessageService.info(t("screenshot_failed"));
      }
    }
  };

  return (
    <ToolIconSectionHoc
      available={!blocked}
      notAvailableMessage={t("subscription.featureNotAvailableForCurrentPlan")}
      titleProps={{ title: t("screenshots_section") }}
    >
      <InputGroup
        inputProps={{
          value: screenshotName,
          placeholder: "caseNumberPlaceholder",
          onChange: (e: any) => setScreenshotName(e.target.value),
        }}
      ></InputGroup>
      <GenerateButtonWrapper>
        <Button
          fullSize
          loading={creatingScreenshot}
          type={screenshotName ? "solid" : "light"}
          onClick={handleGenerate}
        >
          {t("generate")}
        </Button>
      </GenerateButtonWrapper>
    </ToolIconSectionHoc>
  );
};

export default ViewerScreenshotPanel;
