import React, { ReactNode } from 'react'
import styled from 'styled-components';
import Box from './Box';
import { IAppContext } from "../../interfaces";
import PinMapIcon from '../Icons/PinMapIcon';
import CalendarIcon from '../Icons/CalendarIcon';

export enum IconType {
  Clock = 'clock',
  Gps = 'gps',
}


interface IInformationIconRowPropTypes {
  iconType: IconType,
  children: ReactNode,
}

const StyledRow = styled(Box)`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;
const IconBox = styled(Box)`
  align-items: center;
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};

  svg {
    width: 24px;
    color: ${(p: IAppContext) => p.theme.skye.colors.primary};

    path {
      fill: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    }
  }
`;
const ContentBox = styled.div`
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
`;

const getIcon = (iconType: IconType) => {
  switch (iconType) {
    case IconType.Clock:
      return CalendarIcon;
    case IconType.Gps:
      return PinMapIcon;

    default:
      break;
  }
}

const InformationIconRow = ({
  iconType,
  children,
}: IInformationIconRowPropTypes) => {
  const Icon = getIcon(iconType);
  return (
    <StyledRow className="information-icon-row" alignItems="center">
      <IconBox>
        <Icon />
      </IconBox>
      <ContentBox>{children}</ContentBox>
    </StyledRow>
  )
}

export default InformationIconRow;
