import React, { DOMAttributes } from 'react'
import styled from 'styled-components';
import classNames from 'classnames';
import CircleLoader, { ILoaderPropTypes } from '../components/CircleLoader';
import { IAppContext } from "../../interfaces";
import { darken } from 'polished';

export interface IButtonPropTypes extends DOMAttributes<HTMLButtonElement> {
  id?: string;
  size?: "regular" | "small" | "very-small";
  loading?: boolean;
  className?: string;
  loaderProps?: ILoaderPropTypes;
  color?: "primary" | "accent" | "dark" | "danger";
  type?: "transparent" | "solid" | "light" | "bordered";
  disabled?: boolean;
  fullSize?: boolean;
  startIcon?: any;
  endIcon?: any;
  iconColorBy?: "stroke" | "fill";
}

const IconWrapper = styled.div`
  display: flex;
  width: 22px;

  &.end-icon {
    margin-left: 10px;

    &.no-margin {
      margin-left: 0;
    }
  }

  &.start-icon {
    margin-right: 10px;
  }
`;

const StyledButton = styled.button`
  display: flex;
  align-items: center;
  border-radius: 4px;
  cursor: pointer;
  justify-content: center;
  border: none;
  background-color: transparent;
  line-height: 1.1;

  &.fullSize {
    justify-content: center;
    width: 100%;
  }

  &.disabled {
    cursor: not-allowed;
    opacity: 0.5;

    path {
      fill: inherit;
    }
  }

  &.regular {
    font-size: 16px;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    padding: 0 12px;
    height: 42px;
  }

  &.bordered {
    font-weight: 600;
    box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
    border: ${(p: IAppContext) => p.theme.skye.borders.primary};

    &:hover {
      background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
    }
  }

  &.small {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    padding: 0 10px;
    height: 36px;

    ${IconWrapper} {
      width: 16px;
    }
  }

  &.very-small {
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 140%;
    padding: 0 12px;
    height: 24px;
  }

  &.primary {
    &.solid {
      background-color: ${(p: IAppContext) =>
        p.theme.skye.colors.electricViolet};
      color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};

      &:hover {
        background-color: ${(p: IAppContext) =>
          darken(0.1, p.theme.skye.colors.electricViolet)};
      }

      &.color-icon-by-stroke {
        path {
          stroke: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
        }
      }

      &.color-icon-by-fill {
        path {
          fill: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
        }
      }

      &.disabled {
        color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
        background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};

        &.color-icon-by-stroke {
          path {
            stroke: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
          }
        }

        &.color-icon-by-fill {
          path {
            fill: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
          }
        }
      }
    }

    &.light {
      background-color: ${(p: IAppContext) => p.theme.skye.colors.lavender};
      color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};

      &:hover {
        background-color: ${(p: IAppContext) =>
          darken(0.02, p.theme.skye.colors.lavender)};
      }

      path {
        stroke: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
      }
    }

    &.transparent {
      color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
      font-weight: 300;

      &:hover {
        background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
      }

      path {
        stroke: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
      }
    }
  }

  &.accent {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.accent};
    color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};

    path {
      stroke: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
    }
  }

  &.danger {
    &.solid {
      background-color: ${(p: IAppContext) => p.theme.skye.colors.danger};
      color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
    }

    &.bordered {
      background-color: transparent;
      color: ${(p: IAppContext) => p.theme.skye.colors.danger};
    }

    &.transparent {
      color: ${(p: IAppContext) => p.theme.skye.colors.danger};
    }
  }
`;

const Text = styled.div`
  line-height: 1.1;
  height: 100%;
`;

const LoaderWrapper = styled.div`
  margin-left: 15px;
`;

const Button = ({
  children,
  className,
  loading,
  loaderProps,
  disabled,
  fullSize,
  startIcon,
  endIcon,
  size = "regular",
  type = "solid",
  color = "primary",
  iconColorBy = "stroke",
  ...props
}: IButtonPropTypes) => {
  return (
    <StyledButton
      type="button"
      disabled={disabled}
      className={classNames([
        "btn",
        className,
        size,
        color,
        type,
        `color-icon-by-${iconColorBy}`,
        { loading, disabled, fullSize },
      ])}
      {...props}
    >
      {Boolean(startIcon) && (
        <IconWrapper className="start-icon">{startIcon}</IconWrapper>
      )}
      <Text className="flex flex-row items-center">{children}</Text>
      {!loading && Boolean(endIcon) && (
        <IconWrapper
          className={classNames(["end-icon", { "no-margin": !children }])}
        >
          {endIcon}
        </IconWrapper>
      )}
      {loading && (
        <LoaderWrapper>
          <CircleLoader {...loaderProps} />
        </LoaderWrapper>
      )}
    </StyledButton>
  );
};

export default Button;
