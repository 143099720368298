import { ISkyeTheme } from "theme";

export interface IAppContext {
  theme: {
    skye: ISkyeTheme;
  };
}

export interface IconProps {
  size?: number;
  color?: string;
}

export interface IUserBaseDataDTO {
  email: string;
  name: string;
  id: number;
  organization: number;
  approved: boolean;
}

export enum ModelType {
  Owned = "owned",
  Shared = "shared",
  SharedToOthers = "shared_to_others",
  Archived = "archived",
  Organization = "organization",
}

export interface IModelListFilters {
  modelType?: ModelType;
  tags?: string[];
  status?: ModelStatusFilter;
  startDateFilter?: moment.Moment;
  endDateFilter?: moment.Moment;
  name?: string;
}

export enum ModelStatusFilter {
  all = "all",
  finished = "finished",
}

export enum ModelTypeSearchParamsEnum {
  ModelType = "modelType",
  Tags = "tags",
  Status = "status",
  StartDate = "startDate",
  EndDate = "endDate",
  Name = "modelName",
}

export type IModelListPageType = ModelType;

export enum VideoUploadErrorType {
  Network = "network",
  Cancel = "cancel",
  Unknown = "unknown",
}

export enum VideoUploadStatus {
  InProgress = "inprogress",
  Failed = "failed",
  Uploaded = "uploaded",
}
