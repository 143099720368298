const DiamondIcon = () => {
  return (
    <svg
      width="38"
      height="36"
      viewBox="0 0 38 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_4144_329)">
        <path
          d="M23.3963 10.8621L24.8618 6.72412H18.9998H13.1377L14.6032 10.8621H23.3963Z"
          fill="#00D2FF"
        />
        <path d="M19 10.8621H29L19 23.2758V10.8621Z" fill="#00D2FF" />
        <path d="M19 6.72412V10.8621H9L13.1379 6.72412H19Z" fill="#18BDF6" />
        <path d="M19 10.8621H9L19 23.2758V10.8621Z" fill="#18BDF6" />
        <path d="M19 6.72412V10.8621H29L24.8621 6.72412H19Z" fill="#18BDF6" />
        <path
          d="M19.0001 6.72412L14.6045 10.8621H19.0001H23.3957L19.0001 6.72412Z"
          fill="#00D2FF"
        />
        <path
          d="M14.6035 10.8621L19.0001 23.2758L23.3966 10.8621H14.6035Z"
          fill="#28AFF0"
        />
        <path
          d="M24.862 6.72412L23.3965 10.8621H28.9999L24.862 6.72412Z"
          fill="#59E2FF"
        />
        <path
          d="M13.1379 6.72412L14.6034 10.8621H9L13.1379 6.72412Z"
          fill="#28AFF0"
        />
        <path
          d="M25.6504 8.64678C25.0553 8.51108 24.5867 8.04256 24.451 7.44733C24.4408 7.40249 24.3814 7.40249 24.3712 7.44733C24.2355 8.04249 23.767 8.51104 23.1718 8.64678C23.127 8.65702 23.127 8.71635 23.1718 8.72655C23.767 8.86225 24.2355 9.33073 24.3712 9.92596C24.3815 9.97081 24.4408 9.97081 24.451 9.92596C24.5867 9.33073 25.0552 8.86225 25.6504 8.72655C25.6952 8.71635 25.6952 8.65702 25.6504 8.64678Z"
          fill="white"
        />
        <path
          d="M24.1067 11.1495C23.6462 11.0302 23.2837 10.6676 23.1644 10.2071C23.1524 10.1609 23.0925 10.1609 23.0805 10.2071C22.9613 10.6676 22.5987 11.0302 22.1382 11.1495C22.092 11.1614 22.092 11.2213 22.1382 11.2333C22.5987 11.3525 22.9612 11.7151 23.0805 12.1756C23.0925 12.2219 23.1524 12.2219 23.1644 12.1756C23.2836 11.7151 23.6462 11.3526 24.1067 11.2333C24.153 11.2213 24.153 11.1614 24.1067 11.1495Z"
          fill="white"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_4144_329"
          x="0"
          y="-1"
          width="38"
          height="38"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="3" />
          <feGaussianBlur stdDeviation="4.5" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0.823529 0 0 0 0 1 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_4144_329"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_4144_329"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  );
};

export default DiamondIcon;
