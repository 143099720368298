const RecoveryIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.8843 3.66129C11.925 2.68692 10.7131 2.02753 9.38001 1.75377C9.10063 1.69627 8.81502 1.65627 8.53001 1.63439C8.39564 1.62377 8.16188 1.61814 7.96063 1.61502V0.96377C7.96063 0.858145 7.90376 0.76065 7.81126 0.708762C7.71938 0.656887 7.60627 0.658762 7.51625 0.713762L4.89065 2.31189C4.80753 2.36252 4.75503 2.45064 4.75065 2.54752C4.74628 2.6444 4.7894 2.73752 4.8669 2.79565L7.49314 4.76557C7.58189 4.83182 7.70002 4.84244 7.7994 4.79307C7.89815 4.7437 7.96127 4.64245 7.96127 4.53182V3.84557C8.00002 3.84744 8.03877 3.84994 8.0769 3.85307C8.10689 3.85557 8.1419 3.85807 8.18065 3.86119C8.39002 3.87682 8.74002 3.90244 8.89377 3.93369C9.77813 4.11494 10.5843 4.54995 11.2256 5.1912C13.0013 6.96688 13.0213 9.87376 11.2712 11.6718C10.4112 12.5556 9.25315 13.0493 8.01059 13.0618C6.76872 13.0756 5.60115 12.605 4.72307 11.7393C3.32744 10.3631 2.95251 8.24878 3.78933 6.4787C4.02182 5.98683 3.85683 5.3887 3.40496 5.08745C3.1387 4.90995 2.80808 4.85745 2.49997 4.94308C2.19059 5.0287 1.93433 5.24495 1.79747 5.53495C1.19872 6.80121 1.00373 8.20375 1.23248 9.59175C1.46685 11.0099 2.12936 12.2993 3.14928 13.3186C4.44491 14.6143 6.16672 15.3281 7.99808 15.3281H7.9987C9.8299 15.3281 11.5512 14.6149 12.8462 13.3199C14.1375 12.0287 14.8525 10.3162 14.86 8.49863C14.8675 6.68119 14.1656 4.96305 12.8843 3.66129Z"
      fill="currentColor"
    />
  </svg>
);

export default RecoveryIcon;
