import React from 'react'

interface ICalendarIconPropTypes {
  
}

const CalendarIcon = ({}: ICalendarIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.8">
      <path fillRule="evenodd" clipRule="evenodd" d="M8.66634 2.83331C8.94248 2.83331 9.16634 3.05717 9.16634 3.33331V5.49998H22.833V3.33331C22.833 3.05717 23.0569 2.83331 23.333 2.83331C23.6091 2.83331 23.833 3.05717 23.833 3.33331V5.49998H28.6663C28.9425 5.49998 29.1663 5.72384 29.1663 5.99998V11.3333V28.6666C29.1663 28.9428 28.9425 29.1666 28.6663 29.1666H3.33301C3.05687 29.1666 2.83301 28.9428 2.83301 28.6666V11.3333V5.99998C2.83301 5.72384 3.05687 5.49998 3.33301 5.49998H8.16634V3.33331C8.16634 3.05717 8.3902 2.83331 8.66634 2.83331ZM8.16634 6.49998H3.83301V10.8333H28.1663V6.49998H23.833V8.66665C23.833 8.94279 23.6091 9.16665 23.333 9.16665C23.0569 9.16665 22.833 8.94279 22.833 8.66665V6.49998H9.16634V8.66665C9.16634 8.94279 8.94248 9.16665 8.66634 9.16665C8.3902 9.16665 8.16634 8.94279 8.16634 8.66665V6.49998ZM28.1663 11.8333H3.83301V28.1666H28.1663V11.8333Z" fill="#1A1A1A"/>
      </g>
    </svg>
  )
}

export default CalendarIcon;
