import React from 'react'
import styled from 'styled-components';
import classNames from 'classnames';
import { IAppContext } from "../../interfaces";

export interface ILoaderPropTypes {}

const LoaderContainer = styled.div`
  width: 30px;
  height: 30px;
  position: relative;

  span {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    animation: loading 1.5s infinite;
  }

  span:nth-child(1) {
    background-color: red;
  }

  span:nth-child(2) {
    animation-delay: 0.7s;
  }

  @keyframes loading {
    0% {
      top: 0;
      left: 0;
    }
    25% {
      top: 0;
      left: calc(100% - 10px);
      background-color: ${(p: IAppContext) =>
        p.theme.skye.colors.electricViolet};
    }
    50% {
      top: calc(100% - 10px);
      left: calc(100% - 10px);
      background-color: ${(p: IAppContext) =>
        p.theme.skye.colors.electricViolet};
    }
    75% {
      top: calc(100% - 10px);
      left: 0;
      background-color: ${(p: IAppContext) =>
        p.theme.skye.colors.electricViolet};
    }
    100% {
      top: 0;
      left: 0;
    }
  }
`;

const SquareLoader = ({}: ILoaderPropTypes) => {
  return (
    <LoaderContainer className={classNames(['loader loading'])}>
      <span></span>
      <span></span>
    </LoaderContainer>
  )
}

export default SquareLoader;
