import ReactGA from "react-ga4";
import { isProduction } from "../env";

export enum AnalyticEventCategory {
  AllPages = "all_pages",
  ModelDetailsPage = "model_details_page",
  ModelListPage = "model_list_page",
  PublicModelDetailsPage = "public_model_details_page",
  ModelViewerOpened = "model_viewer_opened",
  ViewerMeasurements = "viewer_measurement",
  ViewerScreenshot = "viewer_screenshot",
  ViewerReports = "viewer_reports",
}

export enum EventActionType {
  Click = "click",
  Load = "load",
  LoadError = "load_error",
}

export class AnalyticService {
  static event(
    category: AnalyticEventCategory,
    label: string,
    actionType = EventActionType.Click
  ): void {
    if (!isProduction()) return;

    ReactGA.event({
      category: category,
      label: label,
      action: actionType,
    });
  }
}
