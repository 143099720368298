import React from 'react'
import styled from 'styled-components';

interface IEyeIconPropTypes {
  
}

const EyeIcon = ({
  
}: IEyeIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path fillRule="evenodd" clipRule="evenodd" d="M0.94929 15.6793C4.63217 11.2709 10.0723 7.5 15.9997 7.5C21.927 7.5 27.3672 11.2709 31.0501 15.6793C31.2051 15.8649 31.2051 16.1349 31.0501 16.3204C27.3672 20.7288 21.927 24.5 15.9997 24.5C10.0724 24.5 4.6321 20.7288 0.94929 16.3204C0.794247 16.1349 0.794247 15.8649 0.94929 15.6793ZM1.98874 15.9999C5.5626 20.1464 10.6266 23.5 15.9997 23.5C21.3727 23.5 26.4368 20.1464 30.0106 15.9999C26.4367 11.8533 21.3728 8.5 15.9997 8.5C10.6266 8.5 5.56263 11.8533 1.98874 15.9999ZM10.1663 16C10.1663 12.7783 12.778 10.1667 15.9997 10.1667C19.2213 10.1667 21.833 12.7783 21.833 16C21.833 19.2217 19.2213 21.8333 15.9997 21.8333C12.778 21.8333 10.1663 19.2217 10.1663 16ZM15.9997 11.1667C13.3303 11.1667 11.1663 13.3306 11.1663 16C11.1663 18.6694 13.3303 20.8333 15.9997 20.8333C18.6691 20.8333 20.833 18.6694 20.833 16C20.833 13.3306 18.6691 11.1667 15.9997 11.1667ZM12.833 16C12.833 14.2511 14.2508 12.8333 15.9997 12.8333C16.2758 12.8333 16.4997 13.0572 16.4997 13.3333C16.4997 13.6095 16.2758 13.8333 15.9997 13.8333C14.8031 13.8333 13.833 14.8034 13.833 16C13.833 16.2761 13.6091 16.5 13.333 16.5C13.0569 16.5 12.833 16.2761 12.833 16Z" fill="#1A1A1A"/>
    </svg>
  )
}

export default EyeIcon;

