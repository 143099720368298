import React from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";
import CloseIcon from '../Icons/CloseIcon';
import Box, { BoxAlignedCenter, BoxCentered } from './Box';

interface IViewerSidePanelPropTypes {
  children: any,
  open: boolean,
  title: any,
  onClose: () => void,
}

const PANEL_WIDTH = 340;

const InnerContainerSideNav = styled(Box)`
  height: 100%;
  z-index: 1000;
  width: ${PANEL_WIDTH}px;
`;

const ContainerSideNav = styled(Box)`
  border: ${(p: IAppContext) => p.theme.skye.borders.primary};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  width: 100%;
  flex-direction: column;
  height: 100%;
  justify-content: flex-start;
  position: absolute;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  box-shadow: ${(p: IAppContext) => p.theme.skye.shadows.light};
`;
const OutterContainerSideNav = styled(Box)`
  height: 100%;
  position: relative;
  margin-left: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;
const TopRow = styled(Box)`
  align-items: center;
  justify-content: space-between;
  min-height: 60px;
  padding: 0 ${(p: IAppContext) => p.theme.skye.sizes.average};
  border-bottom: ${(p: IAppContext) => p.theme.skye.borders.primary};
`;
const Title = styled.h3`
  margin-bottom: 0;
`;
const TopRowRightSide = styled(BoxAlignedCenter)``;
const TopRowLeftSide = styled(BoxAlignedCenter)``;
const IconButton = styled(BoxCentered)`
  width: 33px;
  height: 33px;
  cursor: pointer;
`;
const MainContent = styled(Box)`
  padding: ${(p: IAppContext) => {
    const size = p.theme.skye.sizes.average;
    return `${size} ${size} ${size} ${size}`;
  }};
  flex-direction: column;
  overflow: auto;
  height: 100%;
`;

const ViewerSidePanel = ({
  children,
  open,
  title,
  onClose,
}: IViewerSidePanelPropTypes) => {
  return (
    <OutterContainerSideNav style={{ display: open ? "flex" : "none" }}>
      <InnerContainerSideNav>
        <ContainerSideNav>
          <TopRow className="comment-container-title-row">
            <TopRowLeftSide>
              <Title>{title}</Title>
            </TopRowLeftSide>
            <TopRowRightSide>
              <IconButton onClick={onClose}>
                <CloseIcon />
              </IconButton>
            </TopRowRightSide>
          </TopRow>
          <MainContent className="comment-container-main-box">
            {children}
          </MainContent>
        </ContainerSideNav>
      </InnerContainerSideNav>
    </OutterContainerSideNav>
  );
};

export default ViewerSidePanel;
