import React from 'react'

interface ICircleLoaderIconPropTypes {
  
}

const CircleLoaderIcon = ({
  
}: ICircleLoaderIconPropTypes) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="10" stroke="#E1E1E1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21 11C21 9.68678 20.7413 8.38642 20.2388 7.17317C19.7362 5.95991 18.9997 4.85752 18.0711 3.92893C17.1425 3.00035 16.0401 2.26375 14.8268 1.7612C13.6136 1.25866 12.3132 1 11 1" stroke="#5952FF" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default CircleLoaderIcon;
