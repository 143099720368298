import { memo } from "react";
import { MODEL_PUBLIC_DETAILS_PAGE_TAB_KEYS } from "../../pages.const";
import { ModelDetailsPageTabKeys } from "../../../../../const/global";
import SidebarTab from "../../../components/SidebarTab";

interface ISidebarPropTypes {
  currentTabKey: string;
  setCurrentTabKey: (id: ModelDetailsPageTabKeys) => void;
}

const Sidebar = ({ currentTabKey, setCurrentTabKey }: ISidebarPropTypes) => {
  return (
    <>
      {MODEL_PUBLIC_DETAILS_PAGE_TAB_KEYS.map((tab) => (
        <SidebarTab
          key={tab.key}
          active={tab.key === currentTabKey}
          onClick={(id: ModelDetailsPageTabKeys) => setCurrentTabKey(id)}
          tab={tab}
        />
      ))}
    </>
  );
};

export default memo(Sidebar);
