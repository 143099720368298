import { ILocation } from "models/Model";

export const trimStringIfToLong = (
  text: string,
  maxLength: number,
  addDots = true
): string => {
  if (!text) return '';
  const dots = addDots ? '…' : '';

  return text.length > maxLength
    ? text.substr(0, maxLength) + dots
    : text;
};

export const getLocationString = (point: ILocation) => {
  return `${point.latitude.toFixed(6)}, ${point.longitude.toFixed(6)}`;
}

export const ensureHttps = (url: string) => {
  if (!/^https?:\/\//i.test(url)) {
    return "https://" + url;
  }
  return url;
};