import React from 'react'

interface IPrivateIconPropTypes {
  
}

const PrivateIcon = ({
  
}: IPrivateIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.9998 12.6666H27.3332V28.6666H4.6665V12.6666H9.99984M21.9998 12.6666L21.9933 9.31841C21.9869 6.01105 19.304 3.33331 15.9966 3.33331C12.6847 3.33331 9.99984 6.01815 9.99984 9.33006V12.6666M21.9998 12.6666H9.99984M15.9998 21.6666V21.3333C15.9998 20.781 16.4616 20.3511 16.9794 20.1592C17.9675 19.7932 18.6665 18.8898 18.6665 17.8333C18.6665 16.4526 17.4726 15.3333 15.9998 15.3333C14.8388 15.3333 13.6665 16.1872 13.6665 17.4M15.9998 25.3333C15.6316 25.3333 15.3332 25.0348 15.3332 24.6666C15.3332 24.2985 15.6316 24 15.9998 24C16.368 24 16.6665 24.2985 16.6665 24.6666C16.6665 25.0348 16.368 25.3333 15.9998 25.3333Z" stroke="black" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default PrivateIcon;
