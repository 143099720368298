import { memo } from "react";
import InformationBox from "../../../../shared/components/InformationBox/InformationBox";
import { IPublicModel } from "../../../../../models/Model";
import ModelRenewActionButtonComponent from "../../../payments/ModelRenewActionButton.component";

interface IAccessBlockedInfoPropTypes {
  model: IPublicModel;
  allowRenewButton?: boolean;
}

const ModelAccessBlockedInfo = ({
  model,
  allowRenewButton,
}: IAccessBlockedInfoPropTypes) => {
  return (
    <div className="w-full h-full rounded-lg bg-gray-50 flex flex-row items-center justify-center">
      <div className="block flex flex-col items-center">
        <InformationBox
          transparent
          title="Access blocked"
          description="This model is no longer available."
          icon={"error_2"}
          button={
            allowRenewButton && (
              <ModelRenewActionButtonComponent model={model} />
            )
          }
        />
      </div>
    </div>
  );
};

export default memo(ModelAccessBlockedInfo);
