import React from 'react'

interface IProfilePanelIconPropTypes {
  
}

const ProfilePanelIcon = ({
  
}: IProfilePanelIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M25.2285 13.2276L25.5293 13.627L26.3281 13.0255L26.0274 12.6261L25.2285 13.2276ZM22.2146 8.39348L22.6141 8.09273L22.6126 8.09077L22.2146 8.39348ZM21.4306 8.06482L21.3571 7.57024L21.355 7.57056L21.4306 8.06482ZM20.7639 8.61108L20.2991 8.42694L20.2985 8.42839L20.7639 8.61108ZM16.9836 18.2421L16.6195 18.5847C16.7369 18.7095 16.9101 18.7655 17.0783 18.733C17.2465 18.7006 17.3864 18.5842 17.449 18.4247L16.9836 18.2421ZM14.4716 15.5731L14.1065 15.9147L14.1075 15.9157L14.4716 15.5731ZM13.7836 15.3113L13.8369 15.8084L13.8388 15.8082L13.7836 15.3113ZM13.1575 15.7102L12.7343 15.4439L12.7339 15.4445L13.1575 15.7102ZM9.32061 20.8845L9.05486 21.308L9.90193 21.8395L10.1677 21.4159L9.32061 20.8845ZM5.3335 28H4.8335V28.5H5.3335V28ZM26.0274 12.6261L22.614 8.09273L21.8152 8.69423L25.2285 13.2276L26.0274 12.6261ZM22.6126 8.09077C22.3183 7.7039 21.843 7.49806 21.3571 7.57025L21.5041 8.55939C21.6134 8.54314 21.7333 8.58666 21.8167 8.69619L22.6126 8.09077ZM21.355 7.57056C20.8728 7.64428 20.477 7.97788 20.2991 8.42694L21.2288 8.79522C21.2835 8.65722 21.3953 8.57602 21.5062 8.55907L21.355 7.57056ZM20.2985 8.42839L16.5182 18.0594L17.449 18.4247L21.2294 8.79377L20.2985 8.42839ZM17.3477 17.8994L14.8357 15.2304L14.1075 15.9157L16.6195 18.5847L17.3477 17.8994ZM14.8367 15.2315C14.5542 14.9296 14.148 14.7677 13.7284 14.8143L13.8388 15.8082C13.9291 15.7982 14.0284 15.8312 14.1065 15.9147L14.8367 15.2315ZM13.7304 14.8141C13.3179 14.8583 12.9541 15.0945 12.7343 15.4439L13.5807 15.9764C13.6446 15.8749 13.7416 15.8186 13.8369 15.8084L13.7304 14.8141ZM12.7339 15.4445L9.32061 20.8845L10.1677 21.4159L13.581 15.9759L12.7339 15.4445ZM4.8335 5.33337V28H5.8335V5.33337H4.8335ZM5.3335 28.5H26.6668V27.5H5.3335V28.5Z" fill="black"/>
    </svg>
  )
}

export default ProfilePanelIcon;
