import React from 'react'

const AngleIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M28 25.3333H4L16 5.33331M27.4867 20.228H27.5067M26.02 15.6946H26.04M23.62 11.5613H23.64M20.5533 7.96131H20.5733"
        stroke="#1A1A1A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AngleIcon;
