import { memo, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import Dialog from "../../shared/components/Dialog/Dialog";
import InputGroup from "../../shared/form/InputGroup";
import { isUrl } from "../../shared/utils/validationUtils";

export interface IViewerAnnotationDialogValues {
  title: string;
  description: string;
  url: string;
}

interface IViewerAnnotationToolDialogPropTypes {
  open: boolean;
  values: IViewerAnnotationDialogValues;
  onSubmit: () => void;
  onClose: () => void;
  onChange: (values: IViewerAnnotationDialogValues) => void;
}

const ViewerAnnotationToolDialog = ({
  open,
  values,
  onClose,
  onChange,
  onSubmit,
}: IViewerAnnotationToolDialogPropTypes) => {
  const titleInputRef = useRef(null);
  const { t } = useTranslation();

  const handleChange = (newValues: Partial<IViewerAnnotationDialogValues>) => {
    onChange({
      ...values,
      ...newValues,
    });
  };

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        titleInputRef.current?.focus();
      }, 100);
    }
  }, [open]);

  const validationResult = values.url.length > 5 && isUrl(values.url);
  const urlInvalid = validationResult === "validationErrUrl";
  const urlExistAndInvalid = values.url.length && urlInvalid;

  const confirmDisabled = !values.title || urlExistAndInvalid;

  return (
    <Dialog
      title={t("annotationToolDialogTitle")}
      open={open}
      onClose={onClose}
      confirmButtonProps={{
        id: "annotation-dialog-confirm-button",
        onClick: onSubmit,
        disabled: confirmDisabled,
      }}
    >
      <div className="dialog-content">
        <InputGroup
          bordered
          required
          label="annotationName"
          inputProps={{
            placeholder: "annotationNamePlaceholder",
            id: "annotation-title-input",
            ref: titleInputRef,
            value: values.title,
            onChange: (e: any) => handleChange({ title: e.target.value }),
          }}
        />
        <InputGroup
          bordered
          invalid={urlInvalid}
          invalidMessage={t(validationResult)}
          label="annotationUrl"
          inputProps={{
            placeholder: "annotationUrlPlaceholder",
            id: "annotation-url-input",
            value: values.url,
            onChange: (e: any) => handleChange({ url: e.target.value }),
          }}
        />
      </div>
    </Dialog>
  );
};

export default memo(ViewerAnnotationToolDialog);
