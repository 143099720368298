import React, { ReactNode } from 'react'
import styled from 'styled-components';
import Paper from './Paper';
import { IAppContext } from "../../interfaces";
import Box from "./Box";
import CircleLoader from "./CircleLoader";

interface ICardProps {
  children: ReactNode;
  footerContent?: ReactNode;
  title?: ReactNode;
  loading?: boolean;
}

const Content = styled(Box as any)`
  padding: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  flex-direction: column;
`;

const StyledCard = styled(Paper)`
  box-shadow: ${(p: IAppContext) => p.theme.skye.shadows.primary};
  border-radius: 8px;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.large};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
`;

const FooterContent = styled(Content)`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

const TitleContent = styled(Content)`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
`;

const LoadingContent = styled(Box)`
  align-items: center;
  justify-content: center;
`;

const Card = ({ children, footerContent, title, loading }: ICardProps) => {
  const renderContent = () =>
    !loading ? (
      children
    ) : (
      <LoadingContent>
        <CircleLoader size={30} color="primary" />
      </LoadingContent>
    );

  return (
    <StyledCard>
      {!!title && (
        <TitleContent>
          <Title>{title}</Title>
        </TitleContent>
      )}
      <Content className="card-content">{renderContent()}</Content>
      {!!footerContent && <FooterContent>{footerContent}</FooterContent>}
    </StyledCard>
  );
};

export default Card;
