import React from 'react'
import styled from 'styled-components';

interface IOkCircleIconPropTypes {
  
}

const CheckCircleIcon = ({
  
}: IOkCircleIconPropTypes) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
    <circle cx="11" cy="11" r="10" stroke="#2BC605" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16.5303 7.46967C16.8232 7.76256 16.8232 8.23744 16.5303 8.53033L9.86366 15.197C9.72301 15.3376 9.53225 15.4167 9.33333 15.4167C9.13442 15.4167 8.94366 15.3376 8.803 15.197L5.46967 11.8637C5.17678 11.5708 5.17678 11.0959 5.46967 10.803C5.76256 10.5101 6.23744 10.5101 6.53033 10.803L9.33333 13.606L15.4697 7.46967C15.7626 7.17678 16.2374 7.17678 16.5303 7.46967Z" fill="#2BC605"/>
    </svg>
  )
}

export default CheckCircleIcon;
