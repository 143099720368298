import React from "react";
import styled from "styled-components";
import { trimStringIfToLong } from "utils/stringUtils";
import { IAppContext } from "../../../interfaces";
import Box from "../../../shared/components/Box";
import CloseIcon from "../../../shared/Icons/CloseIcon";
import EyeIcon from "../../../shared/Icons/EyeIcon";
import classNames from "classnames";
import EyeClosedIcon from "../../../shared/Icons/EyeClosedIcon";
import { useTranslation } from "react-i18next";

interface IViewerObjectTreeItemPropTypes {
  label: string;
  visible: boolean;
  showVisibility: boolean;
  Icon: any;
  onClick: () => void;
  onDelete: () => void;
  onVisibilityChange: (newVisible: boolean) => void;
}

const ICON_SIZE = "18px";

const ObjectContainer = styled(Box)`
  cursor: pointer;
  height: 32px;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  align-items: center;
  justify-content: space-between;
  padding-right: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  padding-left: ${(p: IAppContext) => p.theme.skye.sizes.small};
  margin-bottom: 6px;

  .eye-icon,
  .close-icon {
    display: none;
  }

  &:hover {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.lavender};
    color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};

    .eye-icon,
    .close-icon {
      display: flex;
    }
  }

  &.hidden {
    opacity: 0.5;

    .eye-icon {
      display: flex;
    }

    &:hover {
      opacity: 1;
    }
  }
`;

const Label = styled.h5`
  margin-top: 0;
  margin-bottom: 0;
  margin-left: ${(p: IAppContext) => p.theme.skye.sizes.small};
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
`;

const IconsContainer = styled(Box)`
  padding-right: 4px;
`;
const TypeIconContainer = styled(Box)`
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  align-items: center;
`;
const LeftSide = styled(Box)`
  align-items: center;
  justify-content: flex-start;
`;
const IconButton = styled(Box)`
  cursor: pointer;
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  align-items: center;
  margin-left: ${(p: IAppContext) => p.theme.skye.sizes.radius};
`;

const ViewerObjectTreeItem = ({
  label,
  visible,
  showVisibility,
  Icon,
  onDelete,
  onVisibilityChange,
  onClick,
}: IViewerObjectTreeItemPropTypes) => {
  const { t } = useTranslation();

  return (
    <ObjectContainer
      data-label={label}
      className={classNames(["object-tree-element", { hidden: !visible }])}
      onClick={onClick}
    >
      <LeftSide>
        <TypeIconContainer>
          <Icon />
        </TypeIconContainer>
        <Label>{trimStringIfToLong(t(label), 15)}</Label>
      </LeftSide>
      <IconsContainer>
        {showVisibility && (
          <IconButton
            className="eye-icon"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              onVisibilityChange(!visible);
            }}
          >
            {visible ? <EyeIcon /> : <EyeClosedIcon />}
          </IconButton>
        )}
        <IconButton
          className="close-icon"
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }}
        >
          <CloseIcon />
        </IconButton>
      </IconsContainer>
    </ObjectContainer>
  );
};

export default ViewerObjectTreeItem;
