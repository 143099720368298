import { memo } from "react";
import { useTranslation } from "react-i18next";
import { useModelPayment } from "./useModelPayment";
import Button, { IButtonPropTypes } from "../../shared/buttons/Button";
import { IPublicModel } from "../../../models/Model";

interface IModelPaidActionButtonPropTypes {
  model: IPublicModel;
  hasPremiumAccess: boolean;
  onClick: () => void;
  buttonProps: Omit<IButtonPropTypes, "onClick" | "children">;
  hasAccessLabel?: string;
  upgraadeAccessLabel?: string;
}

const ModelPaidActionButton = ({
  model,
  buttonProps,
  hasPremiumAccess,
  hasAccessLabel,
  upgraadeAccessLabel,
  onClick,
}: IModelPaidActionButtonPropTypes) => {
  const paymentWidget = useModelPayment();
  const { t } = useTranslation();

  const hasAccessTranslation = hasAccessLabel || t("download");
  const upgraadeAccessTranslation = upgraadeAccessLabel || t("upgrade");
  const translation = hasPremiumAccess
    ? hasAccessTranslation
    : upgraadeAccessTranslation;

  const handleClick = () => {
    if (hasPremiumAccess) {
      onClick();
      return;
    }

    paymentWidget.openWidget(model.id, model.accessType);
  };

  return (
    <Button {...buttonProps} onClick={handleClick} children={translation} />
  );
};

export default memo(ModelPaidActionButton);
