import { useEffect, useState } from "react";
import {
  ViewerEventName,
  useViewerEventEmitter,
} from "../context/ViewerEventEmitter";
import { ViewerService } from "../services/ViewerService";

// Only Y axis is supported for now
type IAxis = "y";

const texturesExist = () => {
  return window.viewer.scene.textures.length > 0;
};

const pointcloudsExist = () => {
  return window.viewer.scene.pointclouds.length > 0;
};

const rotateTexturesY = (newRotationValue: number) => {
  if (texturesExist()) {
    window.viewer.scene.textures[0].rotation.y = newRotationValue;
  }
};

const rotatePointcloudsY = (newRotationValue: number) => {
  if (pointcloudsExist()) {
    window.viewer.scene.pointclouds[0].rotation.y = newRotationValue;
    window.viewer.scene.pointclouds[0].position.x =
      -window.viewer.scene.pointclouds[0].position.x;
    window.viewer.scene.pointclouds[0].position.z =
      -window.viewer.scene.pointclouds[0].position.z;
  }
};

const rotateY = (newRotationValue: number) => {
  rotateTexturesY(newRotationValue);
  rotatePointcloudsY(newRotationValue);
};

export const useReorientTool = () => {
  const vs = new ViewerService();
  const eventEmitter = useViewerEventEmitter();
  const [reoriented, setReoriented] = useState(false);
  const [rotation, setRotation] = useState<Record<IAxis, number>>({ y: 0 });

  useEffect(() => {
    eventEmitter.subscribe(ViewerEventName.TexturesLoaded, () => {
      setRotation((prev) => {
        if (prev.y) rotateTexturesY(prev.y);
        return prev;
      });
    });
  }, [eventEmitter]);

  const rotate = (rotateBy: number, axis: IAxis) => {
    if (!window.viewer) return;
    setReoriented(!reoriented);
    eventEmitter.trigger(ViewerEventName.Rotate, { axis, value: rotateBy });
    vs.closeProfileWindow();

    const rotateMap = {
      y: rotateY,
    };

    setRotation((prev) => {
      let newRotation = prev[axis] + rotateBy;
      rotateMap[axis](newRotation);

      return {
        ...prev,
        [axis]: prev[axis] + rotateBy,
      };
    });
  };

  const rotate180 = (axis: IAxis) => {
    rotate(Math.PI, axis);
  };

  return {
    reoriented,
    rotate180,
  };
};
