import { ModelVideoService } from "../../../services/ModelVideoService";
import {
  MultipartUploadConfig,
  MultipartUploadService,
  UploadProgress,
} from "./MultipartUploadService";

export interface ModelVideoUploadConfig extends MultipartUploadConfig {
  modelId: string;
  modelInputId: string;
}

export class ModelVideoUploadService extends MultipartUploadService {
  modelId: string;
  modelInputId: string;

  constructor(
    file: File,
    config: ModelVideoUploadConfig,
    uploadProgress?: UploadProgress
  ) {
    super(file, config, uploadProgress);

    const { modelId, modelInputId } = config;
    this.file = file;
    this.config = config;
    this.modelId = modelId;
    this.modelInputId = modelInputId;
  }

  async signPartRequest() {
    const presignedResponse = await ModelVideoService.signUploadPart(
      this.modelId,
      this.modelInputId,
      this.uploadId,
      this.partNumber,
      this.file.name
    );
    const { presignedUrl } = presignedResponse.data;
    return presignedUrl;
  }

  async initializeRequest() {
    const initiateResponse = await ModelVideoService.initUpload(
      this.modelId,
      this.modelInputId,
      this.file.name
    );
    const { uploadId, presignedUrl } = initiateResponse.data;
    return { uploadId, presignedUrl };
  }

  async finalizeUploadRequest() {
    await ModelVideoService.completeUpload(
      this.modelId,
      this.modelInputId,
      this.uploadId,
      this.file.name,
      this.parts
    );
  }
}
