import { memo } from "react";

const ReorientIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M5.85806 14.125C6.73988 9.32491 10.9449 5.68753 16 5.68753M14.125 26.142C9.32487 25.2602 5.6875 21.055 5.6875 16M26.1419 17.875C25.2601 22.6751 21.0551 26.3125 16 26.3125M17.875 5.85809C22.6751 6.73984 26.3125 10.945 26.3125 16"
        stroke="#1A1A1A"
        strokeMiterlimit="22.926"
      />
      <path
        d="M28.0803 14.2322L26.3125 16L24.5447 14.2322M7.4553 17.7677L5.68755 16L3.9198 17.7677M14.2323 7.45529L16 5.68748L14.2323 3.91973M17.7678 28.0802L16 26.3125L17.7678 24.5447"
        stroke="#1A1A1A"
        strokeMiterlimit="22.926"
      />
      <path
        d="M16 14.6154L20 13V18.3846L16 20L12 18.3846V13L16 14.6154Z"
        stroke="#1A1A1A"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16 14.4615L12 13L16 11.5L20 13L16 14.4615ZM16 14.4615V20"
        stroke="#1A1A1A"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default memo(ReorientIcon);
