import React from "react";
import styled from "styled-components";
import SkyeTag, { getModelTagColor } from "views/shared/components/SkyeTag";
import SkyeTooltip from "views/shared/components/SkyeTooltip";
import { BrowseType, ModelResolution } from "models/Model";
import Box from "views/shared/components/Box";
import { useTranslation } from "react-i18next";
import { IAppContext } from "views/interfaces";

interface IModelListElementTagsPropTypes {
  tags: string[];
  browseType: BrowseType;
  resolution: ModelResolution;
}

const MAX_TAGS_IN_ROW = 3;

const TagsWrapper = styled(Box)`
  justify-content: flex-start;
  flex-wrap: wrap;

  .ant-tag {
    margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  }
`;

const ModelListElementTags = ({
  tags,
  browseType,
  resolution,
}: IModelListElementTagsPropTypes) => {
  const { t } = useTranslation();
  tags = tags ?? [];
  const filteredTags = tags.filter((tag) => !!tag);
  const visibleTags = [...filteredTags].splice(0, MAX_TAGS_IN_ROW - 1);
  const modelTagLabel = browseType;
  const notVisibletags = [...filteredTags].splice(
    MAX_TAGS_IN_ROW - 1,
    filteredTags.length
  );

  return (
    <TagsWrapper>
      {filteredTags && (
        <>
          {!!modelTagLabel && (
            <SkyeTag color={getModelTagColor(browseType)}>
              {modelTagLabel}
            </SkyeTag>
          )}
          {resolution === ModelResolution.fourK && (
            <SkyeTag color="ultra">{t("ultra")}</SkyeTag>
          )}
          {visibleTags.map((tag) => (
            <SkyeTag key={tag}>{tag}</SkyeTag>
          ))}
          {!!notVisibletags.length && (
            <SkyeTooltip
              title={
                <div>
                  {notVisibletags.map((tag) => (
                    <div key={tag}>{tag}</div>
                  ))}
                </div>
              }
            >
              <SkyeTag
                style={{ cursor: "pointer" }}
              >{`${notVisibletags.length}+`}</SkyeTag>
            </SkyeTooltip>
          )}
        </>
      )}
    </TagsWrapper>
  );
};

export default ModelListElementTags;
