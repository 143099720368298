import classNames from "classnames";
import React from "react";
import styled from "styled-components";
import { IAppContext } from "views/interfaces";
import Box, { BoxCentered } from "../../Box";

interface IBadgePropTypes {
  color?: "danger" | "accent" | "grey" | "success";
  fontSize?: number;
  children: any;
  endIcon?: any;
}

const HostTag = styled(Box)`
  align-items: center;
  height: 1.66em;
  border-radius: 0.88em;
  padding: 0 0.66em;
  text-transform: uppercase;
  margin-left: ${(p: IAppContext) => p.theme.skye.sizes.radius};

  &.accent {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.accent};
    color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
  }

  &.danger {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.dangerHeavy};
    color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  }

  &.grey {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
    color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};

    svg {
      path {
        fill: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
      }
    }
  }

  &.success {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.success};
    color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  }
`;
const EndIconWrapper = styled(BoxCentered)`
  margin-left: 0.3em;
`;

const Badge = ({
  children,
  color = "accent",
  fontSize = 9,
  endIcon,
}: IBadgePropTypes) => {
  return (
    <HostTag
      className={classNames([color, "badge"])}
      style={{ fontSize: `${fontSize}px` }}
    >
      {children}
      {Boolean(endIcon) && (
        <EndIconWrapper className="badge-end-icon-wrapper">
          {endIcon}
        </EndIconWrapper>
      )}
    </HostTag>
  );
};

export default Badge;
