import React, { useCallback, useMemo } from "react";
import styled from "styled-components";
import { observer, inject } from "mobx-react";
import { IVideoUploadStore } from "stores/VideoUploadStore";
import Box from "../../../shared/components/Box";
import { IAppContext } from "views/interfaces";
import FilesUploadProgres from "../../../shared/components/FilesUploadProgres";
import SkyePrompt from "../../../shared/components/SkyePrompt";
import useManageUploads from "./hook/useManageUploads";
import { UploadStatus } from "../../../shared/components/FilesUploadProgres/FilesUploadProgresElement";

interface IVideosUploadsLoadersContainerPropTypes {
  VideoUploadStore?: IVideoUploadStore;
}

const UploadsContainer = styled(Box)`
  position: fixed;
  bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  right: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

/**
 * Container for the files upload loaders.
 * Also main container for the management of the upload queue.
 */
const UploadsManager = ({
  VideoUploadStore,
}: IVideosUploadsLoadersContainerPropTypes) => {
  const [{ cancelUpload }] = useManageUploads({
    VideoUploadStore,
  });
  const queueUploads = VideoUploadStore.getQueued.map((queue) => ({
    id: queue.id,
    name: queue.file.name,
    progress: 0,
    status: UploadStatus.Pending,
  }));

  const currentUploadsList = VideoUploadStore.inProgressArray.map((upload) => ({
    id: upload.id,
    name: upload.id,
    progress: upload.progress,
    status: UploadStatus.InProgress,
  }));

  const allUploads = useMemo(
    () => [...queueUploads, ...currentUploadsList],
    [queueUploads, currentUploadsList]
  );
  const isLoading = allUploads.length > 0;

  const overallProgress = useCallback(() => {
    const totalProgress = allUploads.reduce((acc, upload) => {
      return acc + upload.progress;
    }, 0);

    return Math.floor(totalProgress / allUploads.length);
  }, [allUploads]);

  /**
   * Do not render component when queue/uploads are empty.
   */
  if (allUploads.length === 0) {
    return null;
  }

  return (
    <UploadsContainer>
      <SkyePrompt active={isLoading} />
      <FilesUploadProgres
        uploads={allUploads}
        loading={isLoading}
        overallProgress={overallProgress()}
        onDelete={cancelUpload}
        onCancel={cancelUpload}
      />
    </UploadsContainer>
  );
};

export default inject("VideoUploadStore")(observer(UploadsManager));
