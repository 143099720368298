
export const ULTRA_TAG = { id: Math.random(), text: 'ultra' };
export const WIDEBROWSE_TAG = { id: Math.random(), text: 'widebrowse' };
export const SKYEBROWSE_TAG = { id: Math.random(), text: 'skyebrowse' };
export const HYPER_TAG = { id: Math.random(), text: 'hyper' };

export const DEFAULT_TAGS = [
  SKYEBROWSE_TAG,
  WIDEBROWSE_TAG,
  ULTRA_TAG,
  HYPER_TAG,
];

export class TagService {
  static formatTag(phrase) {
    return phrase
      .toLocaleLowerCase()
      .replace(/\W/g, '')
      .replace(' ', '');
  }

  static filterDefaultTags(tags: string[]) {
    return tags.filter(tag => !TagService.isDefaultTag(tag));
  }

  static isDefaultTag(tag: string) {
    return DEFAULT_TAGS.find(t => t.text === tag.toLocaleLowerCase());
  }
}