import { inject, observer } from 'mobx-react';
import { ServiceType } from "../../../models/ServicePlan";
import React, { useEffect, useState } from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { PermissionService } from "../../../services/PermissionService";
import { IGlobalStore } from "../../../stores/GlobalStore";

interface IPermissionGuardPropTypes extends RouteComponentProps {
  servicePlan: ServiceType,
  children: any,
  GlobalStore?: IGlobalStore,
}

const RoutePermissionGuard = ({
  servicePlan,
  children,
  GlobalStore,
  history,
}: IPermissionGuardPropTypes) => {
  const [hasPermission, setHasPermission] = useState(false);

  const check = () => {
    if (
      GlobalStore.user &&
      PermissionService.hasService(GlobalStore.user, servicePlan)
    ) {
      setHasPermission(true);
      return;
    }
    history.push("/");
  };

  useEffect(() => {
    check();
  }, [check]);

  // Hide Tacbrowse module on freemium
  if (
    servicePlan === ServiceType.Tacbrowse &&
    !GlobalStore.user?.organization?.active
  ) {
    return null;
  }

  if (!hasPermission) {
    return null;
  }

  return <>{children}</>;
}

export default inject('GlobalStore')(observer(withRouter(RoutePermissionGuard)));
