import React from "react";
import CircleLoader from "../../../../../shared/components/CircleLoader";
import "./Placeholder.css";

const Placeholder = () => {
  return (
    <div className="Placeholder">
      <CircleLoader size={40} color="primary" />
    </div>
  );
};

export default Placeholder;
