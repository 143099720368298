const ArchiveIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.08873 1.2525L0.937473 2.99622C0.899973 3.04872 0.869973 3.10872 0.847473 3.16496H11.1525C11.13 3.10871 11.1 3.04871 11.0625 2.99622L9.91122 1.2525C9.70122 0.9375 9.35247 0.75 8.97372 0.75H3.02628C2.64753 0.75 2.29873 0.9375 2.08873 1.2525Z"
      fill="currentColor"
    />
    <path
      d="M1.5 3.91492H0.75V10.1249C0.75 10.7437 1.25626 11.2499 1.875 11.2499H10.125C10.7437 11.2499 11.25 10.7437 11.25 10.1249V3.91492H1.5ZM7.545 5.65492H4.455C4.24874 5.65492 4.08 5.48617 4.08 5.27992C4.08 5.06992 4.24874 4.90492 4.455 4.90492H7.545C7.75126 4.90492 7.92 5.06992 7.92 5.27992C7.92 5.48617 7.75126 5.65492 7.545 5.65492Z"
      fill="currentColor"
    />
  </svg>
);

export default ArchiveIcon;
