import React from "react";

const RefreshIcon = () => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.46889 9.10855C2.91051 5.3403 6.11364 2.41675 10.0003 2.41675C12.1032 2.41675 14.016 3.30984 15.3687 4.64411L15.3727 4.64798L15.3726 4.648L16.5837 5.86916V2.91675C16.5837 2.64061 16.8075 2.41675 17.0837 2.41675C17.3598 2.41675 17.5837 2.64061 17.5837 2.91675V7.08342C17.5837 7.35956 17.3598 7.58342 17.0837 7.58342H12.917C12.6409 7.58342 12.417 7.35956 12.417 7.08342C12.417 6.80727 12.6409 6.58342 12.917 6.58342H15.8836L14.6646 5.35417C13.4828 4.18948 11.8173 3.41675 10.0003 3.41675C6.62683 3.41675 3.84536 5.95458 3.4621 9.22495C3.42995 9.49921 3.18156 9.69549 2.9073 9.66335C2.63303 9.63121 2.43675 9.38282 2.46889 9.10855ZM17.0934 10.3368C17.3676 10.369 17.5639 10.6173 17.5318 10.8916C17.0901 14.6599 13.887 17.5834 10.0003 17.5834C7.89749 17.5834 5.98464 16.6903 4.63192 15.3561L4.62799 15.3522L4.62801 15.3522L3.41699 14.131V17.0834C3.41699 17.3596 3.19313 17.5834 2.91699 17.5834C2.64085 17.5834 2.41699 17.3596 2.41699 17.0834V12.9167C2.41699 12.6406 2.64085 12.4167 2.91699 12.4167H7.08366C7.3598 12.4167 7.58366 12.6406 7.58366 12.9167C7.58366 13.1929 7.3598 13.4167 7.08366 13.4167H4.11702L5.33605 14.646C6.5178 15.8107 8.1833 16.5834 10.0003 16.5834C13.3738 16.5834 16.1553 14.0456 16.5386 10.7752C16.5707 10.501 16.8191 10.3047 17.0934 10.3368Z"
        fill="#8F8F8F"
      />
    </svg>
  );
};

export default RefreshIcon;
