import React from "react";
import styled from "styled-components";
import { IAppContext } from "../../../interfaces";
import Box from "../../../shared/components/Box";
import PaidAccessBox, {
  IPaidAccessInfoBoxProps,
} from "./PaidAccessInfoBox.component";

const WrapperOutter = styled(Box)`
  align-items: flex-end;
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(243, 243, 243, 0.7);
  z-index: 10;
  padding: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;
const WrapperInner = styled(Box)`
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`;

interface IPaidAccessOverlayPropTypes extends IPaidAccessInfoBoxProps {}

const PaidAccessOverlay = (props: IPaidAccessOverlayPropTypes) => {
  const {
    showPayPerModelButton: showButton,
    payPerModelButtonProps: buttonProps,
  } = props;

  if (showButton && !buttonProps) {
    throw new Error("You must provide buttonProps if showButton is true");
  }

  return (
    <WrapperOutter
      id="measurements-subscription-info"
      className="measurements-subscription-info"
    >
      <WrapperInner>
        <PaidAccessBox {...props} />
      </WrapperInner>
    </WrapperOutter>
  );
};

export default PaidAccessOverlay;
