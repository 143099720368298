import { inject, observer } from "mobx-react";
import { UserHelpers } from "../../../../../models/User";
import React from "react";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IGlobalStore } from "../../../../../stores/GlobalStore";
import styled from "styled-components";
import { trimStringIfToLong } from "../../../../../utils/stringUtils";
import { IAppContext } from "../../../../interfaces";
import { PROFILE_ROUTES } from "../../../../routes";
import Button from "../../../buttons/Button";
import SkyeDropdown, {
  DropdownWrapper,
} from "../../../components/Dropdown/Dropdown";
import DropdownItem from "../../../components/Dropdown/DropdownItem";
import ArrowDownIcon from "../../../Icons/ArrowDownIcon";
import UserCircleIcon from "../../../Icons/UserCircleIcon";
import UserIcon from "../../../Icons/UserIcon";
import LogoutIcon from "../../../Icons/LogoutIcon";
import SubscriptionIcon from "../../../Icons/SubscriptionIcon";
import { isProduction } from "../../../../../env";
import PremiumBadge from "../../badges/PremiumBadge/PremiumBadge";
import BorderedBadge from "../../badges/BorderedBadge/BorderedBadge";
import { useStripeSubscription } from "../../../../payments/stripe/useStripeSubscription";

interface INavUserDetailsDropdownPropTypes extends RouteComponentProps {
  GlobalStore?: IGlobalStore;
}

const Wrapper = styled.div`
  .btn {
    padding: 0 12px;

    .start-icon {
      margin-right: 4px;
    }

    .end-icon {
      margin-left: 10px;
    }
  }
`;

const UserDetails = styled.div`
  text-align: left;
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
  font-size: 12px;
  padding-left: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;
const UserName = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  letter-spacing: 0.1px;
`;

const NavUserDetailsDropdown = ({
  history,
  GlobalStore,
}: INavUserDetailsDropdownPropTypes) => {
  const { t } = useTranslation();
  const stripe = useStripeSubscription();
  const logout = async () => {
    GlobalStore.logout();
    history.replace("/login");
  };

  const goToOrganizationDetails = () => {
    history.push(PROFILE_ROUTES.account);
  };

  const goToSubscriptionPage = () => {
    stripe.openSubscriptionPage(GlobalStore.user.email);
    history.push(PROFILE_ROUTES.subscription);
  };

  return (
    <Wrapper>
      <SkyeDropdown
        trigger={["click"]}
        overlay={
          <DropdownWrapper>
            <DropdownItem
              startIcon={<UserIcon />}
              onClick={goToOrganizationDetails}
            >
              {t("profile") as string}
            </DropdownItem>
            {!isProduction() &&
              GlobalStore.isOwner() &&
              GlobalStore.user?.organization?.subscription?.type
                ?.stripeProductId && (
                <DropdownItem
                  startIcon={<SubscriptionIcon />}
                  onClick={goToSubscriptionPage}
                >
                  {t("subscription")}
                </DropdownItem>
              )}
            <DropdownItem startIcon={<LogoutIcon />} onClick={logout}>
              {t("logout") as string}
            </DropdownItem>
          </DropdownWrapper>
        }
        onChange={() => {}}
      >
        <Button
          type="transparent"
          color="dark"
          startIcon={
            <div className="w-16 h-16 flex items-center">
              <UserCircleIcon />
            </div>
          }
          endIcon={<ArrowDownIcon />}
        >
          <UserDetails>
            <UserName>
              {trimStringIfToLong(
                UserHelpers.getUserName(GlobalStore.user),
                22
              )}
            </UserName>
            <div className="mt-0.5">
              {GlobalStore.user.organization.active ? (
                <PremiumBadge>
                  {GlobalStore.user.organization?.subscription?.type?.value}
                </PremiumBadge>
              ) : (
                <BorderedBadge color="danger">
                  {t("subscription.freemium")}
                </BorderedBadge>
              )}
            </div>
          </UserDetails>
        </Button>
      </SkyeDropdown>
    </Wrapper>
  );
};

export default inject("GlobalStore")(
  observer(withRouter(NavUserDetailsDropdown))
);
