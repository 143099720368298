import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import Button from "../../../shared/buttons/Button";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { FacebookConversionApi } from "../../../analytic/facebook/conversion.api";

export interface CheckoutFormProps {
  returnUrl: string;
  userFirstName: string;
  userLastName: string;
  userEmail: string;
  amountInDolars: number;
}

const CheckoutForm = ({
  returnUrl,
  userFirstName,
  userLastName,
  userEmail,
  amountInDolars,
}: CheckoutFormProps) => {
  const { t } = useTranslation();
  const stripe = useStripe();
  const elements = useElements();
  const [isProcessing, setIsProcessing] = useState(false);
  const formDisabled = !stripe || !elements || isProcessing;

  const sendAnalyticEvent = async () => {
    FacebookConversionApi.sendEvent(
      "Purchase",
      {
        customerFirstName: userFirstName,
        customerLastName: userLastName,
        customerEmail: userEmail,
      },
      {
        currency: "USD",
        value: amountInDolars,
      }
    );
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formDisabled) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return;
    }

    setIsProcessing(true);
    sendAnalyticEvent();
    const result = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: returnUrl },
    });

    if (result.error) {
      // Show error to your customer (e.g., insufficient funds)
      console.error(result.error.message);
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }

    setIsProcessing(false);
  };

  return (
    <form>
      <div style={{ minHeight: "228px" }} className="payment-element-wrapper">
        <PaymentElement />
      </div>
      {stripe && (
        <Button
          type="solid"
          color="primary"
          className="mt-4"
          disabled={formDisabled}
          onClick={handleSubmit}
          loading={isProcessing}
        >
          {t("confirmOrder")}
        </Button>
      )}
    </form>
  );
};

export default CheckoutForm;
