import Button from "../../../../../shared/buttons/Button";
import PaymentOption from "../../../PaymentOption/PaymentOption";

interface FremiumPaymentOptionProps {
  onBuy: () => void;
}

const FreemiumSubscriptionPaymentOption = ({
  onBuy,
}: FremiumPaymentOptionProps) => {
  return (
    <PaymentOption
      priceLabel="Freemium"
      description="Free high quality 2D and 3D modeling."
      buttonsPlacement={
        <div className="w-full block">
          <Button type="bordered" color="dark" fullSize onClick={onBuy}>
            Stay Freemium
          </Button>
        </div>
      }
    />
  );
};

export default FreemiumSubscriptionPaymentOption;
