import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import Box from "../../shared/components/Box";
import SkyeDropdown, {
  DropdownWrapper,
} from "../../shared/components/Dropdown/Dropdown";
import DropdownItem from "../../shared/components/Dropdown/DropdownItem";
import ArrowDownIcon from "../../shared/Icons/ArrowDownIcon";
import { LengthUnits } from "../const";
import { ViewerService } from "../services/ViewerService";
import BaseBar from "./BaseBar";

interface IViewerMeasurementsUnitPanelPropTypes {}

const Panel = styled(BaseBar)``;

const MeasurementUnitWrapper = styled(Panel)`
  cursor: pointer;
  min-width: 60px;
  padding: 0 5px 0 10px;
`;
const UnitMeasurementsPanel = styled(Box)`
  align-items: center;
`;
const DownArrowIconWrapper = styled(Box)`
  width: 18px;
  align-items: center;
  justify-content: center;
  margin-left: 4px;
`;
const UnitMeasurements = styled(Box)`
  display: flex;
  align-items: center;
  z-index: ${(p: IAppContext) => p.theme.skye.zIndex.viewerControls};
`;

const viewerService = new ViewerService();

const ViewerMeasurementsUnitPanel =
  ({}: IViewerMeasurementsUnitPanelPropTypes) => {
    const [lengthUnit, setLengthUnit] = useState<LengthUnits>(LengthUnits.FEET);
    const { t } = useTranslation();

    const changeLengthUnit = (lengthUnit: LengthUnits) => () => {
      setLengthUnit(lengthUnit);
      viewerService.setLengthtUnit(lengthUnit);
    };

    useEffect(() => {
      setLengthUnit(viewerService.getLengthUnit());
    }, []);

    const label = (unit) => t(`length_unit_${unit}`);

    return (
      <UnitMeasurements>
        <SkyeDropdown
          onChange={() => {}}
          overlay={
            <DropdownWrapper>
              {Object.values(LengthUnits).map((unit) => (
                <DropdownItem
                  key={unit}
                  active={lengthUnit === unit}
                  onClick={changeLengthUnit(unit)}
                >
                  {label(unit)}
                </DropdownItem>
              ))}
            </DropdownWrapper>
          }
        >
          <MeasurementUnitWrapper>
            <UnitMeasurementsPanel>{label(lengthUnit)}</UnitMeasurementsPanel>
            <DownArrowIconWrapper>
              <ArrowDownIcon />
            </DownArrowIconWrapper>
          </MeasurementUnitWrapper>
        </SkyeDropdown>
      </UnitMeasurements>
    );
  };

export default ViewerMeasurementsUnitPanel;
