import React, { useEffect } from "react";
import { observer, inject } from "mobx-react";
import { IGlobalStore } from "stores/GlobalStore";
import BasePage from "../../../shared/layouts/BasePage";
import { useTranslation } from "react-i18next";
import Heading from "../../../shared/typography/Heading";
import SkyeTabs, { SkyeTab } from "../../../shared/components/SkyeTab";
import { IAppContext } from "../../../interfaces";
import styled from "styled-components";
import UserAccountSettingsContainer from "./components/UserAccountSettingsContainer";
import { IOrganizationStore } from "stores/OrganizationStore";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { ProfileSubRoutes } from "../../../routes";
import SubscriptionExpirationInfo from "../../../shared/components/SubscriptionExpirationInfo/SubscriptionExpirationInfo";
import SubscriptionSection from "../../containers/SubscriptionSection";
import { useStripeSubscription } from "../../../payments/stripe/useStripeSubscription";
import OrganizationUsersTab from "./components/OrganizationUsersTab";
import useCurrentUser from "../../../account/hooks/useCurrentUser";

interface IProfilePagePropTypes extends RouteComponentProps {
  GlobalStore: IGlobalStore;
  OrganizationStore: IOrganizationStore;
}

enum PageTabs {
  AccountSettings = "account",
  Organization = "organization",
  Subscription = "subscription",
}

const TabsWrapper = styled.div`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;

const ProfilePage = ({ OrganizationStore, history }: IProfilePagePropTypes) => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const stripe = useStripeSubscription();
  const activeKey = history.location.pathname.split("/")[2];

  useEffect(() => {
    OrganizationStore.getOrganization();
  }, []);

  const handleTabClick = (tabKey: any) => {
    switch (tabKey) {
      case PageTabs.AccountSettings:
        return history.push(ProfileSubRoutes.account);
      case PageTabs.Organization:
        return history.push(ProfileSubRoutes.organization);
      case PageTabs.Subscription:
        stripe.openSubscriptionPage(user.email);
        return history.push(ProfileSubRoutes.subscription);
      default:
        break;
    }
  };

  return (
    <BasePage backgroundColor="white">
      <div className="mb-4 md:mb-8 lg:mb-12">
        <SubscriptionExpirationInfo
          subscriptionType={user?.organization?.subscription?.type?.value}
          subscriptionActive={user?.organization.active}
          expirationDate={user?.organization.subscription.expirationDate}
          showDaysUntil={30}
        />
      </div>
      <Heading>{t("myProfile")}</Heading>
      <TabsWrapper>
        <SkyeTabs onChange={handleTabClick} activeKey={activeKey}>
          <SkyeTab
            tab={t(PageTabs.AccountSettings)}
            key={ProfileSubRoutes.account}
          >
            <UserAccountSettingsContainer />
          </SkyeTab>
          <SkyeTab
            tab={t(PageTabs.Organization)}
            key={ProfileSubRoutes.organization}
          >
            <OrganizationUsersTab />
          </SkyeTab>
          {user?.organization?.subscription?.type?.stripeProductId && (
            <SkyeTab
              tab={t(PageTabs.Subscription)}
              key={ProfileSubRoutes.subscription}
            >
              <SubscriptionSection />
            </SkyeTab>
          )}
        </SkyeTabs>
      </TabsWrapper>
    </BasePage>
  );
};

export default inject("OrganizationStore")(observer(withRouter(ProfilePage)));
