import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import Dialog from "../../shared/components/Dialog/Dialog";
import Button from "../../shared/buttons/Button";
import { BoxAlignedCenter } from "../../shared/components/Box";
import UserDetails from "../../shared/components/UserDetails";
import InputGroup from "../../shared/form/InputGroup";
import { LoggedUser } from "../../../models/User";

export interface SelectableUser extends LoggedUser {
  selected: boolean;
}

interface IUserChooseModalPropTypes {
  users: SelectableUser[];
  open: boolean;
  onClose: () => void;
  onSelect: (user: LoggedUser) => void;
  onDeselect: (user: LoggedUser) => void;
  onInvite: () => void;
}

const filterUsers =
  (inputValue: string) =>
  (user: SelectableUser): boolean => {
    const normalizedInputValue = inputValue.trim().toLocaleLowerCase();
    return (
      user.email.toLocaleLowerCase().includes(normalizedInputValue) ||
      user.firstName.toLocaleLowerCase().includes(normalizedInputValue)
    );
  };

const ListWrapper = styled.div`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
  overflow: auto;
  min-height: 300px;
`;
const UserListElement = styled(BoxAlignedCenter)`
  justify-content: space-between;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;
const ButtonWrapper = styled(BoxAlignedCenter)``;

const callbackUserFunction = (user: LoggedUser, fn: any) => () => {
  fn(user);
};

const getSelectedUsersCount = (users: SelectableUser[]): number => {
  let counter = 0;
  users.forEach((user) => {
    if (user.selected) {
      counter++;
    }
  });
  return counter;
};

const UserChooseModal = ({
  open,
  users,
  onClose,
  onSelect,
  onDeselect,
  onInvite,
}: IUserChooseModalPropTypes) => {
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState("");
  const usersCount = getSelectedUsersCount(users);

  const handleClose = () => {
    onClose();
  };

  const onInputChange = (e: any) => {
    setInputValue(e.target.value);
  };

  const getButtonText = (): string => {
    if (!usersCount) {
      return t("invite");
    }

    if (usersCount === 1) {
      return t("inviteOneUser");
    }

    return t("inviteMultipleUsers").replace(":number", String(usersCount));
  };

  return (
    <Dialog
      title={t("invitePeopleToSessionModalTitle")}
      open={open}
      onClose={handleClose}
      confirmButtonProps={{
        disabled: !usersCount,
        children: getButtonText(),
        onClick: onInvite,
      }}
      cancelButtonProps={{
        onClick: onClose,
      }}
    >
      <InputGroup
        bordered
        inputProps={{
          placeholder: "searchUser",
          value: inputValue,
          onChange: onInputChange,
        }}
      />
      <ListWrapper>
        {users.filter(filterUsers(inputValue)).map((user) => (
          <UserListElement key={user.id}>
            <UserDetails user={user} />
            <ButtonWrapper>
              {!user.selected ? (
                <Button
                  type="bordered"
                  size="small"
                  onClick={callbackUserFunction(user, onSelect)}
                >
                  {t("add")}
                </Button>
              ) : (
                <Button
                  type="bordered"
                  size="small"
                  color="danger"
                  onClick={callbackUserFunction(user, onDeselect)}
                >
                  {t("remove")}
                </Button>
              )}
            </ButtonWrapper>
          </UserListElement>
        ))}
      </ListWrapper>
    </Dialog>
  );
};

export default UserChooseModal;
