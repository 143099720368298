import React, { memo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IAppContext } from "../../../interfaces";
import Box from "../Box";
import classNames from "classnames";
import breakpoint from "styled-components-breakpoint";

export type IModalSize = "small" | "medium" | "large";

interface IModalPropTypes {
  open: boolean;
  size: IModalSize;
  children: any;
}

const ModalBackground = styled(Box)`
  min-width: 100vw;
  min-height: 100vh;
  z-index: 3000;
  flex-direction: column;
  background-color: rgba(0, 0, 0, 0.7);
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  left: 0;
  top: 0;
  display: none;

  &.open {
    display: flex;
  }
`;

const ModalContent = styled.div`
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  width: calc(100% - 30px);
  max-height: calc(100vh - 120px);
  position: relative;
  box-shadow: ${(p: IAppContext) => p.theme.skye.shadows.primary};
  z-index: 3000;
  margin-top: 40px;
  overflow-y: auto;

  &.small {
    ${breakpoint("sm")`
      width: 480px;
    `}
  }

  &.medium {
    ${breakpoint("sm")`
      width: 664px;
    `}
  }

  &.large {
    ${breakpoint("md")`
      width: 728px;
    `}

    ${breakpoint("lg")`
      width: 940px;
    `}
  }
`;

const Modal = ({ open, children, size = "small" }: IModalPropTypes) => {
  const { t } = useTranslation();

  return (
    <ModalBackground className={classNames(["modal-background", { open }])}>
      <ModalContent className={classNames(["modal-background", size])}>
        {children}
      </ModalContent>
    </ModalBackground>
  );
};

export default memo(Modal);
