import React, { Component } from 'react';
import { observer } from 'mobx-react';
import { withTranslation } from "react-i18next";
import styled from "styled-components";
import InputGroup, { RequiredStar } from "../../shared/form/InputGroup";
import Button from "../../shared/buttons/Button";
import { withRouter } from "react-router";
import GlobalStore from "../../../stores/GlobalStore";
import { ITranslationFields } from "../../../locale/dictionary";
import { IAppContext } from "../../interfaces";
import RegisterForm from '../forms/RegisterForm';
import authRequests from "../../../requests/auth";
import { getResponseErrorTranslation } from "../../../utils/globalUtils";
import { IResponse } from "../../../services/HttpService";
import Dialog from "../../shared/components/Dialog/Dialog";
import { AUTH_ROUTES } from "../../routes";
import couponRequests from "../../../requests/coupon";
import { WindowUtils } from "../../../utils/windowUtils";
import { Row, Col } from "styled-bootstrap-grid";
import SkyeCheckbox from "../../shared/form/SkyeCheckbox";
import Box from "../../shared/components/Box";
import breakpoint from 'styled-components-breakpoint';
import Label from "../../shared/typography/Label";

const StyledForm = styled.form`
  width: 100%;

  .btn {
    margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
  }

  .skye-checkbox {
    margin-top: ${(p: IAppContext) => p.theme.skye.sizes.average};
  }
`;
const PrivacyPolicyLink = styled.a`
  margin-left: 0.5em;
`;
const CouponFieldWrapper = styled.div`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;
const CheckboxSection = styled.div`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;
const SubmitButtonBox = styled(Box)`
  ${breakpoint('lg')`
    width: 130px;
  `}
`;
const ErrorsContainer = styled.div`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

interface IMobxForm {
  isValid?: boolean,
  submitted?: boolean,
  validateError?: undefined | string,
  submit: () => void,
}

interface IRegisterForm extends IMobxForm {
  firstName: string,
  lastName: string,
  password: string,
  passwordRepeat: string,
  phone: string,
  jobTitle: string,
  email: string,
  organization: string,
  couponCode: string,
}

interface State {
  requestError: false | ITranslationFields;
  formSent: boolean;
  loading: boolean;
  successDialogOpen: boolean;
  couponCodeChecked: boolean;
  termsOfUseAccepted: boolean;
}

@observer
class RegisterContainer extends Component<any, any> {
  form: IRegisterForm = new RegisterForm();
  state: State;

  constructor(props) {
    super(props);

    this.form = new RegisterForm();
    this.state = {
      formSent: false,
      requestError: false,
      loading: false,
      successDialogOpen: false,
      couponCodeChecked: false,
      termsOfUseAccepted: false,
    };
  }

  componentDidMount() {
    const couponCode = WindowUtils.getQueryParam("couponCode", "");
    this.form.couponCode = couponCode;
  }

  getFormData() {
    return {
      name: this.form.firstName,
      lastName: this.form.lastName,
      phone: this.form.phone,
      jobTitle: this.form.jobTitle,
      organizationName: this.form.organization,
      password: this.form.password,
      email: this.form.email,
      couponCode: this.form.couponCode,
    };
  }

  handleSubmit() {
    this.form.submit();
    this.setState({ requestError: false });

    if (
      this.form.isValid &&
      this.state.termsOfUseAccepted &&
      !this.state.loading
    ) {
      this.register();
    }
  }

  register() {
    this.setState({ loading: true });
    authRequests
      .signUp(this.getFormData())
      .then((res: IResponse) => this.handleRegistrationSuccess(res))
      .catch((err) =>
        this.setState({ requestError: getResponseErrorTranslation(err) })
      )
      .finally(() => this.setState({ loading: false }));
  }

  handleRegistrationSuccess(res: IResponse) {
    const { t } = this.props;
    GlobalStore.addToast(t("userRegistered"));
    this.setState({ successDialogOpen: true });
  }

  closeSuccessDialogAndRedirect() {
    this.setState({ successDialogOpen: false });
    this.props.history.push(AUTH_ROUTES.login);
  }

  handleCouponFieldBlur() {
    const { t } = this.props;

    if (this.form.couponCode) {
      couponRequests
        .isValid(this.form.couponCode)
        .then(() => {
          GlobalStore.addToast(t("couponValid"), "success");
        })
        .catch(() => {
          GlobalStore.addToast(t("couponNotValid"), "error");
        });
    }
  }

  render() {
    const { t } = this.props;
    const { requestError, loading, couponCodeChecked, termsOfUseAccepted } =
      this.state;
    const { isValid, submitted, validateError } = this.form;

    return (
      <StyledForm>
        <Row>
          <Col sm={6}>
            <InputGroup
              bordered
              required
              label="firstNameLabel"
              inputProps={{
                value: this.form.firstName,
                placeholder: "firstNamePlaceholder",
                onChange: (e: any) => (this.form.firstName = e.target.value),
              }}
            />
          </Col>
          <Col sm={6}>
            <InputGroup
              bordered
              required
              label="lastnameLabel"
              inputProps={{
                value: this.form.lastName,
                placeholder: "lastnamePlaceholder",
                onChange: (e: any) => (this.form.lastName = e.target.value),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <InputGroup
              bordered
              required
              label="emailLabel"
              inputProps={{
                value: this.form.email,
                placeholder: "emailPlaceholder",
                onChange: (e: any) => (this.form.email = e.target.value),
              }}
            />
          </Col>
          <Col sm={6}>
            <InputGroup
              bordered
              required
              label="organizationLabel"
              inputProps={{
                value: this.form.organization,
                placeholder: "organizationPlaceholder",
                onChange: (e: any) => (this.form.organization = e.target.value),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <InputGroup
              bordered
              label="phoneLabel"
              inputProps={{
                value: this.form.phone,
                placeholder: "phonePlaceholder",
                onChange: (e: any) => (this.form.phone = e.target.value),
              }}
            />
          </Col>
          <Col sm={6}>
            <InputGroup
              bordered
              label="jobTitleLabel"
              inputProps={{
                value: this.form.jobTitle,
                placeholder: "jobTitlePlaceholder",
                onChange: (e: any) => (this.form.jobTitle = e.target.value),
              }}
            />
          </Col>
        </Row>
        <Row>
          <Col sm={6}>
            <InputGroup
              bordered
              required
              label="passwordLabel"
              inputProps={{
                type: "password",
                value: this.form.password,
                placeholder: "passwordPlaceholder",
                onChange: (e: any) => (this.form.password = e.target.value),
              }}
            />
          </Col>
          <Col sm={6}>
            <InputGroup
              bordered
              required
              label="newPasswordRepeatLabel"
              inputProps={{
                type: "password",
                value: this.form.passwordRepeat,
                placeholder: "newPasswordRepeatLabel",
                onChange: (e: any) =>
                  (this.form.passwordRepeat = e.target.value),
              }}
            />
          </Col>
        </Row>
        <CheckboxSection>
          <SkyeCheckbox
            checked={couponCodeChecked}
            onChange={(newValue) =>
              this.setState({ couponCodeChecked: newValue })
            }
          >
            {t("coupon")}
          </SkyeCheckbox>
          {couponCodeChecked && (
            <CouponFieldWrapper>
              <InputGroup
                bordered
                inputProps={{
                  type: "text",
                  value: this.form.couponCode,
                  placeholder: "couponPlaceholder",
                  onChange: (e: any) => (this.form.couponCode = e.target.value),
                  onBlur: () => {
                    this.handleCouponFieldBlur();
                  },
                }}
              />
            </CouponFieldWrapper>
          )}
          <SkyeCheckbox
            checked={termsOfUseAccepted}
            onChange={(newValue) =>
              this.setState({ termsOfUseAccepted: newValue })
            }
          >
            <span>{t("toProceedYouNeedToAcceptTermsOfUse")}</span>
            <PrivacyPolicyLink
              href="https://www.skyebrowse.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {t("readPrivacyPolicy")}
            </PrivacyPolicyLink>
            <RequiredStar>*</RequiredStar>
          </SkyeCheckbox>
        </CheckboxSection>
        <ErrorsContainer>
          {submitted && !isValid && (
            <Label color="danger">{t(validateError)}</Label>
          )}
          {!!requestError && <Label color="danger">{t(requestError)}</Label>}
        </ErrorsContainer>
        <Dialog
          hideCancelButton
          open={this.state.successDialogOpen}
          onClose={() => this.closeSuccessDialogAndRedirect()}
          confirmButtonProps={{
            onClick: () => this.closeSuccessDialogAndRedirect(),
          }}
          title={t("userRegistered")}
        >
          {t("confirmationEmailInformation")}
        </Dialog>
        <SubmitButtonBox>
          <Button
            fullSize
            disabled={!termsOfUseAccepted}
            loading={loading}
            onClick={() => this.handleSubmit()}
          >
            {t("register")}
          </Button>
        </SubmitButtonBox>
      </StyledForm>
    );
  }
}

export default withTranslation()(withRouter(RegisterContainer));
