import React from 'react'
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import { BoxCentered } from "./Box";
import CircleLoader from "./CircleLoader";

const LoaderContainer = styled(BoxCentered)`
  width: 100%;
  height: 100%;
`;

const LoaderWrapper = styled(BoxCentered)`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.primary};
  height: 100%;
  max-width: 300px;
  width: 100%;
  max-height: 300px;
  border-radius: 4px;
`;

const LoaderBox = styled.div`
  max-width: 200px;

  img {
    display: block;
    margin: 0 auto;
    width: 100%;
    max-width: 150px;
    height: auto;
  }
`;

const LinearProgressWrapper = styled(BoxCentered)`
  width: 200px;
  margin-top: 25px;
`;

const PageLoader = () => {
  return (
    <LoaderContainer>
      <LoaderWrapper>
        <LoaderBox className="page-loader">
          <img src="/img/logo.svg" alt="skyebrowse-logo" />
          <LinearProgressWrapper>
            <CircleLoader />
          </LinearProgressWrapper>
        </LoaderBox>
      </LoaderWrapper>
    </LoaderContainer>
  );
};

export default PageLoader;
