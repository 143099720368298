import { Model } from "models/Model";
import { STORE_NAMES } from "const/global";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router-dom";
import ModelService from "views/models/services/ModelService";
import { SessionService } from "services/SessionService";
import SessionViewerPageStore, {
  ISessionViewerPageStore,
} from "stores/SessionViewerPageStore";
import { Col, Row } from "styled-bootstrap-grid";
import SessionCreateContainer from "../container/SessionCreateContainer";
import { ISession } from "models/Session";
import BasePage from "views/shared/layouts/BasePage";

interface ISessionDetailsPagePropTypes
  extends RouteComponentProps<IMatchParams> {
  SessionViewerPageStore: ISessionViewerPageStore;
}

interface IMatchParams {
  id: string;
}

const tacbrowseService = new SessionService();

const SessionDetailsPage = (props: ISessionDetailsPagePropTypes) => {
  const [sessionLoaded, setSessionLoaded] = useState(false);
  const [modelLoaded, setModelLoaded] = useState(false);
  const [session, setSession] = useState<ISession>(null);
  const [newModelId, setNewModelId] = useState("");
  const [model, setModel] = useState<null | Model>(null);
  const sessionPublicId = props.match.params.id;

  const getSessionDetails = async () => {
    const newSession = await tacbrowseService.getSession(sessionPublicId);

    setSessionLoaded(true);

    if (newSession) {
      setSession(newSession);
      setNewModelId(newSession.modelId);
    }
  };

  const loadModel = () => {
    ModelService.getModelDetails(session.modelId).then((res: any) => {
      setModel(res.data);
      setModelLoaded(true);
    });
  };

  const loadSessionAndSubscribe = async () => {
    await SessionViewerPageStore.subscribeToSession(session.publicId);
  };

  useEffect(() => {
    getSessionDetails();
  }, []);

  useEffect(() => {
    if (session && session.modelId && !modelLoaded) {
      loadModel();
    }
  }, [session]);

  useEffect(() => {
    if (modelLoaded) {
      loadSessionAndSubscribe();
    }
  }, [modelLoaded]);

  if (!session) {
    return null;
  }

  return (
    <BasePage backgroundColor="white">
      <Row>
        <Col sm={4} smOffset={4}>
          <SessionCreateContainer sessionAddress={session.publicId} />
        </Col>
      </Row>
    </BasePage>
  );
};

export default inject(STORE_NAMES.SessionViewerPageStore)(
  observer(SessionDetailsPage)
);
