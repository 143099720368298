import React from 'react'
import { useTranslation } from 'react-i18next';
import EmptyListInformation from "../../shared/components/EmptyListInformation";
import BasePage from "../../shared/layouts/BasePage";

const UserAccountDeletedPage = () => {
  const { t } = useTranslation();

  return (
    <BasePage backgroundColor='white'>
      <EmptyListInformation
        showButton={false}
        title={t('accountDeletedPageTitle')}
      />
    </BasePage>
  )
}

export default UserAccountDeletedPage;
