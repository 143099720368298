import React from 'react'
import styled from 'styled-components';

interface IDistanceIconPropTypes {
  
}

const DistanceIcon = ({
  
}: IDistanceIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M5.3335 24.4608H7.53942V26.6667H5.3335V24.4608Z" stroke="#1A1A1A" strokeWidth="1.0417" strokeMiterlimit="10"/>
      <path d="M24.4609 5.33334H26.6669V7.53927H24.4609V5.33334Z" stroke="#1A1A1A" strokeWidth="1.0417" strokeMiterlimit="10"/>
      <path d="M7.53955 24.4607L24.4611 7.53915" stroke="#1A1A1A" strokeWidth="1.0417" strokeMiterlimit="10"/>
    </svg>
  )
}

export default DistanceIcon;

