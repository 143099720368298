import { ITranslationFields } from "../../../locale/dictionary";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { BoxCentered } from "./Box";
import Button, { IButtonPropTypes } from "../../shared/buttons/Button";
import { IAppContext } from "../../interfaces";

interface IEmptyListInformationPropTypes {
  title?: ITranslationFields;
  description?: ITranslationFields;
  showButton?: boolean;
  buttonProps?: IButtonPropTypes;
}

const Wrapper = styled(BoxCentered)`
  text-align: center;
  flex-direction: column;
  margin: ${(p: IAppContext) => p.theme.skye.sizes.xLarge} 0;
`;
const Image = styled.img`
  width: 200px;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.xLarge};
`;
const Title = styled.h3`
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 29px;
`;
const Description = styled.h5`
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
  color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
`;
const ButtonWrapper = styled.div`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.xLarge};
`;

const EmptyListInformation = ({
  title,
  description,
  showButton,
  buttonProps,
}: IEmptyListInformationPropTypes) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Image src="/images/empty_list.svg" alt={title} />
      <Title>{t(title)}</Title>
      {Boolean(description) && <Description>{t(description)}</Description>}
      {showButton && Boolean(buttonProps) && (
        <ButtonWrapper>
          <Button {...buttonProps} />
        </ButtonWrapper>
      )}
    </Wrapper>
  );
};

export default EmptyListInformation;
