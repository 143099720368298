import React from 'react'

interface IAddIconPropTypes {
  
}

const AddIconCircle = ({
  
}: IAddIconPropTypes) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="20" height="20" rx="10" fill="#5A11FF"/>
    <path d="M10.4163 5.83325V10.4166M10.4163 10.4166H14.9997M10.4163 10.4166V14.9999M10.4163 10.4166H5.83301" stroke="white" strokeLinecap="round" strokeLinejoin="round" />
    </svg>
  )
}

export default AddIconCircle;
