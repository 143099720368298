const UniversalUploadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_4205_450)">
      <mask
        id="mask0_4205_450"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path d="M0 3.8147e-06H24V24H0V3.8147e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask0_4205_450)">
        <path
          d="M6.23438 14.9315L0.46875 17.6265L6.23438 20.3216L12 17.6265L6.23438 14.9315Z"
          stroke="#5A11FF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M9.60938 13.3539L6.23438 14.9314L12 17.6265L17.7656 14.9314L14.3438 13.332"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <mask
        id="mask1_4205_450"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="24"
        height="24"
      >
        <path d="M0 3.8147e-06H24V24H0V3.8147e-06Z" fill="white" />
      </mask>
      <g mask="url(#mask1_4205_450)">
        <path
          d="M6.23438 20.3216L12 23.0167L17.7656 20.3216L12 17.6266L6.23438 20.3216Z"
          stroke="#5A11FF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.7656 14.9315L12 17.6265L17.7656 20.3216L23.5312 17.6265L17.7656 14.9315Z"
          stroke="#5A11FF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M17.7656 3.98398L12 0.983275L6.23438 3.98398"
          stroke="#5A11FF"
          strokeWidth="1.5"
          strokeMiterlimit="10"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <path
        d="M17.7656 3.98395L12 6.98465V14.4624L17.7656 11.4617"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12 14.4624L6.23438 11.4617V3.98395L12 6.98465V14.4624Z"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7656 11.457L17.7656 4.24243"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_4205_450">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default UniversalUploadIcon;
