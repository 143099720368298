import axios from "axios";
import { IPublicModel } from "../../../../models/Model";
import { useDownloadGeoTiff } from "./useDownloadGeoTiff";
import JSZip from "jszip";
import { DocumentService } from "../../../../services/DocumentService";
import { useState } from "react";

export const useDownload2DAssets = (model: IPublicModel, hasAccess) => {
  const [loading, setLoading] = useState(false);
  const geoTiff = useDownloadGeoTiff({ model, hasAccess: hasAccess });

  const fetchFile = async (url: string): Promise<Blob> => {
    return await (await fetch(url)).blob();
  };

  const generateZip = async (
    files: { fileName: string; blob: Blob }[]
  ): Promise<Blob> => {
    const zip = new JSZip();
    files.forEach((file) => zip.file(file.fileName, file.blob));
    return await zip.generateAsync({ type: "blob" });
  };

  const saveZip = (blob: Blob) => {
    DocumentService.downloadFile(window.URL.createObjectURL(blob), "model.zip");
  };

  /**
   * For unknown reason the download of jpg files is not working in some browsers
   * Not sure if we should use this method or not at the moment. Debugged for a long time without results
   */
  const downloadAllAsZip = async () => {
    setLoading(true);
    try {
      const [getTiff, orthophoto, orthophotoSketch] = await Promise.all([
        geoTiff.fetchGeoTiff(),
        fetchFile(model.orthophotoUrl),
        fetchFile(model.orthophotoSketchUrl),
      ]);
      const zip = await generateZip([
        { fileName: "model.tiff", blob: getTiff },
        { fileName: "orthophoto.jpg", blob: orthophoto },
        { fileName: "orthophotoSketch.jpg", blob: orthophotoSketch },
      ]);
      saveZip(zip);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };
  return { downloadAllAsZip, loading };
};
