import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { RouteComponentProps } from "react-router-dom";
import Box from "../../../shared/components/Box";
import { IAppContext } from "../../../interfaces";
import {
  SESSION_PAGE_VIEW_TYPE_PARAM_NAME,
  SessionPageViewTypes,
  STORE_NAMES,
} from "const/global";
import { useTranslation } from "react-i18next";
import { inject, observer } from "mobx-react";
import { ISessionViewerPageStore } from "stores/SessionViewerPageStore";
import PageLoader from "../../../shared/components/PageLoader";
import SessionViewer from "../../container/SessionViewer";
import { WindowUtils } from "utils/windowUtils";
import { MessageService } from "services/MessageService";
import ModelChooseModalContainer from "views/models/containers/ModelChooseModalContainer";
import { Model } from "models/Model";
import { SessionService } from "services/SessionService";
import { SESSION_ROUTES } from "views/routes";
import SessionModelMapView from "../../components/SessionModelMapView";
import classNames from "classnames";
import { IGlobalStore } from "../../../../stores/GlobalStore";
import SessionTopBar from "./SessionTopBar";
import { ISessionStreamStore } from "../../../../stores/SessionStreamStore";
import { IModelListItem } from "../../../models/pages/ModelListPage.tsx/ModelList/interface";

interface IMatchParams {
  id: string;
}

interface ISessionViewerPagePropTypes
  extends RouteComponentProps<IMatchParams> {
  SessionViewerPageStore?: ISessionViewerPageStore;
  GlobalStore?: IGlobalStore;
  SessionStreamStore: ISessionStreamStore;
}

const PageContainer = styled(Box)`
  width: 100%;
  height: 100%;
  flex-direction: column;

  &.members-open {
    .viewer-measurements-tree {
      right: 372px;
    }
  }
`;
const MainContentRow = styled(Box)`
  width: 100%;
  position: relative;
  padding-left: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  padding-right: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
`;

const sessionService = new SessionService();

const SessionViewerPage = ({
  history,
  match,
  SessionViewerPageStore,
  SessionStreamStore,
}: ISessionViewerPagePropTypes) => {
  const { t } = useTranslation();
  const mainContentContainer = useRef<HTMLDivElement>();
  const [newSelectedModel, setNewSelectedModel] = useState(null);
  const [assignModelModalOpen, setAssignModelModalOpen] = useState(false);

  const model = SessionViewerPageStore.sessionModel;
  const allSessionDataLoaded = SessionViewerPageStore.allDataLoaded;
  const { session, sessionMembersPanelOpen, viewType } = SessionViewerPageStore;

  const sessionAddress = match.params.id;
  const isMapView = SessionPageViewTypes.Map === viewType;

  const getMainContainerHeight = (): string => {
    if (mainContentContainer && mainContentContainer.current) {
      const documentHeight = window.innerHeight;
      const rect = mainContentContainer.current.getBoundingClientRect();

      return `${documentHeight - rect.y}px`;
    }

    return `${window.innerHeight}px`;
  };

  const handleModelChange = (model: IModelListItem) => {
    sessionService
      .updateSession(session.publicId, { modelId: model.id })
      .then(() => {
        initView();
      });
  };

  const handleModelModalClose = () => {
    MessageService.error(t("toastModelNotAssignedToSession"));
  };

  const getSessionDetails = async () => {
    const success = await SessionViewerPageStore.initView(sessionAddress);
    SessionStreamStore.initView(sessionAddress);

    if (!success) {
      history.push(SESSION_ROUTES.sessionList);
      MessageService.error(t("error_be_session_not_found"));
    }
  };

  const subscribeToSession = () => {
    SessionViewerPageStore.subscribeToSession(sessionAddress);
  };

  const initView = (): void => {
    SessionViewerPageStore.resetStore();
    SessionStreamStore.resetStore();

    getSessionDetails();
    subscribeToSession();
    SessionViewerPageStore.setViewType(
      WindowUtils.getQueryParam(
        SESSION_PAGE_VIEW_TYPE_PARAM_NAME,
        SessionPageViewTypes.Map
      )
    );
  };

  useEffect(() => {
    initView();

    return () => {
      SessionViewerPageStore.disconnectFromSession();
      SessionViewerPageStore.resetStore();
    };
  }, []);

  useEffect(() => {
    if (allSessionDataLoaded) {
      if (!model) {
        MessageService.warn(t("toastModelNotAssignedToSession"));
        setAssignModelModalOpen(true);
      }
    }
  }, [allSessionDataLoaded]);

  if (!session) {
    return null;
  }

  return (
    <PageContainer
      className={classNames([
        "session-viewer-page",
        { ["members-open"]: sessionMembersPanelOpen },
      ])}
    >
      <SessionTopBar />
      <MainContentRow
        className="main-content-row"
        ref={mainContentContainer}
        style={{ height: getMainContainerHeight() }}
      >
        {model ? (
          <>
            {isMapView ? (
              <SessionModelMapView model={model} />
            ) : (
              <SessionViewer />
            )}
          </>
        ) : (
          <PageLoader />
        )}
      </MainContentRow>
      <ModelChooseModalContainer
        onClose={handleModelModalClose}
        onChange={handleModelChange}
        selectedModel={newSelectedModel}
        open={assignModelModalOpen}
      />
    </PageContainer>
  );
};

export default inject(
  STORE_NAMES.SessionStreamStore,
  STORE_NAMES.SessionViewerPageStore,
  STORE_NAMES.GlobalStore
)(observer(SessionViewerPage));
