import React from 'react'

interface ILinkIconPropTypes {
  
}

const LinkIcon = ({
  
}: ILinkIconPropTypes) => {
  return (
    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M7.91699 11.0835C8.17498 11.3468 8.48291 11.556 8.82276 11.6988C9.1626 11.8416 9.52753 11.9151 9.89616 11.9151C10.2648 11.9151 10.6297 11.8416 10.9696 11.6988C11.3094 11.556 11.6173 11.3468 11.8753 11.0835L15.042 7.9168C15.5669 7.3919 15.8618 6.67997 15.8618 5.93764C15.8618 5.1953 15.5669 4.48338 15.042 3.95847C14.5171 3.43356 13.8052 3.13867 13.0628 3.13867C12.3205 3.13867 11.6086 3.43356 11.0837 3.95847L10.6878 4.3543" stroke="#959494" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M11.0835 7.91661C10.8255 7.65331 10.5175 7.44413 10.1777 7.30132C9.83786 7.15852 9.47293 7.08496 9.1043 7.08496C8.73567 7.08496 8.37075 7.15852 8.0309 7.30132C7.69106 7.44413 7.38312 7.65331 7.12514 7.91661L3.95847 11.0833C3.43356 11.6082 3.13867 12.3201 3.13867 13.0624C3.13867 13.8048 3.43356 14.5167 3.95847 15.0416C4.48338 15.5665 5.1953 15.8614 5.93764 15.8614C6.67997 15.8614 7.3919 15.5665 7.9168 15.0416L8.31264 14.6458" stroke="#959494" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default LinkIcon;
