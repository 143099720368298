import React, { ReactNode } from 'react'
import styled from "styled-components";
import { IAppContext } from "../../interfaces";

interface ITablePropTypes {
  children: ReactNode,
}

const StyledTableWrapper = styled.div`
  tr {
    border-bottom: 1px solid #E2E2E2;
  }

  thead {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 15px;
    
    td {
      color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
      padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};
    }
  }

  tbody {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;

    td {
      padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};
      padding-top: ${(p: IAppContext) => p.theme.skye.sizes.small};
    }
  }
`;

const Table = styled.table`
  width: 100%;
`;

const SkyeTable = ({
  children
}: ITablePropTypes) => {
  return (
    <StyledTableWrapper>
      <Table>{children}</Table>
    </StyledTableWrapper>
  );
}

export default SkyeTable;
