import { useCallback, useContext, useEffect } from "react";
import { ModelMeasurementService } from "../../models/services/ModelMeasurementService";
import { ViewerPerspectiveContext } from "../context/ViewerPerspectiveContext";
import { MeasurementTypesNames } from "../interfaces";
import BaseMeasure from "../services/tools/BaseMeasure";
import Perspective from "../services/tools/Perspective";
import { ViewerService } from "../services/ViewerService";

interface Props {
  modelId: string;
  viewerInitialized?: boolean;
}

const useChangePerspectiveModelTool = ({
  modelId,
  viewerInitialized,
}: Props) => {
  const { setPerspectiveChanged, perspectiveChanged } = useContext(
    ViewerPerspectiveContext
  );
  const changePerspective = (measurement: any) => {
    Perspective.alignToMeasurement(measurement);
    BaseMeasure.remove(measurement);
    const vs = new ViewerService();
    vs.fitToScreen();
    setPerspectiveChanged(true);
    // TODO: uncomment when feature is ready
    // ModelMeasurementService.postModelMeasurements(modelId, {
    //   rotation: Perspective.getRotation(),
    // });
  };

  const handleInsertionEnd = useCallback(() => {
    const measurement = BaseMeasure.getAll().find(
      (m) => m.name === MeasurementTypesNames.Perspective
    );

    if (!measurement) return;
    changePerspective(measurement);
  }, []);

  const attachEvents = useCallback(() => {
    if (!viewerInitialized) return;

    window.viewer?.addEventListener(
      "end_inserting_measurement",
      handleInsertionEnd
    );
  }, [viewerInitialized]);

  const init = () => {
    Perspective.add();
  };

  const reset = async () => {
    Perspective.reset();
    const vs = new ViewerService();
    vs.fitToScreen();
    setPerspectiveChanged(false);
    await ModelMeasurementService.postModelMeasurements(modelId, {
      rotation: null,
    });
  };

  const restore = async (rotationX, rotationY, rotationZ) => {
    Perspective.alignFromRotation(rotationX, rotationY, rotationZ);
    const vs = new ViewerService();
    vs.fitToScreen();
    setPerspectiveChanged(true);
  };

  useEffect(() => {
    attachEvents();

    return () => {
      if (viewerInitialized) {
        window.viewer?.removeEventListener(
          "cancel_insertions",
          handleInsertionEnd
        );
      }
    };
  }, [attachEvents, handleInsertionEnd, viewerInitialized]);

  return { perspectiveChanged, init, reset, restore };
};

export default useChangePerspectiveModelTool;
