const LockIcon = () => (
  <svg
    width="12"
    height="12"
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M7.55711 4.31182V3.17773C7.55711 2.32134 6.85642 1.62063 6.00001 1.62063C5.14361 1.62063 4.44292 2.32132 4.44292 3.17773V4.31182H3.19726V3.17775C3.19726 1.63624 4.45851 0.375 6.00001 0.375C7.54152 0.375 8.80276 1.63624 8.80276 3.17775V4.31182H7.55711ZM10.3447 5.87135V10.6514C10.3447 11.1869 9.90658 11.625 9.3711 11.625H2.62893C2.09345 11.625 1.65533 11.1869 1.65533 10.6514V5.87133C1.65533 5.33585 2.09345 4.89773 2.62893 4.89773H9.3711C9.90658 4.89773 10.3447 5.33587 10.3447 5.87135ZM6.81878 7.51411C6.81878 7.06193 6.45222 6.69534 6.00001 6.69534C5.54781 6.69534 5.18125 7.06193 5.18125 7.51411C5.18125 7.79234 5.32007 8.0381 5.53215 8.18606V9.3596C5.53215 9.61695 5.74267 9.82746 6.00001 9.82746C6.25736 9.82746 6.46787 9.61692 6.46787 9.3596V8.18604C6.67998 8.03808 6.81878 7.79231 6.81878 7.51411Z"
      fill="currentColor"
    />
  </svg>
);

export default LockIcon;
