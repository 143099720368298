import React from 'react'

interface ILogoutIconPropTypes {
  
}

const LogoutIcon = ({
  
}: ILogoutIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M4.16699 3.33334C4.16699 3.0572 4.39085 2.83334 4.66699 2.83334H28.667C28.9431 2.83334 29.167 3.0572 29.167 3.33334V27.3333C29.167 27.6095 28.9431 27.8333 28.667 27.8333H4.66699C4.39085 27.8333 4.16699 27.6095 4.16699 27.3333V20.6667C4.16699 20.3905 4.39085 20.1667 4.66699 20.1667C4.94313 20.1667 5.16699 20.3905 5.16699 20.6667V26.8333H28.167V3.83334H5.16699V10C5.16699 10.2762 4.94313 10.5 4.66699 10.5C4.39085 10.5 4.16699 10.2762 4.16699 10V3.33334ZM14.3132 8.97995C14.5085 8.78469 14.825 8.78469 15.0203 8.97995L21.0203 14.98C21.2156 15.1752 21.2156 15.4918 21.0203 15.6871L15.0203 21.6871C14.825 21.8823 14.5085 21.8823 14.3132 21.6871C14.1179 21.4918 14.1179 21.1752 14.3132 20.98L19.4596 15.8335H2C1.72386 15.8335 1.5 15.6096 1.5 15.3335C1.5 15.0574 1.72386 14.8335 2 14.8335H19.4596L14.3132 9.68706C14.1179 9.4918 14.1179 9.17522 14.3132 8.97995Z" fill="#1A1A1A"/>
    </svg>
  )
}

export default LogoutIcon;
