import React from 'react'
import styled from 'styled-components';
import classNames from 'classnames';
import { IAppContext } from "../../interfaces";

export interface ILoaderPropTypes {
  size?: number;
  color?: "default" | "primary" | "dark";
}

const LoaderContainer = styled.div`
  &.loader,
  &.loader:after {
    border-radius: 50%;
    width: 10em;
    height: 10em;
  }
  &.loader {
    margin: 0;
    position: relative;
    text-indent: -9999em;
    border-top: 1.1em solid rgba(255, 255, 255, 0.2);
    border-right: 1.1em solid rgba(255, 255, 255, 0.2);
    border-bottom: 1.1em solid rgba(255, 255, 255, 0.2);
    border-left: 1.1em solid #ffffff;
    -webkit-transform: translateZ(0);
    -ms-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-animation: load8 1.1s infinite linear;
    animation: load8 1.1s infinite linear;

    &.primary {
      border-top: 1.1em solid
        ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
      border-right: 1.1em solid
        ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
      border-bottom: 1.1em solid
        ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
      border-left: 1.1em solid
        ${(p: IAppContext) => p.theme.skye.colors.primary};
    }

    &.dark {
      border-top: 1.1em solid
        ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
      border-right: 1.1em solid
        ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
      border-bottom: 1.1em solid
        ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
      border-left: 1.1em solid
        ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
    }
  }
  @-webkit-keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes load8 {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
`;

const CircleLoader = ({
  size = 20,
  color = 'default',
}: ILoaderPropTypes) => {
  return (
    <LoaderContainer
      style={{ fontSize: `${size / 10}px` }}
      className={classNames(["loader", color])}
    />
  );
}

export default CircleLoader;
