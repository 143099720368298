import React from 'react'
import styled from 'styled-components';
import Box from '../components/Box';
import { IAppContext } from "../../interfaces";
import classNames from 'classnames';

interface ISkyeCheckboxPropTypes {
  checked: boolean,
  onChange: (checked: boolean, e: any) => void,
  children?: any,
}

const CHECKBOX_SIZE = 14;

const SwitchBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${CHECKBOX_SIZE}px;
  width: ${CHECKBOX_SIZE}px;
  position: relative;
  box-sizing: border-box;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border: 1px solid ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
  border-radius: 1px;
  margin-top: 2px;
  flex-shrink: 0;
`;

const Group = styled(Box)`
  align-items: flex-top;
  cursor: pointer;

  &.checked {
    .switch-box {
      background-color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
      border: 1px solid ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    }
  }

  &.bottom-padding {
    margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  }
`;

const StyledLabel = styled.span`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-left: 8px;
  padding-bottom: 0;
  margin-bottom: 0;
  padding-top: 1px;
`;

const SkyeCheckbox = ({
  children,
  checked,
  onChange
}: ISkyeCheckboxPropTypes) => {
  return (
    <Group
      className={classNames(['skye-checkbox', { checked }])}
      onClick={(e) => onChange(!checked, e)}
    >
      <SwitchBox className="switch-box">
        {checked && (
          <svg width="9" height="7" viewBox="0 0 9 7" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.05208 6.2709L2.69855 6.62447C2.89381 6.81971 3.21037 6.81971 3.40563 6.62446L3.05208 6.2709ZM7.8027 0.8134L2.69854 5.91734L3.40563 6.62446L8.5098 1.52052L7.8027 0.8134ZM3.40562 5.91733L0.853538 3.36548L0.146462 4.07261L2.69855 6.62447L3.40562 5.91733Z" fill="white"/>
          </svg>
        )}
      </SwitchBox>
      {!!children && (
        <StyledLabel>{children}</StyledLabel>
      )}
    </Group>
  )
}

export default SkyeCheckbox;
