import classNames from "classnames";
import { STORE_NAMES } from "const/global";
import { inject, observer } from "mobx-react";
import { LoggedUser } from "models/User";
import React from "react";
import { useTranslation } from "react-i18next";
import { ISessionViewerPageStore } from "stores/SessionViewerPageStore";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import Badge from "views/shared/components/badges/Badge/Badge";
import Box, { BoxAlignedCenter } from "../../shared/components/Box";
import UserDetails from "../../shared/components/UserDetails";
import ViewerSidePanel from "../../shared/components/ViewerSidePanel";
import LiveIcon from "../../shared/Icons/LiveIcon";
import { ViewerService } from "../../viewer/services/ViewerService";
import Locator from "../../viewer/services/tools/Locator";

interface ISessionMembersPanelContainerPropTypes {
  SessionViewerPageStore?: ISessionViewerPageStore;
}

const TitleWrapper = styled(BoxAlignedCenter)`
  .badge {
    margin-left: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  }
`;
const MemberListElementWrapper = styled(Box)`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  padding: 8px 0 8px 8px;
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radiusMedium};
  opacity: 0.6;

  &.streaming {
    opacity: 1;
    cursor: pointer;
  }

  &.selected {
    background-color: ${(p: IAppContext) =>
      p.theme.skye.colors.backgroundLight};
  }

  .status-icon-wrapper .badge {
    width: 17px;
    height: 17px;
    padding: 2px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

interface MemberListElementProps {
  user: LoggedUser;
  selected?: boolean;
  isOwner?: boolean;
  streaming?: boolean; // Handling UI when we have information about user data location
  onClick: () => void;
}

const MemberListElement = ({
  user,
  isOwner = false,
  selected,
  streaming,
  onClick,
}: MemberListElementProps) => {
  const { t } = useTranslation();

  return (
    <MemberListElementWrapper
      className={classNames([{ selected, streaming }])}
      onClick={onClick}
    >
      <UserDetails
        user={user}
        selected={selected}
        statusIcon={
          streaming && (
            <Badge color="success">
              <LiveIcon />
            </Badge>
          )
        }
      />
      {isOwner && <Badge>{t("sessionOwner")}</Badge>}
    </MemberListElementWrapper>
  );
};

const viewerService = new ViewerService();

const SessionMembersPanelContainer = ({
  SessionViewerPageStore,
}: ISessionMembersPanelContainerPropTypes) => {
  const { t } = useTranslation();
  const {
    sessionMembersPanelOpen,
    sessionMembers,
    sessionOwner,
    selectedMemberId,
  } = SessionViewerPageStore;

  const handleUserElementClick = (user: LoggedUser) => () => {
    if (SessionViewerPageStore.isUserStreaming(String(user.id))) {
      SessionViewerPageStore.selectedMemberId = String(user.id);

      if (viewerService.viewerInitialized()) {
        Locator.setLocatorActive(String(user.id));
      }
    }
  };

  return (
    <ViewerSidePanel
      open={sessionMembersPanelOpen}
      onClose={() => SessionViewerPageStore.setSessionMembersPanelOpen(false)}
      title={
        <TitleWrapper>
          <span>{t("members")}</span>
          <Badge color="grey" fontSize={12}>
            {sessionMembers.length}
          </Badge>
        </TitleWrapper>
      }
    >
      {sessionOwner && (
        <MemberListElement
          user={sessionOwner}
          isOwner
          selected={selectedMemberId === String(sessionOwner.id)}
          streaming={SessionViewerPageStore.isUserStreaming(
            String(sessionOwner.id)
          )}
          onClick={handleUserElementClick(sessionOwner)}
        />
      )}
      {sessionMembers
        .filter(
          (member) => !sessionOwner || member.email !== sessionOwner.email
        )
        .map((member) => (
          <MemberListElement
            key={member.id}
            user={member}
            streaming={SessionViewerPageStore.isUserStreaming(
              String(member.id)
            )}
            selected={selectedMemberId === String(member.id)}
            onClick={handleUserElementClick(member)}
          />
        ))}
    </ViewerSidePanel>
  );
};

export default inject(STORE_NAMES.SessionViewerPageStore)(
  observer(SessionMembersPanelContainer)
);
