import React from "react";
import { inject, observer } from "mobx-react";
import { STORE_NAMES } from "const/global";
import { ISessionViewerPageStore } from "stores/SessionViewerPageStore";
import SessionViewerLocatorComponent from "../components/SessionLocator/SessionViewerLocatorComponent";
import { UserHelpers } from "../../../models/User";
import { Model } from "models/Model";

interface ISessionViewerLocatorsContainerPropTypes {
  SessionViewerPageStore?: ISessionViewerPageStore;
}

const getRelativeAltitude = (altitude: number, model?: Model) => {
  if (model?.operatorAltitude) {
    return altitude - model.operatorAltitude;
  }
  return 0;
};

const SessionUserViewerLocatorsContainer = ({
  SessionViewerPageStore,
}: ISessionViewerLocatorsContainerPropTypes) => {
  const sessionDataStore = SessionViewerPageStore.sessionUsersData;
  const sessionModel = SessionViewerPageStore.sessionModel;
  const selectedMemberId = SessionViewerPageStore.selectedMemberId;

  return (
    <>
      {Object.keys(sessionDataStore).map((key: string) => {
        const sessionData = sessionDataStore[key];

        return (
          <SessionViewerLocatorComponent
            key={key}
            active={selectedMemberId === String(sessionData.userId)}
            userId={String(sessionData.userId)}
            label={UserHelpers.getUserNameShortcut(sessionData.username)}
            longitude={sessionData.lng}
            latitude={sessionData.lat}
            altitude={getRelativeAltitude(sessionData.alt, sessionModel)}
            senderType={sessionData.senderType}
          />
        );
      })}
    </>
  );
};

export default inject(STORE_NAMES.SessionViewerPageStore)(
  observer(SessionUserViewerLocatorsContainer)
);
