import React from 'react'
import BasePage from "views/shared/layouts/BasePage";
import { Col, Row } from "styled-bootstrap-grid";
import SessionCreateContainer from '../container/SessionCreateContainer';

interface ISessionCreatePagePropTypes {
  
}

const SessionCreatePage = ({
  
}: ISessionCreatePagePropTypes) => {
  return (
    <BasePage backgroundColor="white">
      <Row>
        <Col sm={0} smOffset={12} md={2} mdOffset={8} lgOffset={4} lg={4}>
          <SessionCreateContainer />
        </Col>
      </Row>
    </BasePage>
  );
}

export default SessionCreatePage;
