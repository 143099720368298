import React, { ReactNode } from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";
import classNames from "classnames";
import Box from "./Box";

type IAlertType = "success" | "danger" | "warning";

interface IAlertPropTypes {
  type?: IAlertType;
  icon?: any;
  size?: "regular" | "small";
  children: ReactNode;
}

const StyledAlert = styled(Box)`
  align-items: flex-start;
  width: 100%;
  padding: ${(p: IAppContext) => p.theme.skye.sizes.small}
    ${(p: IAppContext) => p.theme.skye.sizes.medium};
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};
  border-radius: 5px;
  color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 160%;

  &.small {
    font-size: 12px;
    padding: 8px 12px;
    margin-bottom: 8px;
  }

  &.success {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.successLight};
    color: ${(p: IAppContext) => p.theme.skye.colors.successSecondary};

    svg {
      path {
        stroke: ${(p: IAppContext) => p.theme.skye.colors.successSecondary};
      }
    }
  }

  &.danger {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.dangerLight};
    color: ${(p: IAppContext) => p.theme.skye.colors.redOrange};

    svg {
      path {
        stroke: ${(p: IAppContext) => p.theme.skye.colors.redOrange};
      }
    }
  }

  &.warning {
    color: #837a4b;
    background-color: #faf0bb;
  }
`;

const IconWrapper = styled(Box)`
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;

const Alert = ({
  children,
  icon,
  type = 'success',
  size = 'regular'
}: IAlertPropTypes) => {
  return (
    <StyledAlert className={classNames(['skye-alert', type, size])}>
      {Boolean(icon) && (
        <IconWrapper>{icon}</IconWrapper>
      )}
      {children}
    </StyledAlert>
  )
}

export default Alert;
