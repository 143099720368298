import React, { memo, useState } from 'react'
import styled from 'styled-components';
import Button from "../../shared/buttons/Button";
import { getEnvVariables } from "../../../env";
import { GoogleMap, useLoadScript } from '@react-google-maps/api';
import { IAppContext } from "../../interfaces";
import {
  AnalyticEventCategory,
  AnalyticService,
} from "../../../services/AnalyticService";
import Box from './Box';
import { useTranslation } from 'react-i18next';

interface ICenterPoint {
  lat: number;
  lng: number;
}

interface IMapPropTypes {
  centerPoint: ICenterPoint;
  zoom?: number;
  onLoad: () => void;
  children: any;
}

export enum MapType {
  SatelliteHybrid = "hybrid",
  RoadMap = "roadmap",
}

const containerStyle = {
  width: "100%",
  height: "100%",
};

const MapWrapper = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

const MapButtonsRow = styled(Box)`
  position: absolute;
  top: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  left: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  z-index: 100;
  align-items: center;
  justify-content: flex-start;

  & > div {
    margin-left: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  }
`;

export const DEFAULT_ZOOM = 17;

const Map = ({
  centerPoint,
  zoom = DEFAULT_ZOOM,
  onLoad,
  children,
}: IMapPropTypes) => {
  const { t } = useTranslation();
  const [mapType, setMapType] = useState<MapType>(MapType.RoadMap);
  const [mapLoaded, setMapLoaded] = useState(false);
  const [map, setMap] = useState(null);
  const { isLoaded, loadError } = useLoadScript({
    googleMapsApiKey: getEnvVariables().GOOGLE_MAPS_API_KEY,
  });

  const changeMapType = () => {
    if (mapType === MapType.RoadMap) {
      setMapType(MapType.SatelliteHybrid);
      AnalyticService.event(
        AnalyticEventCategory.ModelDetailsPage,
        `Change map type - ${MapType.SatelliteHybrid}`
      );
      return;
    }
    AnalyticService.event(
      AnalyticEventCategory.ModelDetailsPage,
      `Change map type - ${MapType.RoadMap}`
    );
    setMapType(MapType.RoadMap);
  };

  const handleOnLoad = React.useCallback((e) => {
    // Changing map type to hybrid when loaded, dirty trigger for change
    changeMapType();
    onLoad();
    setMapLoaded(true);
  }, []);

  const onUnmount = React.useCallback(() => {
    setMap(null);
  }, []);

  return (
    <MapWrapper className="map-wrapper">
      <MapButtonsRow>
        {mapLoaded && (
          <Button size="small" type="light" onClick={changeMapType}>
            {t(mapType === MapType.RoadMap ? "satellite" : MapType.RoadMap)}
          </Button>
        )}
      </MapButtonsRow>
      {isLoaded && (
        <GoogleMap
          mapContainerStyle={containerStyle}
          mapTypeId={mapType}
          options={{
            mapTypeControl: false,
            fullscreenControl: false,
            streetViewControl: false,
            scaleControl: false,
            rotateControl: false,
          }}
          center={centerPoint}
          zoom={zoom}
          onLoad={handleOnLoad}
          onUnmount={onUnmount}
        >
          {mapLoaded && children}
        </GoogleMap>
      )}
    </MapWrapper>
  );
};

export default memo(Map);
