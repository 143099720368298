import React from "react";
import { Tag } from "antd";
import classNames from "classnames";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import { BrowseType, Model } from "models/Model";

export type TagColor =
  | "default"
  | "skyebrowse"
  | "widebrowse"
  | "manual"
  | "external"
  | "ultra";

interface ISkyeTagPropTypes {
  children: string;
  style?: any;
  color?: TagColor;
}

export const getModelTagColor = (browseType: BrowseType): TagColor => {
  switch (browseType) {
    case "skyebrowse":
      return "skyebrowse";

    case "widebrowse":
      return "widebrowse";

    case "manual":
      return "manual";

    case "external":
      return "external";

    default:
      return "default";
  }
};

const StyledTag = styled(Tag)`
  &.ant-tag {
    height: 25px;
    padding: 0 10px;
    font-style: normal;
    font-weight: 300;
    line-height: 24px;
    color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    background-color: ${(p: IAppContext) => p.theme.skye.colors.lavender};
    border: none;

    &.skyebrowse {
    }
    &.widebrowse {
    }
    &.manual {
    }
    &.external {
    }
    &.ultra {
    }
  }
`;

const SkyeTag = ({
  children,
  color = "default",
  ...otherProps
}: ISkyeTagPropTypes) => {
  return (
    <StyledTag className={classNames([color])} {...otherProps}>
      {children.toLocaleLowerCase()}
    </StyledTag>
  );
};

export default SkyeTag;
