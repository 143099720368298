import { useState } from "react";
import {
  SubscriptionPeriod,
  SubscriptionType,
} from "../../payments/payments.model";
import {
  SUBSCRIPTION_PRICING as PREMIUM_SUBSCRIPTION_PRICING,
  LITE_PRICING as LITE_SUBSCRIPTION_PRICING,
  PREMIUM_PRICING_QUOTE_SUVERY_URL,
} from "../../payments/components/PricingTable/PricingTable.const";
import Button from "../../shared/buttons/Button";
import { useTranslation } from "react-i18next";
import { IPricing } from "../../payments/components/PricingTable/PricingTable.model";
import HttpService from "../../../services/HttpService";
import API from "../../../const/api";
import { FacebookConversionApi } from "../../analytic/facebook/conversion.api";
import useCurrentUser from "../../account/hooks/useCurrentUser";
import { LITE_PRICE } from "../../payments/components/Pricing/pricing.const";

const redirectToPremiumQoteSurvey = () => {
  window.location.href = PREMIUM_PRICING_QUOTE_SUVERY_URL;
};

export const useSubscriptionPayment = () => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const [subscriptionPeriod, setSubscriptionPeriod] = useState(
    SubscriptionPeriod.Annually
  );
  const [loading, setLoading] = useState(false);
  const buttonsDisabled = loading;

  const createRedirectLink = async (
    subscriptionType: SubscriptionType
  ): Promise<string> => {
    const url = API.subscriptionCheckout
      .replace(":period", subscriptionPeriod)
      .replace(":type", subscriptionType);
    const response = await HttpService.post(url, {});
    return response.data;
  };

  const sendAnalyticEvent = async () => {
    await FacebookConversionApi.sendEvent(
      "AddToCart",
      {
        customerFirstName: user?.firstName || "",
        customerLastName: user?.lastName || "",
        customerEmail: user?.email || "",
      },
      {
        currency: "USD",
        value: LITE_PRICE(subscriptionPeriod),
      }
    );
  };

  const redirectToStripe = async (subscriptionType: SubscriptionType) => {
    setLoading(true);
    sendAnalyticEvent();
    const redirectUrl = await createRedirectLink(subscriptionType);
    setLoading(false);

    window.location.href = redirectUrl;
  };

  const pricings = (): IPricing[] => {
    const premiumPricing = {
      ...PREMIUM_SUBSCRIPTION_PRICING(subscriptionPeriod),
      buttonComponent: (
        <Button
          loading={loading}
          disabled={buttonsDisabled}
          color="primary"
          fullSize={true}
          onClick={redirectToPremiumQoteSurvey}
        >
          {t("pricing.requestQuote")}
        </Button>
      ),
    };

    const litePricing = {
      ...LITE_SUBSCRIPTION_PRICING(subscriptionPeriod),
      buttonComponent: (
        <Button
          loading={loading}
          disabled={buttonsDisabled}
          color="primary"
          fullSize={true}
          onClick={() => redirectToStripe(SubscriptionType.Lite)}
        >
          {t("subscribe")}
        </Button>
      ),
    };

    return [litePricing, premiumPricing];
  };

  return {
    redirect: redirectToStripe,
    subscriptionPeriod,
    setSubscriptionPeriod,
    pricings,
  };
};
