import React from 'react'

interface ITrashIconPropTypes {
  
}

const TrashIcon = ({
  
}: ITrashIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M6 6.66669V29.3334H26V6.66669H6Z" stroke="#1A1A1A" strokeLinejoin="round"/>
      <path d="M13.3335 13.3333V22" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M18.6665 13.3333V22" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.6665 6.66669H29.3332" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.6665 6.66669L12.8592 2.66669H19.1846L21.3332 6.66669H10.6665Z" stroke="#1A1A1A" strokeLinejoin="round"/>
    </svg>
  );
}

export default TrashIcon;
