import React from "react";
import ResetPasswordContainer from "../../containers/ResetPasswordContainer";
import SignPageLayout from "views/auth/pages/layout";

const ResetPasswordPage = () => {
  return (
    <SignPageLayout pageType="resetPassword">
      <ResetPasswordContainer />
    </SignPageLayout>
  );
};

export default ResetPasswordPage;
