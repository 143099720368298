import styled from "styled-components";
import Box from "../components/Box";
import { IAppContext } from "../../interfaces";

// Component dedicated to separate form elements between
export const FormGroup = styled(Box)`
  width: 100%;
  flex-direction: column;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
`;
