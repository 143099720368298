import { memo } from "react";
import Button from "../../../../../../shared/buttons/Button";
import { useTranslation } from "react-i18next";
import ArchiveIcon from "../../../../../../shared/Icons/ArchivedIcon";
import RecoveryIcon from "../../../../../../shared/Icons/RecoveryIcon";
import { PremiumStarIcon } from "../../../../../../shared/Icons/PremiumStarIcon";

export type UnlockUIType = "unlock" | "recover" | "buy";

export interface IModelListElementUnlockUIPropTypes {
  type: UnlockUIType;
  onClick: () => void;
}

const getIcon = (type: UnlockUIType) => {
  switch (type) {
    case "unlock":
      return <ArchiveIcon />;
    case "recover":
      return <RecoveryIcon />;
    case "buy":
      return <PremiumStarIcon />;
  }
};

const getTranslation = (t: any, type: UnlockUIType) => {
  switch (type) {
    case "unlock":
      return t("unlock");
    case "recover":
      return t("recover");
    case "buy":
      return t("buy");
  }
};

const ModelListElementUnlockUI = ({
  type,
  onClick,
}: IModelListElementUnlockUIPropTypes) => {
  const { t } = useTranslation();

  if (!type) return null;

  return (
    <div className="flex flex-row items-center model-list-element-unlock-ui">
      <Button
        startIcon={<div className="w-5 h-5">{getIcon(type)}</div>}
        className="mr-2"
        color="dark"
        type="bordered"
        onClick={onClick}
      >
        {getTranslation(t, type)}
      </Button>
    </div>
  );
};

export default memo(ModelListElementUnlockUI);
