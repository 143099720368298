import classNames from "classnames";
import React from "react";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import { BoxCentered } from "../../shared/components/Box";
import SkyeTooltip from "../../shared/components/SkyeTooltip";

export type SessionViewerIconColorMethodType = "fill" | "stroke";

interface ISessionViewerToolIconPropTypes {
  icon: any;
  name: string;
  active?: boolean;
  // Describing method how to color tool icon
  colorMethod?: "fill" | "stroke";
  onClick: () => void;
}

const IconInnerWrapper = styled(BoxCentered)`
  cursor: pointer;
  width: 38px;
  height: 38px;
`;

const IconOutterWrapper = styled(BoxCentered)`
  margin: 7px 0;

  &.color-method-fill {
    svg {
      path {
        fill: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
      }
    }
  }

  &.color-method-stroke {
    svg {
      path {
        stroke: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
      }
    }
  }

  &.active,
  &:hover {
    ${IconInnerWrapper} {
      background-color: ${(p: IAppContext) =>
        p.theme.skye.colors.silverChaliceLight};
      border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
    }

    &.color-method-fill {
      svg {
        path {
          fill: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
        }
      }
    }

    &.color-method-stroke {
      svg {
        path {
          stroke: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
        }
      }
    }
  }
`;

const SessionViewerToolIcon = ({
  active,
  icon,
  name,
  colorMethod = "fill",
  onClick,
}: ISessionViewerToolIconPropTypes) => {
  return (
    <IconOutterWrapper
      className={classNames([
        "tool-icon",
        `color-method-${colorMethod}`,
        { active },
      ])}
    >
      <SkyeTooltip title={name} placement="right">
        <IconInnerWrapper className="icon-inner-wrapper" onClick={onClick}>
          {icon}
        </IconInnerWrapper>
      </SkyeTooltip>
    </IconOutterWrapper>
  );
};

export default SessionViewerToolIcon;
