import React from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";

interface TitleProps {
  title: string,
}

interface IToolIconSectionPropTypes {
  titleProps: TitleProps,
  children?: any,
  iconsSection?: any,
}

const IconsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0 -5px -5px -5px;

  .tool-icon {
    margin-right: 5px;
    margin-left: 5px;
    margin-bottom: 10px;
  }
`;

const ContentWrapper = styled.div``;

const Wrapper = styled.div`
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
`;

const Title = styled.h2`
  margin: 0;
  padding: 0;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 140%;
`;

const Section = styled.section`
  width: 100%;
  padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.large};
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
  border-bottom: ${(p: IAppContext) => p.theme.skye.borders.primary};

  &:last-child {
    border-bottom: none;
  }
`;

const ToolIconSection = ({
  titleProps,
  iconsSection,
  children,
}: IToolIconSectionPropTypes) => {
  return (
    <Section className="tool-icon-section">
      <Wrapper>
        <Title>{ titleProps.title }</Title>
      </Wrapper>
      {!!iconsSection && (
        <IconsWrapper>
          { iconsSection }
        </IconsWrapper>
      )}
      {!!children && (
        <ContentWrapper>
          {children}
        </ContentWrapper>
      )}
    </Section>
  )
}

export default ToolIconSection;
