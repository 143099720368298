import styled from "styled-components";
import { IAppContext } from "../../interfaces";

const DateLabel = styled.div`
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
`;

export default DateLabel;
