import React, { ReactNode, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Box from "views/shared/components/Box";
import { IAppContext } from "views/interfaces";
import { ModelPageViewTypes } from "const/global";
import breakpoint from "styled-components-breakpoint";
import { IPotreeFeatures } from "../../../viewer/interfaces";
import { useModelViewerBreakpoint } from "../../containers/ModelViewer/hooks/useModelViewerBreakpoint";

export const SectionName = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
`;

interface IModelPageLayoutProps {
  view?: ModelPageViewTypes;
  topContent?: ReactNode;
  measurements?: IPotreeFeatures;
  sidebarComponent: ReactNode;
  orthophotoViewerComponent: ReactNode;
  headerRowComponent: ReactNode;
  contentSectionComponent: ReactNode;
  mapComponent: ReactNode;
  viewerComponent: ReactNode;
  commentsComponent?: ReactNode;
}

const DetailsSection = styled.div`
  padding: 32px 30px 46px 30px;
  width: 100%;
  overflow-y: auto;
`;
const PageContainer = styled(Box)`
  width: 100%;
  height: 100%;
  flex-direction: column;
`;
const MainContentRow = styled(Box)`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};

  ${breakpoint("lg")`
    padding: 0 20px 20px 20px;
  `}
`;
const GeneralDetailsContainer = styled(Box)`
  width: 100%;
  height: 100%;
  align-items: center;
`;
const Panel = styled(Box)`
  width: 100%;
  height: 100%;
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  overflow: hidden;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
`;
const MapContainer = styled(Panel)`
  min-height: 340px;
`;
const ModelDetailsContainer = styled(Panel)`
  min-width: 470px;
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.large};

  ${breakpoint("lg")`
    min-width: 690px;
  `}
`;
const LeftSidebar = styled.div`
  height: 100%;
  min-width: 100px;
  border-right: ${(p: IAppContext) => p.theme.skye.borders.primary};
  padding: ${(p: IAppContext) => p.theme.skye.sizes.large}
    ${(p: IAppContext) => p.theme.skye.sizes.average};

  ${breakpoint("lg")`
      min-width: 234px;
    `}
`;
const MobileMapSectionElement = styled(Box)`
  width: 100%;
  flex-direction: column;
  padding: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;
const MobileContentWrapper = styled(Box)`
  flex-direction: column;
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;
const MobileSectionMenuWrapper = styled.div`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;

const MobileMapSection = (props: IModelPageLayoutProps) => {
  const { mapComponent, sidebarComponent, contentSectionComponent } = props;

  return (
    <MobileMapSectionElement className="mobile-map-section">
      <MapContainer>{mapComponent}</MapContainer>
      <MobileSectionMenuWrapper>{sidebarComponent}</MobileSectionMenuWrapper>
      <MobileContentWrapper className="mobile-content-wrapper">
        {contentSectionComponent}
      </MobileContentWrapper>
    </MobileMapSectionElement>
  );
};

const ModelPageLayout = (props: IModelPageLayoutProps) => {
  const [desktopView] = useModelViewerBreakpoint();
  const {
    view = ModelPageViewTypes.Map,
    sidebarComponent,
    mapComponent,
    contentSectionComponent,
    viewerComponent,
    commentsComponent,
    headerRowComponent,
  } = props;
  const isMapView = ModelPageViewTypes.Map === view;
  const mainContentContainer = useRef<HTMLDivElement>();
  const [, setInitialized] = useState(false);

  const getMainContainerHeight = (): string => {
    if (!desktopView) {
      if (isMapView) return "100%";
      if (view === ModelPageViewTypes.Ortho) return "auto";
    }

    if (mainContentContainer && mainContentContainer.current) {
      const documentHeight = window.innerHeight;
      const rect = mainContentContainer.current.getBoundingClientRect();
      return `${documentHeight - rect.y}px`;
    }
    return `${window.innerHeight}px`;
  };

  const desktopMapView = (
    <GeneralDetailsContainer>
      <ModelDetailsContainer>
        <LeftSidebar>{sidebarComponent}</LeftSidebar>
        <DetailsSection>{contentSectionComponent}</DetailsSection>
      </ModelDetailsContainer>
      <MapContainer>{mapComponent}</MapContainer>
    </GeneralDetailsContainer>
  );

  const getDesktop = () => {
    switch (view) {
      case ModelPageViewTypes.Map:
        return desktopMapView;
      case ModelPageViewTypes.Model:
        return viewerComponent;
      case ModelPageViewTypes.Ortho:
        return props.orthophotoViewerComponent;
      default:
        return null;
    }
  };

  const getMobile = () => {
    switch (view) {
      case ModelPageViewTypes.Map:
        return <MobileMapSection {...props} />;
      case ModelPageViewTypes.Model:
        return viewerComponent;
      case ModelPageViewTypes.Ortho:
        return props.orthophotoViewerComponent;
      default:
        return null;
    }
  };

  useEffect(() => {
    // Fixing issue with wrong calculation of container height
    setInitialized(true);
  }, []);

  return (
    <PageContainer className="page-container">
      {headerRowComponent}
      <MainContentRow
        className="main-content-row"
        ref={mainContentContainer}
        style={{ height: getMainContainerHeight() }}
      >
        <div className="flex w-full">
          {desktopView ? getDesktop() : getMobile()}
          {commentsComponent}
        </div>
      </MainContentRow>
    </PageContainer>
  );
};

export default ModelPageLayout;
