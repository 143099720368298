import React, { memo } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IAppContext } from "../../../../interfaces";
import Box, { BoxAlignedCenter } from "../../../../shared/components/Box";
import ConnectionStatusIcon from "../../../../shared/Icons/ConnectionStatusIcon";
import { StatusLabel } from "../../../../shared/typography/typography";

interface IConnectionStatusInfoPropTypes {
  subscribed: boolean;
}

const Wrapper = styled(BoxAlignedCenter)``;
const StatusIconWrapper = styled(Box)`
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;

const ConnectionStatusInfo = ({
  subscribed,
}: IConnectionStatusInfoPropTypes) => {
  const { t } = useTranslation();

  return (
    <Wrapper className="">
      <StatusIconWrapper>
        <ConnectionStatusIcon
          status={subscribed ? "connected" : "disconnected"}
        />
      </StatusIconWrapper>
      <StatusLabel>{t(subscribed ? "connected" : "disconnected")}</StatusLabel>
    </Wrapper>
  );
};

export default memo(ConnectionStatusInfo);
