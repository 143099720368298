import { Model } from "models/Model";
import classNames from "classnames";
import React from "react";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import Box, { BoxCentered } from "../../shared/components/Box";
import CloseIcon from "../../shared/Icons/CloseIcon";
import ModelThumbnail from "./ModelThumbnail";

interface IModelRadioButtonPropTypes {
  modelName: string;
  modelId: string;
  thumbnailsUrls: string[];
  selected: boolean;
  onClick: () => void;
  onClose?: () => void;
  showRadioIcon?: boolean;
  showCloseIcon?: boolean;
}

const ThumbnailContainer = styled.div`
  height: 44px;
  width: 69px;
  min-width: 69px;
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.average};
  position: relative;
  background-color: ${(p: IAppContext) =>
    p.theme.skye.colors.backgroundThumbnail};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
`;
const ModelName = styled.h3`
  font-size: 14px;
  line-height: 140%;
  margin-bottom: 0;
  font-weight: 400;
`;
const RadioButtonOutter = styled(BoxCentered)`
  width: 16px;
  height: 16px;
  content: "";
  border: ${(p: IAppContext) => p.theme.skye.borders.primary};
  border-radius: 50%;
`;
const RadiuButtonInner = styled.div`
  background-color: transparent;
  border-radius: 50%;
`;
const CloseIconWrapper = styled(BoxCentered)`
  display: none;
  cursor: pointer;
  width: 20px;
  height: 20px;
  padding: 2px;
  box-shadow: ${(p: IAppContext) => p.theme.skye.shadows.primary};
  border-radius: 50%;
  position: absolute;
  right: -10px;
  top: -10px;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.redOrange};

  svg {
    path {
      stroke: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
    }
  }
`;
const Wrapper = styled(Box)`
  cursor: pointer;
  align-items: center;
  height: 86px;
  border: ${(p: IAppContext) => p.theme.skye.borders.primary};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};
  padding: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  position: relative;

  &:hover {
    ${CloseIconWrapper} {
      display: flex;
    }
  }

  &.selected {
    border: 1px solid ${(p: IAppContext) => p.theme.skye.colors.primary};
    cursor: pointer;

    ${ModelName} {
      font-weight: 600;
    }

    ${RadioButtonOutter} {
      border: 1px solid ${(p: IAppContext) => p.theme.skye.colors.primary};

      ${RadiuButtonInner} {
        width: 12px;
        height: 12px;
        border: 1px solid
          ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
        background-color: ${(p: IAppContext) => p.theme.skye.colors.primary};
      }
    }
  }
`;
const RadioWrapper = styled.div`
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;

const ModelRadioButton = ({
  modelId,
  modelName,
  thumbnailsUrls,
  selected,
  showCloseIcon = false,
  showRadioIcon = true,
  onClick = () => {},
  onClose = () => {},
}: IModelRadioButtonPropTypes) => {
  const handleClose = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onClose();
  };

  return (
    <Wrapper
      key={modelId}
      onClick={onClick}
      className={classNames(["model-radio-button", { selected }])}
    >
      {showRadioIcon && (
        <RadioWrapper>
          <RadioButtonOutter>
            <RadiuButtonInner></RadiuButtonInner>
          </RadioButtonOutter>
        </RadioWrapper>
      )}
      <ThumbnailContainer>
        {Boolean(modelId) && (
          <ModelThumbnail
            showNoVideoIcon
            showVideoDuration={false}
            modelId={modelId}
            thumbnailsUrls={thumbnailsUrls}
            onClick={onClick}
          />
        )}
      </ThumbnailContainer>
      <ModelName>{modelName}</ModelName>
      {showCloseIcon && (
        <CloseIconWrapper onClick={handleClose}>
          <CloseIcon />
        </CloseIconWrapper>
      )}
    </Wrapper>
  );
};

export default ModelRadioButton;
