import React from "react";

const ReportIcon = () => (
  <svg
    width="32"
    height="32"
    viewBox="0 0 32 32"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.52 12.8V19.4207"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.0254 5.41215L3.02833 24.4112C2.85237 24.7152 2.75957 25.0602 2.75928 25.4114C2.75899 25.7627 2.85121 26.1079 3.02668 26.4122C3.20214 26.7164 3.45465 26.9692 3.7588 27.1449C4.06296 27.3206 4.40802 27.4131 4.75928 27.4131H26.7533C27.1046 27.4131 27.4497 27.3206 27.7538 27.1449C28.058 26.9692 28.3105 26.7165 28.486 26.4122C28.6614 26.1079 28.7536 25.7627 28.7534 25.4114C28.7531 25.0602 28.6603 24.7152 28.4843 24.4112L17.4873 5.41215C17.3116 5.10863 17.0592 4.85664 16.7554 4.68146C16.4515 4.50628 16.107 4.41406 15.7563 4.41406C15.4056 4.41406 15.0611 4.50628 14.7573 4.68146C14.4535 4.85664 14.201 5.10863 14.0254 5.41215V5.41215Z"
      stroke="#1A1A1A"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.5999 24.3313C16.2626 24.3313 16.7999 23.794 16.7999 23.1313C16.7999 22.4685 16.2626 21.9313 15.5999 21.9313C14.9372 21.9313 14.3999 22.4685 14.3999 23.1313C14.3999 23.794 14.9372 24.3313 15.5999 24.3313Z"
      fill="#1A1A1A"
    />
  </svg>
);

export default ReportIcon;
