import React, { useEffect, useState } from 'react';
import { WindowUtils } from 'utils/windowUtils';
import PageLoader from "../../shared/components/PageLoader";
import { IViewerPropTypes } from "../Viewer";

const VIEWER_ASSETS_PATH = '/viewer';
const VIEWER_BUILD_SCIRPT = `${VIEWER_ASSETS_PATH}/build/potree/potree.js`;
const SCRIPT_ASSETS: any[] = [
  { src: 'jquery/jquery-3.1.1.min.js' },
  { src: 'spectrum/spectrum.js' },
  { src: 'jquery-ui/jquery-ui.min.js' },
  { src: 'three.js/build/three.min.js' },
  { src: 'three.js/extra/lines.js' },
  { src: 'other/BinaryHeap.js' },
  { src: 'tween/tween.min.js' },
  { src: 'd3/d3.js' },
  { src: 'proj4/proj4.js' },
  { src: 'openlayers3/ol.js' },
  { src: 'i18next/i18next.js' },
  { src: 'jstree/jstree.js' },
  { src: 'plasio/js/laslaz.js' },
];

const LoadViewerScriptsHoc = ViewerComponent => {
  return function LoadScripts(props: IViewerPropTypes) {
    const [scriptsLoaded, setScriptsLoaded] = useState(false);

    const loadScripts = async () => {
      for (const asset of SCRIPT_ASSETS) {
        const url = `${VIEWER_ASSETS_PATH}/libs/${asset.src}`;

        if (!WindowUtils.isScriptLoaded(url)) {
          await WindowUtils.loadScript(url, asset.type);
        }
      }
      await WindowUtils.loadScript(VIEWER_BUILD_SCIRPT, "");
      setScriptsLoaded(true);
    };

    useEffect(() => {
      loadScripts();
    }, []);

    return scriptsLoaded ? <ViewerComponent {...props} /> : <PageLoader />;
  };
}

export default LoadViewerScriptsHoc;
