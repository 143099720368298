import { LengthUnits, VIEWER_RENDER_AREA_ID } from "../const";
import { VolumeClipTypes } from "../const";
import { IPublicModel } from "models/Model";
import { WindowUtils } from "utils/windowUtils";

declare global {
  interface Window {
    viewer: any;
    Potree: any;
    THREE: any;
  }
}

type PointcloudUrl = string;

type HierarchyBin = string;
type MetadataJSON = string;
type OctreeBin = string;
export type PointCloud18Assets = [MetadataJSON, HierarchyBin, OctreeBin];

window.viewer = window.viewer || {};
window.Potree = window.Potree || {};

type IViewerInitCallback = (success: boolean) => void;

interface IViewerScale {
  value: number;
}

export interface IPotreeFeatures {
  volumes: Object;
  measurements: Object;
  scale?: IViewerScale;
}

export interface Volume {
  uuid: string;
  visible: boolean;
  clip: boolean;
}

enum PointBudget {
  Low = 70 * 1000,
  Medium = 500 * 1000,
}

export class ViewerService {
  initViewer(
    viewerContainer: any,
    model: IPublicModel,
    onViewerInit: IViewerInitCallback
  ) {
    if (!window.Potree || !window.Potree.Viewer) {
      onViewerInit(false);
      return;
    }

    window.viewer = new window.Potree.Viewer(viewerContainer, model);

    this.setOptions();
    this.loadGUI();
    onViewerInit(true);
  }

  focusCanvas() {
    if (window.viewer) {
      window.viewer.renderer.domElement.focus();
    }
  }

  loadPointCloud(
    urls: PointcloudUrl | PointCloud18Assets,
    onModelLoaded: IViewerInitCallback
  ) {
    window.Potree.loadPointCloud(urls, "PotreeModel", async (e) => {
      let pointcloud = e.pointcloud;
      let material = pointcloud.material;
      window.viewer?.scene.addPointCloud(pointcloud);
      material.size = 1.5;
      material.pointSizeType = window.Potree.PointSizeType.ADAPTIVE;
      material.shape = window.Potree.PointShape.CIRCLE;
      window.viewer?.fitToScreen(3000);

      if (WindowUtils.isIosDevice()) {
        this.setLowPointBudget();
        this.disablePointCloudVisibility();
      }

      if (onModelLoaded) {
        onModelLoaded(true);
      }

      window.dispatchEvent(new Event("model_loaded"));
    });
  }

  enablePointCloudVisibility() {
    if (window?.viewer?.scene?.pointclouds[0]) {
      window.viewer.scene.pointclouds[0].material.opacity = 1;
    }
  }

  disablePointCloudVisibility() {
    if (window?.viewer?.scene?.pointclouds[0]) {
      window.viewer.scene.pointclouds[0].material.opacity = 0;
    }
  }

  setMediumPointBudget() {
    window.viewer.setPointBudget(PointBudget.Medium);
  }

  /**
   * Disabling completely visibility of pointcloud. This method is used on mobile
   * devices to fix issue with 'viewer crashes' on iOS for safari/chrome
   */
  setLowPointBudget() {
    window.viewer.setPointBudget(PointBudget.Low);
  }

  /**
   * Loading mtl/obj textures
   * @param mtlUrl - presignedUrl to .mtl file
   * @param objUrl - presignedUrl to .obj file
   * @param mapUrl presignedUrl to color map .jpg file
   */
  async loadTextures(
    mtlUrl: string,
    objUrl: string,
    mapUrl: string
  ): Promise<boolean> {
    return new Promise((resolve, reject) => {
      window.viewer?.scene.addTexture(mtlUrl, objUrl, mapUrl, (err: any) => {
        if (err) return reject(err);
        else resolve(true);
      });
    });
  }

  getTextures(): any[] {
    try {
      return window.viewer?.scene.textures;
    } catch (error) {
      return [];
    }
  }

  texturesExists(): boolean {
    return !!this.getTextures().length;
  }

  toggleTextures(enabled: boolean) {
    window.viewer?.scene.toggleTextures(enabled);
  }

  removeFirstTexture() {
    window.viewer?.scene.removeFirstTexture();
  }

  loadGUI() {
    window.viewer?.loadGUI(() => {
      // Temporaty disabled
      // window.viewer?.setLanguage('en');
    });
  }

  setOptions() {
    window.viewer?.setEDLEnabled(false);

    if (/Mobi|Android/i.test(navigator.userAgent)) {
      window.viewer.useHQ = false;
    } else {
      window.viewer.useHQ = true;
    }

    window.viewer?.setFOV(69);
    window.viewer?.setPointBudget(PointBudget.Low);
    window.viewer?.setDescription(``);
    window.viewer?.loadSettingsFromURL();
    window.viewer?.setMinNodeSize(0.69);
    window.viewer?.setBackground("transparent");
  }

  handleViewerDestroy(modelId: string) {
    this.saveMeasurements(modelId);
    this.closeProfileWindow();
  }

  viewerInitialized(): boolean {
    return Boolean(window.viewer && window.viewer?.scene);
  }

  async getMainScreenshot(): Promise<string> {
    const { viewer } = window;
    const backgroundCopy = viewer.background;
    viewer.setFOV(35);
    viewer.setBackground("white");

    return new Promise((res) => {
      setTimeout(() => {
        const screenshot = viewer.getScreenshot();
        viewer.setBackground(backgroundCopy);
        res(screenshot);
      }, 1000);
    });
  }

  cancelPerspectiveTool() {
    // NoOp for now
  }

  changePerspectiveToPoint() {
    window.viewer?.changePerspectiveTool.start();
  }

  addVolume() {
    this.changeVolumeType(VolumeClipTypes.Highlight);
    window.viewer?.volumeTool.startInsertion({ clip: true });
  }

  switchMeasurementUnit() {
    window.viewer?.switchLengthUnit();
  }

  setLengthtUnit(lengthUnit: LengthUnits) {
    window.viewer?.setLengthUnit(lengthUnit);
  }

  getScreenshot(): string {
    return window.viewer?.getScreenshot();
  }

  getLengthUnit() {
    return window.viewer?.getLengthUnitCode();
  }

  setMeasurementsScale(scale: number): void {
    if (window.viewer) {
      window.viewer.lengthScale = scale;
    }
  }

  getMeasurementsScale(): number {
    return window.viewer?.lengthScale;
  }

  saveMeasurements(modelId: string): void {
    window.viewer?.saveAllToLocalStorage(modelId);
  }

  restoreMeasurements(modelId: string, allFeatures?: IPotreeFeatures): void {
    window.viewer?.restoreMeasurements(modelId, allFeatures);
  }

  /**
   * Retrieving features like measurements and volumes
   */
  getAllFeatures(modelId: string): IPotreeFeatures | null {
    return window.viewer?.getAllViewerFeatures(modelId);
  }

  async toggleReportGenerationMode(newState: boolean): Promise<any> {
    if (newState) {
      this.setRenderAreaToSquare();
      await window.viewer?.setReportView();
    } else {
      this.setRenderAreaToRegularSize();
      await window.viewer?.cleanAfterReportView();
    }

    window.viewer?.scene.toggleReportGenerationMode(newState);
  }

  removeProfile(profile: any): void {
    if (profile) {
      window.viewer?.scene.removeProfile(profile);
    }
  }

  removeAllClipVolumes() {
    window.viewer?.scene.removeAllClipVolumes();
  }

  setVolumeVisibility(volume: Volume, visibility: boolean) {
    window.viewer?.scene.volumes.forEach((v) => {
      if (v.uuid === volume.uuid) {
        v.visible = visibility;
        v.clip = visibility;
      }
    });
  }

  getVolumes(): Volume[] {
    return window.viewer?.scene?.volumes || [];
  }

  removeVolume(volume: Volume) {
    window.viewer?.scene.removeVolume(volume);
  }

  closeProfileWindow() {
    if (window.viewer?.profileWindow) {
      window.viewer?.profileWindow.hide();
    }
  }

  profileToolExist(): boolean {
    return !!window.viewer?.profileTool;
  }

  showProfileWindow() {
    window.viewer?.profileWindow.show();
  }

  /**
   * Changing volume clipping type
   * @param id - id of volume type
   */
  changeVolumeType(id: VolumeClipTypes) {
    window.viewer?.setClipTask(id);
  }

  openProfile() {
    const existingProfile = window.viewer?.scene.profiles[0];

    this.closeProfileWindow();
    this.removeProfile(existingProfile);

    window.viewer?.profileTool.startInsertion();
    window.viewer?.profileWindowController.setProfile(
      window.viewer?.scene.profiles[0]
    );
  }

  getLocalCoordinatesFromReal(
    latitude: number,
    longitude: number,
    altitude: number
  ) {
    return window.viewer?.getLocalFromRealCoordinates(
      latitude,
      longitude,
      altitude
    );
  }

  async setReportView() {
    await window.viewer?.setReportView();
  }

  setScrollToPoint(active: boolean) {
    window.viewer?.setScrollToPoint(active);
  }

  getRenderAreaElement(): HTMLElement | null {
    return document.getElementById(VIEWER_RENDER_AREA_ID);
  }

  setRenderAreaToSquare() {
    const elem = this.getRenderAreaElement();

    if (elem) {
      elem.style.width = "1000px";
      elem.style.height = "1000px";
      elem.style.position = "absolute";
      elem.style.top = "0px";
      elem.style.left = "0px";
    }
  }

  setRenderAreaToRegularSize() {
    const elem = this.getRenderAreaElement();

    if (elem) {
      elem.style.width = "100%";
      elem.style.height = "100%";
      elem.style.position = "static";
    }
  }

  handleProfileToolCancel() {
    if (this.profileToolExist()) {
      this.showProfileWindow();
    }
  }

  addEventListener(eventName: string, callback: (data: any) => void) {
    window.viewer?.scene.addEventListener(eventName, callback);
  }

  /**
   * Chaning model view to fit into screen
   */
  fitToScreen() {
    window.viewer?.fitToScreen(1500);
  }
}
