import React from 'react'
import styled from 'styled-components'
import Box from "./Box";
import Label from '../typography/Label';
import { IAppContext } from "../../interfaces";

const Wrapper = styled(Box)`
  width: 100%;
`;

const LoaderBackground = styled.div`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.background};
  border-radius: 1.5px;
  width: 100%;
  height: 3px;
  position: relative;
  overflow: hidden;
`; 

const LoaderProgress = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.primary};
`;

const PercentageText = styled(Label)`
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
  margin-right: 4px;
  font-weight: 500;
`;

interface ILoaderPropTypes {
  percent?: number,
  displayText?: boolean,
}

const ProgressLoader = ({
  percent = 0,
  displayText = false,
}: ILoaderPropTypes) => {
  return (
    <Wrapper className="loader-wrapper" alignItems="center">
      {displayText && (
        <PercentageText>{`${percent}%`}</PercentageText>
      )}
      <LoaderBackground>
        <LoaderProgress className="progress-content" style={{ width: `${percent}%` }} />
      </LoaderBackground>
    </Wrapper>
  )
}

export default ProgressLoader;
