import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { MessageService } from "../../../../services/MessageService";
import Dialog from "../../../shared/components/Dialog/Dialog";
import {
  IModelIssueForm,
  IModelIssueReq,
  ModelIssueType,
} from "../model/Issue";
import { IssueService } from "../service/IssueService";
import ReportIssueForm from "./ReportIssueForm";

export interface IReportIssueProps {
  model: {
    publicKey: string;
    id: string;
  };
  publicView: boolean; // Describing if modal is displayed for public model or regular
}

interface IReportIssueModalContainerPropTypes extends IReportIssueProps {
  open: boolean;
  onClose: () => void;
}

const INITIAL_FORM: IModelIssueForm = {
  description: "",
  reason: null,
  platform: null,
  shareWithSupport: false,
};

const ReportIssueModalContainer = ({
  publicView,
  model,
  open,
  onClose,
}: IReportIssueModalContainerPropTypes) => {
  const { t } = useTranslation();
  const [form, setForm] = useState(INITIAL_FORM);
  const [valid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleFormChange = (newForm: IModelIssueForm, valid: boolean) => {
    setForm(newForm);
    setValid(valid);
  };

  const serializedFormReq = (): IModelIssueReq => {
    return {
      ...form,
      reason: form.reason || ModelIssueType.Other,
      userAgent: navigator.userAgent,
    };
  };

  const report = () => {
    if (!valid) return;

    const reqForm = serializedFormReq();
    const req = publicView
      ? IssueService.reportPublicModel(model.publicKey, reqForm)
      : IssueService.reportModel(model.id, reqForm);

    setLoading(true);
    req
      .then(() => {
        onClose();
      })
      .catch(() => {
        MessageService.error(t("responseErrorRequestFailed"));
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    // Reseting form on close
    if (!open) {
      setForm(INITIAL_FORM);
    }
  }, [open]);

  return (
    <Dialog
      title={t("reportIssue")}
      open={open}
      onClose={onClose}
      confirmButtonProps={{
        disabled: !valid,
        onClick: report,
        loading,
      }}
    >
      <ReportIssueForm currentForm={form} onChange={handleFormChange} />
    </Dialog>
  );
};

export default ReportIssueModalContainer;
