import React, { useState } from 'react'
import styled from 'styled-components';
import breakpoint from 'styled-components-breakpoint';
import moment from 'moment';
import 'react-dates/lib/css/_datepicker.css';
import 'react-dates/initialize';
import { DateRangePicker } from 'react-dates';
import { IAppContext } from "../../interfaces";
import { lighten } from 'polished';

interface IDatePickerPropTypes {
  startDate: moment.Moment | null,
  endDate: moment.Moment | null,
  onDateChange: (startDate: moment.Moment | null, endDate: moment.Moment | null) => void,
}

const DatePickerWrapper = styled.div`
  &,
  .DateRangePicker {
    width: 100%;
  }

  .DateRangePicker_picker {
    z-index: 100;
  }

  .DateInput_fang {
    z-index: 101;
    padding-bottom: 5px;
  }

  .DateRangePickerInput {
    width: 100%;
    color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
    border: ${(p: IAppContext) => p.theme.skye.borders.primary};
    border-radius: 4px;
    height: ${(p: IAppContext) => p.theme.skye.sizes.xLarge};
    align-items: center;
    display: flex;

    ${breakpoint("lg")`
      width: 278px;
      padding-left: 2px;
    `}
  }

  .DateInput {
    cursor: pointer;
    width: 110px;
    height: 39px;
    text-align: center;
  }

  .DateInput_input {
    color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
    font-size: ${(p: IAppContext) => p.theme.skye.fontSizes.regular};
    border-bottom: 0px;
    padding: 0;
    text-align: center;
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    width: 100%;
    height: 100%;

    ${breakpoint("lg")`
      padding-left: 10px;
    `}
  }

  .DateRangePickerInput_arrow_svg {
    width: 14px;
  }

  .CalendarDay__selected_span,
  .CalendarDay__hovered_span_3,
  .CalendarDay__hovered_span:hover,
  .CalendarDay__hovered_span {
    background: ${(p: IAppContext) =>
      lighten(0.1, p.theme.skye.colors.primary)};
    border: 1px double
      ${(p: IAppContext) => lighten(0.1, p.theme.skye.colors.primary)};
    color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  }

  .CalendarDay__selected,
  .CalendarDay__selected:active,
  .CalendarDay__selected_span:active,
  .CalendarDay__selected_span:hover,
  .CalendarDay__selected:hover {
    background: ${(p: IAppContext) => p.theme.skye.colors.primary};
    border: 1px double ${(p: IAppContext) => p.theme.skye.colors.primary};
  }
`;

/**
 * Overlay around react dates component
 * https://github.com/airbnb/react-dates
 */
const DatePicker = ({
  startDate,
  endDate,
  onDateChange,
}: IDatePickerPropTypes) => {
  const [focusedInput, setFocusedInput] = useState(null);

  return (
    <DatePickerWrapper>
      <DateRangePicker
        withPortal
        showClearDates
        hideKeyboardShortcutsPanel
        enableOutsideDays
        isOutsideRange={() => false}
        startDate={startDate} // momentPropTypes.momentObj or null,
        startDateId="start_date" // PropTypes.string.isRequired,
        endDate={endDate} // momentPropTypes.momentObj or null,
        endDateId="end_date" // PropTypes.string.isRequired,
        onDatesChange={({ startDate, endDate }) =>
          onDateChange(startDate, endDate)
        } // PropTypes.func.isRequired,
        focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
        onFocusChange={(focusedInpt) => setFocusedInput(focusedInpt)} // PropTypes.func.isRequired,
      />
    </DatePickerWrapper>
  );
}

export default DatePicker;
