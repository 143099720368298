export default class Angle {
  static add() {
    window.viewer?.measuringTool.startInsertion({
      showDistances: false,
      showArea: false,
      showAngles: true,
      closed: false,
      name: "Angle",
    });
  }
}
