import { computed, makeAutoObservable } from "mobx";
import { SessionService } from "services/SessionService";
import { OrganizationService } from "services/OrganizationService";
import ModelService from "views/models/services/ModelService";
import { connect, MqttClient } from "precompiled-mqtt";
import type { LoggedUser } from "models/User";
import type { Model } from "models/Model";
import {
  IncomingSessionData,
  SessionData,
  TacbrowseEntity,
} from "models/SessionData";
import type { ISession, ISessionAddress } from "models/Session";
import { SessionPageViewTypes } from "../const/global";
import { IStream } from "../services/StreamService";

// Defining maximum time for which findings will be stored and displayed
const FINDING_TTL = 5;

interface SessionDataStore {
  [id: string]: SessionData;
}

type IIncomingSessionData = IncomingSessionData | IncomingSessionData[];
type IInternalSessionData = SessionData | SessionData[];

interface FakeUserData {
  userId: string;
  deviceId: string;
  name: string;
  repeats: number;
}

export interface ISessionViewerPageStore {
  subscribed: boolean;
  sessionUsersData: SessionDataStore; // Users subscribed to session
  sessionFindingsData: SessionDataStore; // Objects detected during session
  session: ISession | null;
  sessionLoading: boolean;
  sessionLoaded: boolean;
  sessionMembers: LoggedUser[];
  sessionMembersPanelOpen: boolean;
  sessionModel: Model;
  sessionModelLoaded: boolean;
  sessionOwner: LoggedUser;
  selectedMemberId: string;
  allDataLoaded: boolean;
  liveResults: boolean;
  viewType: SessionPageViewTypes;

  disconnectFromSession(): void;

  setSelectedMember(userId: string): void;
  setViewType(viewType: SessionPageViewTypes): void;

  subscribeToSession(sessionAddress: string): void;

  toggleLive(live: boolean): void;

  /**
   * Main function which is getting all data for session view
   * @param sessionAddress
   * @returns
   */
  initView: (sessionAddress: string) => Promise<boolean>;
  setSessionMembersPanelOpen: (open: boolean) => void;
  resetStore: () => void;
  isUserStreaming: (userId: string) => boolean;
}

const isFinding = (data: SessionData) => {
  return data.senderType === TacbrowseEntity.Finding;
};

const isExpired = (data: SessionData, now: number) => {
  return data.addedAt - now >= FINDING_TTL * 1000;
};

const MAX_RECCONECTION_ATTEMPT = 10; // Number of recconections event to try reconnect again

class SessionViewerPageStore {
  tacbrowseService: SessionService;
  organisationService: OrganizationService;

  // Temporary disabled as Research wants something else and Bobby also
  // allowedObjectTypes: Record<string, true> = allowedObjects();
  reconnectionAttempt = 0;
  subscribed: boolean = false;
  error: any = false;
  mqtt: MqttClient;
  sessionUsersData: SessionDataStore = {};
  sessionFindingsData: SessionDataStore = {};

  sessionLoading = false;
  sessionLoaded = false;
  session: ISession;
  sessionMembersPanelOpen = true;
  streamPanelOpen = true;
  sessionMembers: LoggedUser[] = [];
  sessionModel: Model;
  sessionModelLoaded: boolean;
  sessionOwner: LoggedUser;
  selectedMemberId = "";
  // If set to true it will display current results in specified max time
  // If set to false it will prevent from possition changes of findings
  liveResults: boolean = true;
  viewType = SessionPageViewTypes.Map;
  // Streams
  streams: IStream[];

  constructor() {
    makeAutoObservable(this);
    this.tacbrowseService = new SessionService();
  }

  mockFakeUsers(users: FakeUserData[]) {
    setTimeout(() => {
      if (this.sessionModel && this.sessionModel.centerLocation) {
        users.forEach((fakeUser) => {
          this.tacbrowseService.startMockingData(
            this.session.publicId,
            fakeUser.name,
            fakeUser.userId,
            fakeUser.deviceId,
            this.sessionModel.centerLocation.point.latitude,
            this.sessionModel.centerLocation.point.longitude,
            fakeUser.repeats
          );
        });
      }
    }, 4000);
  }

  detectedObjectAllowed() {
    return;
  }

  async getSessionOwner() {
    if (this.session) {
      const owner = await OrganizationService.getUser(this.session.host.id);
      this.sessionOwner = owner;
    }
  }

  getSessionModel() {
    if (this.session && this.session.modelId) {
      ModelService.getModelDetails(this.session.modelId)
        .then((res) => {
          this.sessionModel = res.data;

          // For development purposes
          // if (isLocal()) {
          //   this.mockFakeUsers(SESSION_USERS_MOCK_DATA);
          // }
        })
        .finally(() => {
          this.sessionModelLoaded = true;
        });
    }
  }

  async getSessionMembers() {
    if (this.session.invited && this.session.invited.users) {
      const organizationUsers = await OrganizationService.getUsers();

      // For development purposes
      // if (isLocal()) {
      //   const fakeUsers: FakeUserData[] = members.map(member => ({
      //     userId: String(member.id),
      //     name: UserHelpers.getUserName(member),
      //     deviceId: '',
      //     repeats: 20,
      //   }));
      //   this.mockFakeUsers(fakeUsers);
      // }

      return (this.sessionMembers = organizationUsers);
    }
  }

  async initView(sessionAddress: string): Promise<boolean> {
    this.sessionLoading = true;
    const session = await this.tacbrowseService.getSession(sessionAddress);
    this.session = session;
    this.sessionLoading = false;

    if (session) {
      this.sessionLoaded = true;
      this.getSessionMembers();
      this.getSessionModel();
      this.getSessionOwner();
    }

    return Boolean(session);
  }

  handleConnectionClose() {
    this.error = null;
    this.subscribed = false;
  }

  handleConnectionOpen() {
    this.error = null;
    this.subscribed = true;
  }

  handleConnectionError(err: any) {
    this.subscribed = false;
    this.error = err;
  }

  /**
   * Function to clean crowdy view.
   * When findings allowed it is possible that after some time view will be full of locators.
   * To prevent this, it's cleaning view from old locators after some time.
   */
  cleanFindingsData() {
    const now = new Date().getTime();
    const newSessionData = { ...this.sessionFindingsData };

    Object.keys(newSessionData).forEach((key) => {
      const data = newSessionData[key];

      if (isFinding(data) && isExpired(data, now)) {
        delete newSessionData[key];
      }
    });

    this.sessionFindingsData = newSessionData;
  }

  handleFindings(findings: SessionData[]) {
    findings.forEach((finding) => {
      if (this.liveResults) {
        this.sessionFindingsData[finding.deviceId] = finding;
        return;
      }

      if (!this.sessionFindingsData[finding.deviceId]) {
        this.sessionFindingsData[finding.deviceId] = finding;
      }
    });

    if (this.liveResults) this.cleanFindingsData();
  }

  handleMessage(dataOrDataArr: IIncomingSessionData) {
    this.subscribed = true;
    const now = new Date().getTime();
    const findings = [];

    if (!this.sessionUsersData) {
      this.sessionUsersData = {};
    }

    if (Array.isArray(dataOrDataArr)) {
      dataOrDataArr.forEach((incomingData) => {
        const sessionData = { ...incomingData, addedAt: now };

        if (isFinding(sessionData)) {
          findings.push(incomingData);
        } else {
          this.sessionUsersData[incomingData.userId] = sessionData;
        }
      });
    } else {
      this.sessionUsersData[String(dataOrDataArr.userId)] = {
        ...dataOrDataArr,
        addedAt: now,
      };
    }

    this.handleFindings(findings);
  }

  handleReconnectAttempt(sessionAddress: ISessionAddress) {
    this.reconnectionAttempt++;

    // Trying to reconnect every MAX_RECCONECTION_ATTEMPT times. Preventig from reconnect every second.
    if (!this.subscribed && this.reconnectionAttempt === 1) {
      this.subscribeToSession(sessionAddress);
    } else if (this.reconnectionAttempt >= MAX_RECCONECTION_ATTEMPT) {
      this.reconnectionAttempt = 0;
    }
  }

  disconnectFromSession() {
    if (this.mqtt) this.mqtt.end();
    this.subscribed = false;
  }

  async subscribeToSession(sessionAddress: ISessionAddress) {
    this.disconnectFromSession();

    const sessionConnectionConfig =
      await this.tacbrowseService.getSessionLiveFeed(sessionAddress);
    this.mqtt = connect(sessionConnectionConfig.endpoint, {
      clientId: sessionConnectionConfig.clientId,
    })
      .on("connect", () => this.handleConnectionOpen())
      .on("close", () => this.handleConnectionClose())
      .on("error", (err: any) => this.handleConnectionError(err))
      .on("reconnect", () => this.handleReconnectAttempt(sessionAddress))
      .on("message", (topic: string, buffer) =>
        this.handleMessage(JSON.parse(buffer.toString("utf-8")))
      )
      .subscribe(sessionConnectionConfig.topic);
  }

  setSessionMembersPanelOpen(open: boolean): void {
    this.sessionMembersPanelOpen = open;
  }

  resetStore(): void {
    this.subscribed = false;
    this.error = false;
    this.mqtt = null;
    this.sessionUsersData = {};

    this.sessionLoading = false;
    this.sessionLoaded = false;
    this.session = null;
    this.sessionMembersPanelOpen = false;
    this.sessionMembers = [];
    this.sessionModel = null;
    this.sessionModelLoaded = false;
    this.sessionOwner = null;
  }

  setSelectedMember(userId: string): void {
    this.selectedMemberId = String(userId);
    this.sessionMembersPanelOpen = true;
  }

  toggleLive(live: boolean): void {
    this.liveResults = live;
  }

  isUserStreaming(userId): boolean {
    return Boolean(this.sessionUsersData[userId]);
  }

  setViewType(viewType: SessionPageViewTypes): void {
    this.viewType = viewType;
  }

  @computed get allDataLoaded(): boolean {
    if (this.sessionLoaded) {
      if (this.session) {
        return this.session.modelId && this.sessionModelLoaded;
      }
      return true;
    }

    return false;
  }
}

export default new SessionViewerPageStore();
