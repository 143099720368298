import React, { ReactNode } from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import ButtonsToggleGroup from "../../shared/buttons/ButtonsToggleGroup";
import { AUTH_ROUTES } from "../../routes";
import AuthPageLayout from "../../shared/layouts/auth-page";
import classNames from "classnames";

interface ISignPagePropTypes extends RouteComponentProps {
  title?: string;
  description?: string;
  children: ReactNode;
  showSwitch?: boolean;
  pageType: "login" | "register" | "resetPassword" | "finishRegisterByToken";
  contentSize?: "md" | "lg";
}

const SIGN_IN_PAGE_TYPES = [
  { label: "login", id: "login" },
  { label: "register", id: "register" },
];

const SkyebrowseAuthPageLayout = ({
  title,
  description,
  children,
  showSwitch,
  pageType,
  contentSize = "md",
  history,
}: ISignPagePropTypes) => {
  const changePageType = () => {
    switch (pageType) {
      case "login":
        history.push(AUTH_ROUTES.register);
        return;
      case "register":
        history.push(AUTH_ROUTES.login);
        return;
      default:
        return;
    }
  };

  return (
    <AuthPageLayout
      companyImg="/img/logo_white_horizontal.svg"
      companyImgAlt="SkyeBrowse"
      slides={[
        {
          imgUrl: "/img/login-img.png",
          imgAlt: "Turn any video into a 3D model",
          title: "Turn any video into a 3D model",
          description: (
            <ul className="text-left">
              <li className="mb-3">
                <span className="mr-2">💨</span> Create 2D maps and 3D models in
                minutes.
              </li>
              <li className="mb-3">
                <span className="mr-2">✍️</span> Produce interior AI designs,
                sketch drawings, and export.
              </li>
              <li className="mb-3">
                <span className="mr-2">✅</span> Sign up to make unlimited 3D
                models for free.
              </li>
            </ul>
          ),
        },
      ]}
    >
      <div className="w-full h-full flex items-center flex-col text-left justify-start md:justify-center py-8">
        <div className="top-content lg:hidden flex items-center flex-col">
          <a href="/">
            <img
              src="/img/logo_black_horizontal.svg"
              alt="Skyebrowse logotype"
              className="h-10 w-auto mb-8"
            />
          </a>
        </div>
        <div
          className={classNames([
            "w-full p-4",
            {
              "max-w-md": contentSize === "md",
              "max-w-2xl": contentSize === "lg",
            },
          ])}
        >
          <div className="mb-8">
            {!!title && <h1 className="text-3xl font-bold mb-4">{title}</h1>}
            {!!description && (
              <h3 className="text-base font-md text-gray-500 mb-4">
                {description}
              </h3>
            )}
          </div>
          {showSwitch && (
            <div className="mb-12">
              <ButtonsToggleGroup
                size="large"
                fullSize
                backgroundColor="grey"
                elements={SIGN_IN_PAGE_TYPES}
                selected={pageType}
                onChange={changePageType}
              />
            </div>
          )}
          <div className="form-content">{children}</div>
        </div>
      </div>
    </AuthPageLayout>
  );
};

export default withRouter(SkyebrowseAuthPageLayout);
