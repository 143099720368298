import React from "react";

const CancelIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M23.3337 8.66663L16.0003 16M16.0003 16L23.3337 23.3333M16.0003 16L8.66699 23.3333M16.0003 16L8.66699 8.66663"
        stroke="black"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default CancelIcon;
