import { inject, observer } from 'mobx-react';
import styled from 'styled-components';
import React from 'react'
import { useTranslation } from 'react-i18next';
import { IModelDetailsPageStore } from 'stores/ModelDetailsPageStore';
import Button from "../../shared/buttons/Button";
import CommentsIcon from "../../shared/Icons/CommentsIcon";
import { BoxCentered } from "../../shared/components/Box";
import { IAppContext } from "../../interfaces";
import { AnalyticEventCategory, AnalyticService } from 'services/AnalyticService';
import SkyeTooltip from "../../shared/components/SkyeTooltip";

const COUNTER_SIZE = 20;

interface ICommentsCountButtonContainerPropTypes {
  ModelDetailsPageStore?: IModelDetailsPageStore
}

const ButtonWrapper = styled.div`
  position: relative;
`;
const CounterWrapper = styled(BoxCentered)`
  width: ${COUNTER_SIZE}px;
  height: ${COUNTER_SIZE}px;
  border-radius: 50%;
  border: 2px solid ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.danger};
  position: absolute;
  top: -${COUNTER_SIZE / 2}px;
  right: -${COUNTER_SIZE / 2}px;
  color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  font-style: normal;
  font-weight: 500;
  font-size: 8px;
  line-height: 14px;
`;

const CommentsCountButtonContainer = ({
  ModelDetailsPageStore,
}: ICommentsCountButtonContainerPropTypes) => {
  const { t } = useTranslation();

  const handleClick = () => {
    if (ModelDetailsPageStore.commentsOpen) {
      AnalyticService.event(AnalyticEventCategory.ModelDetailsPage, 'comment_toggle_panel_close');
      ModelDetailsPageStore.closeCommentsPanel()
    } else {
      AnalyticService.event(AnalyticEventCategory.ModelDetailsPage, 'comment_toggle_panel_open');
      ModelDetailsPageStore.openCommentsPanel();
    }
  }

  return (
    <SkyeTooltip title={t("comments")}>
      <ButtonWrapper>
        <Button
          endIcon={<CommentsIcon />}
          type="light"
          onClick={handleClick}
        ></Button>
        {Boolean(ModelDetailsPageStore.commentsCount) && (
          <CounterWrapper>{ModelDetailsPageStore.commentsCount}</CounterWrapper>
        )}
      </ButtonWrapper>
    </SkyeTooltip>
  );
}

export default inject('ModelDetailsPageStore')(observer(CommentsCountButtonContainer));
