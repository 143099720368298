import React from "react";
import { IModelList, IModelListActions, IModelListItem } from "./interface";
import { IModelListPageType, ModelType } from "../../../../interfaces";
import { IGlobalStore } from "../../../../../stores/GlobalStore";
import EmptyListInformation from "../../../../shared/components/EmptyListInformation";
import styled from "styled-components";
import Box from "../../../../shared/components/Box";
import PageLoader from "../../../../shared/components/PageLoader";
import {
  ISharedInModelItem,
  ISharedOutModelItem,
} from "../../../../../models/SharedModel";
import SharedOutModelList from "./SharedOutModelList";
import SharedInModelList from "./SharedInModelList";
import OrganizationModelList from "./OrganizationModelList";
import { Model } from "../../../../../models/Model";

interface IModelListPropTypes extends IModelListActions {
  GlobalStore?: IGlobalStore;
  models: IModelList;
  loading: boolean;
  listType: IModelListPageType;
}

const LoaderWrapper = styled(Box)`
  width: 100%;
  height: 400px;
  align-items: flex-start;
`;

const Loader = () => (
  <LoaderWrapper>
    <PageLoader />
  </LoaderWrapper>
);

const ModelList = ({
  listType,
  loading,
  models,
  GlobalStore,
  ...props
}: IModelListPropTypes) => {
  if (!loading && !models?.length) {
    return <EmptyListInformation title="modelsListEmptyInfo" />;
  }

  if (loading) {
    return <Loader />;
  }

  if (listType === ModelType.SharedToOthers) {
    return (
      <SharedOutModelList
        sharedOutModels={models as ISharedOutModelItem[]}
        {...props}
      />
    );
  }

  if (listType === ModelType.Shared) {
    return (
      <SharedInModelList sharedInModels={models as ISharedInModelItem[]} />
    );
  }

  return (
    <OrganizationModelList
      listType={listType}
      models={models as IModelListItem[]}
      {...props}
    />
  );
};

export default ModelList;
