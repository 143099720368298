import copy from "copy-to-clipboard";  
import moment from 'moment';

export const REDIRECT_URL_KEY = 'redirectUrl';

export class WindowUtils {
  static copyToClipboard(text: string) {
    copy(text);
  }

  static getRedirectUrl(): string {
    return WindowUtils.getQueryParam(REDIRECT_URL_KEY, "");
  }

  /**
   * Getting url parameter by specific name
   * @param key - name of parameter
   * @param defaultValue
   */
  static getQueryParam(key: string, defaultValue: any): any {
    const urlParams = new URLSearchParams(window.location.search);
    const param = urlParams.get(key);

    if (!param) {
      return defaultValue;
    }

    const isArray = param.indexOf(",") > -1;
    if (isArray) {
      const arrayParam = param.split(",");
      return arrayParam;
    }

    if (key === "startDate" || key === "endDate") {
      const date = moment(Number(param));
      return date;
    }

    return param;
  }

  static async loadScript(url, type?: string, className = "") {
    return new Promise((resolve) => {
      const elem = document.createElement("script");
      elem.src = url;

      if (type) {
        elem.type = type;
      }

      if (className) {
        elem.className = className;
      }

      elem.onload = () => {
        resolve(null);
      };

      document.head.appendChild(elem);
    });
  }

  static async loadStylesheet(url, rel = "stylesheet") {
    return new Promise((resolve) => {
      const elem = document.createElement("link");
      elem.href = url;
      elem.rel = rel;
      elem.onload = () => {
        resolve(null);
      };
      document.head.appendChild(elem);
    });
  }

  static isScriptLoaded = (src: string): boolean => {
    return Boolean(document.querySelector(`script[src="${src}"]`));
  };

  // Describing if device in iOS platform
  // https://stackoverflow.com/questions/9038625/detect-if-device-is-ios
  static isIosDevice(): boolean {
    return (
      [
        "iPad Simulator",
        "iPhone Simulator",
        "iPod Simulator",
        "iPad",
        "iPhone",
        "iPod",
      ].includes(navigator.platform) ||
      // iPad on iOS 13 detection
      (navigator.userAgent.includes("Mac") && "ontouchend" in document)
    );
  }
}