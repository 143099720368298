import { SubscriptionType } from "../../payments/payments.model";
import useCurrentUser from "./useCurrentUser";

const useSubscription = () => {
  const { user } = useCurrentUser();

  const expired: boolean = !user?.organization?.active;

  const active = user?.organization?.active;

  const type: SubscriptionType = user?.organization?.subscription?.type?.value;

  const expirationDate = user?.organization.subscription.expirationDate;

  return { active, expirationDate, expired, type };
};

export default useSubscription;
