import React, { useEffect, useState } from 'react'
import styled from 'styled-components';
import { Container } from "styled-bootstrap-grid";
import { useTranslation } from "react-i18next";
import Description from "../../shared/typography/Description";
import Card from "../../shared/components/Card";
import Title from "../../shared/typography/Title";
import { withRouter, RouteComponentProps } from 'react-router-dom';
import Heading from "../../shared/typography/Heading";
import { IAppContext } from "../../interfaces";
import { TUTORIAL_PAGES } from "../../../const/global";
import { VideoTutorialPageData } from "../../../interfaces/global";

interface IEmergencyUploadInformationPagePropTypes extends RouteComponentProps {
  
}

const StyledContainer = styled(Container)`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;

const VideoTutorialWrapper = styled.div`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;

const getPage = (locationPathname: string): VideoTutorialPageData | null => {
  return TUTORIAL_PAGES.find(p => p.route === locationPathname);
}

const VideoTutorialPage = ({
  location
}: IEmergencyUploadInformationPagePropTypes) => {
  const [page, setPage] = useState<null | VideoTutorialPageData>(null);
  const { t } = useTranslation();

  useEffect(() => {
    setPage(getPage(location.pathname));
  }, []);

  if (!page) {
    return null;
  }

  return (
    <StyledContainer>
      <Card>
        <Heading>{t(page.title)}</Heading>
        {!!page.description && (
          <Description>{t(page.description)}</Description>
        )}
        <VideoTutorialWrapper>
          <Title>{t('videoTutorial')}</Title>
          <iframe width="560" height="315" src={page.videoUrl} title={page.title} frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
        </VideoTutorialWrapper>
      </Card>
    </StyledContainer>
  )
}

export default withRouter(VideoTutorialPage);
