const CrownIcon = () => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.47 10.49L19.8201 18.75C19.8013 18.8313 19.7779 18.9114 19.75 18.99C19.6494 19.2142 19.4867 19.405 19.2812 19.5397C19.0756 19.6743 18.8358 19.7473 18.5901 19.75H5.41012C5.12166 19.749 4.84226 19.6492 4.61842 19.4672C4.39459 19.2853 4.23982 19.0322 4.17989 18.75L2.52996 10.49C2.4811 10.2386 2.51049 9.97819 2.61417 9.74396C2.71784 9.50973 2.89082 9.31291 3.10979 9.18001C3.26403 9.08208 3.43853 9.02053 3.62008 9.00001C3.95036 8.96462 4.2812 9.06173 4.53996 9.27001L5.91008 10.37C6.15343 10.566 6.43501 10.7089 6.73679 10.7898C7.03858 10.8707 7.35393 10.8877 7.66265 10.8396C7.97137 10.7916 8.26667 10.6797 8.52965 10.511C8.79263 10.3423 9.0175 10.1206 9.18986 9.86001L10.9599 7.21001C11.08 7.0472 11.2367 6.91488 11.4174 6.82369C11.598 6.7325 11.7975 6.685 11.9999 6.685C12.2023 6.685 12.4018 6.7325 12.5824 6.82369C12.7631 6.91488 12.9198 7.0472 13.0399 7.21001L14.8099 9.86001C14.9823 10.1206 15.2072 10.3423 15.4702 10.511C15.7332 10.6797 16.0285 10.7916 16.3373 10.8396C16.646 10.8876 16.9614 10.8706 17.2632 10.7898C17.565 10.7089 17.8466 10.5659 18.0899 10.37L19.46 9.27001C19.6594 9.1082 19.9043 9.01258 20.1607 8.99645C20.417 8.98032 20.672 9.04448 20.8901 9.18001C21.1091 9.31287 21.2822 9.50969 21.3858 9.74393C21.4895 9.97817 21.5189 10.2386 21.47 10.49Z"
        fill="#FFBC02"
      />
      <path
        d="M21.47 10.49L19.8201 18.75C19.8013 18.8313 19.7779 18.9114 19.75 18.99C19.6971 18.9983 19.6436 19.0017 19.5901 19H9.47996C8.32448 18.9994 7.20488 18.5986 6.31157 17.8657C5.41826 17.1328 4.80641 16.1131 4.58006 14.98L3.52996 9.74001C3.47784 9.49014 3.50951 9.23006 3.62008 9.00001C3.95036 8.96462 4.28119 9.06173 4.53996 9.27001L5.91008 10.37C6.15343 10.566 6.43501 10.7089 6.73679 10.7898C7.03858 10.8707 7.35392 10.8877 7.66265 10.8396C7.97137 10.7916 8.26667 10.6797 8.52965 10.511C8.79263 10.3423 9.0175 10.1206 9.18986 9.86001L10.9599 7.21001C11.08 7.0472 11.2367 6.91488 11.4174 6.82369C11.598 6.7325 11.7975 6.685 11.9999 6.685C12.2023 6.685 12.4018 6.7325 12.5824 6.82369C12.7631 6.91488 12.9198 7.0472 13.0399 7.21001L14.8099 9.86001C14.9823 10.1206 15.2072 10.3423 15.4702 10.511C15.7332 10.6797 16.0285 10.7916 16.3373 10.8396C16.646 10.8876 16.9614 10.8706 17.2632 10.7898C17.565 10.7089 17.8466 10.5659 18.0899 10.37L19.46 9.27001C19.6594 9.1082 19.9043 9.01258 20.1607 8.99645C20.417 8.98032 20.672 9.04448 20.8901 9.18001C21.1092 9.31287 21.2822 9.50969 21.3858 9.74393C21.4895 9.97817 21.5189 10.2386 21.47 10.49Z"
        fill="#FFC933"
      />
      <path
        d="M13.5 4C13.5044 4.1539 13.4772 4.30707 13.4199 4.45C13.3281 4.7547 13.1402 5.02155 12.8843 5.21076C12.6284 5.39998 12.3182 5.50144 12 5.5C11.8029 5.50027 11.6078 5.46166 11.4257 5.38638C11.2435 5.31109 11.0781 5.20061 10.9387 5.06127C10.7994 4.92193 10.6889 4.75646 10.6136 4.57435C10.5383 4.39224 10.4997 4.19706 10.5 4C10.4992 3.69807 10.5908 3.40312 10.7624 3.15468C10.9339 2.90625 11.1774 2.71619 11.46 2.61C11.631 2.53813 11.8145 2.50074 12 2.5C12.1971 2.49973 12.3922 2.53834 12.5744 2.61363C12.7565 2.68891 12.9219 2.79939 13.0613 2.93873C13.2006 3.07808 13.3111 3.24354 13.3864 3.42565C13.4617 3.60777 13.5003 3.80294 13.5 4Z"
        fill="#5A11FF"
      />
      <path
        d="M3.99999 6.65C4.00377 6.76868 3.98685 6.88712 3.94999 7C3.87469 7.32779 3.69012 7.62022 3.4266 7.82921C3.16309 8.03821 2.83631 8.15133 2.49999 8.15C2.30293 8.15026 2.10776 8.11164 1.92565 8.03635C1.74354 7.96107 1.57808 7.85059 1.43874 7.71125C1.2994 7.5719 1.18892 7.40644 1.11363 7.22433C1.03834 7.04223 0.999722 6.84705 0.999986 6.65C0.998266 6.43645 1.04403 6.22518 1.13396 6.03149C1.22389 5.83779 1.35574 5.6665 1.51999 5.53C1.78647 5.28336 2.13689 5.14748 2.49999 5.15C2.69704 5.14973 2.89222 5.18834 3.07433 5.26362C3.25644 5.33891 3.42191 5.44939 3.56125 5.58873C3.7006 5.72807 3.81107 5.89354 3.88636 6.07565C3.96165 6.25776 4.00026 6.45294 3.99999 6.65Z"
        fill="#5A11FF"
      />
      <path
        d="M23 6.34998C23.0033 6.55729 22.9589 6.76259 22.8701 6.94998C22.7623 7.1957 22.5904 7.40795 22.3724 7.56453C22.1545 7.72111 21.8985 7.81627 21.6312 7.84008C21.3639 7.86389 21.0951 7.81547 20.8529 7.69987C20.6107 7.58428 20.404 7.40576 20.2544 7.18296C20.1048 6.96017 20.0178 6.70129 20.0024 6.43336C19.9871 6.16544 20.044 5.89832 20.1672 5.65991C20.2904 5.4215 20.4754 5.22056 20.7028 5.07808C20.9302 4.9356 21.1917 4.85683 21.46 4.84998H21.5C21.6971 4.84971 21.8922 4.88833 22.0744 4.96362C22.2565 5.03891 22.4219 5.14939 22.5613 5.28873C22.7006 5.42807 22.8111 5.59353 22.8864 5.77564C22.9617 5.95775 23.0003 6.15292 23 6.34998Z"
        fill="#5A11FF"
      />
      <path
        d="M13.5 4C13.5044 4.1539 13.4772 4.30707 13.4199 4.45C13.2837 4.49089 13.1422 4.51112 13 4.51C12.7937 4.5105 12.5893 4.47023 12.3986 4.39151C12.2079 4.31279 12.0346 4.19716 11.8887 4.05128C11.7428 3.90539 11.6272 3.73212 11.5485 3.54141C11.4697 3.3507 11.4294 3.14632 11.4299 2.94C11.43 2.82932 11.44 2.71887 11.4599 2.61C11.631 2.53812 11.8145 2.50074 12 2.5C12.1971 2.49973 12.3922 2.53834 12.5744 2.61363C12.7565 2.68891 12.9219 2.79939 13.0613 2.93873C13.2006 3.07808 13.3111 3.24354 13.3864 3.42565C13.4617 3.60777 13.5003 3.80294 13.5 4Z"
        fill="#BEA1FF"
      />
      <path
        d="M4.00001 6.65C4.00379 6.76868 3.98687 6.88712 3.95001 7C3.69313 7.18289 3.38546 7.2808 3.07013 7.28C2.8638 7.2805 2.65941 7.24024 2.4687 7.16152C2.27798 7.0828 2.1047 6.96718 1.9588 6.82129C1.8129 6.6754 1.69726 6.50213 1.61853 6.31142C1.53979 6.12071 1.49952 5.91632 1.50001 5.71C1.49674 5.64931 1.5035 5.58849 1.52001 5.53C1.78649 5.28336 2.13691 5.14748 2.50001 5.15C2.69707 5.14973 2.89224 5.18834 3.07435 5.26362C3.25647 5.33891 3.42193 5.44939 3.56127 5.58873C3.70062 5.72807 3.8111 5.89354 3.88638 6.07565C3.96167 6.25776 4.00028 6.45294 4.00001 6.65Z"
        fill="#BEA1FF"
      />
      <path
        d="M23 6.34998C23.0033 6.55729 22.9589 6.76258 22.8701 6.94998C22.4665 6.93792 22.0834 6.76952 21.8016 6.48032C21.5198 6.19112 21.3614 5.80375 21.3599 5.39998C21.3613 5.21224 21.3951 5.02616 21.46 4.84998H21.5C21.697 4.84971 21.8922 4.88832 22.0743 4.96361C22.2564 5.0389 22.4219 5.14937 22.5612 5.28872C22.7006 5.42806 22.8111 5.59352 22.8864 5.77563C22.9616 5.95774 23.0003 6.15292 23 6.34998Z"
        fill="#BEA1FF"
      />
    </svg>
  );
};

export default CrownIcon;
