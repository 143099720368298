import React from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";
import Box from '../components/Box';
import classNames from 'classnames';

interface IButtonsGroupPropTypes {
  children: any,
  fullSize?: boolean,
}

const ButtonGroupContainer = styled(Box)`
  &.fullSize {
    width: 100%;

    .btn {
      width: 100%;
      justify-content: center;
    }
  }

  .btn {
    font-size: 14px;
    padding-left: ${(p: IAppContext) => p.theme.skye.sizes.average};
    padding-right: ${(p: IAppContext) => p.theme.skye.sizes.average};
    height: 40px;
    border-radius: 0;

    &:first-child {
      border-top-left-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
      border-bottom-left-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
    }
    
    &:last-child {
      border-top-right-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
      border-bottom-right-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
    }

    &:not(.active) {
      color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
      background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};

      &:hover {
        background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
      }
    }
  }
`;

const ButtonsGroup = ({
  children,
  fullSize = false,
}: IButtonsGroupPropTypes) => {
  return (
    <ButtonGroupContainer className={classNames({ fullSize })}>
      {children}
    </ButtonGroupContainer>
  )
}

export default ButtonsGroup;
