const AppUploadIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <mask
      id="mask0_4205_420"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <path d="M0 0H24V24H0V0Z" fill="white" />
    </mask>
    <g mask="url(#mask0_4205_420)">
      <path
        d="M21.0681 5.21674L12 0.468767L2.93185 5.21674"
        stroke="#5A11FF"
        strokeWidth="1.2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <path
      d="M12 9.99595V9.96469L2.93185 5.21672V17.4637L5.95314 18.9844"
      stroke="#5A11FF"
      strokeWidth="1.2"
      strokeMiterlimit="10"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <mask
      id="mask1_4205_420"
      maskUnits="userSpaceOnUse"
      x="0"
      y="0"
      width="24"
      height="24"
    >
      <path d="M0 0H24V24H0V0Z" fill="white" />
    </mask>
    <g mask="url(#mask1_4205_420)">
      <path
        d="M17.861 20.1474V13.3798L12 9.99594L6.13907 13.3798V20.1474L12 23.5312L17.861 20.1474Z"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M10.6154 17.8471V17.7132C10.6154 17.2411 10.3265 17.1495 9.9389 17.1495C9.69937 17.1495 9.62188 16.9381 9.62188 16.7267C9.62188 16.5153 9.69937 16.304 9.9389 16.304C10.2067 16.304 10.4885 16.2687 10.4885 15.6979C10.4885 15.2892 10.256 15.1906 9.96712 15.1906C9.62188 15.1906 9.44568 15.2751 9.44568 15.5499C9.44568 15.7895 9.33998 15.9516 8.93132 15.9516C8.42399 15.9516 8.36052 15.8459 8.36052 15.5077C8.36052 14.9581 8.75512 14.2464 9.96712 14.2464C10.862 14.2464 11.5385 14.5705 11.5385 15.5218C11.5385 16.0362 11.3482 16.5153 10.9959 16.6774C11.4116 16.8324 11.7147 17.1425 11.7147 17.7132V17.8471C11.7147 19.0027 10.9184 19.4396 9.93187 19.4396C8.71992 19.4396 8.25482 18.6997 8.25482 18.1078C8.25482 17.7908 8.3887 17.7062 8.77631 17.7062C9.22724 17.7062 9.33998 17.8048 9.33998 18.0726C9.33998 18.4037 9.65001 18.4813 9.96712 18.4813C10.4463 18.4813 10.6154 18.3051 10.6154 17.8471Z"
        fill="#5A11FF"
      />
      <path
        d="M13.3846 15.2047V18.439H14.0118C14.4063 18.439 14.6459 18.2135 14.6459 17.7344V15.9093C14.6459 15.4302 14.4063 15.2047 14.0118 15.2047H13.3846ZM14.0118 14.2463C14.9842 14.2463 15.7452 14.6973 15.7452 15.9093V17.7344C15.7452 18.9464 14.9842 19.3973 14.0118 19.3973H12.7645C12.4756 19.3973 12.2854 19.2423 12.2854 19.0661V14.5776C12.2854 14.4014 12.4756 14.2463 12.7645 14.2463H14.0118Z"
        fill="#5A11FF"
      />
      <path
        d="M18.0469 18.9844L21.0682 17.4637V5.4"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M21.0682 5.21674L12 9.96471V9.99597"
        stroke="#5A11FF"
        strokeWidth="1.5"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
  </svg>
);
export default AppUploadIcon;
