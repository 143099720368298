import { memo } from "react";

const AdjustmentIcon = () => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3627_9243)">
        <path
          d="M11.1426 3.00004H9.85728C9.62055 3.00004 9.42838 2.80832 9.42838 2.5716C9.42838 2.33488 9.62057 2.1427 9.85728 2.1427H11.1431H11.1426C11.3793 2.1427 11.5715 2.33489 11.5715 2.5716C11.5715 2.80833 11.3793 3.00004 11.1426 3.00004Z"
          fill="#959494"
        />
        <path
          d="M11.1426 9.85746H9.85728C9.62055 9.85746 9.42838 9.66527 9.42838 9.42856C9.42838 9.19183 9.62057 9.00012 9.85728 9.00012H11.1431H11.1426C11.3793 9.00012 11.5715 9.19185 11.5715 9.42856C11.5715 9.66528 11.3793 9.85746 11.1426 9.85746Z"
          fill="#959494"
        />
        <path
          d="M0.85734 3H6.91782C7.13392 3.83578 7.93735 4.38096 8.79378 4.27224C9.65021 4.16352 10.2923 3.43458 10.2923 2.5716C10.2923 1.70817 9.65015 0.979682 8.79378 0.870962C7.93741 0.762242 7.13394 1.3069 6.91782 2.14272H0.85734C0.620616 2.14272 0.428436 2.33491 0.428436 2.57163C0.428436 2.80835 0.620628 3 0.85734 3Z"
          fill="#959494"
        />
        <path
          d="M11.1426 5.57157H5.08212C4.88946 4.81548 4.20885 4.28625 3.4284 4.28625C2.6484 4.28625 1.96776 4.81548 1.77516 5.57157H0.857356C0.620632 5.57157 0.428452 5.7633 0.428452 6.00001C0.428452 6.23672 0.620644 6.42845 0.857356 6.42845H1.77516C1.96782 7.18454 2.64844 7.71377 3.4284 7.71377C4.20887 7.71377 4.88952 7.18454 5.08212 6.42845H11.1426C11.3793 6.42845 11.5715 6.23672 11.5715 6.00001C11.5715 5.7633 11.3793 5.57157 11.1426 5.57157Z"
          fill="#959494"
        />
        <path
          d="M8.5716 7.71425C7.79207 7.71706 7.11288 8.24534 6.91788 9.00005H0.857401C0.620677 9.00005 0.428497 9.19177 0.428497 9.42848C0.428497 9.66521 0.620689 9.85739 0.857401 9.85739H6.91788C7.08288 10.4888 7.59241 10.9721 8.23224 11.1024C8.87208 11.2327 9.53016 10.988 9.92868 10.471C10.3276 9.95395 10.3979 9.2555 10.1091 8.66954C9.82087 8.08408 9.22404 7.7133 8.5716 7.71425Z"
          fill="#959494"
        />
      </g>
      <defs>
        <clipPath id="clip0_3627_9243">
          <rect width="12" height="12" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default memo(AdjustmentIcon);
