import { IPotreeMeasurement, MeasurementTypesNames } from "../../interfaces";

export default class CenterPoint {
  static add(): void {
    window.viewer?.measuringTool.startInsertion({
      showDistances: false,
      showAngles: false,
      showCoordinates: true,
      showLabel: true,
      showArea: false,
      closed: true,
      name: MeasurementTypesNames.CenterPoint,
      maxMarkers: 1,
    });
  }

  static getCenterPoint() {
    return window.viewer?.scene.getCenterPoint();
  }

  static getDistanceToCenterPoint = (measurement: IPotreeMeasurement) => {
    return window.viewer?.scene.getDistanceToCenterPoint(measurement);
  };
}
