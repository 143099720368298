import React, { memo } from "react";
import styled from "styled-components";

interface IViewerCompanyIconPropTypes {}

const SkyebrowseIcon = styled.img`
  width: 40px;
  height: 40px;
  opacity: 0.5;
`;

const ViewerCompanyIcon = ({}: IViewerCompanyIconPropTypes) => {
  return (
    <SkyebrowseIcon
      src="/viewer/images/logo_white.svg"
      alt="skyebrowse_logotype"
    />
  );
};

export default memo(ViewerCompanyIcon);
