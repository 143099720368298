import React, { useEffect, useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { IAppContext } from "../../interfaces";
import SquareLoader from "../../shared/components/SquareLoader";
import NoVideoIcon from "../../shared/Icons/NoVideoIcon";
import { BoxCentered } from "../../shared/components/Box";
import ModelService from "../services/ModelService";

const THUMBNAIL_TIMEOUT = 400;

interface IModelThumbnailPropTypes {
  modelId: string;
  thumbnailsUrls: string[];
  showVideoDuration?: boolean;
  showNoVideoIcon?: boolean;
  onClick: () => void;
}

const ThumbnailWrapper = styled.div`
  position: relative;
  width: 100%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;
const ThumbnailImage = styled.img`
  display: none;
  z-index: 2;
  position: relative;
  width: auto;
  max-width: 100%;

  &.active {
    display: block;
    z-index: 5;
  }
`;
const ThumbnailLoaderWrapper = styled.div`
  position: absolute;
  background-color: ${(p: IAppContext) =>
    p.theme.skye.colors.backgroundThumbnail};
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NoVideoWrapper = styled(BoxCentered)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;

  svg {
    width: 24px;

    path {
      fill: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
    }

    line {
      stroke: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
    }
  }
`;

let timeout = null;

const ModelThumbnail = ({
  modelId,
  thumbnailsUrls,
  showNoVideoIcon = false,
  onClick,
}: IModelThumbnailPropTypes) => {
  const [currentActiveIndex, setCurrentActiveIndex] = useState(0);
  const [mouseOver, setMouseOver] = useState(false);
  const [imageLoaded, setImageLoaded] = useState(false);
  const [allThumbnails, setAllThumbnails] = useState(thumbnailsUrls);
  const [error, setError] = useState(false);

  useEffect(() => {
    if (!mouseOver && allThumbnails?.length > 1) {
      setMouseOver(true);
    }
  }, [allThumbnails]);

  const mouseLeave = () => {
    setMouseOver(false);
    setCurrentActiveIndex(0);
  };

  const mouseEnter = () => {
    if (allThumbnails?.length < 2) {
      ModelService.getModelThumbnails(modelId).then((response) => {
        setAllThumbnails(response.data);
      });
    } else {
      setMouseOver(true);
    }
  };

  const changeThumbnail = (activeIndex) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => {
      if (mouseOver) {
        const newIndex =
          activeIndex === allThumbnails.length - 1 ? 0 : activeIndex + 1;
        setCurrentActiveIndex(newIndex);
        changeThumbnail(newIndex);
      }
    }, THUMBNAIL_TIMEOUT);
  };

  const handleImageLoad = (e) => {
    if (!imageLoaded) {
      setImageLoaded(true);
    }
  };

  const handleImageError = () => {
    setError(true);
    setImageLoaded(true);
  };

  useEffect(() => {
    changeThumbnail(currentActiveIndex);
  }, [mouseOver]);

  return (
    <ThumbnailWrapper
      className="thumbnail-wrapper"
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
      onClick={onClick}
    >
      {!imageLoaded && (
        <ThumbnailLoaderWrapper>
          <SquareLoader />
        </ThumbnailLoaderWrapper>
      )}
      {(showNoVideoIcon || error || !!allThumbnails.length) && (
        <NoVideoWrapper className="no-video">
          <NoVideoIcon />
        </NoVideoWrapper>
      )}
      {!!allThumbnails?.length && !error && (
        <>
          {allThumbnails.map((url, index) => {
            if (
              index === currentActiveIndex ||
              index === currentActiveIndex + 1
            ) {
              return (
                <ThumbnailImage
                  key={url}
                  src={url}
                  onLoad={handleImageLoad}
                  onError={handleImageError}
                  className={classNames([
                    "thumbnail-image",
                    { active: index === currentActiveIndex },
                  ])}
                />
              );
            }
            return null;
          })}
        </>
      )}
    </ThumbnailWrapper>
  );
};

export default ModelThumbnail;
