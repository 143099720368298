import { ServiceType } from "../../models/ServicePlan";
import { LoggedUser } from "../../models/User";
import { PermissionService } from "../../services/PermissionService";

export class SessionAccessPolicy {
  static hasSessionAccess(user: LoggedUser) {
    return (
      PermissionService.hasService(user, ServiceType.Tacbrowse) &&
      user.organization?.active
    );
  }
}
