import React from "react";
import styled from "styled-components";
import { IAppContext } from "../../../../interfaces";
import { BoxAlignedCenter } from "../../Box";

interface ISubscriptionBadgePropTypes {
  text?: string;
}

const Badge = styled(BoxAlignedCenter)`
  font-style: normal;
  font-weight: 500;
  line-height: 11px;
  padding: 3px 6px;
  border-radius: 2px;
  background: linear-gradient(258.89deg, #5a11ff 1.93%, #a075ff 100%);
  color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
`;

const SubscriptionTypeBadge = ({
  text = "PRO",
}: ISubscriptionBadgePropTypes) => {
  return <Badge className="subscription-type-badge py-1 text-xs">{text}</Badge>;
};

export default SubscriptionTypeBadge;
