import { IPotreeMeasurement, MeasurementTypesNames } from "../../interfaces";
import BaseMeasure from "./BaseMeasure";

export default class Locator {
  static removeAllLocators() {
    const locators = BaseMeasure.getAll().filter(
      (measurement) => measurement.name === MeasurementTypesNames.Locator
    );
    locators.forEach((measurement) => {
      BaseMeasure.remove(measurement);
    });
  }

  static addLocator(
    userId: number | string,
    label: string,
    latitude: number,
    longitude: number,
    altitude: number,
    iconPath?: string
  ): IPotreeMeasurement {
    return window.viewer?.locatorTool.insertLocator(
      userId,
      label,
      latitude,
      longitude,
      altitude,
      iconPath
    );
  }

  static removeLocator(measurement: IPotreeMeasurement) {
    return window.viewer?.locatorTool.remove(measurement);
  }

  static setLocatorActive(userId: string) {
    return window.viewer?.locatorTool.setLocatorActive(userId);
  }

  static updateLocator(
    measurement: IPotreeMeasurement,
    lat: number,
    lng: number,
    alt: number
  ) {
    return window.viewer?.locatorTool.updatePosition(
      measurement,
      lat,
      lng,
      alt
    );
  }

  static isLocator(measurement: IPotreeMeasurement): boolean {
    return measurement.name === "Locator";
  }
}
