import modelEnv from "../model.env";
import HttpService from "services/HttpService";

interface UploadFileData {
  fileName: string;
}

interface GetPresignedUrlResponse {
  presignedUrl: string;
}

export class ModelFlightLogService {
  static async getPresignedUrlForLogs(
    modelId: string,
    uploadFileData: UploadFileData
  ): Promise<GetPresignedUrlResponse> {
    const url = `${modelEnv.apiUrl}/${modelId}/logs`;
    const res = await HttpService.post(url, uploadFileData);
    return res.data;
  }
}
