import React from "react";
import SessionViewerPageStore, {
  ISessionViewerPageStore,
} from "stores/SessionViewerPageStore";
import { inject, observer } from "mobx-react";
import { STORE_NAMES } from "const/global";
import SessionMapLocatorComponent from "../components/SessionLocator/SessionMapLocatorComponent";
import { UserHelpers } from "../../../models/User";
import { SessionData, TacbrowseEntity } from "models/SessionData";

interface ISessionMapLocatorsContainerPropTypes {
  SessionViewerPageStore?: ISessionViewerPageStore;
}

const locatorClickHandler =
  (SessionViewerPageStore: ISessionViewerPageStore, sessionData: SessionData) =>
  () => {
    SessionViewerPageStore.setSelectedMember(String(sessionData.userId));
  };

const SessionUserMapLocatorsContainer =
  ({}: ISessionMapLocatorsContainerPropTypes) => {
    const sessionDataStore = SessionViewerPageStore.sessionUsersData;
    const selectedMemberId = SessionViewerPageStore.selectedMemberId;

    return (
      <>
        {Object.keys(sessionDataStore).map((key: string) => {
          const sessionData = sessionDataStore[key];
          const username = sessionData.username;
          const label = UserHelpers.getUserNameShortcut(username);

          return (
            <SessionMapLocatorComponent
              key={`${sessionData.lng}_${sessionData.lat}_${username}`}
              label={label}
              longitude={sessionData.lng}
              latitude={sessionData.lat}
              active={selectedMemberId === String(sessionData.userId)}
              onClick={locatorClickHandler(SessionViewerPageStore, sessionData)}
              senderType={sessionData.senderType || TacbrowseEntity.Mobile}
            ></SessionMapLocatorComponent>
          );
        })}
      </>
    );
  };
export default inject(STORE_NAMES.SessionViewerPageStore)(
  observer(SessionUserMapLocatorsContainer)
);
