import httpService from "services/HttpService";
import API from "const/api";
import axios from "axios";

let getTagsCancelToken;

const getTags = (phrase: string) => {
  if (typeof getTagsCancelToken != typeof undefined) {
    getTagsCancelToken.cancel("Operation canceled due to new request.");
  }

  getTagsCancelToken = axios.CancelToken.source();

  const url = API.tags;
  const options = {
    cancelToken: getTagsCancelToken.token,
    params: { phrase },
  };

  return httpService.get(url, null, options);
};

const createTag = (phrase: string) => {
  const url = API.tags;
  return httpService.post(url, { phrase });
};

const tagsRequests = {
  getTags,
  createTag,
};

export default tagsRequests;
