export default class Height {
  static add() {
    window.viewer?.measuringTool.startInsertion({
      showDistances: false,
      showHeight: true,
      showArea: false,
      closed: false,
      maxMarkers: 2,
      name: "Height",
    });
  }
}
