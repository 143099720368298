import _ from "lodash";
import { observable, action, makeAutoObservable } from "mobx";
import { OrganizationService } from "services/OrganizationService";
import type { Organization } from "models/Organization";
import type { LoggedUser } from "models/User";

interface OrganisationUsersStore {
  [id: string]: LoggedUser;
}

export interface IOrganizationStore {
  _organizationUsersStore: OrganisationUsersStore;
  organizationUsersLoaded: boolean;
  getOrganizatioUsersArray: () => Promise<LoggedUser[]>;
  getOrganizationUsersStore: () => Promise<OrganisationUsersStore>;
  getOrganization: () => number;
  getOrganizationUser: (id: number) => Promise<LoggedUser | null>;
}

class OrganizationStore implements OrganizationStore {
  _organization: Organization;
  // Organization users
  _organizationUsersStore = {};
  organizationUsersLoaded = false;

  constructor() {
    makeAutoObservable(this);
  }

  async getOrganizatioUsersArray(): Promise<LoggedUser[]> {
    const store = this.getOrganizationUsersStore();
    return Object.keys(store).map((key) => store[key]);
  }

  async getOrganizationUsersStore(): Promise<OrganisationUsersStore> {
    if (this.organizationUsersLoaded) return this._organizationUsersStore;

    const users = await OrganizationService.getUsers();
    const newStore = {};
    users.forEach((user) => (newStore[user.id] = user));
    this._organizationUsersStore = newStore;
    this.organizationUsersLoaded = true;

    return newStore;
  }

  async getOrganization(): Promise<null | Organization> {
    if (this._organization) {
      return this._organization;
    }

    this._organization = await OrganizationService.getCurrentOrganization();
    return this._organization;
  }

  async getOrganizationUser(id: number): Promise<null | LoggedUser> {
    const store = await this.getOrganizationUsersStore();
    if (store[id]) return store[id];

    const user = await OrganizationService.getUser(id);
    return user;
  }
}

export default new OrganizationStore();
