import API from "const/api";
import httpService from "services/HttpService";
import {
  ModelTypeFilter,
  ModelResolutionFilter,
} from "../views/models/services/ModelService";

interface GetSharedModelsQueryParams {
  tags: string[],
  status?: 'created' | '',
  type?: ModelTypeFilter,
  resolution?: ModelResolutionFilter,
  startDate?: number,
  endDate?: number
}

const getModels = (page = 0, params: GetSharedModelsQueryParams) => {
  const url = API.getSharedModels;
  const queryParams = { page, ...params };
  return httpService.get(url, null, { params: queryParams });
}

const getModelsSharedToOthers = (page = 0, params: GetSharedModelsQueryParams) => {
  const url = API.getSharedModelsToOthers;
  const queryParams = { page, ...params };
  return httpService.get(url, null, { params: queryParams });
}

const shareModel = (modelId: string, email: string) => {
  const url = API.shareModel.replace(':id', modelId);
  return httpService.post(url, { email })
}

const unshareModel = (modelId: string, email: string) => {
  const url = API.shareModel.replace(":id", modelId);
  return httpService.delete(url, { email });
}

const sharedModelRequests = {
  getModels,
  getModelsSharedToOthers,
  shareModel,
  unshareModel,
};

export default sharedModelRequests;
