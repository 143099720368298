import React from "react";
import styled from "styled-components";
import { IAppContext } from "../../../interfaces";

interface INewToolIndicatorPropTypes {}

const BlinkContainer = styled.div`
  display: block;
`;

const BlinkPulse = styled.div`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.danger};
  opacity: 0.5;
  border-radius: 50%;
  width: 10px;
  height: 10px;
  position: absolute;
  transform: scale(1);
  animation: blink-pulse-anim 2s infinite;
  z-index: 100;

  @keyframes blink-pulse-anim {
    0% {
      opacity: 0.75;
      transform: scale(1);
    }
    100% {
      opacity: 0;
      transform: scale(3);
    }
  }
`;
const BlinkDot = styled.div`
  border-radius: 50%;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.danger};
  width: 10px;
  height: 10px;
`;

const NewToolIndicator = ({}: INewToolIndicatorPropTypes) => {
  return (
    <BlinkContainer className="new-tool-indicator blink-container">
      <BlinkPulse className="blink-pulse" />
      <BlinkDot className="blink-dot" />
    </BlinkContainer>
  );
};

export default NewToolIndicator;
