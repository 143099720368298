import React from "react";
import clsx from "clsx";

type ProgressBarVariant = "primary" | "danger";

interface ProgressBarProps {
  percent: number;
  variant?: ProgressBarVariant;
}

const nonFilledColorMap = {
  primary: "bg-primary-100",
  danger: "bg-danger-100",
};

const filledColorMap = {
  primary: "bg-primary-500",
  danger: "bg-danger-500",
};

const ProgressBar = ({ percent, variant = "primary" }: ProgressBarProps) => {
  const calculatedPercent = Math.min(100, Math.max(0, percent));

  return (
    <div
      className={clsx(
        "progress-bar relative h-0.5 w-full rounded-full",
        nonFilledColorMap[variant]
      )}
    >
      <div
        className={clsx("progres-bar-progress h-full", filledColorMap[variant])}
        style={{
          width: `${calculatedPercent}%`,
        }}
      />
    </div>
  );
};

export default ProgressBar;
