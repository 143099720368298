import React from "react";
import styled from "styled-components";
import Button from "../../../../../views/shared/buttons/Button";
import Box from "../../../../shared/components/Box";
import breakpoint from "styled-components-breakpoint";
import { IAppContext } from "../../../../interfaces";
import { AUTH_ROUTES } from "../../../../routes";
import {
  AnalyticEventCategory,
  AnalyticService,
} from "../../../../../services/AnalyticService";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { useTranslation } from "react-i18next";

interface INavbarSignInInfoPropTypes extends RouteComponentProps {}

const SignUpInfoBox = styled(Box)`
  padding-right: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  flex-direction: column;
  max-width: 300px;

  ${breakpoint("md")`
    align-items: center;
    flex-direction: row;
    justify-content: flex-end;
    padding-right: 0;
    max-width: max-content;
  `}
`;

const NavbarSignInInfo = ({ history }: INavbarSignInInfoPropTypes) => {
  const { t } = useTranslation();
  const onSignUp = () => {
    AnalyticService.event(
      AnalyticEventCategory.PublicModelDetailsPage,
      "sign_up_click"
    );
    history.push(AUTH_ROUTES.register);
  };

  return (
    <SignUpInfoBox>
      <Button onClick={onSignUp}>{t("signUp")}</Button>
    </SignUpInfoBox>
  );
};

export default withRouter(NavbarSignInInfo);
