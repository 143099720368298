import { TacbrowseEntity } from "../../../../models/SessionData";

export type FindingType = string;
export type IconFileName = string;
export type IconType = string;

export interface SharedLocatorProps {
  label: string;
  longitude: number;
  latitude: number;
  active: boolean;
  findingType?: FindingType;
  senderType: TacbrowseEntity;
}

export const LOCATOR_ICONS_PATH = "/icons";
export const DEFAULT_ICON = "object-locator.svg";
export const DRONE_ICON = "drone-locator.svg";

export const FINDING_ICON_MAP = {
  bicycle: "bike-locator.svg",
  car: "car-locator.svg",
  van: "bus-locator.svg",
  truck: "truck-locator.svg",
  motor: "motorcycle-locator.svg",
  airplane: "plane-locator.svg",
  pedestrian: "pedestrian-locator.svg",
  people: "pedestrian-locator.svg",
};

export const getFindingIcon = (findingType: FindingType) => {
  return FINDING_ICON_MAP[findingType];
};

export const getIcon = (
  active: boolean,
  findingType: FindingType,
  senderType?: TacbrowseEntity
): IconFileName => {
  if (senderType === TacbrowseEntity.Drone) return DRONE_ICON;
  if (senderType === TacbrowseEntity.Mobile) {
    return active ? "locator-active.svg" : "locator.svg";
  }

  if (!!getFindingIcon(findingType)) {
    return getFindingIcon(findingType);
  }

  return DEFAULT_ICON;
};

export const getIconType = (
  active: boolean,
  findingType: FindingType,
  senderType?: TacbrowseEntity
): IconType => {
  if (senderType === TacbrowseEntity.Drone) return "drone";
  if (senderType === TacbrowseEntity.Mobile) {
    return active ? "user-active" : "user";
  }
  if (senderType === TacbrowseEntity.Finding)
    return !!getFindingIcon(findingType) ? findingType : "default";
};
