export class ViewerBirdViewService {
  static activate() {
    if (window.viewer) {
      window.viewer.setControls(window.viewer.fpControls);
    }
  }

  static deactivate() {
    if (window.viewer) {
      window.viewer.setControls(window.viewer.orbitControls);
    }
  }
}
