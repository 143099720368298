export const PremiumStarIcon = () => (
  <svg
    width="100%"
    height="100%"
    viewBox="0 0 8 8"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.6047 0.256938L2.72929 2.27168C2.69615 2.34797 2.64102 2.41299 2.57075 2.45868C2.50048 2.50437 2.41816 2.52873 2.33399 2.52873H0.429946C0.344914 2.52876 0.2618 2.55365 0.191105 2.60023C0.120411 2.64682 0.0653072 2.71302 0.0327584 2.79048C0.000209627 2.86793 -0.00832412 2.95315 0.00823441 3.03539C0.0247929 3.11762 0.0657007 3.19316 0.12579 3.25248L1.52393 4.63154C1.57642 4.68331 1.61442 4.74759 1.63423 4.81814C1.65405 4.88868 1.655 4.96308 1.637 5.03409L1.01876 7.4732C0.998328 7.55381 1.00237 7.63857 1.03038 7.71694C1.0584 7.79531 1.10915 7.86384 1.17634 7.91401C1.24352 7.96418 1.32418 7.99377 1.40829 7.99912C1.49239 8.00447 1.57624 7.98534 1.6494 7.9441L3.78658 6.73953C3.85157 6.7029 3.92514 6.68363 4 6.68363C4.07486 6.68363 4.14843 6.7029 4.21342 6.73953L6.35059 7.9441C6.42376 7.98534 6.50761 8.00447 6.59171 7.99912C6.67582 7.99377 6.75647 7.96418 6.82366 7.91401C6.89085 7.86384 6.9416 7.79531 6.96961 7.71694C6.99763 7.63857 7.00167 7.55381 6.98123 7.4732L6.36296 5.03413C6.34495 4.96312 6.34591 4.88872 6.36572 4.81818C6.38554 4.74763 6.42354 4.68335 6.47603 4.63158L7.87421 3.25248C7.9343 3.19316 7.97521 3.11762 7.99177 3.03539C8.00832 2.95315 7.99979 2.86793 7.96724 2.79048C7.93469 2.71302 7.87959 2.64682 7.80889 2.60023C7.7382 2.55365 7.65509 2.52876 7.57005 2.52873H5.66601C5.58184 2.52873 5.49951 2.50437 5.42924 2.45868C5.35898 2.41299 5.30385 2.34797 5.27071 2.27168L4.3953 0.256938C4.36213 0.180677 4.307 0.115685 4.23674 0.0700147C4.16647 0.0243441 4.08416 0 4 0C3.91584 0 3.83353 0.0243441 3.76326 0.0700147C3.693 0.115685 3.63787 0.180677 3.6047 0.256938Z"
      fill="#FFC833"
    />
  </svg>
);
