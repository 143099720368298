import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import ButtonsToggleGroup from "../../shared/buttons/ButtonsToggleGroup";
import { ModelPageViewTypes } from "../../../const/global";

interface IModelViewTypePropTypes {
  currentView:
    | ModelPageViewTypes.Map
    | ModelPageViewTypes.Model
    | ModelPageViewTypes.Ortho;
  availableViews?: Partial<Record<ModelPageViewTypes, boolean>>;
  disabledViews?: Partial<Record<ModelPageViewTypes, boolean>>;
  onClick: (view: ModelPageViewTypes) => void;
}

const VIEWS = [
  { translation: "models.3DView", id: ModelPageViewTypes.Model },
  {
    translation: "models.2DView",
    id: ModelPageViewTypes.Ortho,
  },
  { translation: "models.mapView", id: ModelPageViewTypes.Map },
];

const ModelViewTypeButton = ({
  currentView,
  availableViews,
  disabledViews,
  onClick,
}: IModelViewTypePropTypes) => {
  const { t } = useTranslation();

  const handleChange = (id: any) => {
    onClick(id as ModelPageViewTypes);
  };

  return (
    <ButtonsToggleGroup
      activeBtnShadow
      bordered
      size="regular"
      fullSize
      backgroundColor="grey"
      elements={VIEWS.map(
        (type) =>
          (!availableViews || availableViews.model) && {
            ...type,
            disabled: disabledViews && disabledViews[type.id],
            label: t(type.translation),
          }
      )}
      selected={currentView}
      onChange={handleChange}
    />
  );
};

export default memo(ModelViewTypeButton);
