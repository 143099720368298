import { useRef } from "react";

interface IProps {
  onPoll: () => void;
  onFinish?: () => void;
  interval: number;
  maxAttempts: number;
}

const usePoll = ({ onPoll, onFinish, interval, maxAttempts }: IProps) => {
  const isActive = useRef(false);
  const attempts = useRef(0);
  const timeoutRef = useRef(null);

  const startPolling = () => {
    isActive.current = true;
    attempts.current = 0;
    poll();
  };

  const stopPolling = () => {
    isActive.current = false;
    clearTimeout(timeoutRef.current);
  };

  const poll = () => {
    if (!isActive) return;

    if (attempts.current >= maxAttempts) {
      isActive.current = false;
      clearTimeout(timeoutRef.current);
      onFinish();
      return;
    }

    onPoll();
    timeoutRef.current = setTimeout(() => {
      attempts.current += 1;
      poll();
    }, interval);
  };

  return {
    isActive,
    startPolling,
    stopPolling,
  };
};

export default usePoll;
