import React, { useState } from "react";
import { FormGroup } from "../../../../../shared/form/FormGroup";
import Title from "../../../../../shared/typography/Title";
import { useTranslation } from "react-i18next";
import UniversalUpload from "../UniversalUpload";
import SkyebrowseAppUpload from "../SkyebrowseAppUpload";
import SelectRadioButton from "../../../../../shared/components/SelectRadioButton";
import AppUploadIcon from "../../icons/AppUploadIcon";
import UniversalUploadIcon from "../../icons/UniversalUploadIcon";
import InteriorAIIcon from "../../../../../shared/Icons/InteriorAIIcon";

interface UploadWizardProps {
  onLoadingChange: (loading: boolean) => void;
}

enum UploadTypes {
  SkyebrowseApp = "skyebrowse_app",
  Universal = "universal",
  InteriorMapping = "interior_mapping",
}

const UploadTypeButtons = [
  {
    id: UploadTypes.SkyebrowseApp,
    label: "upload.dialog.regularMappingLabel",
    description: "upload.dialog.regularUploadDescription",
  },
  {
    id: UploadTypes.Universal,
    label: "upload.dialog.universalUploadLabel",
    description: "upload.dialog.universalUploadDescription",
  },
  {
    id: UploadTypes.InteriorMapping,
    label: "upload.dialog.interiorMappingUploadLabel",
    description: "upload.dialog.interiorMappingUploadDescription",
  },
];

const ICONS_MAP = {
  [UploadTypes.SkyebrowseApp]: AppUploadIcon,
  [UploadTypes.Universal]: UniversalUploadIcon,
  [UploadTypes.InteriorMapping]: InteriorAIIcon,
};

const getIcon = (type: UploadTypes) => {
  const Icon = ICONS_MAP[type];
  if (!Icon) return null;

  return <Icon />;
};

const UploadWizard = ({ onLoadingChange }: UploadWizardProps) => {
  const { t } = useTranslation();
  const [currentUploadType, setCurrentUploadType] = useState(null);

  const handleLoadingChange = (loading: boolean) => {
    onLoadingChange(loading);
  };

  const handleUploadChange = (id: string) => {
    setCurrentUploadType(id as UploadTypes);
  };

  const getUpload = () => {
    if (!currentUploadType) return null;

    switch (currentUploadType) {
      case UploadTypes.InteriorMapping:
        return (
          <UniversalUpload
            onLoad={handleLoadingChange}
            defaultModelName={t("upload.defaultInteriorModelName")}
            interiorChecked
          />
        );
      case UploadTypes.Universal:
        return <UniversalUpload onLoad={handleLoadingChange} />;
      default:
        return <SkyebrowseAppUpload onLoad={handleLoadingChange} />;
    }
  };

  return (
    <div>
      <FormGroup>
        <Title>{t("chooseMappingType")}</Title>
        <div className="flex flex-col md:grid md:grid-cols-3 gap-4">
          {UploadTypeButtons.map((btn) => {
            return (
              <SelectRadioButton
                noMargin
                icon={getIcon(btn.id)}
                id={btn.id}
                key={btn.id}
                label={t(btn.label)}
                description={t(btn.description)}
                onClick={handleUploadChange}
                selected={currentUploadType === btn.id}
              ></SelectRadioButton>
            );
          })}
        </div>
      </FormGroup>
      {getUpload()}
    </div>
  );
};

export default UploadWizard;
