import React, { ReactNode } from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../../interfaces";
import classNames from 'classnames';
import Box from '../Box';

interface IDropdownItemPropTypes {
  active?: boolean,
  children: ReactNode,
  value?: string | number,
  startIcon?: any,
  onClick?: (e: any) => void,
}

const Wrapper = styled(Box)`
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  height: 40px;
  padding: 0 ${(p: IAppContext) => p.theme.skye.sizes.medium};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};

  &:hover {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  }

  &.active {
    color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
  }
`;

const StartIconWrapper = styled(Box)`
  width: 18px;
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;

const DropdownItem = ({
  active,
  children,
  startIcon,
  ...otherProps
}: IDropdownItemPropTypes) => {
  return (
    <Wrapper className={classNames(['dropdown-item', { active }])} {...otherProps}>
      {Boolean(startIcon) && (
        <StartIconWrapper>
          {startIcon}
        </StartIconWrapper>
      )}
      {children}
    </Wrapper>
  )
}

export default DropdownItem;
