import _ from "lodash";
import { IPotreeMeasurement } from "../../interfaces";

export default class BaseMeasure {
  static getAll(): IPotreeMeasurement[] {
    if (!(window.viewer && window.viewer?.scene)) {
      return [];
    }
    return window.viewer?.scene.measurements;
  }

  static getByUuid(uuid: string): any {
    return window.viewer?.scene.getMeasurement(uuid);
  }

  static remove(measurement: IPotreeMeasurement): void {
    window.viewer?.scene.removeMeasurement(measurement);
  }

  static deleteAll() {
    window.viewer?.scene.removeAllMeasurements();
  }

  static getAreaLabelsVisible(): boolean {
    return _.get(window, "viewer.scene.areaLabelsVisible");
  }

  static setAreaLabelsVisible(visible: boolean) {
    window.viewer.scene.areaLabelsVisible = visible;
  }

  static getEdgeLabelsVisible(): boolean {
    return _.get(window, "viewer.scene.edgeLabelsVisible");
  }

  static setEdgeLabelsVisible(visible: boolean) {
    window.viewer.scene.edgeLabelsVisible = visible;
  }

  static setMeasurement(newMeasurement: IPotreeMeasurement): void {
    window.viewer.scene.measurements = this.getAll().map((measurement) =>
      measurement.uuid === newMeasurement.uuid ? newMeasurement : measurement
    );
  }
}
