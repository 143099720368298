import React from "react";

interface IUserCircleIconPropTypes {}

const UserCircleIcon = ({}: IUserCircleIconPropTypes) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.29019 26.0856C6.60461 25.224 7.17455 24.6061 8 24.0295C12 21.2353 20 21.3333 23.9849 24.0295C24.8211 24.5952 25.3952 25.2171 25.7106 26.0849M6.29019 26.0856C8.8073 28.5095 12.2296 30 16 30C19.7708 30 23.1934 28.5092 25.7106 26.0849M6.29019 26.0856C3.64545 23.5388 2 19.9616 2 16C2 8.26801 8.26801 2 16 2C23.732 2 30 8.26801 30 16C30 19.9612 28.3549 23.5381 25.7106 26.0849M11.3333 13.3333C11.3333 15.9107 13.4227 18 16 18C18.5773 18 20.6667 15.9107 20.6667 13.3333C20.6667 10.756 18.5773 8.66667 16 8.66667C13.4227 8.66667 11.3333 10.756 11.3333 13.3333Z"
        stroke="#1A1A1A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default UserCircleIcon;
