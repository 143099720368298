import React from 'react'
import styled from 'styled-components';
import { IAppContext } from "views/interfaces";
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import Box from "../../shared/components/Box";
import ToolIcon from "../../shared/components/ToolIcon";
import SkyeTooltip from "../../shared/components/SkyeTooltip";

interface IToolIconPropTypes {
  tooltip: string,
  id: string,
  active: boolean,
  onClick: (id: string, e: any) => void,
}

const InnerWrapper = styled(Box)`
  align-items: center;
  justify-content: center;
  width: 42px;
  height: 42px;
  padding: 7px;
`;

const StyledIcon = styled.a`
  cursor: pointer;
  height: 100%;
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border-radius: 2px;
  position: relative;

  .inner-icon-wrapper {
    transition: background-color 0.5s;
  }

  &:hover,
  &.active {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.lavender};
  }
`;

const ViewerToolIcon = ({
  tooltip,
  id,
  active,
  onClick,
}: IToolIconPropTypes) => {
  const { t } = useTranslation();
  
  const getClass = () => {
    return classNames(['tool-icon', { active }]);
  }

  const handleClick = (e: any) => {
    onClick(id, e);
  }

  const getContent = (content) => {
    return (
      <SkyeTooltip key={id} title={t(tooltip)} leaveDelay={0}>
        {content}
      </SkyeTooltip>
    );
  };

  return (
    <StyledIcon
      id={id}
      className={`tool-icon ${getClass()}`}
      onClick={handleClick}
    >
      {getContent(
        <InnerWrapper className="inner-icon-wrapper">
          <ToolIcon toolId={id} />
        </InnerWrapper>
      )}
    </StyledIcon>
  );
}

export default ViewerToolIcon;
