import { inject, observer } from "mobx-react";
import React from "react";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { IGlobalStore } from "../../../../stores/GlobalStore";
import DesktopAuthorizedNavbar from "./desktop/authorized";
import DesktopNonAuthorizedNavbar from "./desktop/non-authorized";
import MobileAuthorizedNavbar from "./mobile/authorized";
import MobileNonAuthorizedNavbar from "./mobile/non-authorized";

interface IPublicNavbarPropTypes extends RouteComponentProps {
  GlobalStore?: IGlobalStore;
}

const PublicNavbar = ({ GlobalStore }: IPublicNavbarPropTypes) => {
  const loaded = GlobalStore.userLoaded;
  const isLogged = GlobalStore.isLoggedIn();

  if (!loaded) {
    return null;
  }

  return (
    <>
      <div className="hidden lg:block w-full">
        {isLogged ? (
          <DesktopAuthorizedNavbar />
        ) : (
          <DesktopNonAuthorizedNavbar />
        )}
      </div>
      <div className="lg:hidden w-full">
        {isLogged ? <MobileAuthorizedNavbar /> : <MobileNonAuthorizedNavbar />}
      </div>
    </>
  );
};

export default inject("GlobalStore")(withRouter(observer(PublicNavbar)));
