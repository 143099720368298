import moment from "moment";
import { SubscriptionType } from "../../../payments/payments.model";
import { IExpirationInfoProps } from "./SubscriptionExpirationInfo.model";
import ExpiredSubscriptionInfo from "./components/ExpiredSubscriptionInfo";
import LiteSubscriptionInfo from "./components/LiteSubscriptionInfo";
import PremiumExpiringSubscriptionInfo from "./components/PremiumExpiringSubscriptionInfo";

const SubscriptionExpirationInfo = ({
  subscriptionType,
  subscriptionActive,
  expirationDate,
  showDaysUntil = 30,
}: IExpirationInfoProps) => {
  if (!subscriptionActive) {
    return <ExpiredSubscriptionInfo />;
  }

  if (subscriptionType === SubscriptionType.Premium) {
    const daysToExpire = moment(expirationDate).diff(moment(), "days");
    const expiring = daysToExpire < showDaysUntil;

    if (expiring) {
      return <PremiumExpiringSubscriptionInfo daysToExpire={daysToExpire} />;
    }
  }

  if (subscriptionType === SubscriptionType.Lite) {
    return <LiteSubscriptionInfo />;
  }

  return null;
};

export default SubscriptionExpirationInfo;
