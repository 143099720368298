import { memo, useContext } from "react";
import { useTranslation } from "react-i18next";
import InformationBox from "../../../shared/components/InformationBox/InformationBox";
import AdjustmentIcon from "../../../shared/Icons/AdjustmentIcon";
import ScaleIcon from "../../../shared/Icons/ScaleIcon";
import { ViewerScaleContext } from "../../context/ViewerScaleContext";
import useRescaleModelTool from "../../hook/useRescaleModelTool";
import ViewerObjectTreeItem from "./ViewerObjectTreeItem";

interface IObjectTreeAdjustmentsPropTypes {}

const ObjectTreeAdjustments = ({}: IObjectTreeAdjustmentsPropTypes) => {
  const { scale, isRescaled, rescale } = useContext(ViewerScaleContext);
  const { rescaleModal } = useRescaleModelTool();
  const { t } = useTranslation();
  // TODO: Change this to use perspectiveChanged
  const adjustmentsExists = isRescaled;
  const scaleLabel = isRescaled
    ? `${t("scale")}: ${scale.toFixed(2)}`
    : t("scale");

  const onScaleDetele = () => {
    rescale(1);
  };

  return (
    <div>
      {isRescaled && (
        <>
          {rescaleModal}
          <ViewerObjectTreeItem
            label={scaleLabel}
            Icon={ScaleIcon}
            showVisibility={false}
            onDelete={onScaleDetele}
            onVisibilityChange={() => {}}
            onClick={() => {}}
            visible={true}
          />
        </>
      )}
      {/* {perspectiveChanged && (
        <ViewerObjectTreeItem
          label={t("perspective")}
          Icon={ReorientIcon}
          showVisibility={false}
          onDelete={onPerspectiveDelete}
          onVisibilityChange={() => {}}
          onClick={() => {}}
          visible={true}
        />
      )} */}
      {!adjustmentsExists && (
        <InformationBox
          transparent
          description={t("adjustmentsEmpty")}
          icon={<AdjustmentIcon />}
        ></InformationBox>
      )}
    </div>
  );
};

export default memo(ObjectTreeAdjustments);
