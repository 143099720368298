import React from 'react'

interface IDefaultViewIconPropTypes {
  
}

const DefaultViewIcon = ({
  
}: IDefaultViewIconPropTypes) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.83301 5.83325H14.1663V14.1666H5.83301V5.83325Z" stroke="#959494" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.16699 18.3333V15.8333H1.66699" stroke="#959494" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.833 18.3333V15.8333H18.333" stroke="#959494" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.16699 1.66675V4.16675H1.66699" stroke="#959494" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.833 1.66675V4.16675H18.333" stroke="#959494" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default DefaultViewIcon;
