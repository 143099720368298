import React, { useEffect, useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { WindowUtils } from "../../../../utils/windowUtils";
import { IAppContext } from "../../../interfaces";
import SingleStreamWindowContainer, {
  SMALL_STREAM_PREVIEW_HEIGHT,
} from "./SingleStreamWindowContainer";
import { inject, observer } from "mobx-react";
import { STORE_NAMES } from "../../../../const/global";
import { ISessionStreamStore } from "../../../../stores/SessionStreamStore";
import { BoxAlignedCenter } from "../../../shared/components/Box";

interface ISessionStreamContainerPropTypes {
  SessionStreamStore?: ISessionStreamStore;
}

const PADDING = 8;
const WRAPPER_HEIGHT = SMALL_STREAM_PREVIEW_HEIGHT + 2 * PADDING;

const Wrapper = styled(BoxAlignedCenter)`
  width: 100%;
  padding: ${PADDING}px ${PADDING}px;
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  box-shadow: ${(p: IAppContext) => p.theme.skye.shadows.light};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  height: ${WRAPPER_HEIGHT}px;
  justify-content: flex-end;
`;

const SessionStreamContainer = ({
  SessionStreamStore,
}: ISessionStreamContainerPropTypes) => {
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const { streams } = SessionStreamStore;

  const loadScript = async () => {
    try {
      await WindowUtils.loadScript(
        "https://player.live-video.net/1.10.0/amazon-ivs-player.min.js"
      );
      setScriptLoaded(true);
    } catch (error) {
      setScriptLoaded(false);
    }
  };

  useEffect(() => {
    loadScript();
  }, []);

  if (!scriptLoaded) return null;

  return (
    <Wrapper className={classNames(["session-stream-container"])}>
      {Array.from(streams.values()).map((stream) => (
        <SingleStreamWindowContainer key={stream.publicId} stream={stream} />
      ))}
    </Wrapper>
  );
};

export default inject(STORE_NAMES.SessionStreamStore)(
  observer(SessionStreamContainer)
);
