import React, { useState } from "react";
import ReportIssueModalContainer, {
  IReportIssueProps,
} from "../containers/ReportIssueModalContainer";

const withReportIssueModal = (WrappedComponent) => {
  return (props: IReportIssueProps) => {
    const [open, setOpen] = useState(false);

    const openModal = () => {
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
    };

    return (
      <div>
        <ReportIssueModalContainer
          onClose={handleClose}
          open={open}
          {...props}
        />
        {<WrappedComponent onClick={openModal} />}
      </div>
    );
  };
};

export default withReportIssueModal;
