import { useContext, useState } from "react";
import { IPublicModel, ITypedModelType } from "../../../../../models/Model";
import { IViewerStore } from "../../../../../stores/ViewerStore";
import { ViewerScaleContext } from "../../../../viewer/context/ViewerScaleContext";
import useChangePerspectiveModelTool from "../../../../viewer/hook/useChangePerspectiveModelTool";
import { IPotreeFeatures } from "../../../../viewer/interfaces";
import { ViewerService } from "../../../../viewer/services/ViewerService";
import {
  ModelMeasurementService,
  Rotation,
} from "../../../services/ModelMeasurementService";
import useTextureManager from "./useTextureManager";

interface useModelViewerPropTypes {
  ViewerStore: IViewerStore;
  model: IPublicModel;
}

interface useModelViewerState {
  viewerInitialized: boolean;
  pointCloudLoaded: boolean;
  texturesLoaded: boolean;
}

interface useModelViewerHandlers {
  reset: () => void;
  onDestroy: () => void;
  initialize: () => void;
  saveMeasurements: (measurements: IPotreeFeatures) => void;
}

type IUseModelViewer = (
  props: useModelViewerPropTypes
) => [useModelViewerState, useModelViewerHandlers];

const useModelViewer: IUseModelViewer = ({ ViewerStore, model }) => {
  const { rescale } = useContext(ViewerScaleContext);
  const [features, setFeatures] = useState<IPotreeFeatures>(null);
  const perspective = useChangePerspectiveModelTool({
    viewerInitialized: ViewerStore.viewerInitialized,
    modelId: model.id,
  });
  const [texture] = useTextureManager({ ViewerStore });
  const [pointCloudLoaded, setPointCloudLoaded] = useState(false);
  const viewerService = new ViewerService();

  const loadTextures = async () => {
    // Initial load of textures on start
    await texture.loadTextures(model, () => {
      texture.showTextures();
    });
  };

  const loadPointCloud = () => {
    if (model.assets[ITypedModelType.PointCloud]) {
      viewerService.loadPointCloud(
        model.assets[ITypedModelType.PointCloud],
        handlePointcloudLoaded
      );
      return;
    }

    // Old point cloud type
    viewerService.loadPointCloud(
      model.assets[ITypedModelType.PointCloud18],
      handlePointcloudLoaded
    );
  };

  const handlePointcloudLoaded = () => {
    setPointCloudLoaded(true);
    restoreFeatures();
  };

  const restoreFeatures = async () => {
    const modelMeasurement = await ModelMeasurementService.getModelMeasurements(
      model.id
    );
    const features = modelMeasurement.measurements;
    if (!features) return;

    if (features.measurements) {
      viewerService.restoreMeasurements(model.id, features);
    }

    if (features?.scale?.value) {
      rescale(features?.scale?.value || 1);
    }

    if (features?.rotation) {
      const rotation = features?.rotation as Rotation;
      perspective.restore(rotation.x, rotation.y, rotation.z);
    }

    if (features) setFeatures(features);
  };

  const reset = () => {
    ViewerStore.resetStore();
  };

  const initialize = () => {
    ViewerStore.viewerInitialized = true;
    loadPointCloud();
    loadTextures();
  };

  const onDestroy = () => {
    viewerService.saveMeasurements(model.id);
    viewerService.closeProfileWindow();
    ViewerStore.resetStore();
  };

  const saveMeasurements = (newFeatures) => {
    setFeatures({ ...features, ...newFeatures });
    ModelMeasurementService.postModelMeasurements(model.id, {
      ...features,
      ...newFeatures,
    });
  };

  const handlers: useModelViewerHandlers = {
    reset,
    initialize,
    onDestroy,
    saveMeasurements,
  };

  const state: useModelViewerState = {
    pointCloudLoaded,
    texturesLoaded: ViewerStore.texturesLoaded,
    viewerInitialized: ViewerStore.viewerInitialized,
  };

  return [state, handlers];
};

export default useModelViewer;
