import httpService from "./HttpService";
import { getEnvVariables } from "env";
import { ISession, SessionConnection } from "models/Session";

const env = getEnvVariables();

// Base url with replaced port for local development
const BASE_URL = `${env.API_URL.replace("3000", "3100")}tacbrowse`;
const BASE_SESSION_URL = `${BASE_URL}/sessions`;
const MOCK_DATA_HOST = `${BASE_URL}/mock/session`;

export interface IGetSessionsResponse {
  results: ISession[];
  total: number;
}

export interface ISessionPatch {
  name?: string;
  modelId?: string;
  open?: boolean;
}

export class SessionService {
  async getOwnedSessions(page = 1, take = 10): Promise<IGetSessionsResponse> {
    const url = `${BASE_SESSION_URL}`;
    const params = { page, take };

    try {
      const sessions = (await httpService.get(url, null, { params }))
        .data as IGetSessionsResponse;
      return sessions;
    } catch (error) {
      return { results: [], total: 0 };
    }
  }

  async getSession(publicId: string): Promise<ISession | null> {
    const url = `${BASE_SESSION_URL}/${publicId}`;
    try {
      const session = (await httpService.get(url)).data as ISession;
      return session;
    } catch (error) {
      return null;
    }
  }

  createSession(
    sessionName: string,
    modelId = undefined,
    users = [],
    organisations = [],
    isPublic = true
  ) {
    const url = BASE_SESSION_URL;
    const body = {
      name: sessionName,
      modelId: modelId,
      open: isPublic,
      invited: {
        users: users,
        organisations: organisations,
      },
    };

    return httpService.post(url, JSON.stringify(body));
  }

  updateSession(sessionPublicId, updateObj: ISessionPatch) {
    const url = `${BASE_SESSION_URL}/${sessionPublicId}`;
    const data = {
      // omitting not supported yet params
      name: updateObj.name,
      modelId: updateObj.modelId,
      open: updateObj.open,
    };
    return httpService.patch(url, data);
  }

  async getSessionLiveFeed(
    sessionAddress: string | number
  ): Promise<SessionConnection> {
    const url = `${BASE_SESSION_URL}/live/${sessionAddress}`;
    return (await httpService.post(url)).data;
  }

  startMockingData(
    sessionId: string | number,
    username: string,
    userId: string,
    deviceId: string,
    latitude: number,
    longitude: number,
    repeats = 20
  ): Promise<any> {
    const url = MOCK_DATA_HOST.replace(":sessionId", String(sessionId));
    const mockData = {
      initialCoordinates: {
        lng: longitude,
        lat: latitude,
        sessionId: sessionId,
        deviceId: deviceId,
        username: username,
        alt: 10.5234,
        userId: userId,
        takenAt: new Date().getTime(),
      },
      config: {
        latDelta: 0.00007,
        lngDelta: 0.00007,
        altDelta: 0.01,
        n: repeats,
      },
    };
    return httpService.post(url, mockData);
  }
}
