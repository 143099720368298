import React from 'react'

interface IFilterIconPropTypes {
  
}

const FilterIcon = ({
  
}: IFilterIconPropTypes) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.5391 15.5393C14.07 17.0084 12.0776 17.8337 10.0001 17.8337C7.92255 17.8337 5.93011 17.0084 4.46108 15.5393C2.99204 14.0703 2.16675 12.0779 2.16675 10.0003C2.16675 7.9228 2.99204 5.93036 4.46108 4.46132C5.93011 2.99229 7.92255 2.16699 10.0001 2.16699C12.0776 2.16699 14.07 2.99229 15.5391 4.46132C17.0081 5.93036 17.8334 7.9228 17.8334 10.0003C17.8334 12.0779 17.0081 14.0703 15.5391 15.5393Z" stroke="#2E2E2E"/>
    <path d="M4.89575 8.28125H15.1041" stroke="#2E2E2E" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.71875 10.833H13.2813" stroke="#2E2E2E" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.90625 13.3857H11.0938" stroke="#2E2E2E" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default FilterIcon;
