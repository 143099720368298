import { STORE_NAMES } from "const/global";
import { inject, observer } from "mobx-react";
import React from "react";
import styled from "styled-components";
import SkyeTooltip from "../../shared/components/SkyeTooltip";

interface IToolAvailabilityHocPropTypes {
  children: any;
  available: boolean;
  notAvailableMessage: string;
}

const NotAvailableWrapper = styled.div`
  opacity: 0.5;
  pointer-events: none;
  cursor: not-allowed;
`;

const Wrapper = ({
  children,
  available,
  notAvailableMessage,
}: IToolAvailabilityHocPropTypes) => {
  if (available) {
    return children;
  }

  return (
    <SkyeTooltip title={notAvailableMessage}>
      <div>
        <NotAvailableWrapper>{children}</NotAvailableWrapper>
      </div>
    </SkyeTooltip>
  );
};
const StoreConnectedWrapper = inject(STORE_NAMES.ViewerStore)(
  observer(Wrapper)
);

const ToolAvailabilityHoc =
  (Component) =>
  ({ available, notAvailableMessage, ...props }) => {
    return (
      <StoreConnectedWrapper
        available={available}
        notAvailableMessage={notAvailableMessage}
      >
        <Component {...props} />
      </StoreConnectedWrapper>
    );
  };

export default ToolAvailabilityHoc;
