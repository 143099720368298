import API from "const/api";
import httpService from "services/HttpService";

export interface ISignInDTO {
  email: string,
  password: string,
}

export interface ISignUpDTO extends ISignInDTO {
  name: string,
  lastName: string,
  phone: string,
  jobTitle: string,
  organizationName: string,
}

export interface IResetPasswordDTO {
  newPassword: string,
  newPasswordRepeat: string,
}

const signIn = (data: ISignInDTO) => {
  const url = API.signIn;
  return httpService
    .post(url, data, { withJWTToken: false, getHeaders: true })
};

const signUp = (data: ISignUpDTO) => {
  const url = API.signUp;
  return httpService
    .post(url, data, { withJWTToken: false, getHeaders: true });
}

const resetPassword = (token: string, data: IResetPasswordDTO) => {
  const url = API.resetPassword.replace(':token', token);
  return httpService.post(url, data, { withJWTToken: false });
}

const resetPasswordRequest = (email: string) => {
  const body = { email };
  return httpService.post(
    API.resetPasswordRequest,
    body,
    { withJWTToken: false },
  );
}

/**
 * Signing out from all devices
 * @returns
 */
const signOut = async () => {
  return httpService.post(API.signOut);
}

const authRequests = {
  signIn,
  signUp,
  signOut,
  resetPassword,
  resetPasswordRequest,
}

export default authRequests;
