import React from "react";

const EditIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.66699 28H28.667"
        stroke="#1A1A1A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M7.33301 17.8133V22.6667H12.2111L25.9997 8.87207L21.1297 4L7.33301 17.8133Z"
        stroke="#1A1A1A"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default EditIcon;
