import { useCallback, useMemo } from "react";
import { ModelDetailsPageTabKeys } from "../../../../const/global";
import ModelVideo from "../../components/ModelVideo";
import ModelFlightSettingsSection from "../../components/ModelFlightSettingsSection";
import ModelWeatherSection from "../../components/ModelWeatherSection";
import EmergencyUploadSection from "../../components/EmergencyUploadSection";
import ModelGeneralDetailsSectionContainer from "../../containers/ModelGeneralDetailsSectionContainer";
import { IPublicModel } from "../../../../models/Model";
import { SectionName } from "../../layouts/ModelPageLayout/ModelPageLayout";
import { useTranslation } from "react-i18next";
import { MODEL_DETAILS_PAGE_TABS_KEYS } from "../pages.const";
import { getCurrentTab } from "../pages.utils";

interface IContentSectionPropTypes {
  model: IPublicModel;
  editable: boolean;
  currentTabKey: string;
  downloadSectionComponent: any;
  setCurrentTabKey: (key: string) => void;
  onModelChange: () => void;
}

const ContentSection = ({
  model,
  editable,
  currentTabKey,
  downloadSectionComponent,
  onModelChange,
}: IContentSectionPropTypes) => {
  const { t } = useTranslation();
  const getContent = useCallback((currentTabKey) => {
    switch (currentTabKey) {
      case ModelDetailsPageTabKeys.Video:
        return <ModelVideo model={model} />;
      case ModelDetailsPageTabKeys.FlightDetails:
        return <ModelFlightSettingsSection model={model} />;
      case ModelDetailsPageTabKeys.Download:
        return downloadSectionComponent;
      case ModelDetailsPageTabKeys.Weather:
        return <ModelWeatherSection model={model} />;
      case ModelDetailsPageTabKeys.EmergencyUpload:
        return (
          <EmergencyUploadSection model={model} onModelUpdate={onModelChange} />
        );
      case ModelDetailsPageTabKeys.General:
      default:
        return (
          <ModelGeneralDetailsSectionContainer
            editable={editable}
            model={model}
            onModelChange={onModelChange}
          />
        );
    }
  }, []);

  const content = useMemo(() => {
    return getContent(currentTabKey);
  }, [currentTabKey, getContent]);

  return (
    <>
      <SectionName>
        {t(getCurrentTab(currentTabKey, MODEL_DETAILS_PAGE_TABS_KEYS).key)}
      </SectionName>
      {content}
    </>
  );
};

export default ContentSection;
