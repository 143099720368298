import React, { ReactNode, useEffect, useRef } from "react";
import styled from "styled-components";
import classNames from "classnames";
import { IPublicModel } from "models/Model";
import { ViewerService } from "./services/ViewerService";
import { IAppContext } from "views/interfaces";
import PageLoader from "views/shared/components/PageLoader";
import { VIEWER_RENDER_AREA_ID } from "./const";
import LoadViewerScriptsHoc from "./hoc/LoadViewerScriptsHoc";
import { IPotreeFeatures } from "./interfaces";
import useAnnotations from "./hook/useAnnotations";
import breakpoint from "styled-components-breakpoint";

const VIEWER_ZINDEX = 4000;

export interface IPotreeViewerInput {
  model: IPublicModel;
  measurements?: IPotreeFeatures;
}

export interface IViewerPropTypes extends IPotreeViewerInput {
  sidePanel?: ReactNode;
  modelLoadedContent?: ReactNode;
  showLoader?: boolean;
  fullscreen?: boolean;
  onMeasurementsSave: (measurements: IPotreeFeatures) => void;
  onLoad: () => void;
}

const ViewerRenderAreaContainer = styled.div`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.woodSmoke};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  width: 100%;
  height: 100%;
  position: relative;
`;

const ViewerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  position: relative;

  &.fullscreen {
    z-index: ${VIEWER_ZINDEX};
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;

    ${ViewerRenderAreaContainer} {
      border-radius: 0;
    }
  }
`;
const ViewerRenderArea = styled.div`
  height: 100%;
  width: 100%;

  ${breakpoint("lg")`
    & > canvas {
      border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
    }
  `}
`;

const PageLoaderContainer = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  z-index: 5000;
`;
const ViewerLoaderContainer = styled(PageLoaderContainer)`
  z-index: 990;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
`;
const ViewerRenderAreaOutterContainer = styled.div`
  overflow: hidden;
  width: 100%;
  height: 100%;
  position: relative;
`;
const ViewerProfileContainer = styled.div`
  position: absolute;
  bottom: 5px;
  right: 5px;
  width: calc(100% - 20px);
  height: 100%;
`;

const viewerService = new ViewerService();

const Viewer = ({
  model,
  modelLoadedContent,
  showLoader,
  fullscreen,
  onMeasurementsSave,
  onLoad,
}: IViewerPropTypes) => {
  const { reloadAnnotations } = useAnnotations();
  const [viewerLoaded, setViewerLoaded] = React.useState(false);
  const viewerArea = useRef<HTMLDivElement>();

  const initViewer = () => {
    viewerService.initViewer(viewerArea.current, model, (sucess: boolean) => {
      if (!sucess) {
        /**
         * If it happen that viewer resources are not loaded yet
         * Trying to init viewer with some delay
         */
        setTimeout(() => {
          initViewer();
        }, 100);

        return;
      }

      onViewerInit();
    });
  };

  const saveFeatures = () => {
    const features = viewerService.getAllFeatures(model.id);
    if (features) onMeasurementsSave(features);
  };

  const handleAnnotationAdd = () => {
    reloadAnnotations();
    saveFeatures();
  };

  const onViewerInit = () => {
    onLoad();
    setViewerLoaded(true);

    window?.viewer?.scene.addEventListener("tool_edit_canceled", saveFeatures);
    window?.viewer?.scene.addEventListener(
      "annotation_added",
      handleAnnotationAdd
    );
  };

  useEffect(() => {
    setTimeout(() => {
      initViewer();
    }, 500);

    return () => {
      window?.viewer?.scene?.removeEventListener(
        "tool_edit_canceled",
        saveFeatures
      );
      window?.viewer?.scene.removeEventListener(
        "annotation_added",
        handleAnnotationAdd
      );
    };
  }, []);

  return (
    <ViewerContainer
      id="viewer-container"
      className={classNames(["viewer-container", { fullscreen }])}
    >
      <ViewerRenderAreaContainer className="viewer-render-area-container">
        {showLoader && (
          <ViewerLoaderContainer>
            <PageLoader />
          </ViewerLoaderContainer>
        )}
        <ViewerProfileContainer id="viewer-profile-container" />
        <ViewerRenderAreaOutterContainer>
          <ViewerRenderArea
            ref={viewerArea}
            id={VIEWER_RENDER_AREA_ID}
          ></ViewerRenderArea>
        </ViewerRenderAreaOutterContainer>
        {viewerLoaded && modelLoadedContent}
      </ViewerRenderAreaContainer>
    </ViewerContainer>
  );
};

export default LoadViewerScriptsHoc(Viewer);
