import React, { useState } from "react";
import { Select, Spin } from "antd";
import styled from "styled-components";
import { BaseTag } from "models/Model";
import tagsRequests from "requests/tag";
import { formatTag } from "../utils/tagsUtils";
import { IResponse } from "services/HttpService";
import { useTranslation } from "react-i18next";
import { debounce } from "utils/globalUtils";
import SkyeTag, { TagColor } from "../../shared/components/SkyeTag";
import {
  DEFAULT_TAGS,
  WIDEBROWSE_TAG,
  SKYEBROWSE_TAG,
  TagService,
} from "services/TagService";
import { IAppContext } from "../../interfaces";
import classNames from "classnames";

const CreateNewTagLabel = styled.span`
  margin-right: 0.5em;
`;

const Wrapper = styled.div`
  .ant-select-selection__placeholder {
    font-style: normal;
    font-size: 14px;
    line-height: 17px;
    color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
    left: 7px;
    margin-top: -7px;
  }

  .ant-select-search__field {
    margin-left: 12px;
  }

  .ant-select-selection {
    border: none;
    height: 45px;
  }

  .ant-select {
    border: 1px solid ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  }

  .ant-select-focused {
    box-shadow: ${(p: IAppContext) => p.theme.skye.shadows.inputFocused};
    border: 1px solid ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  }

  &.bordered {
    .ant-select-selection {
      border: ${(p: IAppContext) => p.theme.skye.borders.primary};
    }
  }

  .ant-select.ant-select-lg {
    border: none;
  }

  .ant-select-multiple.ant-select-lg .ant-select-selector {
    border-radius: 4px;
    height: 46px;
    border: 1px solid #e2e2e2;
  }

  .ant-select-selection--multiple {
    .ant-select-selection__choice {
      margin-top: 6px;
      height: 25px;
      padding: 0 20px 0 10px;
      font-style: normal;
      font-weight: 300;
      line-height: 24px;
      color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
      background-color: ${(p: IAppContext) => p.theme.skye.colors.lavender};
      border: none;
    }
  }

  .ant-select-lg {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};

    &.ant-select-focused {
      .ant-select-selection {
        border: none;
        outline: none;
        box-shadow: none;
      }
    }
  }
`;

interface ITagEditContainerPropTypes {
  // Flag to set when container should create new tag if already not exist
  allowToCreateNew?: boolean;
  showDefaultTags?: boolean;
  bordered?: boolean;
  selectedTags: string[];
  onChange: (tags: string[]) => void;
}

const { Option } = Select;
let debounceTimeout = null;

const getTagColor = (text): TagColor => {
  switch (text) {
    case "skyebrowse":
      return "skyebrowse";
    case "widebrowse":
      return "widebrowse";
    case "ultra":
      return "ultra";
    default:
      return "default";
  }
};

const getDefaultTags = (selectedTags: string[]) => {
  return DEFAULT_TAGS;
};

const TagEditContainer = ({
  allowToCreateNew = true,
  showDefaultTags = false,
  selectedTags,
  bordered,
  onChange,
}: ITagEditContainerPropTypes) => {
  const { t } = useTranslation();
  const [suggestedTags, setSuggestedTags] = useState<BaseTag[]>(
    showDefaultTags ? getDefaultTags(selectedTags) : []
  );
  const [inputValue, setInputValue] = useState("");
  const [fetching, setFetching] = useState(false);

  const getTags = (phrase: string) => {
    setFetching(true);
    setSuggestedTags(showDefaultTags ? getDefaultTags(selectedTags) : []);

    debounceTimeout = debounce(debounceTimeout, 300, () => {
      tagsRequests
        .getTags(formatTag(phrase))
        .then((response: IResponse) => {
          const baseTags = showDefaultTags
            ? [...getDefaultTags(selectedTags)]
            : [];
          const newTags = [...response.data.tags, ...baseTags];
          setSuggestedTags(newTags);
        })
        .finally(() => setFetching(false));
    });
  };

  const createTags = (phrase: string) => {
    const tagsPhrases = phrase.split(" ");
    tagsPhrases.forEach((text) => {
      if (
        text &&
        selectedTags &&
        suggestedTags &&
        !selectedTags.find((tag) => tag === text) &&
        !suggestedTags.find((tag) => tag.text === text)
      ) {
        if (allowToCreateNew) {
          tagsRequests.createTag(text).then((r: IResponse) => {
            const newTag = r.data.tag as BaseTag;
            onChange([
              ...selectedTags.filter((tag) => tag !== newTag.text),
              newTag.text,
            ]);
          });
        }
      }
    });
  };

  const selectTag = (phrases: string[]) => {
    let tags = [...selectedTags];

    // Handling remove of items
    if (selectedTags.length > phrases.length) {
      tags = selectedTags.filter((tag) =>
        phrases.find((phrase) => phrase === tag)
      );
    } else {
      // Handle add items
      phrases.forEach((phrase) => {
        const newSelectedTag =
          suggestedTags && suggestedTags.find((tag) => tag.text === phrase);
        if (newSelectedTag) {
          if (!selectedTags.find((tag) => tag === newSelectedTag.text)) {
            tags.push(newSelectedTag.text);
          }
        }
      });
    }

    onChange(tags);
    setInputValue("");
  };

  const handleInputChange = (value: any) => {
    setInputValue(value);

    if (value.length > 1) {
      getTags(value);
    }
  };

  const onSelect = (e) => {
    createTags(inputValue);
    setInputValue("");
  };

  const createNewOption = () => {
    const mockItemKey = `new-${inputValue}`;

    return (
      <Option key={mockItemKey} value={mockItemKey}>
        <CreateNewTagLabel>{t("createNewTag")}</CreateNewTagLabel>
        <SkyeTag>{`"${inputValue}"`}</SkyeTag>
      </Option>
    );
  };

  const getSuggestedTags = () => {
    const withoutDuplicates = [
      ...suggestedTags
        .filter((tag) => {
          return !selectedTags.find((t) => {
            if (
              /**
               * Handling filter of skyebrowse tag when widebrowse is selected
               * and widebrowse when skyebrowse is selected
               */
              (t === WIDEBROWSE_TAG.text && tag.text === SKYEBROWSE_TAG.text) ||
              (t === SKYEBROWSE_TAG.text && tag.text === WIDEBROWSE_TAG.text)
            ) {
              return true;
            }
            return t === tag.text;
          });
        })
        .sort((tagA, tagB) => tagA.text.length - tagB.text.length),
    ];

    const elements = withoutDuplicates.map((tag) => (
      <Option key={tag.id} value={tag.text}>
        <SkyeTag color={getTagColor(tag.text)}>{tag.text}</SkyeTag>
      </Option>
    ));

    const inputValueAlreadySelected = selectedTags.find(
      (tag) => tag === inputValue
    );

    if (fetching || !allowToCreateNew || TagService.isDefaultTag(inputValue)) {
      return elements;
    }

    if (
      (inputValue && !withoutDuplicates[0]) ||
      (inputValue &&
        !inputValueAlreadySelected &&
        withoutDuplicates[0] &&
        withoutDuplicates[0].text !== inputValue)
    ) {
      return [createNewOption(), ...elements];
    }

    return elements;
  };

  return (
    <Wrapper className={classNames([{ bordered }])}>
      <Select
        onSearch={handleInputChange}
        autoClearSearchValue
        size="large"
        mode="multiple"
        showSearch
        style={{ width: "100%" }}
        placeholder={t("selectTag")}
        onChange={selectTag}
        onSelect={onSelect}
        searchValue={inputValue}
        value={selectedTags}
        optionFilterProp="value"
        notFoundContent={fetching ? <Spin size="small" /> : null}
      >
        {getSuggestedTags()}
      </Select>
    </Wrapper>
  );
};

export default TagEditContainer;
