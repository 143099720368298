export enum TacbrowseEntity {
  Finding = "finding",
  Mobile = "mobile",
  Drone = "drone",
}

export interface IncomingSessionData {
  alt?: number;
  lat: number;
  lng: number;
  takenAt: number;
  // Used when senderType='finding', Describing name of detected object
  detectedObject?: string;
  sessionId: string | number;
  userId?: string | number;
  deviceId?: string;
  senderType: TacbrowseEntity;
  username: string;
}

export interface SessionData extends IncomingSessionData {
  // Timestamp of time when added
  addedAt: number;
}

export class SessionDataHelpers {
  static getUniqueLocatorId(sessionData: SessionData): string {
    return `${sessionData.userId}_${sessionData.deviceId}`;
  }

  static getUsername(sessionData: SessionData): string {
    if (sessionData.username) {
      const [firstName] = sessionData.username.split(" ");
      return firstName;
    }

    return `${sessionData.userId || sessionData.deviceId}`;
  }
}
