import {
  JWT_STORAGE_NAME,
  HEADER_AUTHORIZATION_KEY,
  JWT_SESSION_STORAGE_NAME,
  CURRENT_USER_STORAGE_NAME,
} from "const/global";
import authRequests, { ISignInDTO } from "requests/auth";
import { IResponse } from "./HttpService";
import { getEnvVariables } from "env";
import { LoggedUser } from "../models/User";

const setCookie = (
  cname: string,
  cvalue: string,
  exdays: number = 7,
  domain: string = ""
) => {
  const d = new Date();
  const domainParam = domain ? `;domain=${domain}` : "";

  d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie =
    cname + "=" + cvalue + ";" + expires + ";path=/" + domainParam;
};

const setAuthorizationToken = (token: string) => {
  localStorage.setItem(JWT_STORAGE_NAME, token);
  // Cookie set for skyebrowse viewer to pass token
  setCookie(JWT_SESSION_STORAGE_NAME, token, 30, getEnvVariables().APP_DOMAIN);
};

const setCurrentUser = (user: LoggedUser): void => {
  try {
    localStorage.setItem(CURRENT_USER_STORAGE_NAME, JSON.stringify(user));
  } catch (error) {
    return;
  }
};

const getStoredUser = (): LoggedUser | null => {
  try {
    const storedUser = localStorage.getItem(CURRENT_USER_STORAGE_NAME);
    return JSON.parse(storedUser);
  } catch (error) {
    return null;
  }
};

const deleteStoredUser = (): void => {
  localStorage.removeItem(CURRENT_USER_STORAGE_NAME);
};

const getAuthorizationToken = (): string | null =>
  localStorage.getItem(JWT_STORAGE_NAME);

const hasToken = () => !!getAuthorizationToken();

const signIn = (data: ISignInDTO): Promise<IResponse> =>
  new Promise((res, rej) => {
    authRequests
      .signIn(data)
      .then((r: IResponse) => {
        if (r.headers && r.headers[HEADER_AUTHORIZATION_KEY]) {
          setAuthorizationToken(r.headers[HEADER_AUTHORIZATION_KEY]);
          res(r);
          return;
        }
        rej(r);
      })
      .catch(rej);
  });

const signOut = () => {
  localStorage.removeItem(JWT_STORAGE_NAME);
  deleteStoredUser();
};

const AuthService = {
  getAuthorizationToken,
  hasToken,
  signIn,
  signOut,
  setCurrentUser,
  getStoredUser,
};

export default AuthService;
