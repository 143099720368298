import React from 'react'

interface ICameraIconPropTypes {
  
}

const CameraIcon = ({
  
}: ICameraIconPropTypes) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2 5.39014C2 4.54014 2.68932 3.8457 3.54004 3.8457H13.769C14.6198 3.8457 15.3091 4.54014 15.3091 5.39014V7.30184L16.4121 6.71345C16.5697 6.62819 16.745 6.58496 16.9214 6.58496C17.1846 6.58496 17.4327 6.68208 17.6256 6.84817L17.6258 6.84835C17.8688 7.05788 18 7.36284 18 7.67676V12.2939C18 12.6188 17.8592 12.9288 17.606 13.1379L17.6006 13.1424L17.6006 13.1423C17.4104 13.295 17.1703 13.3828 16.9214 13.3828C16.7171 13.3828 16.5165 13.3253 16.3414 13.2132C16.3413 13.2131 16.3412 13.2131 16.3411 13.213L15.3105 12.5556V14.6099C15.3105 15.4599 14.6212 16.1543 13.7705 16.1543H3.54004C2.68932 16.1543 2 15.4599 2 14.6099V5.39014ZM3.54004 4.8457C3.24525 4.8457 3 5.08876 3 5.39014V14.6099C3 14.9112 3.24525 15.1543 3.54004 15.1543H13.7705C14.0653 15.1543 14.3105 14.9112 14.3105 14.6099V11.6436V10.7315L15.0795 11.222L16.8797 12.3705L16.8806 12.371C16.8929 12.3789 16.9061 12.3828 16.9214 12.3828C16.9354 12.3828 16.9546 12.3775 16.9717 12.3648C16.987 12.3509 17 12.3276 17 12.2939V7.67676C17 7.63911 16.9847 7.61595 16.9728 7.60576L17.2993 7.22705L16.9731 7.60594C16.955 7.59038 16.9365 7.58496 16.9214 7.58496C16.9081 7.58496 16.8967 7.58823 16.8878 7.59305L16.8843 7.59498L16.8843 7.59497L15.0444 8.57641L14.3091 8.96867V8.13525V5.39014C14.3091 5.08876 14.0638 4.8457 13.769 4.8457H3.54004Z" fill="#1A1A1A"/>
    </svg>
  )
}

export default CameraIcon;
