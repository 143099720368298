import React from 'react'
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import Card from "views/shared/components/Card";
import { IAppContext } from "views/interfaces";
import { Link } from "react-router-dom";
import { TUTORIAL_PAGES } from "const/global";
import BasePage from "views/shared/layouts/BasePage";

interface ITutorialsListPagePropTypes {
  
}

const Row = styled.div`
  padding: ${(p: IAppContext) => p.theme.skye.sizes.small} 0;
  border-bottom: 1px solid ${(p: IAppContext) => p.theme.skye.colors.textDisabled};

  &:last-of-type {
    border-bottom: 0;
    padding-bottom: 0;
  }
`;

const Title = styled.h3`
  font-size: ${(p: IAppContext) => p.theme.skye.fontSizes.regular};
  font-weight: 300;
  margin: 0;
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};e
`;

const TutorialsListPage = ({
  
}: ITutorialsListPagePropTypes) => {
  const { t } = useTranslation();

  return (
    <BasePage>
      <Card title={t("tutorials")}>
        {TUTORIAL_PAGES.map((tutorial) => (
          <Row key={tutorial.title}>
            <Link to={tutorial.route}>
              <Title>{t(tutorial.title)}</Title>
            </Link>
          </Row>
        ))}
        <Title></Title>
      </Card>
    </BasePage>
  );
}

export default TutorialsListPage;
