import React, { useCallback, useEffect, useState } from "react";
import ModelService from "views/models/services/ModelService";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Model, ModelStatus } from "models/Model";
import { MODEL_ROUTES } from "views/routes";
import { AnalyticEventCategory } from "services/AnalyticService";
import { ModelPageViewTypes, STORE_NAMES } from "const/global";
import ShareDialogContainer from "../../containers/ShareDialogContainer";
import EditModelNameDialogContainer from "../../containers/EditModelNameDialogContainer";
import ModelPageLayout from "../../layouts/ModelPageLayout/ModelPageLayout";
import ModelDetailsPageStore from "stores/ModelDetailsPageStore";
import { IGlobalStore } from "stores/GlobalStore";
import { ModelAnalyticService } from "../../services/ModelAnalyticService";
import { ModelAccessPolicy } from "../../ModelAccessPolicy";
import { inject, observer } from "mobx-react";
import withViewerProviders from "../../../viewer/hoc/withViewerProviders";
import { useModelDetailsPage } from "./useModelDetailsPage";
import ModelDetailsPageSidebarComponent from "./Sidebar/ModelDetailsPageSidebar.component";
import ModelDetailsPageContentSectionComponent from "./ContentSection/ModelDetailsPageContentSection.component";
import { ModelDetailsPageContextProvider } from "./context";
import { ModelPaymentProvider } from "../../payments/ModelPayment.context";
import ModelMapView from "../../components/ModelMapView";
import ModelDetailsPageViewer from "./Viewer/Viewer.component";
import ModelDetailsRow from "../../layouts/ModelPageLayout/components/ModelDetailsRow";
import ModelCommentsContainer from "../../containers/ModelCommentsContainer";
import Dialog from "../../../shared/components/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import ModelDetailsPageDownloadSection from "./DownloadSection/ModelDetailsPageDownloadSection";
import ModelDetailsPagePaymentPollDialogComponent from "./PaymentPollDialog/ModelDetailsPagePaymentPollDialog.component";
import ModelOrthophotoViewer from "../components/ModelOrthophotoViewer";
import {
  IModelChangedEvent,
  useModelChangedSubscriber,
} from "../../hooks/web-socket/useModelChangedSubscriber";

interface IMatchParams {
  id: string;
}

interface IModelDetailsPagePropTypes extends RouteComponentProps<IMatchParams> {
  GlobalStore?: IGlobalStore;
}

const getModelFunc = (modelId: string) => async (): Promise<Model> => {
  const response = await ModelService.getModelDetails(modelId);
  return response?.data as Model;
};

const ModelDetailsPage = ({
  history,
  match,
  GlobalStore,
}: IModelDetailsPagePropTypes) => {
  const { model, init, viewType, setViewType, setModel } =
    useModelDetailsPage();
  const { user } = GlobalStore;
  const { t } = useTranslation();
  const [shareDialogOpen, setShareDialogOpen] = useState(false);
  const [editNameDialogOpen, setEditNameDialogOpen] = useState(false);
  const modelId = match.params.id;
  const canEditModel = ModelAccessPolicy.canEdit(user, model);
  const [downloadDialogOpen, setDownloadDialogOpen] = useState(false);

  const handleModelChanged = (data: IModelChangedEvent) => {
    if (data?.model?.id === modelId) {
      getModelDetails();
    }
  };

  useModelChangedSubscriber({ onChanged: handleModelChanged });

  const getModelDetails = async (): Promise<Model | null> => {
    try {
      const response = await ModelService.getModelDetails(modelId);
      return response?.data;
    } catch (error) {
      return null;
    }
  };

  const initView = useCallback(async () => {
    if (!model) {
      const modelDetails = await init(modelId);
      // Fixme - Move to dedicated context/hook instead of store
      ModelDetailsPageStore.loadComments(modelId);

      if (!modelDetails) {
        history.push(MODEL_ROUTES.list);
        return;
      }

      if (
        viewType === ModelPageViewTypes.Model &&
        modelDetails.status !== ModelStatus.Finished
      ) {
        setViewType(ModelPageViewTypes.Map);
      }

      ModelAnalyticService.modelOpened(
        modelDetails,
        viewType === ModelPageViewTypes.Model,
        AnalyticEventCategory.ModelDetailsPage
      );
    }
  }, [model, modelId]);

  // Resetting store on entrance and leave
  useEffect(() => {
    ModelDetailsPageStore.resetStore();

    return () => {
      ModelDetailsPageStore.resetStore();
    };
  }, []);

  useEffect(() => {
    initView();
  }, [history, match.params.id, initView]);

  const openShareDialog = () => {
    setShareDialogOpen(true);
  };

  const closeShareDialog = () => {
    setShareDialogOpen(false);
  };

  const handleModelNameChange = (m: Model) => {
    setModel(m);
    setEditNameDialogOpen(false);
  };

  if (!model) return null;

  return (
    <div className="page-container w-full h-full flex flex-col">
      <Dialog
        size="medium"
        open={downloadDialogOpen}
        title={t("download")}
        onClose={() => setDownloadDialogOpen(false)}
        hideCancelButton
        hideConfirmButton
      >
        <ModelDetailsPageDownloadSection model={model} />
      </Dialog>
      <ModelDetailsPagePaymentPollDialogComponent
        model={model}
        getModelFunction={getModelFunc(modelId)}
        onSuccess={setModel}
      />
      <ModelPageLayout
        orthophotoViewerComponent={<ModelOrthophotoViewer model={model} />}
        mapComponent={<ModelMapView model={model} points={[]} />}
        commentsComponent={<ModelCommentsContainer model={model} />}
        sidebarComponent={<ModelDetailsPageSidebarComponent />}
        view={viewType}
        viewerComponent={<ModelDetailsPageViewer model={model} />}
        contentSectionComponent={<ModelDetailsPageContentSectionComponent />}
        headerRowComponent={
          <ModelDetailsRow
            showEditNameButton={canEditModel}
            showCommentsModule
            showDownloadButton
            showDeleteButton
            showShareButton
            model={model}
            view={viewType}
            onViewChange={(viewType) => setViewType(viewType)}
            onDownload={() => setDownloadDialogOpen(true)}
            onEditName={() => setEditNameDialogOpen(true)}
            onShare={openShareDialog}
          />
        }
      />
      <ShareDialogContainer
        modelId={model.id}
        modelPublicKey={model.publicKey}
        modelName={model.name}
        open={shareDialogOpen}
        onClose={closeShareDialog}
        onModelChange={setModel}
      />
      <EditModelNameDialogContainer
        model={model}
        open={editNameDialogOpen}
        onClose={() => setEditNameDialogOpen(false)}
        onModelChange={handleModelNameChange}
      />
    </div>
  );
};

const withContext = (Component: any) => {
  return (props: any) => {
    return (
      <ModelDetailsPageContextProvider>
        <ModelPaymentProvider>
          <Component {...props} />
        </ModelPaymentProvider>
      </ModelDetailsPageContextProvider>
    );
  };
};

export default withContext(
  withRouter(
    inject(STORE_NAMES.GlobalStore)(
      observer(withViewerProviders(ModelDetailsPage))
    )
  )
);
