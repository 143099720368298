import React from "react";

interface IAngleIconPropTypes {}

const AngleIcon = ({}: IAngleIconPropTypes) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path d="M29 27H7V6L29 27Z" stroke="black" />
      <path d="M13 27C13 23.6769 10.3231 21 7 21" stroke="black" />
      <path d="M7 12C8.59184 12 10.102 11.24 11 10" stroke="black" />
      <path d="M24 27C24 25.4742 24.3721 24.0309 25 23" stroke="black" />
      <rect x="9" y="24" width="1" height="1" rx="0.5" fill="black" />
    </svg>
  );
};

export default AngleIcon;
