import React, { DOMAttributes } from 'react'
import styled from 'styled-components';
import classNames from 'classnames';
import { IAppContext } from "../../interfaces";

interface IDescriptionPropTypes extends DOMAttributes<HTMLHeadingElement> {
  className?: string,
}

const StyledDescription = styled.h6`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 24px;
  font-size: 0.875rem;
  font-weight: 400;
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
`;

const Description = ({
  className,
  ...otherProps
}: IDescriptionPropTypes) => {
  return (
    <StyledDescription className={classNames(['description', className])} {...otherProps} />
  );
}

export default Description;
