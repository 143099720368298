import React from 'react'
import styled from 'styled-components';

interface ICommentsIconPropTypes {
  
}

const CommentsIcon = ({
  
}: ICommentsIconPropTypes) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M2.66184 10.9961C1.96297 9.81803 1.71822 8.4253 1.97355 7.07949C2.22888 5.73368 2.96672 4.52737 4.04852 3.68708C5.13033 2.84679 6.48168 2.43031 7.84882 2.51587C9.21596 2.60142 10.5048 3.18311 11.4735 4.15171C12.4421 5.12032 13.0238 6.4092 13.1093 7.77634C13.1949 9.14348 12.7784 10.4948 11.9381 11.5766C11.0978 12.6584 9.89152 13.3963 8.54572 13.6516C7.19991 13.907 5.80718 13.6622 4.62905 12.9634L4.62907 12.9633L2.6863 13.5184C2.60592 13.5413 2.52086 13.5424 2.43994 13.5214C2.35902 13.5005 2.28518 13.4582 2.22607 13.3991C2.16696 13.34 2.12473 13.2662 2.10376 13.1852C2.08279 13.1043 2.08384 13.0193 2.10681 12.9389L2.66189 10.9961L2.66184 10.9961Z" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.19189 13.7411C7.46841 14.5261 7.91559 15.2401 8.5012 15.8314C9.08682 16.4228 9.7963 16.877 10.5785 17.1612C11.3608 17.4454 12.1963 17.5526 13.025 17.4751C13.8537 17.3975 14.6548 17.1371 15.3707 16.7127L15.3707 16.7127L17.3135 17.2677C17.3939 17.2907 17.4789 17.2918 17.5599 17.2708C17.6408 17.2498 17.7146 17.2076 17.7737 17.1485C17.8328 17.0894 17.8751 17.0155 17.896 16.9346C17.917 16.8537 17.9159 16.7686 17.893 16.6883L17.3379 14.7455L17.338 14.7455C17.8311 13.9138 18.1016 12.9689 18.1234 12.0022C18.1451 11.0354 17.9173 10.0794 17.4619 9.22636C17.0066 8.37329 16.3391 7.65197 15.5238 7.132C14.7085 6.61202 13.773 6.31095 12.8074 6.25781" stroke="#000000" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default CommentsIcon;
