import { toast } from "react-toastify";

const OPTIONS = {
  autoClose: 10000,
};

export interface ToastMessage {
  type: "success" | "warning" | "error" | "info" | "danger";
  text: string;
}
export class MessageService {
  static success(text: string) {
    MessageService.show({ text, type: "success" });
  }

  static info(text: string) {
    MessageService.show({ text, type: "info" });
  }

  static error(text: string): void {
    MessageService.show({ text, type: "error" });
  }

  static warn(text: string): void {
    MessageService.show({ text, type: "warning" });
  }

  static show(msg: ToastMessage): void {
    switch (msg.type) {
      case "success":
        toast.success(msg.text, OPTIONS);
        break;
      case "danger":
      case "error":
        toast.error(msg.text, OPTIONS);
        break;
      case "warning":
        toast.warn(msg.text, OPTIONS);
        break;
      case "info":
        toast.info(msg.text, OPTIONS);
        break;
      default:
        toast.success(msg.text, OPTIONS);
    }
  }
}
