import React, { useEffect, useState } from "react";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import {
  IAppContext,
  IModelListFilters,
  ModelStatusFilter,
  ModelType,
  ModelTypeSearchParamsEnum,
} from "views/interfaces";
import Box, { BoxAlignedCenter } from "views/shared/components/Box";
import SkyeSwitch from "views/shared/form/SkyeSwitch";
import DatePicker from "views/shared/components/DatePicker";
import TagEditContainer from "../../containers/TagEditContainer";
import InputGroup from "views/shared/form/InputGroup";
import { WindowUtils } from "utils/windowUtils";
import { debounce } from "utils/globalUtils";
import { useTranslation } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import useBreakpoint from "views/shared/hook/useBreakpointHook";
import ModelTypeDropdown from "./ModelTypeDropdown";
import Button from "views/shared/buttons/Button";
import FilterIcon from "views/shared/Icons/FilterIcon";
import Dialog from "views/shared/components/Dialog/Dialog";
import classNames from "classnames";

interface IModelFiltersRowPropTypes extends RouteComponentProps {
  loading: boolean;
  onChange: (filters: IModelListFilters, page: number) => void;
}

const setQueryParam = (key: string, value: string | string[]): void => {
  if (window.history.pushState) {
    let searchParamsString = "";
    let searchParams = new URLSearchParams(window.location.search);

    const isDateKey =
      key === ModelTypeSearchParamsEnum.StartDate ||
      key === ModelTypeSearchParamsEnum.EndDate;
    const isEmptyNotDateValue = !value && !isDateKey;

    if (isEmptyNotDateValue && key !== ModelTypeSearchParamsEnum.Name) {
      return;
    }

    if (!value) {
      searchParams.delete(key);
    } else if (Array.isArray(value)) {
      searchParamsString = JSON.stringify(value);
      const arrStr = value.toString();
      searchParams.set(`${key}`, arrStr);
      searchParamsString = searchParams.toString();
    } else {
      searchParams.set(key, value);
      searchParamsString = searchParams.toString();
    }

    let newurl =
      window.location.protocol +
      "//" +
      window.location.host +
      window.location.pathname +
      "?" +
      searchParamsString;
    window.history.pushState({ path: newurl }, "", newurl);
  }
};

const TagsSearchWrapper = styled.div`
  .input-wrapper {
    height: 46px;
  }
  ${breakpoint("lg")`
    width: 100% !important;
    max-width: 360px;
  `}
`;
const ModelStatusWrapper = styled.div`
  display: flex;
  align-items: center;

  .skye-switch {
    width: 100%;
    justify-content: space-between;
    flex-direction: row-reverse;

    ${breakpoint("lg")`
      flex-direction: row;
      justify-content: center;
    `}
  }
`;
const DatePickerWrapper = styled.div``;
const StyledModelFiltersRow = styled(Box)`
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;

  &.loading {
    pointer-events: none;
    opacity: 0.6;
    cursor: not-allowed !important;
  }

  ${breakpoint("lg")`
    flex-direction: row;
    padding-top: 38px;
    padding-bottom: 39px;
  `}

  .dropdown-wrapper {
    min-width: 160px;
  }

  // Drodown styles
  & > div {
    padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};

    ${breakpoint("lg")`
      padding-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
      width: 100%;
    `}
  }
`;
const MobileBasicFiltersRow = styled(BoxAlignedCenter)`
  padding-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
  padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.large};

  .skye-dropdown-wrapper {
    width: 100%;
    margin-right: 10px;
  }

  .btn {
    height: 46px;
  }
`;
const FiltersCountWrapper = styled.div`
  position: relative;
`;
const FiltersCountIndicator = styled(BoxAlignedCenter)`
  font-size: 8px;
  line-height: 14px;
  color: white;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
  width: 14px;
  height: 14px;
  border-radius: 14px;
  border: 2px solid ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  box-shadow: 0px 2px 4px rgb(0 0 0 / 10%);
  position: absolute;
  right: -5px;
  top: -5px;
`;

let searchDebounceTimer = null;

const ModelFiltersRow = ({
  onChange,
  location,
  match,
  loading,
}: IModelFiltersRowPropTypes) => {
  const { t } = useTranslation();
  const getModelTypeDefaultValue = () => {
    return WindowUtils.getQueryParam(
      ModelTypeSearchParamsEnum.ModelType,
      ModelType.Owned
    );
  };
  const [filtersOpen, setFiltersOpen] = useState(false);
  const { breakpoint } = useBreakpoint();
  const isDesktopView = breakpoint("lg");
  const isMobileFiltersOpen = filtersOpen && !isDesktopView;
  const queryTags = WindowUtils.getQueryParam(
    ModelTypeSearchParamsEnum.Tags,
    []
  );
  const getDefaultFilters = (): IModelListFilters => {
    return {
      modelType: getModelTypeDefaultValue(),
      endDateFilter: WindowUtils.getQueryParam(
        ModelTypeSearchParamsEnum.EndDate,
        null
      ),
      status: WindowUtils.getQueryParam(
        ModelTypeSearchParamsEnum.Status,
        ModelStatusFilter.all
      ),
      startDateFilter: WindowUtils.getQueryParam(
        ModelTypeSearchParamsEnum.StartDate,
        null
      ),
      tags: Array.isArray(queryTags) ? queryTags : [queryTags],
      name: searchInputValue,
    };
  };
  const [searchInputValue, setSearchInputValue] = useState<string>(
    WindowUtils.getQueryParam(ModelTypeSearchParamsEnum.Name, "")
  );
  const [currentFilters, setCurrentFilters] = useState<IModelListFilters>(
    getDefaultFilters()
  );
  // Used to store values for mobile view before confirmation
  const [savedFilters, setSavedFilters] = useState(currentFilters);
  const isNotSharedType =
    currentFilters.modelType !== ModelType.Shared &&
    currentFilters.modelType !== ModelType.SharedToOthers;

  const changeFilters = (filters: IModelListFilters, resetPage = true) => {
    const newFilters = { ...currentFilters, ...filters };
    const newPage = resetPage ? 1 : Number((match.params as any).page);
    setCurrentFilters(newFilters);

    // Automatically emitting for desktop view
    if (isDesktopView || filters.modelType) {
      emitChanges(newFilters, newPage);
    }
  };

  const emitChanges = (newFilters: IModelListFilters, newPage: number) => {
    onChange(newFilters, newPage);
  };

  const onSearchInputChange = (value: string) => {
    setSearchInputValue(value);

    searchDebounceTimer = debounce(searchDebounceTimer, 1000, () => {
      setQueryParam(ModelTypeSearchParamsEnum.Name, value);
      changeFilters({ name: value });
    });
  };

  const handleTypeChange = (value: ModelType) => {
    if (value !== currentFilters.modelType) {
      setQueryParam(ModelTypeSearchParamsEnum.ModelType, value);
      setQueryParam(ModelTypeSearchParamsEnum.Tags, []);
      changeFilters({ modelType: value, tags: [] });
    }
  };

  const handleTagsChange = (tags: string[]) => {
    setQueryParam(ModelTypeSearchParamsEnum.Tags, tags);
    changeFilters({ tags });
  };

  const handleDateChange = (
    startDate: moment.Moment | null,
    endDate: moment.Moment | null
  ) => {
    setQueryParam(
      ModelTypeSearchParamsEnum.StartDate,
      startDate ? startDate.valueOf().toString() : null
    );
    setQueryParam(
      ModelTypeSearchParamsEnum.EndDate,
      endDate ? endDate.valueOf().toString() : null
    );
    changeFilters({ startDateFilter: startDate, endDateFilter: endDate });
  };

  const handleCloseFilters = () => {
    const newFilters = { ...savedFilters };
    setCurrentFilters(newFilters);
    setFiltersOpen(false);
  };

  const handleStatusChange = (checked: boolean) => {
    const newValue = checked
      ? ModelStatusFilter.finished
      : ModelStatusFilter.all;

    if (newValue !== currentFilters.status) {
      setQueryParam(ModelTypeSearchParamsEnum.Status, newValue);
      changeFilters({ status: newValue });
    }
  };

  const renderModelTypeDropdown = () => (
    <div className="skye-dropdown-wrapper">
      <ModelTypeDropdown
        currentModelType={currentFilters.modelType}
        onChange={handleTypeChange}
      />
    </div>
  );

  const openMobileFilters = () => {
    setFiltersOpen(true);
    setSearchInputValue(currentFilters.name);
    setSavedFilters(currentFilters);
  };

  const handleConfirmMobileFilters = () => {
    emitChanges(currentFilters, 1);
    setFiltersOpen(false);
  };

  const handleResetMobileFilters = () => {
    const newFilters = {
      modelType: getModelTypeDefaultValue(),
      status: ModelStatusFilter.all,
      endDateFilter: null,
      startDateFilter: null,
      tags: [],
      name: "",
    };
    setCurrentFilters(newFilters);
    setFiltersOpen(false);
    emitChanges(newFilters, 1);
  };

  const getAppliedFiltersCount = () => {
    let count = 0;

    if (currentFilters.tags && currentFilters.tags.length) {
      count = count + currentFilters.tags.length;
    }

    if (currentFilters.name) count++;
    if (currentFilters.status === ModelStatusFilter.finished) count++;

    return count;
  };

  const handleSearchChange = () => {
    if (!loading) {
      const newFilters = {
        ...getDefaultFilters(),
        modelType: getModelTypeDefaultValue(),
      };
      changeFilters(newFilters, false);
    }
  };

  // Initial search and
  // Handling event when somebody will change model type from navbar
  useEffect(() => {
    handleSearchChange();
  }, [location.search]);

  const renderFilters = () => (
    <StyledModelFiltersRow
      id="modelFilters"
      className={classNames({ loading })}
    >
      {isDesktopView && renderModelTypeDropdown()}
      {isNotSharedType && (
        <TagsSearchWrapper>
          <InputGroup
            bordered
            inputProps={{
              placeholder: "searchModel",
              value: searchInputValue,
              onChange: (e: any) => onSearchInputChange(e.target.value),
            }}
          />
        </TagsSearchWrapper>
      )}
      {isDesktopView && (
        <DatePickerWrapper>
          <DatePicker
            startDate={currentFilters.startDateFilter}
            endDate={currentFilters.endDateFilter}
            onDateChange={handleDateChange}
          />
        </DatePickerWrapper>
      )}
      {isNotSharedType && (
        <>
          <TagsSearchWrapper>
            <TagEditContainer
              bordered
              allowToCreateNew={false}
              showDefaultTags={true}
              onChange={handleTagsChange}
              selectedTags={currentFilters.tags}
            />
          </TagsSearchWrapper>
        </>
      )}
      <ModelStatusWrapper>
        <SkyeSwitch
          bottomPadding={false}
          checked={currentFilters.status === ModelStatusFilter.finished}
          onChange={(newChecked) => handleStatusChange(newChecked)}
        >
          {t("created")}
        </SkyeSwitch>
      </ModelStatusWrapper>
    </StyledModelFiltersRow>
  );

  const renderMobileFilters = () => {
    const filtersCount = getAppliedFiltersCount();

    return (
      <MobileBasicFiltersRow>
        {renderModelTypeDropdown()}
        <FiltersCountWrapper>
          {!!filtersCount && (
            <FiltersCountIndicator>{filtersCount}</FiltersCountIndicator>
          )}
          <Button onClick={openMobileFilters} type="light">
            <FilterIcon />
          </Button>
        </FiltersCountWrapper>
      </MobileBasicFiltersRow>
    );
  };

  return (
    <>
      <Dialog
        title={t("filters")}
        size="medium"
        open={isMobileFiltersOpen}
        onClose={handleCloseFilters}
        confirmButtonProps={{ onClick: handleConfirmMobileFilters }}
        cancelButtonProps={{
          children: t("reset"),
          onClick: handleResetMobileFilters,
        }}
      >
        {renderFilters()}
      </Dialog>
      {isDesktopView && renderFilters()}
      {!isDesktopView && renderMobileFilters()}
    </>
  );
};

export default withRouter(ModelFiltersRow);
