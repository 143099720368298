import API from "const/api";
import httpService from "./HttpService";

export interface CommentDTO {
  text: string,
  id: number,
  ownerName: string,
  createdAt: Date,
  updatedAt: Date,
}

export class CommentService {
  async getModelComments(modelId: string): Promise<CommentDTO[]> {
    const url = API.getModelComments.replace(':id', modelId);
    try {
      const res = await httpService.get(url);
      return res.data;
    } catch (error) {
      return [];
    }
  }

  /**
   * If comment finished it'll return comment, other way will return null
   * @param modelId
   * @param text
   * @param commentId
   * @returns Comment | null
   */
  async createComment(modelId: string, text: string, commentId = ''): Promise<CommentDTO | null> {
    const url = API.commentCreate.replace(':id', modelId);
    const body = { modelId, text, commentId };
    try {
      const res = await httpService.post(url, body);
      return res.data;
    } catch (error) {
      return null;
    }
  }

  async deleteComment(modelId: string, commentId: string): Promise<boolean> {
    const url = API.deleteComment
      .replace(':commentId', commentId)
      .replace(':modelId', modelId);

    try {
      await httpService.delete(url);
      return true;
    } catch (error) {
      return false;
    }
  }
}
