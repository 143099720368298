import React from "react";
import Component from "../component";
import NavbarSignInInfo from "../../components/NavbarSignInInfo";

interface MobileNonAuthorizedNavbarProps {}

const MobileNonAuthorizedNavbar = ({}: MobileNonAuthorizedNavbarProps) => {
  return <Component topSection={<NavbarSignInInfo />} />;
};

export default MobileNonAuthorizedNavbar;
