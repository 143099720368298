import React, { ReactNode } from "react";
import styled from "styled-components";
import { Container } from "styled-bootstrap-grid";
import classNames from "classnames";
import { IAppContext } from "../../interfaces";
import breakpoint from "styled-components-breakpoint";

interface IBasePagePropTypes {
  children: ReactNode;
  backgroundColor?: "white" | "grey";
}

const PageWrapper = styled(Container)`
  width: 100%;
  height: 100%;
  padding-bottom: 100px;
  padding: 0;
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.small};

  ${breakpoint("md")`
    padding: ${(p) => p.theme.skye.sizes.small};
    margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
  `}
`;

const ContentContainer = styled(Container)`
  padding-top: 10px;
  width: 100%;
  min-height: calc(
    100vh - ${(p: IAppContext) => p.theme.skye.elementSizes.navbarHeight}
  );

  &.white {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  }

  ${breakpoint("md")`
    padding-top: 30px;
  `}
`;

const BasePage = ({
  children,
  backgroundColor = "grey",
}: IBasePagePropTypes) => {
  return (
    <ContentContainer
      fluid
      className={classNames(["base-page", backgroundColor])}
    >
      <PageWrapper>{children}</PageWrapper>
    </ContentContainer>
  );
};

export default BasePage;
