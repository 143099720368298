import { ILocation } from "models/Model";
import { SKYE_THEME } from "theme";

export interface IGoogleMapLocation {
  lat: number,
  lng: number,
}

export const POLYLINE_OPTIONS = {
  strokeColor: SKYE_THEME.colors.primary,
  strokeOpacity: 1,
};

export class GoogleMapService {
  static getLocationPoint(location: ILocation) {
    return {
      lat: location.latitude,
      lng: location.longitude,
    }
  }
}