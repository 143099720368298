import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import { ViewerService } from "../services/ViewerService";
import SkyeTooltip from "../../shared/components/SkyeTooltip";
import { useTranslation } from "react-i18next";
import DefaultViewIcon from "../../shared/Icons/DefaultViewIcon";
import HandIcon from "../../shared/Icons/HandIcon";
import HandPointingIcon from "../../shared/Icons/HandPointingIcon";
import Box from "../../shared/components/Box";
import classNames from "classnames";
import breakpoint from "styled-components-breakpoint";
import FullScreenIcon from "views/shared/Icons/FullScreenIcon";
import BirdViewIcon from "../../shared/Icons/BirdViewIcon";
import { useBirdView } from "../hook/useBirdView";
import NewToolIndicatorWrapper from "../../shared/components/NewToolIndicator/NewToolIndicatorWrapper";
import RotateIcon from "../../shared/Icons/RotateIcon";
import { useReorientTool } from "../hook/useReorientTool";

interface IBottomRightControlsPropTypes {
  fullScreen: boolean;
  vertical?: boolean;
  onFullscreen: (fullscreen: boolean) => void;
}

const ControlsWrapper = styled(Box)``;

const ControlElement = styled(Box)`
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-left: 1px solid
    ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};

  &.mobile-control {
    ${breakpoint("lg")`
      display: none;
    `}
  }

  &.desktop-control {
    display: none;

    ${breakpoint("lg")`
      display: flex;
    `}
  }

  &.btn {
    cursor: pointer;
  }

  &:first-of-type {
    border: none;
  }

  path {
    stroke: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
  }

  &.btn:hover,
  &.active {
    path {
      stroke: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    }
  }
`;
const IconWrapper = styled(Box)`
  width: 20px;
  height: 20px;
  align-items: center;
  justify-content: center;
`;

const RotateIconWrapper = styled(IconWrapper)`
  width: 24px;
  height: 24px;
`;

const viewerService = new ViewerService();

const ViewerViewControls = ({
  fullScreen,
  onFullscreen,
  vertical,
}: IBottomRightControlsPropTypes) => {
  const birdView = useBirdView();
  const reorientTool = useReorientTool();
  const [movementModeActive, setMovementModeActive] = useState(false);
  const [scrollToPointActive, setScrollToPointActive] = useState(false);
  const { t } = useTranslation("");

  const handleCenter = () => {
    viewerService.fitToScreen();
  };

  const startMovementMode = () => {
    setMovementModeActive(true);
  };

  const endMovementMode = () => {
    setMovementModeActive(false);
  };

  const endScrollToPointMode = (): void => {
    setScrollToPointActive(false);
  };

  const handleScrollToPointClick = () => {
    const newState = !scrollToPointActive;
    setScrollToPointActive(newState);
    viewerService.setScrollToPoint(newState);
  };

  useEffect(() => {
    setTimeout(() => {
      window.viewer.addEventListener("movement_mode_start", startMovementMode);
      window.viewer.addEventListener("movement_mode_end", endMovementMode);
      window.viewer.addEventListener(
        "scroll_to_point_mode_end",
        endScrollToPointMode
      );
    }, 1000);

    return () => {
      window.viewer.removeEventListener(
        "movement_mode_start",
        startMovementMode
      );
      window.viewer.removeEventListener("movement_mode_end", endMovementMode);
      window.viewer.removeEventListener(
        "scroll_to_point_mode_end",
        endScrollToPointMode
      );
    };
  }, []);

  const handleFullscreenToggle = () => {
    onFullscreen(!fullScreen);
  };

  const handleRotate = () => {
    reorientTool.rotate180("y");
  };

  return (
    <ControlsWrapper
      className={classNames(["viewer-bottom-controls", { vertical }])}
    >
      <SkyeTooltip title={t("tooltipFitModelToScreen")}>
        <ControlElement className={classNames(["btn"])} onClick={handleCenter}>
          <IconWrapper>
            <DefaultViewIcon />
          </IconWrapper>
        </ControlElement>
      </SkyeTooltip>
      <div className="hidden lg:block">
        <NewToolIndicatorWrapper>
          <SkyeTooltip title={t("tooltipBirdView")}>
            <ControlElement
              className={classNames([
                "btn",
                "desktop-control",
                { active: birdView.isActive },
              ])}
              onClick={birdView.toggle}
            >
              <IconWrapper>
                <BirdViewIcon />
              </IconWrapper>
            </ControlElement>
          </SkyeTooltip>
        </NewToolIndicatorWrapper>
      </div>
      <NewToolIndicatorWrapper>
        <SkyeTooltip title={t("tooltipRotateYTool")}>
          <ControlElement
            className={classNames(["btn", { active: reorientTool.reoriented }])}
            onClick={handleRotate}
          >
            <RotateIconWrapper>
              <RotateIcon />
            </RotateIconWrapper>
          </ControlElement>
        </SkyeTooltip>
      </NewToolIndicatorWrapper>
      <SkyeTooltip title={t("tooltipFullscreen")}>
        <ControlElement
          className={classNames([
            "btn",
            "mobile-control",
            { active: fullScreen },
          ])}
          onClick={handleFullscreenToggle}
        >
          <IconWrapper>
            <FullScreenIcon />
          </IconWrapper>
        </ControlElement>
      </SkyeTooltip>
      <SkyeTooltip title={t("tooltipMovementModeIndicator")}>
        <ControlElement
          className={classNames([
            "desktop-control",
            { active: movementModeActive },
          ])}
        >
          <IconWrapper>
            <HandIcon />
          </IconWrapper>
        </ControlElement>
      </SkyeTooltip>
      <SkyeTooltip title={t("tooltipScrollToPointMode")}>
        <ControlElement
          className={classNames([
            "btn",
            "desktop-control",
            { active: scrollToPointActive },
          ])}
          onClick={handleScrollToPointClick}
        >
          <IconWrapper>
            <HandPointingIcon />
          </IconWrapper>
        </ControlElement>
      </SkyeTooltip>
    </ControlsWrapper>
  );
};

export default ViewerViewControls;
