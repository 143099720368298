import React, { memo } from "react";
import styled from "styled-components";
import { BoxAlignedCenter } from "../../../../../../shared/components/Box";
import { IAppContext } from "../../../../../../interfaces";
import TrashIcon from "../../../../../../shared/Icons/TrashIcon";

export interface IModelListElementShareByPropTypes {
  shareBy: string;
  onDelete: () => void;
}

const ShareRow = styled(BoxAlignedCenter)`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.radius}};
  color: ${(p: IAppContext) => p.theme.skye.colors.textSecondary};
  font-size: 0.9em;
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  padding: 4px 2px 4px 6px;
  width: 100%;
  cursor: pointer;

  svg {
    display: none;
  }

  &:hover {
    background-color: ${(p: IAppContext) =>
      p.theme.skye.colors.backgroundLight};

    svg {
      display: block;
      height: 14px;
      margin-left: ${(p: IAppContext) => p.theme.skye.sizes.radius};
    }
  }
`;

const UserEmail = styled.span``;

const ModelListElementShareBy = ({
  shareBy,
  onDelete,
}: IModelListElementShareByPropTypes) => {
  const handleOnDelete = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    onDelete();
  };

  return (
    <ShareRow className="share-by" onClick={handleOnDelete}>
      <UserEmail>{shareBy}</UserEmail>
      <TrashIcon />
    </ShareRow>
  );
};

export default memo(ModelListElementShareBy);
