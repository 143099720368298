import React from "react";

interface IZoomIconPropTypes {}

const FieldOfViewToolIcon = ({}: IZoomIconPropTypes) => {
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M25.54 5.58908V22.4107C22.8004 20.2174 18.5331 18.9646 13.9524 18.9646C9.43641 18.9646 5.20387 20.192 2.45996 22.338V5.66201C5.20377 7.80787 9.43642 9.03538 13.9524 9.03538C18.5323 9.03538 22.7995 7.78245 25.54 5.58908Z"
        stroke="#1A1A1A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.48298 13.9594C9.80335 12.3789 11.7901 10.98 14 10.98C16.2099 10.98 18.1967 12.3789 19.5171 13.9594C19.6721 14.145 19.6721 14.4149 19.5171 14.6005C18.1967 16.181 16.2099 17.58 14 17.58C11.7901 17.58 9.80333 16.181 8.48298 14.6005C8.32794 14.4149 8.32794 14.145 8.48298 13.9594ZM9.53099 14.2799C10.5334 15.3732 11.8197 16.2442 13.1834 16.502C12.2787 16.1694 11.6334 15.3001 11.6334 14.28C11.6334 13.2599 12.2787 12.3905 13.1835 12.058C11.8197 12.3157 10.5334 13.1867 9.53099 14.2799ZM14.8166 12.058C15.7213 12.3905 16.3667 13.2599 16.3667 14.28C16.3667 15.3001 15.7213 16.1694 14.8166 16.502C16.1804 16.2442 17.4667 15.3732 18.4691 14.2799C17.4667 13.1867 16.1803 12.3157 14.8166 12.058ZM14 12.9133C13.2452 12.9133 12.6334 13.5252 12.6334 14.28C12.6334 15.0348 13.2452 15.6466 14 15.6466C14.7548 15.6466 15.3667 15.0348 15.3667 14.28C15.3667 13.5252 14.7548 12.9133 14 12.9133Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};

export default FieldOfViewToolIcon;
