import { useState } from "react";
import {
  AnalyticEventCategory,
  AnalyticService,
} from "../../../../services/AnalyticService";
import { DocumentService } from "../../../../services/DocumentService";
import { useTranslation } from "react-i18next";
import { IUseDownloadModel, IUseDownloadModelProps } from "./useDownload.model";

export const useDownloadLazFile = ({
  model,
  hasAccess,
}: IUseDownloadModelProps): IUseDownloadModel => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const lasFileUrl = model?.assets?.lazFileUrl;

  const handleDownload = () => {
    if (!hasAccess) return;

    AnalyticService.event(
      AnalyticEventCategory.ModelDetailsPage,
      "laz_download"
    );
    setLoading(true);
    DocumentService.downloadFile(lasFileUrl, t("downloadLazFileName"));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  return {
    handleDownload,
    loading,
    isAvailable: !!lasFileUrl,
  };
};
