import React, { memo, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { EMAIL_PATTERN } from "../../shared/utils/validationUtils";
import GlobalStore from "stores/GlobalStore";
import Title from "../../shared/typography/Title";
import Dialog from "views/shared/components/Dialog/Dialog";
import InputGroup from "../../shared/form/InputGroup";

import { Row, Col } from "styled-bootstrap-grid";
import SkyeSwitch from "../../shared/form/SkyeSwitch";
import { IAppContext } from "../../interfaces";

export interface IInviteUserDialogInvite {
  email: string;
  firstName: string;
  lastName: string;
  organizationAccess: boolean;
}

interface IInviteUserDialogContainerPropTypes {
  open: boolean;
  loading: boolean;
  onInvite: (data: IInviteUserDialogInvite) => void;
  onClose: () => void;
}

const DialogContentRow = styled(Row)`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};

  &:last-of-type {
    margin-bottom: 0;
  }

  .input-group:last-of-type {
    margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
  }
`;

const InviteUserDialogContainer = ({
  open,
  loading,
  onInvite,
  onClose,
}: IInviteUserDialogContainerPropTypes) => {
  const { t } = useTranslation();
  const [submitted, setSubmitted] = useState(false);
  const [organizationAccess, setOrganizationAccess] = useState(true);

  const [email, setEmail] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const emailInvalid = !EMAIL_PATTERN.test(email);
  const firstNameInvalid = firstName.length < 1;
  const lastNameInvalid = lastName.length < 1;

  const handleInvite = () => {
    if (loading) return;

    setSubmitted(true);
    if (emailInvalid || firstNameInvalid || lastNameInvalid) return;
    if (emailInvalid)
      GlobalStore.addToast(t("validationErrorWrongEmail"), "error");
    if (firstNameInvalid)
      GlobalStore.addToast(t("validationErrorNotEmpty"), "error");
    if (lastNameInvalid)
      GlobalStore.addToast(t("validationErrorNotEmpty"), "error");

    const trimmedEmail = email.trim();

    onInvite({ email: trimmedEmail, firstName, lastName, organizationAccess });
  };

  return (
    <Dialog
      size="medium"
      open={open}
      onClose={onClose}
      title={t("inviteNewUser")}
      confirmButtonProps={{
        disabled: loading,
        loading: loading,
        onClick: handleInvite,
      }}
      cancelButtonProps={{
        onClick: onClose,
      }}
    >
      <DialogContentRow>
        <Col md={6}>
          <InputGroup
            bordered
            label="firstNameLabel"
            inputProps={{
              placeholder: "firstNamePlaceholder",
              value: firstName,
              onChange: (e: any) => setFirstName(e.target.value),
            }}
            invalid={submitted && firstNameInvalid}
            invalidMessage={t("validationErrorNotEmpty")}
          />
        </Col>
        <Col md={6}>
          <InputGroup
            bordered
            label="lastnamePlaceholder"
            inputProps={{
              placeholder: "lastnamePlaceholder",
              value: lastName,
              onChange: (e: any) => setLastName(e.target.value),
            }}
            invalid={submitted && lastNameInvalid}
            invalidMessage={t("validationErrorNotEmpty")}
          />
        </Col>
        <Col md={6}>
          <InputGroup
            bordered
            label="emailLabel"
            inputProps={{
              placeholder: "email",
              value: email,
              onChange: (e: any) => setEmail(e.target.value),
            }}
            invalid={submitted && emailInvalid}
            invalidMessage={t("validationErrorWrongEmail")}
          />
        </Col>
      </DialogContentRow>
      <DialogContentRow>
        <Col md={12}>
          <Title>{t("accessSectionLabel")}</Title>
          <SkyeSwitch
            onChange={(newChecked) => setOrganizationAccess(newChecked)}
            checked={organizationAccess}
          >
            {t("organizationAccessLabel")}
          </SkyeSwitch>
        </Col>
      </DialogContentRow>
    </Dialog>
  );
};

export default memo(InviteUserDialogContainer);
