import React from 'react'

interface IHandIconPropTypes {
  
}

const HandIcon = ({
  
}: IHandIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M21.0003 15V8.5C21.0003 7.83696 21.2637 7.20107 21.7325 6.73223C22.2014 6.26339 22.8373 6 23.5003 6C24.1634 6 24.7992 6.26339 25.2681 6.73223C25.7369 7.20107 26.0003 7.83696 26.0003 8.5V19C26.0003 20.3132 25.7417 21.6136 25.2391 22.8268C24.7366 24.0401 24 25.1425 23.0714 26.0711C22.1428 26.9997 21.0404 27.7362 19.8271 28.2388C18.6139 28.7413 17.3135 29 16.0003 29C10.4775 29 8.00031 26 3.92924 17.4108C3.76477 17.1265 3.65793 16.8125 3.61485 16.4869C3.57178 16.1612 3.59329 15.8303 3.67818 15.513C3.76306 15.1956 3.90965 14.8982 4.10956 14.6375C4.30947 14.3769 4.55879 14.1582 4.84326 13.9939C5.12773 13.8297 5.44178 13.7231 5.76746 13.6803C6.09314 13.6375 6.42407 13.6593 6.74132 13.7445C7.05857 13.8296 7.35593 13.9764 7.61641 14.1766C7.87688 14.3767 8.09537 14.6262 8.25937 14.9108L11.0003 19.6583V6.5C11.0003 5.83696 11.2637 5.20107 11.7325 4.73223C12.2014 4.26339 12.8373 4 13.5003 4C14.1634 4 14.7992 4.26339 15.2681 4.73223C15.7369 5.20107 16.0003 5.83696 16.0003 6.5V15" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.0003 15L21 4.5C21 3.83696 20.7366 3.20107 20.2678 2.73223C19.7989 2.26339 19.163 2 18.5 2C17.837 2 17.2011 2.26339 16.7322 2.73223C16.2634 3.20107 16 3.83696 16 4.5L16.0003 15" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default HandIcon;
