const CONVERSIONS = {
  km: 0.001,
  m: 1,
  cm: 100,
  mm: 1000,
  yd: 1.09361,
  ft: 3.28084,
  in: 39.3701,
};

export class MeasureCalculator {
  static calculateScale(
    realDistanceInMeters: number,
    viewerDistanceInMeters: number,
    currentScale: number
  ): number {
    if (!viewerDistanceInMeters || !realDistanceInMeters) return 1;
    if (viewerDistanceInMeters === realDistanceInMeters) return 1;
    return Math.abs(
      (realDistanceInMeters / viewerDistanceInMeters) * currentScale
    );
  }

  static metersToUnit(meters: number, unitCode: string) {
    const conversionFactor = CONVERSIONS[unitCode] || 1;
    return meters * conversionFactor;
  }

  static unitToMeters(unitValue: number, unitCode: string) {
    const conversionFactor = CONVERSIONS[unitCode] || 1;
    return unitValue / conversionFactor;
  }
}
