import React, { useContext, useEffect, useState } from "react";
import Dialog from "../../shared/components/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import InputGroup from "../../shared/form/InputGroup";
import { ViewerService } from "../services/ViewerService";
import { MeasurementTypesNames } from "../interfaces";
import Label from "../../shared/typography/Label";
import { BoxAlignedCenter } from "../../shared/components/Box";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import { MeasureCalculator } from "../services/MeasureCalculator";
import BaseMeasure from "../services/tools/BaseMeasure";
import { ViewerScaleContext } from "../context/ViewerScaleContext";

const viewerService = new ViewerService();

const InputWrapper = styled(BoxAlignedCenter)`
  .label {
    margin-left: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  }

  .input-group {
    max-width: 200px;
  }
`;

interface IRescaleModelToolHookResult {
  rescaleModal: JSX.Element;
  initRescaleTool: () => void;
}

const useRescaleModelTool = (): IRescaleModelToolHookResult => {
  const { rescale } = useContext(ViewerScaleContext);
  const { t } = useTranslation();

  const inputRef = React.createRef<HTMLInputElement>();
  const [scaleInputValue, setScaleInputValue] = useState("");
  const [_open, setOpen] = useState(false);
  const [viewerInMetersDistance, setViewerInMetersDistance] =
    useState<number>(null);

  const handleClose = () => {
    setOpen(false);
  };

  const handleInsertionEnd = () => {
    const measurement = BaseMeasure.getAll().find(
      (m) => m.name === MeasurementTypesNames.RescaleLine
    );

    if (!measurement) return;

    const newViewerInMetersDistance = measurement.getTotalDistance();
    BaseMeasure.remove(measurement);

    setViewerInMetersDistance(newViewerInMetersDistance);
    setScaleInputValue(
      MeasureCalculator.metersToUnit(
        newViewerInMetersDistance,
        viewerService.getLengthUnit()
      ).toFixed(2)
    );
    setOpen(true);
    setTimeout(() => {
      focusInput();
    }, 300);
  };

  const focusInput = () => {
    inputRef?.current?.focus();
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setScaleInputValue(e.target.value);
  };

  const handleSubmit = () => {
    const realDistanceInMeters = MeasureCalculator.unitToMeters(
      Number(scaleInputValue),
      viewerService.getLengthUnit()
    );
    const newScale = MeasureCalculator.calculateScale(
      realDistanceInMeters,
      viewerInMetersDistance,
      viewerService.getMeasurementsScale()
    );
    rescale(newScale);
    setOpen(false);
  };

  const attachEvents = () => {
    window.viewer?.addEventListener(
      "end_inserting_measurement",
      handleInsertionEnd
    );
  };

  const detachEvents = () => {
    window.viewer?.removeEventListener("cancel_insertions", handleInsertionEnd);
  };

  const initRescaleTool = () => {
    window.viewer?.measuringTool.startInsertion({
      showDistances: true,
      showArea: false,
      showAngles: false,
      showEdges: true,
      showHeight: false,
      closed: false,
      maxMarkers: 2,
      name: MeasurementTypesNames.RescaleLine,
    });
  };

  useEffect(() => {
    attachEvents();

    return () => {
      detachEvents();
    };
  }, []);

  const modal = () => {
    if (!_open) return null;

    const unitCode = viewerService.getLengthUnit();
    const lengthUnitLabel = t(`length_unit_${unitCode}`);

    return (
      <Dialog
        open={_open}
        onClose={() => setOpen(false)}
        title={t("rescaleModelTitle")}
        cancelButtonProps={{ onClick: handleClose }}
        confirmButtonProps={{
          disabled: !scaleInputValue,
          onClick: handleSubmit,
          children: t("rescaleModelConfirmButton"),
        }}
      >
        <InputWrapper>
          <InputGroup
            bordered
            inputProps={{
              ref: (ref) => {
                if (ref) {
                  (inputRef as any).current = ref;
                }
              },
              placeholder: "rescaleModelPlaceholder",
              value: scaleInputValue,
              type: "number",
              onKeyUp: (e: React.KeyboardEvent<HTMLInputElement>) => {
                if (e.code === "Enter") handleSubmit();
              },
              onChange: handleInputChange,
            }}
          />
          <Label>{lengthUnitLabel}</Label>
        </InputWrapper>
      </Dialog>
    );
  };

  return {
    rescaleModal: modal(),
    initRescaleTool,
  };
};

export default useRescaleModelTool;
