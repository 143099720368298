import React, { DOMAttributes } from 'react'
import styled from 'styled-components';
import classNames from 'classnames';
import { IAppContext } from "../../interfaces";

interface IHeadingPropTypes extends DOMAttributes<HTMLHeadingElement> {
  className?: string;
  id?: string;
}

const StyledHeading = styled.h1`
  font-size: 24px;
  line-height: 24px;
  font-weight: 400;
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
`;

const Heading = ({
  className,
  ...otherProps
}: IHeadingPropTypes) => {
  return (
    <StyledHeading className={classNames(['heading', className])} {...otherProps} />
  );
}

export default Heading;
