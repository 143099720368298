import React from 'react'

const VideoIcon = () => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M21.1114 16L12.667 21.3334V10.6667L21.1114 16Z" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default VideoIcon;
