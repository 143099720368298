import { memo } from "react";
import { useDownloadObjFile } from "../../../hooks/download/useDownloadObjFile";
import { useDownloadLazFile } from "../../../hooks/download/useDownloadLazFile";
import { useDownloadGeoTiff } from "../../../hooks/download/useDownloadGeoTiff";
import { IPublicModel } from "../../../../../models/Model";
import { ModelAccessPolicy } from "../../../ModelAccessPolicy";
import GlobalStore from "../../../../../stores/GlobalStore";
import ModelDownloadButtonWrapperComponent from "../../../components/ModelDownloadButtonWrapper.component";
import Button from "../../../../shared/buttons/Button";
import { useTranslation } from "react-i18next";
import PaidAccessInfoBoxComponent from "../../components/PaidAccessInfoBox.component";

interface IPublicModelDetailsPageDownloadSection {
  model: IPublicModel;
}

const SharedModelDetailsPageDownloadSection = ({
  model,
}: IPublicModelDetailsPageDownloadSection) => {
  const hasAccess = ModelAccessPolicy.canDownloadAssets(
    GlobalStore?.user,
    model
  );
  const { t } = useTranslation();
  const useDownloadProps = { model, hasAccess };
  const obj = useDownloadObjFile(useDownloadProps);
  const laz = useDownloadLazFile(useDownloadProps);
  const geoTiff = useDownloadGeoTiff(useDownloadProps);

  if (!hasAccess) {
    return (
      <PaidAccessInfoBoxComponent
        title="download"
        description="downloadDescription"
        showPayPerModelButton={false}
      />
    );
  }

  return (
    <div className="grid gap-8">
      <ModelDownloadButtonWrapperComponent
        model={model}
        titleDictionary="downloadLazFile"
        descriptionDictionary="downloadLazFileDescription"
        alertDictionary="lazFileNotAvailable"
        showAlert={!laz.isAvailable}
      >
        <Button
          onClick={laz.handleDownload}
          loading={laz.loading}
          disabled={!laz.isAvailable}
        >
          {t("download")}
        </Button>
      </ModelDownloadButtonWrapperComponent>
      <ModelDownloadButtonWrapperComponent
        model={model}
        titleDictionary="downloadObjFile"
        descriptionDictionary="downloadObjFileDescription"
        alertDictionary="objFileNotAvailable"
        showAlert={!obj.isAvailable}
      >
        <Button
          onClick={obj.handleDownload}
          loading={obj.loading}
          disabled={!obj.isAvailable}
        >
          {t("download")}
        </Button>
      </ModelDownloadButtonWrapperComponent>
      <ModelDownloadButtonWrapperComponent
        model={model}
        titleDictionary="downloadTiffFile"
        descriptionDictionary="downloadTiffFileDescription"
        alertDictionary="fileNotAvailable"
        showAlert={false}
      >
        <Button
          onClick={geoTiff.handleDownload}
          loading={geoTiff.loading}
          disabled={!geoTiff.isAvailable}
        >
          {t("download")}
        </Button>
      </ModelDownloadButtonWrapperComponent>
    </div>
  );
};

export default memo(SharedModelDetailsPageDownloadSection);
