export default class Distance {
  static add(): void {
    window.viewer?.measuringTool.startInsertion({
      showDistances: true,
      showArea: false,
      closed: false,
      name: "Distance",
    });
  }
}
