import { memo } from "react";
import Dialog from "../../../shared/components/Dialog/Dialog";
import { IPaymentDialogPropTypes } from "./model";
import StripeDialogContent from "./StripeDialogContent.component";
import ModelSimplifiedPricing from "./components/SimplifiedPricing/SimplifiedPricing";

const ModelPaymentDialog = (props: IPaymentDialogPropTypes) => {
  const getContent = () => {
    switch (props.contentType) {
      case "pickOption":
        return (
          <div className="w-full flex items-center justify-center pt-4 md:pt-8 pb-4 md:pb-6">
            <ModelSimplifiedPricing {...props} />
          </div>
        );
      case "stripe":
        return <StripeDialogContent {...props} />;
      default:
        return null;
    }
  };

  return (
    <Dialog hideCancelButton hideConfirmButton {...props}>
      {getContent()}
    </Dialog>
  );
};

export default memo(ModelPaymentDialog);
