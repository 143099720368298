import { createContext } from "react";
import PaymentDialogComponent from "../../payments/components/ModelPaymentDialog";
import { useModelPaymentWidget } from "./useModelPaymentWidget";
import { useTranslation } from "react-i18next";

export const ModelPaymentContext = createContext(null);

export const ModelPaymentProvider = ({ children }) => {
  const { t } = useTranslation();
  const dialog = useModelPaymentWidget();

  return (
    <ModelPaymentContext.Provider value={{ dialog }}>
      {children}
      <PaymentDialogComponent
        quote={dialog.quote}
        showPeriodToggle={true}
        subscriptionPeriod={dialog.subscriptionPeriod}
        onSelectSubscriptionPeriod={dialog.setSubscriptionPeriod}
        handleBuySubscription={dialog.handleBuySubscription}
        stripeMetadata={dialog.stripeMetadata}
        contentType={dialog.contentType as any}
        size={dialog.contentType === "pickOption" ? "large" : "small"}
        open={dialog.open}
        onClose={dialog.handleClose}
        title={t("modelPaymentDialogTitle")}
        options={dialog.options}
        returnUrl={dialog.returnUrl}
        onBuyAccess={dialog.handleBuyAccess}
        loading={dialog.loading}
      ></PaymentDialogComponent>
    </ModelPaymentContext.Provider>
  );
};
