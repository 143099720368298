import React from "react";
import SignPageLayout from "views/auth/pages/layout";
import RegisterContainer from "../../containers/RegisterContainer";

interface IResetPasswordPagePropTypes {}

const RegisterPage = ({}: IResetPasswordPagePropTypes) => {
  return (
    <SignPageLayout showSwitch pageType="register" contentSize="lg">
      <RegisterContainer />
    </SignPageLayout>
  );
};

export default RegisterPage;
