export class WeatherService {
  static convertCelsiusToFahrenheit(temperatureInCelsius) {
    return (temperatureInCelsius * 1.8) + 32;
  }

  static convertMmToInches(mm) {
    return mm * 0.039370;
  }

  static convertMetersPerSecondToMilesPerHour(metersPerSecond: number) {
    return (Number(metersPerSecond) * 2.237).toFixed(1);
  }
}