import React, { ReactElement, ReactNode } from "react";
import styled from "styled-components";
import { IAppContext } from "../../../interfaces";
import Description from "../../typography/Description";
import Box from "../Box";
import classNames from "classnames";

interface IInformationBoxPropTypes {
  icon?: ReactNode | "error" | "error_2" | "success";
  title?: string;
  description?: string;
  transparent?: boolean;
  button?: ReactElement;
}

const BoxContainer = styled(Box)`
  text-align: center;
  padding: 35px 15px;
  align-items: center;
  flex-direction: column;
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.lavender};

  &.transparent {
    background-color: transparent;

    .title {
      color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
      font-weight: 300;
    }
  }
`;
const IconContainer = styled(Box)`
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};

  svg {
    width: 100%;
    height: 100%;
  }
`;

const Icon = ({ icon }) => {
  if (typeof icon !== "string") {
    return <IconContainer>{icon}</IconContainer>;
  }

  return (
    <div className="w-40 h-40 mb-4">
      <img className="w-full h-auto" src={`/images/${icon}.svg`} alt={icon} />
    </div>
  );
};

const InformationBox = ({
  transparent = false,
  icon,
  title,
  description,
  button,
}: IInformationBoxPropTypes) => {
  return (
    <BoxContainer className={classNames(["information-box", { transparent }])}>
      {!!icon && <Icon icon={icon} />}
      {Boolean(title) && (
        <h3 className="text-base font-semibold mb-2">{title}</h3>
      )}
      {Boolean(description) && (
        <Description className="information-box-description">
          {description}
        </Description>
      )}
      {!!button && button}
    </BoxContainer>
  );
};

export default InformationBox;
