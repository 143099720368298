import { useState } from "react";
import JSZip from "jszip";
import {
  AnalyticEventCategory,
  AnalyticService,
} from "../../../../services/AnalyticService";
import { DocumentService } from "../../../../services/DocumentService";
import { IUseDownloadModel, IUseDownloadModelProps } from "./useDownload.model";
import { ModelStatus } from "../../../../models/Model";

const modelName = (url: string) => {
  const regexp = /model\.(?<extension>.*)?(?=\?)/;
  const extension = regexp.exec(url).groups?.extension;
  return `model.${extension}`;
};

export const useDownloadObjFile = ({
  model,
  hasAccess,
}: IUseDownloadModelProps): IUseDownloadModel => {
  const [downloaded, setDownloaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const textures = model?.assets?.texture || [];
  const objExists = !!textures.length;
  const isAvailable = model.status === ModelStatus.Finished && objExists;

  const fetchFile = async (url: string): Promise<Blob> => {
    return await (await fetch(url)).blob();
  };

  const generateZip = async (
    files: { fileName: string; blob: Blob }[]
  ): Promise<Blob> => {
    const zip = new JSZip();
    files.forEach((file) => zip.file(file.fileName, file.blob));
    const blob = await zip.generateAsync({ type: "blob" });

    return blob;
  };

  const saveZip = (blob: Blob) => {
    DocumentService.downloadFile(window.URL.createObjectURL(blob), "model.zip");
  };

  const handleDownload = () => {
    if (!objExists || !hasAccess) return;

    AnalyticService.event(
      AnalyticEventCategory.ModelDetailsPage,
      "obj_download"
    );
    setLoading(true);
    setDownloaded(false);

    const bestQualityTextures = textures[0];
    if (bestQualityTextures) downloadPackage(bestQualityTextures);
  };

  const downloadPackage = async (urls: string[]) => {
    try {
      // Preventing from downloading multiple packages if exists
      if (downloaded || loading) return;

      setLoading(true);
      const files = await Promise.all(
        urls.map(async (url) => {
          const blob = await fetchFile(url);
          return {
            fileName: modelName(url),
            blob,
          };
        })
      );
      const zip = await generateZip(files);
      saveZip(zip);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      // Package for specific quality does not exist
    }
  };

  return {
    handleDownload,
    isAvailable,
    loading,
  };
};
