import { useEffect } from "react";
import { Model } from "../../../../models/Model";
import { useWebSocket } from "../../../web-socket/useWebSockets";
import { ModelWebSocketSubscribeEvent } from "../../model";

export interface IModelChangedEvent {
  model: Model;
}

interface IProps {
  onChanged: (data: IModelChangedEvent) => void;
}

export const useModelChangedSubscriber = ({ onChanged }: IProps) => {
  const ws = useWebSocket();

  useEffect(() => {
    const unsubscribe = ws.subscribe(
      ModelWebSocketSubscribeEvent.ModelUpdated,
      onChanged
    );

    return () => {
      unsubscribe();
    };
  }, []);

  return {};
};
