import { Model } from "models/Model";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import InputGroup from "../../shared/form/InputGroup";
import Dialog from "../../shared/components/Dialog/Dialog";
import ModelRadioButton from "./ModelRadioButton";
import { IModelListItem } from "../pages/ModelListPage.tsx/ModelList/interface";

interface IModelChooseModalPropTypes {
  inputValue: string;
  open: boolean;
  currentModel?: IModelListItem;
  models: IModelListItem[];
  onInputChange: (value: string) => void;
  // Event fired when user selected any model. It still need confirmation
  onSelect: (model: IModelListItem) => void;
  onClose: () => void;
  // Event fired when user confirm which model want to choose
  onChoose: () => void;
}
const ListWrapper = styled.div`
  max-height: 500px;
  overflow: auto;
`;

const handleSelect = (model: IModelListItem, fun: any) => () => {
  fun(model);
};

const ModelChooseModal = ({
  currentModel,
  inputValue,
  models,
  open,
  onInputChange,
  onClose,
  onSelect,
  onChoose,
}: IModelChooseModalPropTypes) => {
  const { t } = useTranslation();

  return (
    <Dialog
      title={t("chooseSessionModel")}
      open={open}
      onClose={onClose}
      confirmButtonProps={{
        children: t("chooseModel"),
        onClick: onChoose,
        disabled: !currentModel,
      }}
    >
      <InputGroup
        bordered
        inputProps={{
          placeholder: "searchModel",
          value: inputValue,
          onChange: (e: any) => onInputChange(e.target.value),
        }}
      />
      <ListWrapper>
        {models.map((model) => (
          <ModelRadioButton
            key={model.id}
            modelId={model.id}
            thumbnailsUrls={model?.assets?.thumbnailsUrls}
            modelName={model?.name}
            onClick={handleSelect(model, onSelect)}
            selected={currentModel && currentModel.id === model.id}
          />
        ))}
      </ListWrapper>
    </Dialog>
  );
};

export default ModelChooseModal;
