import React from 'react'
import { SKYE_THEME } from "../../../theme";
import { IconProps } from "../../interfaces";

const InfoIcon = ({
  color = SKYE_THEME.colors['textPrimary'],
}: IconProps) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M15.9998 29.3333C19.6817 29.3333 23.015 27.8409 25.4279 25.4281C27.8408 23.0152 29.3332 19.6819 29.3332 16C29.3332 12.3181 27.8408 8.98479 25.4279 6.5719C23.015 4.15904 19.6817 2.66666 15.9998 2.66666C12.318 2.66666 8.98464 4.15904 6.57174 6.5719C4.15889 8.98479 2.6665 12.3181 2.6665 16C2.6665 19.6819 4.15889 23.0152 6.57174 25.4281C8.98464 27.8409 12.318 29.3333 15.9998 29.3333Z" stroke={color} strokeLinejoin="round"/>
      <path d="M15.9998 8.5C16.4601 8.5 16.8332 8.8731 16.8332 9.33333C16.8332 9.79356 16.4601 10.1667 15.9998 10.1667C15.5396 10.1667 15.1665 9.79356 15.1665 9.33333C15.1665 8.8731 15.5396 8.5 15.9998 8.5Z" fill={color} stroke={color}/>
      <path d="M16.3333 22.6667V13.3333H15.6667H15" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M14 22.6667H18.6667" stroke={color} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default InfoIcon;
