export type ISkyeColors =
  | "primary"
  | "primary300"
  | "danger"
  | "dangerLight"
  | "dangerHeavy"
  | "secondary"
  | "success"
  | "successLight"
  | "successSecondary"
  | "accent"
  | "accent_secondary"
  | "textPrimary"
  | "textSecondary"
  | "textDescription"
  | "textDisabled"
  | "textAccent"
  | "background"
  | "backgroundThumbnail"
  | "backgroundLight"
  | "warning"
  | "electricViolet"
  | "electricVioletLight"
  | "lavender"
  | "whiteSmoke"
  | "woodSmoke"
  | "mineShaft"
  | "mobileNavBackground"
  | "silverChalice"
  | "silverChaliceLight"
  | "silverSand"
  | "redOrange"
  | "redOrangeLight"
  | "backgroundCard";

export type ISkyeSizes =
  | "small"
  | "radius"
  | "radiusMedium"
  | "average"
  | "medium"
  | "large"
  | "xLarge";

export type ISkyeShadows = "inputFocused" | "light" | "primary";

export type ISkyeBorders = "active" | "primary" | "secondary";

export type ISkyeFontSizes = "label" | "regular" | "title";

export type ISkyeZIndexes = "viewerControls";

export type IElementSizes = "navbarHeight" | "subscriptionInfoBarHeight";

export interface ISkyeTheme {
  colors: Record<ISkyeColors, string>;
  sizes: Record<ISkyeSizes, string>;
  fontSizes: Record<ISkyeFontSizes, string>;
  shadows: Record<ISkyeShadows, string>;
  borders: Record<ISkyeBorders, string>;
  zIndex: Record<ISkyeZIndexes, string>;
  elementSizes: Record<IElementSizes, string>;
}

export type Breakpoints = "xs" | "sm" | "md" | "lg" | "xl";
export const BREAKPOINTS_GRID = {
  breakpoints: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
  },
};

export const SKYE_THEME = {
  colors: {
    danger: "#EB5757",
    dangerLight: "#FEF4F4",
    dangerHeavy: "#FF1111",
    redOrange: "#DF2323",
    redOrangeLight: "#FDE2E2",
    primary: "#626BF6",
    primary300: "#EFEAFC",
    secondary: "",
    accent: "#FFD702",
    accent_secondary: "#9198EA",
    warning: "#F7A001",
    textPrimary: "#2b2b2b",
    textSecondary: "#2b2b2b",
    mineShaft: "#2e2e2e",
    textDisabled: "#c6c6c6",
    textDescription: "#A4A4A4",
    textAccent: "#626BF6",
    backgroundLight: "#f0f0f0",
    background: "#dadada",
    backgroundThumbnail: "#f3f3f3",
    backgroundCard: "#fff",
    successLight: "#EDF5EB",
    success: "#6FCF97",
    successSecondary: "#2BC605",
    mobileNavBackground: "#303030",
    // New colors
    lavender: "#F1EBFF",
    electricViolet: "#5A11FF",
    electricVioletLight: "#AC88FF",
    whiteSmoke: "#f4f0ff",
    woodSmoke: "#1a1a1a",
    silverChalice: "#959494",
    silverChaliceLight: "#FAFAFA",
    silverSand: "#C8C8C8",
  },
  fontSizes: {
    label: "12px",
    regular: "16px",
    title: "21px",
  },
  sizes: {
    radius: "4px",
    radiusMedium: "6px",
    small: "12px",
    average: "16px",
    medium: "20px",
    large: "24px",
    xLarge: "46px",
  },
  borders: {
    primary: "1px solid #E2E2E2",
    secondary: "1px solid #F1F1F1",
    active: "2px solid #5A11FF",
  },
  shadows: {
    primary: "0px 2px 8px rgba(0, 0, 0, 0.15)",
    light: "0px 0px 10px rgba(0, 0, 0, 0.1)",
    inputFocused: "0px 0px 4px rgba(90, 17, 255, 0.41)",
  },
  zIndex: {
    viewerControls: 800,
  },
  elementSizes: {
    navbarHeight: "60px",
    subscriptionInfoBarHeight: "52px",
  },
};

export const APP_THEME = {
  ...BREAKPOINTS_GRID,
  skye: SKYE_THEME,
};
