import { useTranslation } from "react-i18next";
import Dialog from "../../shared/components/Dialog/Dialog";
import { useDialog } from "../../shared/components/Dialog/useDialog";
import { useSubscriptionPayment } from "./useSubscriptionPayment";
import SimplifiedPricing from "../../payments/components/SimplifiedPricing";

export const useSubscriptionPaymentDialog = () => {
  const { t } = useTranslation();
  const dialog = useDialog();
  const { setSubscriptionPeriod, subscriptionPeriod, redirect } =
    useSubscriptionPayment();

  const openDialog = () => {
    dialog.openDialog();
  };

  const content = (
    <Dialog
      size="medium"
      title={t("buySubscription")}
      hideCancelButton
      hideConfirmButton
      open={dialog.open}
      onClose={dialog.closeDialog}
    >
      <div className="w-full flex justify-center py-8 md:py-16 lg:py-24">
        <div className="max-w-[500px]">
          <SimplifiedPricing
            subscriptionPeriod={subscriptionPeriod}
            onSelectSubscriptionPeriod={setSubscriptionPeriod}
            handleBuySubscription={redirect}
          />
        </div>
      </div>
    </Dialog>
  );

  return {
    openDialog,
    content,
  };
};
