import { useTranslation } from "react-i18next";
import SubscriptionInformationRow from "../../SubscriptionInformationRow/SubscriptionInformationRow";

const LiteSubscriptionInfo = () => {
  const { t } = useTranslation();

  return (
    <div id="subscription-expiring-info" className="subscription-info">
      <SubscriptionInformationRow
        iconColor="blue"
        color="transparent"
        icon="diamond"
        primaryText={t(`subscription.lite.upgradeInfoTitle`)}
        secondaryText={t("subscription.lite.upgradeInfoDescription")}
      />
    </div>
  );
};

export default LiteSubscriptionInfo;
