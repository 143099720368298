interface GetUpload {
  modelId: string;
  modelInputId: string;
  fileName: string;
}

const UPLOADS_KEY = "uploads";

const uploadId = (upload: GetUpload) => {
  const { modelId, modelInputId, fileName } = upload;
  return `${modelId}-${modelInputId}-${fileName}`;
};

export class UploadLocalStorageService {
  static getUploads() {
    return localStorage.getItem(UPLOADS_KEY);
  }

  static setUploads(uploads: any) {
    localStorage.setItem(UPLOADS_KEY, uploads);
  }

  static addUpload(upload: GetUpload, data: any) {
    const uploads = UploadLocalStorageService.getUploads();

    if (uploads) {
      const parsedUploads = JSON.parse(uploads);
      parsedUploads[uploadId(upload)] = data;
      UploadLocalStorageService.setUploads(JSON.stringify(parsedUploads));
    } else {
      const newUploads = {
        [uploadId(upload)]: data,
      };
      UploadLocalStorageService.setUploads(JSON.stringify(newUploads));
    }
  }

  static removeUpload(upload: GetUpload) {
    const uploads = UploadLocalStorageService.getUploads();

    if (uploads) {
      const parsedUploads = JSON.parse(uploads);
      delete parsedUploads[uploadId(upload)];
      UploadLocalStorageService.setUploads(JSON.stringify(parsedUploads));
    }
  }

  static removeUploads() {
    localStorage.removeItem(UPLOADS_KEY);
  }

  static getUpload(upload: GetUpload) {
    const uploads = UploadLocalStorageService.getUploads();

    if (uploads) {
      const parsedUploads = JSON.parse(uploads);
      return parsedUploads[uploadId(upload)];
    }

    return null;
  }
}

export default UploadLocalStorageService;
