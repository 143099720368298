import React, { ReactNode, useEffect, useRef, useState } from "react";
import { inject, observer } from "mobx-react";
import { STORE_NAMES } from "const/global";
import Viewer, { IPotreeViewerInput } from "views/viewer/Viewer";
import { IViewerStore } from "../../../../stores/ViewerStore";
import useModelViewer from "./hooks/useModelViewer";
import { useTranslation } from "react-i18next";
import useBreakpoint from "../../../shared/hook/useBreakpointHook";
import { IAppContext } from "../../../interfaces";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import Box, { BoxAlignedCenter } from "../../../shared/components/Box";
import ToggleButton from "./components/ModelViewerLayout/ToggleButton.component";
import ViewerCompanyIcon from "../../../viewer/components/ViewerCompanyIcon";
import ViewerViewControls from "../../../viewer/components/ViewerViewControls";
import ViewerObjectTree from "../../../viewer/containers/ViewerObjectTree";
import ViewerTexturePanel from "../../../viewer/components/ViewerTexturePanel";
import ViewerMeasurementsUnitPanel from "../../../viewer/components/ViewerMeasurementsUnitPanel";
import ModelAccessBlockedInfo from "./components/ModelAccessBlockedInfo.component";

interface IViewerPropTypes extends IPotreeViewerInput {
  accessBlocked?: boolean;
  allowRenewButton?: boolean;
  ViewerStore?: IViewerStore;
  sidepanel: ReactNode;
  informationBars: ReactNode;
  showMeasurementsUnitPanel: boolean;
  showObjectsTree: boolean;
  showTexturePanel: boolean;
}

const FULLSCREEN_CLOSE_ZINDEX = 4100;

const FullScreenCloseBtn = styled(Box)`
  font-size: 14px;
  color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
  z-index: ${FULLSCREEN_CLOSE_ZINDEX};
`;
const TopRight = styled.div`
  position: absolute;
  right: ${(p: IAppContext) => p.theme.skye.sizes.large};
  top: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;
const BottomLeft = styled.div`
  position: absolute;
  left: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;
const ViewerTopRightPanel = styled(Box)`
  position: absolute;
  right: 0;
  top: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  align-items: center;

  ${breakpoint("xl")`
    right: ${(p: IAppContext) => p.theme.skye.sizes.large};
  `}
`;
const BottomRightContent = styled.div`
  position: absolute;
  right: 20px;
  bottom: 20px;
  z-index: 1000;
`;

const Wrapper = styled(BoxAlignedCenter)`
  width: 100%;
  height: 100%;
`;
const ViewerWrapper = styled(Wrapper)`
  position: relative;
`;
const ToggleButtonWrapper = styled.div`
  top: 50%;
  left: -13px;
  transform: translateY(-50%);
  position: absolute;
  z-index: 1000;
`;
const BottomCenterContent = styled.div`
  height: 40px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border: 1px solid ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
  z-index: ${(p: IAppContext) => p.theme.skye.zIndex.viewerControls};
  position: absolute;
  bottom: 20px;
  left: 50%;
`;
const TopLeftWrapper = styled.div`
  z-index: 1000;
  position: absolute;
  left: 0px;
  top: 0px;
  padding: 12px;

  ${breakpoint("xl")`
    padding: 0;
    left: ${(p: IAppContext) => p.theme.skye.sizes.large};
    top: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  `}
`;

const ModelViewer = ({
  accessBlocked,
  allowRenewButton,
  model,
  measurements,
  ViewerStore,
  sidepanel,
  informationBars,
  showMeasurementsUnitPanel,
  showObjectsTree,
  showTexturePanel = true,
}: IViewerPropTypes) => {
  const [state, handlers] = useModelViewer({ ViewerStore, model });
  const [fullscreen, setFullscreen] = useState(false);
  const [viewerExpanded, setViewerExpanded] = useState(true);
  const showLoader = !state.viewerInitialized || !state.pointCloudLoaded;
  const loaded = state.viewerInitialized && state.pointCloudLoaded;
  const viewControlsWrapperRef = useRef(null);

  useEffect(() => {
    handlers.reset();

    return () => {
      if (state.viewerInitialized) {
        handlers.onDestroy();
      }
    };
  }, []);
  const { t } = useTranslation();
  const { breakpoint } = useBreakpoint();
  const desktopView = breakpoint("xl");

  const showTopRightPanel = showMeasurementsUnitPanel || showTexturePanel;

  const getViewControlsLeftOffset = (): string => {
    if (viewControlsWrapperRef.current) {
      return viewControlsWrapperRef.current.clientWidth / 2 + "px";
    }
    return 0 + "px";
  };

  const loadedContent = (
    <>
      {loaded && informationBars && (
        <TopLeftWrapper>
          {informationBars}
          {/* <TopLeftContent {...{ model, showPaidContent }} /> */}
        </TopLeftWrapper>
      )}
      {showTopRightPanel && (
        <ViewerTopRightPanel>
          {loaded && showTexturePanel && <ViewerTexturePanel model={model} />}
          {desktopView && showMeasurementsUnitPanel && (
            <ViewerMeasurementsUnitPanel />
          )}
        </ViewerTopRightPanel>
      )}
      {loaded && showObjectsTree && (
        <BottomRightContent>
          <ViewerObjectTree model={model} />
        </BottomRightContent>
      )}
      {fullscreen && (
        <TopRight>
          <FullScreenCloseBtn
            className="btn"
            onClick={() => setFullscreen(false)}
          >
            {t("close")}
          </FullScreenCloseBtn>
        </TopRight>
      )}
      <BottomCenterContent
        ref={viewControlsWrapperRef}
        style={{
          left: `calc(50% - ${getViewControlsLeftOffset()})`,
        }}
      >
        {loaded && !!viewControlsWrapperRef.current && (
          <ViewerViewControls
            fullScreen={!desktopView && fullscreen}
            onFullscreen={(value) => setFullscreen(value)}
          />
        )}
      </BottomCenterContent>
      <BottomLeft>
        <ViewerCompanyIcon />
      </BottomLeft>
    </>
  );

  return (
    <Wrapper>
      {desktopView && !accessBlocked && viewerExpanded && sidepanel}
      <ViewerWrapper>
        {desktopView && !accessBlocked && (
          <ToggleButtonWrapper>
            <ToggleButton
              onClick={() => setViewerExpanded(!viewerExpanded)}
              open={viewerExpanded}
            />
          </ToggleButtonWrapper>
        )}
        {accessBlocked ? (
          <ModelAccessBlockedInfo
            allowRenewButton={allowRenewButton}
            model={model}
          />
        ) : (
          <Viewer
            {...{
              model,
              measurements,
              showLoader: showLoader,
              onLoad: handlers.initialize,
              onMeasurementsSave: handlers.saveMeasurements,
              fullscreen,
            }}
            modelLoadedContent={loadedContent}
          />
        )}
      </ViewerWrapper>
    </Wrapper>
  );
};

export default inject(STORE_NAMES.ViewerStore)(observer(ModelViewer));
