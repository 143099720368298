import { memo, useCallback, useEffect, useState } from "react";
import { useDialog } from "../../../../shared/components/Dialog/useDialog";
import paymentUtils from "../../../payments/payments.utils";
import usePoll from "../../../../shared/hook/usePoll";
import ModelPaymenPollDialog from "../../components/ModelPaymenPollDialog";
import { ModelAccessType, Model } from "../../../../../models/Model";
import { MessageService } from "../../../../../services/MessageService";
import { useTranslation } from "react-i18next";

interface IModelDetailsPagePaymentPollDialogPropTypes {
  onSuccess: (model: Model) => void;
  getModelFunction: () => Promise<Model>;
  model: Model;
}

/**
 * Dialog finished to inform users that payment is being processed
 * and to poll the model until it is premium
 */
const ModelDetailsPagePaymentPollDialog = ({
  onSuccess,
  getModelFunction,
  model,
}: IModelDetailsPagePaymentPollDialogPropTypes) => {
  const { t } = useTranslation();
  const [initialized, setInitialized] = useState(false);
  const [failed, setFailed] = useState(false);

  const onPoll = useCallback(async () => {
    try {
      const updatedModel = await getModelFunction();
      if (updatedModel.accessType === ModelAccessType.Premium) {
        onSuccess(updatedModel);
        MessageService.success(t("paymentPollDialog.success"));
        poll.stopPolling();
        dialog.closeDialog();
      }
    } catch (error) {
      setFailed(true);
    }
  }, []);

  const onFinish = useCallback(() => {
    if (model.accessType !== ModelAccessType.Premium) {
      setFailed(true);
    }
  }, []);

  const startPolling = useCallback(() => {
    poll.startPolling();
    dialog.openDialog();
  }, []);

  const dialog = useDialog();
  const poll = usePoll({ onPoll, onFinish, interval: 3000, maxAttempts: 10 });

  useEffect(() => {
    if (!initialized) {
      setInitialized(true);

      if (
        paymentUtils.isPaymentRedirectSuccessRoute() &&
        model.accessType !== ModelAccessType.Premium
      ) {
        startPolling();
      }
    }
  }, []);

  return (
    <ModelPaymenPollDialog
      failed={failed}
      open={dialog.open}
      onClose={dialog.closeDialog}
    />
  );
};

export default memo(ModelDetailsPagePaymentPollDialog);
