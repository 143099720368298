import { useState } from "react";
import { ViewerBirdViewService } from "../services/ViewerBirdViewService";
import { ViewerService } from "../services/ViewerService";

export const useBirdView = () => {
  const [isActive, setIsActive] = useState(false);

  const activate = () => {
    setIsActive(true);
    ViewerBirdViewService.activate();
    new ViewerService().focusCanvas();
  };

  const deactivate = () => {
    setIsActive(false);
    ViewerBirdViewService.deactivate();
  };

  const toggle = () => {
    isActive ? deactivate() : activate();
  };

  return {
    toggle,
    isActive,
    activate,
    deactivate,
  };
};
