import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CheckoutForm, {
  CheckoutFormProps,
} from "./StripeCheckoutForm.component";
import { useEffect, useState } from "react";
import { getEnvVariables } from "../../../../env";

interface IStripeCheckout extends CheckoutFormProps {
  clientSecret: string;
}

const StripeCheckout = ({ clientSecret, ...props }: IStripeCheckout) => {
  const [stripePromise, setStripePromise] = useState(null);

  useEffect(() => {
    setStripePromise(loadStripe(getEnvVariables().STRIPE_PUBLISHABLE_KEY));
  }, []);

  const options = {
    // Pass the client secret obtained from the PaymentIntent
    clientSecret: clientSecret,
  };

  return (
    <>
      <Elements stripe={stripePromise} options={options}>
        <CheckoutForm {...props} />
      </Elements>
    </>
  );
};

export default StripeCheckout;
