import ModelViewer from "../../../containers/ModelViewer";
import {
  ModelAccessType,
  IPublicModel,
  ModelPermissionsType,
} from "../../../../../models/Model";
import { STORE_NAMES } from "../../../../../const/global";
import { inject, observer } from "mobx-react";
import { ModelAccessPolicy } from "../../../ModelAccessPolicy";
import GlobalStore, { IGlobalStore } from "../../../../../stores/GlobalStore";
import ModelViewerToolsSidePanel from "../../../containers/ModelViewer/components/ModelViewerToolsSidePanel";
import FreemiumAccessInfoBarComponent from "../../components/infobars/FreemiumAccessInfoBar.component";
import {
  ViewerScaleContext,
  ViewerScaleProvider,
} from "../../../../viewer/context/ViewerScaleContext";
import { ViewerPerspectiveProvider } from "../../../../viewer/context/ViewerPerspectiveContext";
import { useContext } from "react";
import GpsLogsNotAvailableInfoBarComponent from "../../components/infobars/GpsLogsNotAvailableInfoBar.component";
import ModelDetailsPagePaidAccessOverlayComponent from "../PaidAccessOverlay/ModelDetailsPagePaidAccessOverlay.component";
import { ViewerMeasuringToolsProvider } from "../../../../viewer/context/ViewerMeasuringToolsContext";
import { MODEL_LITE_SUBSCRIPTION_BLOCKED_MEASURING_TOOLS_IDS } from "../../pages.const";
import { ViewerVolumeToolsProvider } from "../../../../viewer/context/ViewerVolumeToolContext";
import { ViewerScreenshotToolProvider } from "../../../../viewer/context/ViewerScreenshotToolContext";
import { ViewerReportToolProvider } from "../../../../viewer/context/ViewerReportToolContext";
import { ViewerEventEmitterProvider } from "../../../../viewer/context/ViewerEventEmitter";

interface IViewerPropTypes {
  model: IPublicModel;
  GlobalStore?: IGlobalStore;
}

const ModelDetailsPageViewer = ({ model }: IViewerPropTypes) => {
  const { isRescaled } = useContext(ViewerScaleContext);
  const showNoGpsInfo = !model.gpsUploaded && !isRescaled;
  const modelAccess = ModelAccessPolicy.hasAccess(GlobalStore.user, model);

  return (
    <ModelViewer
      allowRenewButton
      accessBlocked={
        model.accessType === ModelAccessType.Archived ||
        model.accessType === ModelAccessType.Expired
      }
      showTexturePanel
      model={model}
      showMeasurementsUnitPanel={modelAccess}
      showObjectsTree={modelAccess}
      informationBars={
        <>
          {!modelAccess && (
            <div className="mb-2 hidden lg:block">
              <FreemiumAccessInfoBarComponent />
            </div>
          )}
          {modelAccess && showNoGpsInfo && (
            <div className="mb-2">
              <GpsLogsNotAvailableInfoBarComponent />
            </div>
          )}
        </>
      }
      sidepanel={
        <ModelViewerToolsSidePanel
          model={model}
          showPremiumContent={modelAccess}
          paymentOverlay={
            <ModelDetailsPagePaidAccessOverlayComponent model={model} />
          }
        />
      }
    />
  );
};

const ViewerWithContext = (props: IViewerPropTypes) => {
  const { GlobalStore, model } = props;

  const premiumAccess =
    ModelAccessPolicy.modelPermissionsType(
      GlobalStore.user,
      model.accessType,
      model.accessExpiresAt
    ) === ModelPermissionsType.Premium;

  return (
    <ViewerReportToolProvider blocked={!premiumAccess}>
      <ViewerScreenshotToolProvider blocked={!premiumAccess}>
        <ViewerVolumeToolsProvider blocked={!premiumAccess}>
          <ViewerMeasuringToolsProvider
            blockedTools={
              premiumAccess
                ? []
                : MODEL_LITE_SUBSCRIPTION_BLOCKED_MEASURING_TOOLS_IDS
            }
          >
            <ViewerScaleProvider>
              <ViewerPerspectiveProvider>
                <ViewerEventEmitterProvider>
                  <ModelDetailsPageViewer {...props} />
                </ViewerEventEmitterProvider>
              </ViewerPerspectiveProvider>
            </ViewerScaleProvider>
          </ViewerMeasuringToolsProvider>
        </ViewerVolumeToolsProvider>
      </ViewerScreenshotToolProvider>
    </ViewerReportToolProvider>
  );
};

export default inject(STORE_NAMES.GlobalStore)(observer(ViewerWithContext));
