import { memo } from "react";
import Dialog from "../../../shared/components/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import CircleLoader from "../../../shared/components/CircleLoader";

interface IModelPaymenPollDialogPropTypes {
  failed: boolean;
  open: boolean;
  onClose: () => void;
}

const ModelPaymenPollDialog = ({
  failed,
  open,
  onClose,
}: IModelPaymenPollDialogPropTypes) => {
  const { t } = useTranslation();
  return (
    <Dialog
      open={open}
      title={t("paymentPollDialog.title")}
      description={t("paymentPollDialog.description")}
      hideCancelButton={true}
      hideConfirmButton={true}
      onClose={onClose}
    >
      <div className="w-full-h-full flex items-center justify-center py-8">
        {failed ? (
          <div className="flex flex-col items-center justify-center">
            <img height={60} src="/images/empty_list.svg" alt="error" />
            <h3 className="text-center mt-8 text-lg font-medium mt-2">
              {t("paymentPollDialog.failed")}
            </h3>
          </div>
        ) : (
          <CircleLoader size={60} color="primary" />
        )}
      </div>
    </Dialog>
  );
};

export default memo(ModelPaymenPollDialog);
