import httpService from "services/HttpService";
import API from "const/api";

const isValid = (coupon: string) => {
  const url = API.couponValid;
  return httpService.post(url, { coupon });
}

const couponRequests = {
  isValid,
};

export default couponRequests;
