import axios from "axios";
import { getEnvVariables, isProduction } from "../../../env";
import { FacebookAnalyticUtils } from "./utils";

interface UserConversion {
  customerEmail: string;
  customerFirstName: string;
  customerLastName: string;
}

interface FormattedUserData {
  em: string;
  fn: string;
  ln: string;
}

interface PaymentData {
  currency: "USD" | "EUR" | "GBP";
  value: number;
}

const ACCESS_KEY = getEnvVariables().FACEBOOK_CONVERSION_API_KEY;
const PIXEL_ID = getEnvVariables().FACEBOOK_PIXEL_ID;

const facebookUrl = (pixelId: string) =>
  `https://graph.facebook.com/v19.0/${pixelId}/events`;

const formatConversionData = async (
  data: UserConversion
): Promise<FormattedUserData> => {
  const [em, fn, ln] = await Promise.all([
    await FacebookAnalyticUtils.hashValue(data.customerEmail),
    await FacebookAnalyticUtils.hashValue(data.customerFirstName),
    await FacebookAnalyticUtils.hashValue(data.customerLastName),
  ]);

  return {
    em,
    fn,
    ln,
  };
};

export type ConversionEventType =
  | "Purchase"
  | "AddToCart"
  | "CompleteRegistration";

export class FacebookConversionApi {
  static async sendEvent(
    eventName: ConversionEventType,
    userData: UserConversion,
    paymentData?: PaymentData
  ) {
    if (!isProduction()) {
      console.log("Facebook Conversion API is disabled in non-production env");
      return;
    }

    if (!ACCESS_KEY) {
      console.warn("Facebook Conversion API key is not set");
      return;
    }

    const data = {
      event_name: eventName,
      event_time: Math.floor(Date.now() / 1000),
      action_source: "website",
      user_data: await formatConversionData(userData),
    };

    if (paymentData) {
      data["custom_data"] = {
        currency: paymentData.currency,
        value: paymentData.value,
      };
    }

    return axios.post(
      facebookUrl(PIXEL_ID),
      { data: [data] },
      {
        headers: { "Content-Type": "application/json" },
        params: { access_token: ACCESS_KEY },
      }
    );
  }
}
