import React from 'react'

interface IUserIconPropTypes {
  
}

const UserIcon = ({
  
}: IUserIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M10.1665 8.66668C10.1665 5.44502 12.7782 2.83334 15.9998 2.83334C19.2215 2.83334 21.8332 5.44502 21.8332 8.66668C21.8332 11.8883 19.2215 14.5 15.9998 14.5C12.7782 14.5 10.1665 11.8883 10.1665 8.66668ZM15.9998 3.83334C13.3305 3.83334 11.1665 5.9973 11.1665 8.66668C11.1665 11.3361 13.3305 13.5 15.9998 13.5C18.6692 13.5 20.8332 11.3361 20.8332 8.66668C20.8332 5.9973 18.6692 3.83334 15.9998 3.83334ZM4.1665 24.6667C4.1665 19.9723 7.97208 16.1667 12.6665 16.1667H19.3332C24.0276 16.1667 27.8332 19.9723 27.8332 24.6667V28.6667C27.8332 28.9428 27.6093 29.1667 27.3332 29.1667C27.057 29.1667 26.8332 28.9428 26.8332 28.6667V24.6667C26.8332 20.5245 23.4753 17.1667 19.3332 17.1667H12.6665C8.52437 17.1667 5.1665 20.5245 5.1665 24.6667V28.6667C5.1665 28.9428 4.94265 29.1667 4.6665 29.1667C4.39036 29.1667 4.1665 28.9428 4.1665 28.6667V24.6667Z" fill="#1A1A1A"/>
    </svg>
  )
}

export default UserIcon;
