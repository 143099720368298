import React, { useState } from "react";
import styled from "styled-components";
import { IPublicModel, Model } from "models/Model";
import { useTranslation } from "react-i18next";
import DateComponent from "../../shared/components/Date";
import Box from "../../shared/components/Box";
import { IAppContext } from "../../interfaces";
import InformationIconRow, {
  IconType,
} from "../../shared/components/InformationIconRow";
import { getLocationString } from "utils/stringUtils";
import Description from "../../shared/typography/Description";
import LightButton from "../../shared/buttons/LightButton";
import ModelService from "views/models/services/ModelService";
import GlobalStore from "stores/GlobalStore";
import { getResponseErrorTranslation } from "utils/globalUtils";
import TagEditContainer from "./TagEditContainer";
import { TagService } from "services/TagService";
import {
  AnalyticService,
  AnalyticEventCategory,
} from "services/AnalyticService";
import InputGroup from "../../shared/form/InputGroup";
import ModelVideo from "../components/ModelVideo";

interface IModelGeneralDetailsSectionContainerPropTypes {
  model: IPublicModel;
  editable: boolean;
  onModelChange: (model: Model) => void;
}

const SaveButtonRow = styled(Box)`
  padding-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;

const ShortInformationRow = styled.div`
  margin: ${(p: IAppContext) => p.theme.skye.sizes.medium} 0;
`;

const Address = styled(Description)`
  margin-top: 5px;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;

const SaveButton = styled(LightButton)`
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;

const AddressRow = styled.div`
  .information-icon-row {
    align-items: flex-start;
  }
`;

interface IModelForm {
  shortDescription: string;
  name: string;
}

const TagsWrapper = styled.div`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

const VideoWrapper = styled.div`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

const ModelGeneralDetailsSectionContainer = (
  props: IModelGeneralDetailsSectionContainerPropTypes
) => {
  const { t } = useTranslation();
  const { onModelChange, model, editable } = props;
  const [loading, setLoading] = useState(false);
  const [form, setForm] = useState<IModelForm>({
    shortDescription: model.shortDescription || "",
    name: model.name || "",
  });
  const [tags, setTags] = useState<string[]>(model?.tags || []);
  const { shortDescription } = form;

  const handleSave = () => {
    const { shortDescription, name } = form;
    const data = {
      shortDescription: String(shortDescription),
      name: String(name),
      tags: TagService.filterDefaultTags(tags).map((tag) =>
        TagService.formatTag(tag)
      ),
    };

    setLoading(true);
    AnalyticService.event(
      AnalyticEventCategory.ModelDetailsPage,
      "save_details"
    );

    ModelService.patchModel(model.id, data)
      .then((res) => {
        const model = res.data as Model;
        onModelChange(model);
        GlobalStore.addToast(t("modelSaved"));
      })
      .catch((err) => {
        GlobalStore.addToast(t(getResponseErrorTranslation(err)));
      })
      .finally(() => setLoading(false));
  };

  const onTagsChange = (newTags: string[]) => {
    setTags(newTags);
    ModelService.patchModel(model.id, { tags: newTags });
  };
  return (
    <div className="">
      <ShortInformationRow>
        <VideoWrapper>
          <ModelVideo model={model} />
        </VideoWrapper>
        {model.location && (
          <AddressRow>
            <InformationIconRow iconType={IconType.Gps}>
              <>
                {!!model.address && <Address>{model.address}</Address>}
                {getLocationString(model.location.point)}
              </>
            </InformationIconRow>
          </AddressRow>
        )}
        <InformationIconRow iconType={IconType.Clock}>
          <DateComponent>{model.created}</DateComponent>
        </InformationIconRow>
      </ShortInformationRow>
      {editable && (
        <div>
          <TagsWrapper>
            <TagEditContainer
              selectedTags={tags || []}
              onChange={onTagsChange}
            />
          </TagsWrapper>
          <InputGroup
            bordered
            inputProps={{
              value: shortDescription || "",
              placeholder: "descriptionPlaceholder",
              onChange: (e: any) =>
                setForm({ ...form, shortDescription: e.target.value }),
            }}
          />
        </div>
      )}
      {editable && (
        <SaveButtonRow>
          <SaveButton loading={loading} onClick={handleSave}>
            {t("save")}
          </SaveButton>
        </SaveButtonRow>
      )}
    </div>
  );
};

export default ModelGeneralDetailsSectionContainer;
