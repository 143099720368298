import { makeAutoObservable } from "mobx";
import validate from "mobx-form-validate";
import { PASSWORD_PATTERN } from "../../shared/utils/validationUtils";

export default class ResetPasswordForm {
  constructor() {
    makeAutoObservable(this);
  }

  @validate(PASSWORD_PATTERN, "validationErrorPassword")
  newPassword = "";

  @validate((value, that) => {
    if (value !== that.newPassword)
      return "validationErrorRepeatPasswordDifferent";
    if (!PASSWORD_PATTERN.test(value)) return "validationErrorPassword";
    return undefined;
  })
  newPasswordRepeat = "";

  submitted: boolean = false;

  // Bind this for future use.
  submit = () => {
    this.submitted = true;
  };
}
