import {
  AnalyticEventCategory,
  AnalyticService,
  EventActionType,
} from "../../../services/AnalyticService";
import moment from "moment";
import { IPublicModel } from "../../../models/Model";

type ModelOpenedAnalyticCategory =
  | AnalyticEventCategory.ModelDetailsPage
  | AnalyticEventCategory.PublicModelDetailsPage;

export class ModelAnalyticService extends AnalyticService {
  static modelOpened = (
    model: IPublicModel,
    isModelView: boolean,
    category: ModelOpenedAnalyticCategory
  ) => {
    const currentDate = moment(new Date());
    const modelDate = moment(model?.created);
    const diff = currentDate.diff(modelDate, "days");
    let timeLabel = "";
    let viewLabel = isModelView ? "model" : "map";

    if (diff) {
      if (diff < 30) {
        this.event(category, "Created under 30 days", EventActionType.Load);
      } else if (diff >= 30 && diff < 60) {
        timeLabel = "Created between 30-60 days";
      } else if (diff >= 60 && diff < 90) {
        timeLabel = "Created between 60-90 days";
      } else {
        timeLabel = "Created more than 90 days";
      }
    }

    this.event(category, `loaded_${viewLabel}_view`, EventActionType.Load);
    this.event(category, timeLabel, EventActionType.Load);
  };
}
