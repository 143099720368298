import { memo } from "react";
import styles from "./PremiumBadge.module.css";
import { PremiumStarIcon } from "../../../Icons/PremiumStarIcon";

interface IPremiumBadgePropTypes {
  children: string;
}

const PremiumBadge = ({ children }: IPremiumBadgePropTypes) => {
  return (
    <div className={styles.badge}>
      <div className="mr-1 w-2 h-2">
        <PremiumStarIcon />
      </div>
      <div className="flex items-center">{children}</div>
    </div>
  );
};

export default memo(PremiumBadge);
