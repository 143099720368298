import { useContext } from "react";
import { ModelPaymentContext } from "./ModelPayment.context";
import { ModelAccessType } from "../../../models/Model";

export const useModelPayment = () => {
  const ctx = useContext(ModelPaymentContext);
  if (!ctx) {
    throw new Error("useModelPayment must be used within ModelPaymentProvider");
  }

  const { dialog } = ctx;

  const openWidget = (modelId: string, modelAccessType: ModelAccessType) => {
    dialog.openDialog(modelId, modelAccessType);
  };

  const closeWidget = () => {
    dialog.closeDialog();
  };

  return {
    openWidget,
    closeWidget,
  };
};
