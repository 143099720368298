import { useEffect, useState } from "react";
import ModelService, { ModelCanUpload } from "../services/ModelService";

interface UseCanUploadReturn {
  canUpload: boolean | undefined;
  currentModelsCount: number | undefined;
  maxModelsCount: number | undefined;
  loaded: boolean;
}

const useCanUpload = (): UseCanUploadReturn => {
  const [data, setData] = useState<ModelCanUpload>();
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    const init = () => {
      ModelService.canUpload().then((response) => {
        setData(response.data);
        setLoaded(true);
      });
    };

    init();
  }, []);

  return {
    canUpload: data?.canUpload,
    currentModelsCount: data?.currentCount,
    maxModelsCount: data?.maxCount,
    loaded,
  };
};

export default useCanUpload;
