import { memo } from "react";
import Button from "../../../shared/buttons/Button";
import { useTranslation } from "react-i18next";
import InformationBox from "../../../shared/components/InformationBox/InformationBox";
import styles from "./OrthophotoPreview.module.css";

export interface IOrthophotoPreviewPropTypes {
  name: string;
  exists: boolean;
  imgSrc: string;
  loading?: boolean;
  showDownload?: boolean;
  onDownload: () => void;
}

const OrthophotosNotAvailable = () => {
  const { t } = useTranslation();

  return (
    <div className={styles.orthophotoPreview}>
      <InformationBox
        icon="error_2"
        transparent
        title={t("models.orthophotoNotAvailable")}
        description={t("models.orthophotoNotAvailableDescription")}
      />
    </div>
  );
};

const OrthophotoPreview = ({
  name,
  exists,
  imgSrc,
  loading,
  showDownload = true,
  onDownload,
}: IOrthophotoPreviewPropTypes) => {
  const { t } = useTranslation();

  if (!exists) {
    return <OrthophotosNotAvailable />;
  }

  return (
    <div className={styles.orthophotoPreview}>
      <div className="preview-name absolute left-4 top-4 text-gray-500 text-sm">
        {name}
      </div>
      <div className="company-logotype absolute left-4 bottom-4">
        <img
          className="h-10 w-10 opacity-50"
          src="/viewer/images/logo_dark.svg"
          alt="skyebrowse_logotype"
        />
      </div>
      {exists && showDownload && (
        <div className="absolute right-4 bottom-4 download-btn-wrapper">
          <Button
            size="small"
            type="bordered"
            onClick={onDownload}
            disabled={loading}
            loading={loading}
            loaderProps={{ color: "dark" }}
          >
            {t("download")}
          </Button>
        </div>
      )}
      <div className={styles["orthophoto-wrapper"]}>
        {exists && (
          <div
            className={styles["ortho-img"]}
            style={{
              backgroundImage: `url('${imgSrc}')`,
            }}
          />
        )}
      </div>
    </div>
  );
};

export default memo(OrthophotoPreview);
