import { useTranslation } from "react-i18next";
import {
  IModelExpirationMap,
  ModelAccessType,
  ModelStatus,
} from "../../../models/Model";
import { useMemo } from "react";
import { differenceInDays } from "date-fns";

interface IProps {
  modelStatus: ModelStatus;
  expirationInfo: IModelExpirationMap;
  accessType: ModelAccessType;
}

export const useModelExpirationTimeLeft = ({
  expirationInfo,
  accessType,
  modelStatus,
}: IProps) => {
  const { t } = useTranslation();

  const expirationLabel = useMemo(() => {
    const now = new Date();

    if (accessType === ModelAccessType.Premium) {
      return "";
    }

    if (modelStatus === ModelStatus.Archived) {
      return t("modelDeepArchivedLabel");
    }

    if (accessType === ModelAccessType.Expired) {
      const daysToExpire = -differenceInDays(
        new Date(),
        new Date(expirationInfo[ModelAccessType.Archived])
      );

      if (daysToExpire <= 0) {
        return t("modelArchivedLabel");
      }

      return t("modelArchiveLabel", {
        formattedDate: daysToExpire,
      });
    }

    if (accessType !== ModelAccessType.Preview) {
      console.warn(`Access type not handled: ${accessType}`);
      return "";
    }

    const premiumExpiration = new Date(expirationInfo[ModelAccessType.Premium]);
    const isPremium = premiumExpiration > now;
    if (isPremium) return "";

    const previewExpiration = new Date(expirationInfo[ModelAccessType.Preview]);
    const isPreview = previewExpiration > now;
    if (isPreview) {
      const daysToExpire = -differenceInDays(new Date(), previewExpiration);

      if (!daysToExpire) return t("modelExpireTodayLabel");
      if (daysToExpire === 1) return t("modelExpireTomorrowLabel");
      return t("modelExpireLabel", {
        formattedDate: daysToExpire,
      });
    }

    const expirationDate = new Date(expirationInfo[ModelAccessType.Expired]);
    const isExpired = expirationDate > now;
    if (isExpired) {
      const daysToExpire = -differenceInDays(new Date(), previewExpiration);

      if (!daysToExpire) return t("modelArchiveTodayLabel");
      if (daysToExpire === 1) return t("modelArchiveTomorrowLabel");
      return t("modelArchiveLabel", {
        formattedDate: -differenceInDays(new Date(), expirationDate),
      });
    }

    const archivedDate = new Date(expirationInfo[ModelAccessType.Archived]);
    const isTemporaryArchived = archivedDate > now;
    if (isTemporaryArchived) {
      return t("modelArchivedLabel");
    }

    const isDeepArchived = archivedDate < now;
    if (isDeepArchived) {
      return t("modelDeepArchivedLabel");
    }

    return "";
  }, [expirationInfo, accessType, modelStatus, t]);

  return {
    expirationLabel,
  };
};
