import { getEnvVariables } from "env";
import { INFORMATION_ROUTES } from "views/routes";
import { VideoTutorialPageData } from "interfaces/global";
import { SubscriptionType } from "../views/payments/payments.model";

// Authentication
export const JWT_STORAGE_NAME = "skyebrowse_token";
export const CURRENT_USER_STORAGE_NAME = "sb_current_user";
export const JWT_SESSION_STORAGE_NAME = "skyebrowse_token";
export const HEADER_AUTHORIZATION_KEY = "authorization";
export const JWT_BEARER_KEY = "Bearer";

export enum SkyebrowseEmails {
  Sales = "sales@skyebrowse.com",
}
export const FREEMIUM_EMAIL_SUBJECT = "I would like to purchase a subscription";

export const STORE_NAMES = {
  ViewerStore: "ViewerStore",
  GlobalStore: "GlobalStore",
  VideoUploadStore: "VideoUploadStore",
  SessionViewerPageStore: "SessionViewerPageStore",
  ModelDetailsPageStore: "ModelDetailsPageStore",
  OrganizationStore: "OrganizationStore",
  SessionStreamStore: "SessionStreamStore",
};

// Models
export const MODEL_VIDEO_FILE_EXTENSIONS = ["video/mp4", "video/quicktime"];
export const GPS_LOGS_FILE_EXTENSIONS = [
  "text/plain",
  "srt",
  "SRT",
  "txt",
  "TXT",
  "CSV",
  "csv",
];
const GB_MULTIPLIER = Math.pow(1024, 3);
const FREEMIUM_MAX_UPLOAD_SIZE = 2 * GB_MULTIPLIER;
const LITE_MAX_UPLOAD_SIZE = 5 * GB_MULTIPLIER;
const PREMIUM_MAX_UPLOAD_SIZE = 10 * GB_MULTIPLIER;

export const UPLOAD_SIZE_PER_SUBSCRIPTION: Record<SubscriptionType, number> = {
  Freemium: FREEMIUM_MAX_UPLOAD_SIZE,
  Lite: LITE_MAX_UPLOAD_SIZE,
  Premium: PREMIUM_MAX_UPLOAD_SIZE,
};

export const SCREENSHOT_FILENAME = "skyebrowse_screenshot";

export const PRICING_PAGE_URL = "https://www.skyebrowse.com/pricing";
export const PRICING_REQUEST_QUOTE_URL =
  "https://www.skyebrowse.com/pricing#SectionAboutQuote";

export const MAP_URLS = {
  publicModelDetailsPage: `${getEnvVariables().MAP_URL}model/public/:publicKey`,
};
export const PRIVACY_POLICY_URL = "https://www.skyebrowse.com/privacy";
export const _URL = "https://www.skyebrowse.com/privacy";

export const TUTORIAL_PAGES: VideoTutorialPageData[] = [
  {
    route: INFORMATION_ROUTES.introduction,
    title: "introductionInfoPageTitle",
    description: "",
    videoUrl: "https://www.youtube.com/embed/AqfjDKP1hNo",
  },
  {
    route: INFORMATION_ROUTES.cardNotShowingUp,
    title: "modelNotShowingUpTitle",
    description: "",
    videoUrl: "https://www.youtube.com/embed/8UQV7fsRh_M",
  },
  {
    route: INFORMATION_ROUTES.batchUpload,
    title: "batchUploadTitle",
    description: "",
    videoUrl: "https://www.youtube.com/embed/rr8qJk71wKY",
  },
  {
    route: INFORMATION_ROUTES.emergencyUpload,
    title: "emergencyUploadTitle",
    description: "emergencyUploadInstruction",
    videoUrl: "https://www.youtube.com/embed/OnSAZ61eIpk",
  },
  {
    route: INFORMATION_ROUTES.measurements,
    title: "measurementsInfoTitle",
    description: "",
    videoUrl: "https://www.youtube.com/embed/I5skEgd5u9Q",
  },
  {
    route: INFORMATION_ROUTES.advancedDashboardTools,
    title: "advancedDashboardToolsTitle",
    description: "",
    videoUrl: "https://www.youtube.com/embed/NANccLyb8oA",
  },
  {
    route: INFORMATION_ROUTES.offlineMode,
    title: "offlineModeTitle",
    description: "",
    videoUrl: "https://www.youtube.com/embed/8zg5wqsjo0E",
  },
];

/**
 * This properties are used to redirect and handle correct tab when
 * changing page to model details page
 */
export const MODEL_DETAILS_PAGE_TAB_PATH_KEY = "tab";
export enum ModelDetailsPageTabKeys {
  General = "general",
  Video = "video",
  FlightDetails = "flightDetails",
  Download = "download",
  Weather = "weather",
  EmergencyUpload = "emergencyUpload",
}

export const MODEL_PAGE_VIEW_TYPE_PARAM_NAME = "viewType";
export enum ModelPageViewTypes {
  Map = "map",
  Model = "model",
  Ortho = "ortho",
}

export const SESSION_PAGE_VIEW_TYPE_PARAM_NAME = "viewType";
export enum SessionPageViewTypes {
  Map = "map",
  Model = "model",
}
