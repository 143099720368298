export default class BasementAngle {
  static add() {
    window.viewer?.measuringTool.startInsertion({
      showDistances: false,
      showArea: false,
      showAngles: true,
      showEdges: true,
      showHeight: true,
      closed: true,
      maxMarkers: 2,
      name: "BasementAngle",
    });
  }
}
