import React from 'react'
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";
import Button from '../buttons/Button';
import { Textarea } from '../form/Input';
import Box from './Box';

const MIN_LENGTH_TO_SEND = 1;

interface ICommentInputPropTypes {
  value: string,
  onSend: () => void,
  onChange: (newValue: string) => void,
}

const Wrapper = styled(Box)`
  position: relative;
  width: 100%;
`;
const SkyeInput = styled(Textarea)`
  min-height: auto;
  height: 54px;
  width: 100%;
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  padding-right: 75px;
  padding-top: 18px;
  padding-bottom: 10px;
  resize: none;
`;
const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  right: 8px;
  transform: translateY(-50%);
`;

const CommentInput = ({
  value,
  onSend,
  onChange
}: ICommentInputPropTypes) => {
  const { t } = useTranslation();
  const handleInputChange = (e: any) => {
    onChange(e.target.value);
  }
  const canAddComment = value && value.trim().length >= MIN_LENGTH_TO_SEND;

  const send = () => {
    if (canAddComment) {
      onSend();
    }
  }

  const handleKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      send();
    }
  }

  return (
    <Wrapper>
      <SkyeInput
        value={value}
        onKeyDown={handleKeyDown}
        onChange={handleInputChange}
        placeholder={t('commentInputPlaceholder')}
      />
      <ButtonWrapper>
        <Button
          disabled={!canAddComment}
          size="small"
          onClick={onSend}
        >{t('send')}</Button>
      </ButtonWrapper>
    </Wrapper>
  )
}

export default CommentInput;
