import React, { ReactNode, useState } from 'react'
import { Dropdown } from 'antd';
import styled from 'styled-components';
import { IAppContext } from "../../../interfaces";
import { DropDownProps } from "antd/lib/dropdown";

interface IDropdownPropTypes extends DropDownProps {
  children: ReactNode;
  overlay: any;
  trigger?: ("click" | "hover" | "contextMenu")[];
  onChange: () => void;
}

interface DropdownWrapperPropTypes {
  children: ReactNode;
}

const Wrapper = styled.div`
  box-shadow: 0px 0px 4px rgba(51, 51, 51, 0.08),
   
    0px 14px 24px rgba(51, 51, 51, 0.125);
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  overflow: hidden;
  border-top: 2px solid
   
    ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
`;

export const DropdownWrapper = ({ children }: DropdownWrapperPropTypes) => {
  return <Wrapper className="dropdown-wrapper">{children}</Wrapper>;
};

const SkyeDropdown = ({
  onChange,
  children,
  overlay,
  trigger,
  ...props
}: IDropdownPropTypes) => {
  const [visible, setVisible] = useState(false);

  return (
    <Dropdown
      {...props}
      trigger={trigger}
      className="skye-dropdown"
      open={visible}
      onOpenChange={(newOpen) => {
        setVisible(newOpen);
        onChange();
      }}
      overlayStyle={{ zIndex: 4300 }}
      overlay={<div onClick={() => setVisible(false)}>{overlay}</div>}
    >
      {children}
    </Dropdown>
  );
};

export default SkyeDropdown;
