import FileSaver from "file-saver";
import JSZip from "jszip";

export interface IDownloadableFile {
  blob: Blob;
  fileName: string;
}

export class FileService {
  static downloadPdf(blob: Blob, fileName: string): void {
    var link = document.createElement("a");
    link.href = window.URL.createObjectURL(blob);
    link.download = `Report.pdf`;
    link.click();
  }

  static saveImage(
    base64String: string,
    fileName: string,
    imageFormat = "jpg"
  ) {
    FileSaver.saveAs(base64String, `${fileName}.${imageFormat}`);
  }

  static downloadPngFile(base64String: string, fileName: string) {
    FileSaver.saveAs(base64String, `${fileName}.png`);
  }

  static downloadJpgFile(base64String: string, fileName: string) {
    FileSaver.saveAs(base64String, `${fileName}.jpg`);
  }

  static async downloadFilesAsZip(
    files: IDownloadableFile[],
    folderName: string
  ): Promise<boolean> {
    return new Promise((res, rej) => {
      const zip = new JSZip();
      const imageFolder = zip.folder(folderName);

      files.forEach((file) => {
        imageFolder.file(file.fileName, file.blob);
      });

      zip
        .generateAsync({ type: "blob" })
        .then((content) => {
          FileSaver.saveAs(content, `${folderName}.zip`);
          res(true);
        })
        .catch((err) => rej(err));
    });
  }
}
