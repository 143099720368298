import { inject, observer } from "mobx-react";
import React from "react";
import { STORE_NAMES } from "../../../../../const/global";
import { IPublicModel } from "../../../../../models/Model";
import { IGlobalStore } from "../../../../../stores/GlobalStore";
import ModelDownloadButtonWrapperComponent from "../../../components/ModelDownloadButtonWrapper.component";
import ModelPaidActionButtonComponent from "../../../payments/ModelPaidActionButton.component";
import { ModelAccessPolicy } from "../../../ModelAccessPolicy";
import { useDownloadGeoTiff } from "../../../hooks/download/useDownloadGeoTiff";
import { useDownloadObjFile } from "../../../hooks/download/useDownloadObjFile";
import { useDownloadLazFile } from "../../../hooks/download/useDownloadLazFile";

interface IDownloadSectionPropTypes {
  GlobalStore?: IGlobalStore;
  model: IPublicModel;
}

const ModelDetailsPageDownloadSection = ({
  GlobalStore,
  model,
}: IDownloadSectionPropTypes) => {
  const hasAccess = ModelAccessPolicy.canDownloadAssets(
    GlobalStore?.user,
    model
  );
  const isAccessible = ModelAccessPolicy.isAccesible(model);

  const useDownloadProps = { model, hasAccess };
  const obj = useDownloadObjFile(useDownloadProps);
  const laz = useDownloadLazFile(useDownloadProps);
  const geoTiff = useDownloadGeoTiff(useDownloadProps);

  return (
    <div className="grid gap-8">
      <ModelDownloadButtonWrapperComponent
        model={model}
        titleDictionary="downloadLazFile"
        descriptionDictionary="downloadLazFileDescription"
        alertDictionary="lazFileNotAvailable"
        showAlert={!laz.isAvailable}
      >
        <ModelPaidActionButtonComponent
          model={model}
          hasPremiumAccess={hasAccess && isAccessible}
          buttonProps={{
            disabled: (!laz.isAvailable && isAccessible) || laz.loading,
            loading: laz.loading,
          }}
          onClick={laz.handleDownload}
        />
      </ModelDownloadButtonWrapperComponent>
      <ModelDownloadButtonWrapperComponent
        model={model}
        titleDictionary="downloadObjFile"
        descriptionDictionary="downloadObjFileDescription"
        alertDictionary="objFileNotAvailable"
        showAlert={!obj.isAvailable}
      >
        <ModelPaidActionButtonComponent
          model={model}
          hasPremiumAccess={hasAccess && isAccessible}
          onClick={obj.handleDownload}
          buttonProps={{
            disabled: (!obj.isAvailable && isAccessible) || obj.loading,
            loading: obj.loading,
          }}
        />
      </ModelDownloadButtonWrapperComponent>
      <ModelDownloadButtonWrapperComponent
        model={model}
        titleDictionary="downloadTiffFile"
        descriptionDictionary="downloadTiffFileDescription"
        alertDictionary="fileNotAvailable"
        showAlert={false}
      >
        <ModelPaidActionButtonComponent
          model={model}
          hasPremiumAccess={hasAccess && isAccessible}
          onClick={geoTiff.handleDownload}
          buttonProps={{
            disabled: (!geoTiff.isAvailable && isAccessible) || geoTiff.loading,
            loading: geoTiff.loading,
          }}
        />
      </ModelDownloadButtonWrapperComponent>
    </div>
  );
};

export default inject(STORE_NAMES.GlobalStore)(
  observer(ModelDetailsPageDownloadSection)
);
