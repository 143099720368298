import React, { DOMAttributes, useState } from 'react'
import styled from 'styled-components';
import { ITranslationFields } from 'locale/dictionary';
import { useTranslation } from 'react-i18next';
import { IAppContext } from "../../interfaces";
import classNames from 'classnames';
import Box from '../components/Box';
import Input from './Input';
import Label from '../typography/Label';

interface IInputProps
  extends DOMAttributes<HTMLInputElement | HTMLTextAreaElement> {
  ref?: any;
  type?: string;
  name?: string;
  placeholder?: ITranslationFields | string;
  value: string;
  id?: string;
}

interface IInputGroupPropTypes {
  label?: ITranslationFields;
  invalid?: boolean;
  invalidMessage?: string;
  disabled?: boolean;
  inputProps: IInputProps;
  fullWidth?: boolean;
  bordered?: boolean;
  endIcon?: any;
  filled?: boolean;
  required?: boolean;
  CustomInput?: any; // Custom input component for example: textarea
  onEndIconClick?: () => void;
}

export const RequiredStar = styled.span`
  margin-left: 0.5em;
  color: ${(p: IAppContext) => p.theme.skye.colors.redOrange};
`;

const InputWrapper = styled.div`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border: 1px solid ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  overflow: hidden;
  position: relative;

  &.focused {
    box-shadow: ${(p: IAppContext) => p.theme.skye.shadows.inputFocused};
    border: 1px solid ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
  }
`;
const SkyeInput = styled(Input)``;
const StyledInputGroup = styled.div`
  width: 100%;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};

  &.bordered {
    ${InputWrapper} {
      border-radius: 4px;
      border: ${(p: IAppContext) => p.theme.skye.borders.primary};

      &.focused {
        box-shadow: ${(p: IAppContext) => p.theme.skye.shadows.inputFocused};
        border: 1px solid
          ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
      }
    }
  }

  &.disabled {
    ${InputWrapper} {
      input {
        cursor: not-allowed;
      }

      &.focused {
        box-shadow: none;
        border: ${(p: IAppContext) => p.theme.skye.borders.primary};
      }
    }
  }

  &.filled {
    ${InputWrapper} {
      background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
    }
  }
`;

const EndIconWrapperOutter = styled(Box)`
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  position: absolute;
  right: 0;
  top: 0;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  cursor: pointer;

  svg {
    path {
      stroke: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    }
  }
`;
const EndIconWrapperInner = styled(Box)`
  width: 16px;
  height: 16px;
  align-items: center;
  justify-content: center;
`;
const ErrorText = styled.div`
  color: ${(p: IAppContext) => p.theme.skye.colors.danger};
  font-size: 12px;
  margin-top: 2px;
`;

/**
 * Callback function to pass setState function
 * @param callback
 */
export const onChangeHandler =
  (callback: (value: string) => void) => (e: any) => {
    callback(e.target.value);
  };

const InputGroup = ({
  label,
  invalid = false,
  invalidMessage = "",
  disabled = false,
  inputProps,
  endIcon,
  fullWidth,
  filled,
  bordered = false,
  required,
  CustomInput,
  onEndIconClick = () => {},
}: IInputGroupPropTypes) => {
  const { t } = useTranslation();
  const [focused, setFocused] = useState(false);
  const ChoosenInput = CustomInput || SkyeInput;

  return (
    <StyledInputGroup
      className={classNames([
        "input-group",
        { disabled, fullWidth, bordered, filled },
      ])}
    >
      {Boolean(label) && (
        <Label>
          {t(label)}
          {required && <RequiredStar>*</RequiredStar>}
        </Label>
      )}
      <InputWrapper className={classNames(["input-wrapper", { focused }])}>
        <ChoosenInput
          {...inputProps}
          disabled={disabled}
          placeholder={t(inputProps.placeholder)}
          onFocus={(e: any) => {
            setFocused(true);
            inputProps.onFocus && inputProps.onFocus(e);
          }}
          onBlur={(e: any) => {
            setFocused(false);
            inputProps.onBlur && inputProps.onBlur(e);
          }}
        />
        {Boolean(endIcon) && (
          <EndIconWrapperOutter
            onClick={onEndIconClick}
            className="end-icon-wrapper"
          >
            <EndIconWrapperInner>{endIcon}</EndIconWrapperInner>
          </EndIconWrapperOutter>
        )}
      </InputWrapper>
      {Boolean(invalid && invalidMessage) && (
        <ErrorText>{invalidMessage}</ErrorText>
      )}
    </StyledInputGroup>
  );
};

export default InputGroup;
