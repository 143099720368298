import React from 'react'
import styled from 'styled-components';

interface IFacebookIconPropTypes {
  
}

const FacebookIcon = ({
  
}: IFacebookIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 28V7.42857C16 5.53502 17.535 4 19.4286 4H22M10 14.2857H22" stroke="#1A1A1A"/>
    </svg>
  )
}

export default FacebookIcon;
