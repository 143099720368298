import { createContext, useState } from "react";
import { IAnnotation } from "../interfaces";

interface IViewerAnnotationsContext {
  annotations: IAnnotation[];
  setAnnotations: (annotations: IAnnotation[]) => void;
}

export const ViewerAnnotationsContext =
  createContext<IViewerAnnotationsContext>(null);

export const ViewerAnnotationsProvider = ({ children }) => {
  const [annotations, setAnnotations] = useState<IAnnotation[]>([]);

  return (
    <ViewerAnnotationsContext.Provider value={{ annotations, setAnnotations }}>
      {children}
    </ViewerAnnotationsContext.Provider>
  );
};
