import { memo } from "react";

const BirdViewIcon = () => {
  return (
    <svg
      width="33"
      height="33"
      viewBox="0 0 33 33"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.8618 16.933C11.8618 16.933 14.2724 15.622 16.7267 12.8146C18.6178 10.6528 19.2262 10.6025 22.0285 10.4839C25.0165 10.3576 27.6451 9.55195 29.7653 7.91743C31.1949 6.8153 31.9529 6.42213 31.5778 7.75243C31.1575 9.24258 28.0791 11.8748 28.0791 11.8748C28.0791 11.8748 30.6959 10.4233 30.8274 11.1877C30.9614 11.9521 29.5654 13.4191 27.713 14.4117C26.3079 15.1645 25.6015 15.1632 25.6015 15.1632C25.6015 15.1632 27.062 15.024 28.0237 14.8912C28.8577 14.7726 28.8035 15.9186 27.3353 16.5721C25.501 17.3868 23.5248 17.3121 23.5248 17.3121C23.5248 17.3121 24.0842 17.6189 24.6463 17.8908C25.2083 18.1616 24.8242 19.0407 23.4358 19.0716C21.9856 19.1038 21.6015 19.0123 21.6015 19.0123C21.6015 19.0123 22.7616 19.3797 22.5812 20.1106C22.4059 20.8144 21.5499 20.8737 20.7185 21.031C19.7311 21.2166 18.8107 22.409 18.8107 22.409C18.8107 22.4077 19.8871 23.9649 28.7351 26.5753C28.7351 26.5753 26.0861 27.3255 23.9 27.2121C23.9 27.2121 24.0379 27.9765 23.0608 28.0925C22.0837 28.2085 16.5562 25.6111 16.5562 25.6111C16.5562 25.6111 12.649 26.0738 9.75518 24.4935C6.85867 22.9143 5.27576 20.0203 3.66173 19.5369C2.05041 19.0522 1.32338 18.8176 1.32338 18.8176C1.32338 18.8176 2.03493 17.602 3.20798 17.6677C3.20798 17.6677 3.73522 16.1672 5.34269 16.1685C5.34269 16.1685 2.81614 14.1447 2.03873 11.76C1.26012 9.37655 2.46799 6.44275 3.69645 4.90594C3.69645 4.90594 4.26104 5.28363 4.05093 6.02613C4.05093 6.02613 4.74189 5.35841 5.01645 5.89594C5.21754 6.29038 4.86821 6.83178 4.86821 6.83178C4.86821 6.83178 5.51273 6.16149 5.83758 6.51467C6.14438 6.84725 5.70867 7.52144 5.70867 7.52144C5.70867 7.52144 6.25265 7.17338 6.47438 7.62585C6.69738 8.07574 6.25137 8.78214 6.25137 8.78214C6.25137 8.78214 6.71155 8.4805 7.03383 8.99483C7.3561 9.50917 6.92684 10.592 6.92684 10.592C6.92684 10.592 7.7325 10.5662 7.86397 11.9184C7.99546 13.2732 9.43662 15.4557 11.8613 16.9328L11.8618 16.933Z"
        stroke="black"
      />
    </svg>
  );
};

export default memo(BirdViewIcon);
