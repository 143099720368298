import React from 'react'

interface IAddIconPropTypes {
  
}

const AddIcon = ({
  
}: IAddIconPropTypes) => {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8 3.33345V12.6668" stroke="#5A11FF" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.33325 8.00012H12.6666" stroke="#5A11FF" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default AddIcon;
