import React, { useEffect, useState } from "react";
import Dialog from "../../../shared/components/Dialog/Dialog";
import { inject, observer } from "mobx-react";
import { IGlobalStore } from "stores/GlobalStore";
import { useTranslation } from "react-i18next";
import UploadWizard from "./containers/UploadWizard";
import UploadLockedTemplate from "./components/UploadLocked";
import { useSubscriptionPaymentDialog } from "../../payments/useSubscriptionPaymentDialog";
import ModelService from "../../services/ModelService";
import CircleLoader from "../../../shared/components/CircleLoader";

interface IUploadVideosDialogContainerPropTypes {
  GlobalStore?: IGlobalStore;
}

const UploadVideosDialogContainer = ({
  GlobalStore,
}: IUploadVideosDialogContainerPropTypes) => {
  const { uploadDialogOpen } = GlobalStore;

  const [canUploadLoading, setCanUploadLoading] = useState(false);
  const [canUploadLoaded, setCanUploadLoaded] = useState(false);
  const [loading, setLoading] = useState(false);

  const [canUpload, setHasPermissions] = useState(false);
  const subscription = useSubscriptionPaymentDialog();
  const pricingDialog = subscription.content;
  const modelReq = ModelService;

  const checkPermissions = async () => {
    setCanUploadLoading(true);

    modelReq.canUpload().then((response) => {
      const canUpload = response.data.canUpload;
      setCanUploadLoaded(true);
      setCanUploadLoading(false);
      setHasPermissions(canUpload);
    });
  };

  useEffect(() => {
    if (!GlobalStore.uploadDialogOpen) {
      cleanup();
      return;
    }

    checkPermissions();
  }, [GlobalStore.uploadDialogOpen]);

  const cleanup = () => {
    setHasPermissions(false);
  };

  const close = () => {
    GlobalStore.closeUploadDialog();
  };

  const handleCancel = () => {
    close();
  };

  const handleUpgrade = () => {
    close();
    subscription.openDialog();
  };

  return (
    <>
      {pricingDialog}
      <Dialog
        size={canUpload ? "large" : "small"}
        title={null}
        open={!!uploadDialogOpen}
        onClose={close}
        hideCancelButton={true}
        hideConfirmButton={true}
      >
        {canUploadLoading || !canUploadLoaded ? (
          <div className="min-h-12 w-full flex items-center justify-center pb-8">
            <CircleLoader color="dark" size={30} />
          </div>
        ) : (
          <>
            {canUpload ? (
              <UploadWizard onLoadingChange={setLoading} />
            ) : (
              <div className="px-4">
                <UploadLockedTemplate
                  onCancel={handleCancel}
                  onUpgrade={handleUpgrade}
                />
              </div>
            )}
          </>
        )}
      </Dialog>
    </>
  );
};

export default inject("GlobalStore")(observer(UploadVideosDialogContainer));
