import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import SkyeTooltip from "../../../../shared/components/SkyeTooltip";
import Button from "../../../../shared/buttons/Button";
import DownloadIcon from "../../../../shared/Icons/DownloadIcon";

const DownloadAssetsButton = (props: any) => {
  const { t } = useTranslation();

  return (
    <SkyeTooltip title={t("download")}>
      <Button
        id="model-download-assets-button"
        className="download-btn"
        endIcon={<DownloadIcon />}
        type="light"
        {...props}
      ></Button>
    </SkyeTooltip>
  );
};

export default memo(DownloadAssetsButton);
