import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Dialog from "../../shared/components/Dialog/Dialog";
import { Model, ModelHelpers } from "models/Model";
import { useTranslation } from "react-i18next";
import InputGroup, { onChangeHandler } from "../../shared/form/InputGroup";
import sharedModelRequests from "requests/sharedModel";
import { IErrorResponse } from "services/HttpService";
import GlobalStore from "stores/GlobalStore";
import { isEmail } from "../../shared/utils/validationUtils";
import SkyeSwitch from "../../shared/form/SkyeSwitch";
import SocialIcons from "../components/SocialIcons";
import { WindowUtils } from "utils/windowUtils";
import ModelService from "views/models/services/ModelService";
import { IAppContext } from "../../interfaces";
import ButtonsToggleGroup from "../../shared/buttons/ButtonsToggleGroup";
import Box from "../../shared/components/Box";
import InformationBox from "../../shared/components/InformationBox/InformationBox";
import CopyIcon from "../../shared/Icons/CopyIcon";
import Label from "../../shared/typography/Label";
import PrivateIcon from "../../shared/Icons/PrivateIcon";
import { v4 as uuid } from "uuid";

interface IShareDialogContainerPropTypes {
  open: boolean;
  modelId: string;
  modelPublicKey: string;
  modelName: string;
  onClose: () => void;
  onModelChange: (model: Model) => void;
}

/**
 * Some hack solution to make sharedToOthers and shareToEmail equal height
 */
const ContentWrapper = styled(Box)`
  min-height: 162px;
`;

const ShareByLinkWrapper = styled(ContentWrapper)`
  justify-content: center;
  flex-direction: column;
  text-align: center;

  .public-link-label {
    margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
  }

  .information-box {
    margin-top: ${(p: IAppContext) => p.theme.skye.sizes.average};
  }
`;
const ShareByEmailWrapper = styled(ContentWrapper)`
  align-items: flex-start;
`;
const InputWrapper = styled.div`
  text-align: center;

  .input-wrapper {
    cursor: pointer;

    input {
      cursor: pointer;
    }
  }
`;
const SocialIconsWrapper = styled(Box)`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  padding: ${(p: IAppContext) => p.theme.skye.sizes.small};
  justify-content: center;
`;
const ShareTypeButtonWrapper = styled.div`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;
const SwitchContainer = styled(Box)`
  justify-content: center;
  width: 100%;
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
`;

enum ShareType {
  Link = "link",
  Email = "email",
}

const SHARE_TYPE_BUTTONS = [
  { label: "sharedToOthers", id: String(ShareType.Link) },
  { label: "shareModel", id: String(ShareType.Email) },
];

const ShareDialogContainer = ({
  open,
  modelId,
  modelPublicKey,
  modelName,
  onClose,
  onModelChange,
}: IShareDialogContainerPropTypes) => {
  const { t } = useTranslation();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [blurred, setBlurred] = useState(false);
  const validEmail = isEmail(email, t);
  const invalidMessage = blurred && validEmail;
  const [type, setType] = useState(ShareType.Link);
  const isShareByEmail = type === ShareType.Email;
  const [publicKey, setPublicKey] = useState(modelPublicKey);

  const copyToClipboard = (publicKey: string) => {
    WindowUtils.copyToClipboard(ModelHelpers.getPublicUrl(publicKey));
    GlobalStore.addToast(t("linkCopiedToClipboard"));
  };

  const changeShareType = (shareType: string | number) => {
    setType(String(shareType) as ShareType);
  };

  const handleChange = (newChecked: boolean) => {
    if (newChecked) {
      handleCreatePublicKey();
      return;
    }

    setLoading(true);
    ModelService.patchModel(modelId, { publicKey: "" })
      .then((res) => {
        const model = res.data as Model;
        setPublicKey(model.publicKey);
        onModelChange(model);
      })
      .finally(() => setLoading(false));
  };

  const handleCreatePublicKey = () => {
    setLoading(true);
    ModelService.patchModel(modelId, { publicKey: uuid() })
      .then((res) => {
        const model = res.data as Model;
        setPublicKey(model.publicKey);
        copyToClipboard(model.publicKey);
        onModelChange(model);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const shareModel = () => {
    setLoading(true);

    sharedModelRequests
      .shareModel(modelId, email)
      .then(() => {
        setEmail("");
        onClose();
        GlobalStore.addToast(t("toastModelShared"));
      })
      .catch((err: IErrorResponse) => {
        GlobalStore.addToast(
          t(err.localizationKey || "requestFailed"),
          "error"
        );
      })
      .finally(() => setLoading(false));
  };

  useEffect(() => {
    if (open) {
      setBlurred(false);
      setEmail("");
    }
  }, [open]);

  return (
    <Dialog
      title={t("shareModel")}
      open={open}
      onClose={onClose}
      hideConfirmButton={!isShareByEmail}
      hideCancelButton={!isShareByEmail}
      confirmButtonProps={{
        loading,
        disabled: !!validEmail || loading,
        onClick: shareModel,
      }}
    >
      <ShareTypeButtonWrapper>
        <ButtonsToggleGroup
          size="large"
          fullSize
          backgroundColor="grey"
          elements={SHARE_TYPE_BUTTONS}
          selected={type}
          onChange={changeShareType}
        />
      </ShareTypeButtonWrapper>
      {isShareByEmail ? (
        <ShareByEmailWrapper>
          <InputGroup
            label="emailLabel"
            bordered
            inputProps={{
              placeholder: "emailPlaceholder",
              value: email,
              onBlur: () => setBlurred(true),
              onChange: onChangeHandler(setEmail),
            }}
            invalid={!!invalidMessage}
            invalidMessage={invalidMessage}
          />
        </ShareByEmailWrapper>
      ) : (
        <ShareByLinkWrapper>
          {ModelHelpers.hasPublicAccess(publicKey) ? (
            <>
              <SocialIconsWrapper>
                <SocialIcons
                  modelName={modelName}
                  modelPublicKey={modelPublicKey}
                />
              </SocialIconsWrapper>
              <Label className="public-link-label">{t("orShareByLink")}</Label>
              <InputWrapper>
                <InputGroup
                  filled
                  disabled
                  endIcon={<CopyIcon />}
                  inputProps={{
                    value: ModelHelpers.getPublicUrl(publicKey),
                    onClick: () => copyToClipboard(publicKey),
                  }}
                  onEndIconClick={() => copyToClipboard(publicKey)}
                />
              </InputWrapper>
            </>
          ) : (
            <InformationBox
              title={t("modelIsPrivate")}
              description={t("modelPublicAccessDialogInformation")}
              icon={<PrivateIcon />}
            ></InformationBox>
          )}
          <SwitchContainer>
            <SkyeSwitch
              onChange={handleChange}
              checked={ModelHelpers.hasPublicAccess(publicKey)}
              loading={loading}
            >
              {t("modelHasPublicAccess")}
            </SkyeSwitch>
          </SwitchContainer>
        </ShareByLinkWrapper>
      )}
    </Dialog>
  );
};

export default ShareDialogContainer;
