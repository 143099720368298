import { IPotreeAnnotation } from "../../interfaces";

export type AnnotationInsert = Omit<IPotreeAnnotation, "uuid">;

export interface AnnotationAdd extends AnnotationInsert {
  position: [number, number, number];
}

export class Annotation {
  static toAnnotation(potreeAnnotation) {
    const { title, description, url, uuid } = potreeAnnotation;
    return { title, description, url, uuid };
  }

  static getAll(): IPotreeAnnotation[] {
    try {
      return window.viewer.scene.annotations.children.map(
        Annotation.toAnnotation
      );
    } catch (error) {
      return [];
    }
  }

  static getByUuid(uuid: string): IPotreeAnnotation | null {
    return window.viewer.scene.annotations.children.find(
      (a) => a.uuid === uuid
    );
  }

  static startInsertion(data: AnnotationInsert): IPotreeAnnotation {
    return window.viewer.annotationTool.startInsertion({
      title: data.title,
      description: data.description,
      url: data.url,
    }) as IPotreeAnnotation;
  }

  static add(data: AnnotationAdd): IPotreeAnnotation {
    return window.viewer.annotationTool.add({
      title: data.title,
      url: data.url,
      description: data.description,
      position: data.position,
    });
  }

  static remove(annotation: IPotreeAnnotation) {
    window.viewer.scene.annotations.remove(annotation);
  }

  static removeAll() {
    window.viewer.scene.annotations.children.forEach((annotation) => {
      window.viewer.scene.annotations.remove(annotation);
    });
  }
}
