import { memo } from "react";
import { ISharedInModelItem } from "../../../../../models/SharedModel";
import SharedInModelListElement from "./ModelListElement/SharedInModelListElement";

interface ISharedInModelListPropTypes {
  sharedInModels: ISharedInModelItem[];
}

const SharedInModelList = ({
  sharedInModels,
  ...rest
}: ISharedInModelListPropTypes) => {
  return (
    <>
      {sharedInModels.map((sharedInModel) => {
        return (
          <SharedInModelListElement
            key={sharedInModel.model.id}
            sharedInModel={sharedInModel}
            {...rest}
          />
        );
      })}
    </>
  );
};

export default memo(SharedInModelList);
