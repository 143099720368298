export enum SubscriptionPeriod {
  Monthly = "Monthly",
  Annually = "Annually",
}

export enum SubscriptionType {
  Freemium = "Freemium",
  Lite = "Lite",
  Premium = "Premium",
}
