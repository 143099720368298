import API from "../const/api";
import HttpService from "./HttpService";

export interface IStream {
  userId: string;
  updatedAt: string;
  publicId: string;
  ivsId: string;
  ingestEndpoint: string;
  ivsArn: string;
  playbackUrl: string;
}

export class StreamService {
  static async getStreamsBySessionAddress(
    sessionAddress: string
  ): Promise<IStream[]> {
    const url = `${API.getStreams}?sessionAddress=${sessionAddress}`;
    return (await HttpService.get(url)).data;
  }
}
