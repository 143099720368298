import { IResponse } from "services/HttpService";
import { ITranslationFields } from "locale/dictionary";
import { getEnvVariables } from "env";
import moment from "moment";
import {
  ModelPageViewTypes,
  MODEL_PAGE_VIEW_TYPE_PARAM_NAME,
} from "const/global";
import { MODEL_ROUTES } from "views/routes";
import { ModelType } from "../views/interfaces";

const env = getEnvVariables();

export const noOp = () => {};

export const getResponseErrorTranslation = (
  res: IResponse
): ITranslationFields => {
  if (!res) return "responseErrorRequestFailed";

  if (res.data && res.data.localizationKey) {
    return res.data.localizationKey;
  }

  switch (res.status) {
    case 422:
      return "responseError422";
    case 409:
      return "responseError409";
    case 404:
      return "responseError404";
    case 403:
      return "responseError403";
    default:
      return "responseErrorRequestFailed";
  }
};

export const debounce = (timeout: any, wait: number, callback: any) => {
  if (timeout) {
    clearTimeout(timeout);
  }

  return setTimeout(() => {
    callback();
  }, wait);
};

export const wait = async (timeInMiliseconds) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(null);
    }, timeInMiliseconds);
  });
};

export const getLogoUrl = () => {
  return `${env.MAP_URL}img/logo_dark_large.png`;
};

export const getModelListPageUrl = (modelType: ModelType) => {
  return `/models/1?modelType=${modelType}`;
};

export const getModelPageUrl = (id: string, viewType: ModelPageViewTypes) => {
  return `${MODEL_ROUTES.details.replace(
    ":id",
    id
  )}?${MODEL_PAGE_VIEW_TYPE_PARAM_NAME}=${viewType}`;
};

export const getModelSharedUrl = (
  modelId: string,
  viewType: ModelPageViewTypes
) => {
  return `${MODEL_ROUTES.sharedModelDetails.replace(
    ":id",
    modelId
  )}?${MODEL_PAGE_VIEW_TYPE_PARAM_NAME}=${viewType}`;
};

export const formatDate = (date: string | Date) => {
  return moment(date).format("LLL");
};
