import styled from "styled-components";
import Dialog from "../../shared/components/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import {
  BASE_USER_ROLES,
  IOpenUserRole,
  IOrganizationUser,
  UserHelpers,
} from "../../../models/User";
import { useState } from "react";
import Label from "../../shared/typography/Label";
import SkyeSwitch from "../../shared/form/SkyeSwitch";
import { IAppContext } from "../../interfaces";
import { OrganizationService } from "../../../services/OrganizationService";
import { MessageService } from "../../../services/MessageService";

interface IuseChangePermissionHookPropTypes {
  onChange: () => void;
}

const SwitchBox = styled.div`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.medium}};
`;

const useChangePermissionHook = ({
  onChange,
}: IuseChangePermissionHookPropTypes) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [user, setUser] = useState<IOrganizationUser>(null);
  const [organizationAccess, setOrganizationAccess] = useState(true);
  const [loading, setLoading] = useState(false);

  const init = (userToEdit: IOrganizationUser) => {
    setUser(userToEdit);
    setOrganizationAccess(
      UserHelpers.hasRole(userToEdit, IOpenUserRole.OrganizationMaintainer)
    );
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setUser(null);
  };

  const handleConfirm = async () => {
    setLoading(true);

    try {
      await OrganizationService.assignRoles(
        user.email,
        organizationAccess
          ? [...BASE_USER_ROLES, IOpenUserRole.OrganizationMaintainer]
          : BASE_USER_ROLES
      );
      setOpen(false);
      setUser(null);
      MessageService.success(t("permissionsChanged"));
      onChange();
    } catch (error) {
      MessageService.error(t("requestFailed"));
    }

    setLoading(false);
  };

  const dialog = (): any => {
    if (!user) return null;

    return (
      <Dialog
        open={open}
        onClose={handleClose}
        title={t("editPermissions")}
        confirmButtonProps={{
          onClick: handleConfirm,
          disabled: loading,
          loading,
        }}
      >
        <Label>
          <b className="mb-1">{`${user.firstName} ${user.lastName}`}</b>
          <br />
          {user.email}
        </Label>
        <SwitchBox>
          <SkyeSwitch
            onChange={(newChecked) => setOrganizationAccess(newChecked)}
            checked={organizationAccess}
          >
            {t("organizationAccessLabel")}
          </SkyeSwitch>
        </SwitchBox>
      </Dialog>
    );
  };

  return [dialog(), init];
};

export default useChangePermissionHook;
