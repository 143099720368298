import { getEnvVariables } from "../../../env";
import httpService, { IResponse } from "../../../services/HttpService";
import { StripePaymentIntend } from "../stripe/Stripe.model";

const env = getEnvVariables();
const BASE_URL = `${env.API_V3_URL}v3/quotes`;

export enum AccessPeriod {
  WEEK = "week",
  MONTH = "month",
  SIX_MONTHS = "six_months",
  FIVE_YEARS = "five_years",
}

export interface Quote {
  id: string;
  data: {
    modelId: string;
    prices: {
      week?: number;
      six_months?: number;
      month?: number;
      five_years?: number;
    };
  };
}

export class QuoteService {
  static getModelQuote = async (modelId: string): Promise<IResponse<Quote>> => {
    const queryUrl = `${BASE_URL}?modelId=${modelId}`;
    return httpService.get(queryUrl);
  };

  static fulfill = async (
    quoteId: string,
    period: AccessPeriod
  ): Promise<IResponse<StripePaymentIntend>> => {
    const queryUrl = `${BASE_URL}/${quoteId}/${period}`;
    return httpService.post(queryUrl);
  };
}
