import httpService from "services/HttpService";
import API from "const/api";

const deleteAccount = (message: string) => {
  const url = API.deleteAccount;
  return httpService.post(url, { message });
};

const userRequests = {
  deleteAccount,
};

export default userRequests;
