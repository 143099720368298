import React, { memo } from "react";
import { Marker } from "@react-google-maps/api";
import { TacbrowseEntity } from "../../../../models/SessionData";
import {
  DEFAULT_ICON,
  DRONE_ICON,
  FINDING_ICON_MAP,
  getIconType,
  LOCATOR_ICONS_PATH,
  SharedLocatorProps,
} from "./SessionLocatorUtils";
import { createGlobalStyle } from "styled-components";

interface ISessionMapLocatorComponentPropTypes extends SharedLocatorProps {
  onClick: () => void;
}

const GlobalStyleMarkerLabel = createGlobalStyle`
  .marker-label {
    border-radius: 50%;
    width: 26px;
    height: 26px;
    margin-top: -7px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-size: cover;
    background-color: #C6C6C6;
    background-origin: content-box;
    background-repeat: no-repeat;
    padding: 3px;
    
    &--user {
      background-color: #C6C6C6;
    }
    
    &--user-active {
      background-color: #505050;
    }
    
    &--bicycle {
      background-image: url(${LOCATOR_ICONS_PATH}/${FINDING_ICON_MAP.bicycle});
    }

    &--car {
      background-image: url(${LOCATOR_ICONS_PATH}/${FINDING_ICON_MAP.car});
    }

    &--van {
      background-image: url(${LOCATOR_ICONS_PATH}/${FINDING_ICON_MAP.van});
    }

    &--truck {
      background-image: url(${LOCATOR_ICONS_PATH}/${FINDING_ICON_MAP.truck});
    }

    &--motor {
      background-image: url(${LOCATOR_ICONS_PATH}/${FINDING_ICON_MAP.motor});
    }

    &--airplane {
      background-image: url(${LOCATOR_ICONS_PATH}/${FINDING_ICON_MAP.airplane});
    }

    &--pedestrian {
      background-image: url(${LOCATOR_ICONS_PATH}/${FINDING_ICON_MAP.pedestrian});
    }

    &--people {
      background-image: url(${LOCATOR_ICONS_PATH}/${FINDING_ICON_MAP.people});
    }
    
    &--drone {
      background-image: url(${LOCATOR_ICONS_PATH}/${DRONE_ICON});
    }
    
    &--default {
      background-image: url(${LOCATOR_ICONS_PATH}/${DEFAULT_ICON});
    }
  }
`;

const SessionMapLocatorComponent = ({
  label,
  latitude,
  longitude,
  onClick,
  active,
  findingType,
  senderType = TacbrowseEntity.Mobile,
}: ISessionMapLocatorComponentPropTypes) => {
  const isFinding = !!findingType;
  const markerIcon = "locator.svg";
  const markerIconActive = "locator-active.svg";
  const iconType = getIconType(active, findingType, senderType);
  const fontColor = active ? "#E0E0E0" : "#616161";
  const showLabel = !isFinding && senderType !== TacbrowseEntity.Drone;

  return (
    <>
      <GlobalStyleMarkerLabel />
      <Marker
        onClick={onClick}
        key={label}
        position={{
          lat: latitude,
          lng: longitude,
        }}
        zIndex={active ? 100 : 50}
        icon={
          active
            ? `${LOCATOR_ICONS_PATH}/${markerIconActive}`
            : `${LOCATOR_ICONS_PATH}/${markerIcon}`
        }
        label={{
          // String required. Empty string causes to display object hy default, that's why '  '
          text: showLabel ? label : "  ",
          className: `marker-label marker-label--${iconType}`,
          fontFamily: `'Inter', 'Helvetica', sans-serif`,
          fontSize: "12px",
          fontWeight: "600",
          color: fontColor,
        }}
      />
    </>
  );
};

/**
 * Preventing unecessary rerenders. Component will only rerender when lat, long or active will change
 */
const MemoizedLocator = memo(
  SessionMapLocatorComponent,
  (prevProps, nextProps) => {
    return (
      prevProps.latitude === nextProps.latitude &&
      prevProps.longitude === nextProps.longitude &&
      prevProps.active === nextProps.active
    );
  }
);

export default MemoizedLocator;
