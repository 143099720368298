import { IAppContext } from "../../../../../interfaces";
import styled from "styled-components";
import Box from "../../../../../shared/components/Box";
import classNames from "classnames";

const ToggleButtonWrapper = styled(Box)`
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 26px;
  height: 40px;
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
  transform: rotateZ(180deg);

  &.open {
    transform: rotateZ(0deg);
  }
`;

interface IProps {
  onClick: () => void;
  open: boolean;
}

const ToggleButton = ({ onClick, open }: IProps) => (
  <ToggleButtonWrapper
    onClick={onClick}
    className={classNames(["toggle-button", { open }])}
  >
    <svg
      width="8"
      height="13"
      viewBox="0 0 7 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.35355 0.146447C6.54882 0.341709 6.54882 0.658291 6.35355 0.853553L1.20711 6L6.35355 11.1464C6.54882 11.3417 6.54882 11.6583 6.35355 11.8536C6.15829 12.0488 5.84171 12.0488 5.64645 11.8536L0.146447 6.35355C0.0526784 6.25979 0 6.13261 0 6C0 5.86739 0.0526784 5.74022 0.146447 5.64645L5.64645 0.146447C5.84171 -0.0488155 6.15829 -0.0488155 6.35355 0.146447Z"
        fill="white"
      />
    </svg>
  </ToggleButtonWrapper>
);

export default ToggleButton;
