import { IAppContext } from "../../interfaces";
import React from "react";
import styled from "styled-components";
import Button from "../buttons/Button";
import Box from "../components/Box";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

interface ButtonToggleElement {
  label: string;
  id: string | number;
  disabled?: boolean;
}

interface IButtonsToggleGroupPropTypes {
  size?: "regular" | "large";
  activeBtnShadow?: boolean;
  bordered?: boolean;
  elements: ButtonToggleElement[];
  selected: string | number;
  backgroundColor?: "white" | "grey";
  fullSize?: boolean;
  onChange: (id: string | number) => void;
}

const ToggleGroupContainer = styled(Box)`
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  padding: 4px;

  &.bordered {
    border: 1px solid #e5e5e5;
  }

  &.activeBtnShadow {
    .btn.active {
      box-shadow: 0px 3px 9px 0px rgba(0, 0, 0, 0.25);
    }
  }

  &.white {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  }

  &.fullSize {
    width: 100%;
  }

  &.large {
    padding: 4px;

    .btn {
      height: 40px;
    }
  }

  .btn {
    height: 32px;
    font-weight: normal;
    font-size: 14px;
    line-height: 17px;
    padding-left: 16px;
    padding-right: 16px;
    min-width: fit-content;
  }

  .btn:not(.active) {
    &.light,
    &.transparent,
    &.primary {
      background-color: transparent;
      color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
    }
  }
`;

const ButtonsToggleGroup = ({
  activeBtnShadow,
  bordered,
  elements,
  selected,
  size,
  fullSize,
  backgroundColor = "white",
  onChange,
}: IButtonsToggleGroupPropTypes) => {
  const { t } = useTranslation();

  return (
    <ToggleGroupContainer
      className={classNames([
        "toggle-group",
        backgroundColor,
        size,
        { fullSize, bordered, activeBtnShadow },
      ])}
    >
      {elements.map((elem) => (
        <Button
          disabled={elem.disabled}
          fullSize={fullSize}
          type={backgroundColor === "grey" ? "solid" : "light"}
          className={classNames({ active: elem.id === selected })}
          key={elem.id}
          onClick={() => onChange(elem.id)}
        >
          {t(elem.label)}
        </Button>
      ))}
    </ToggleGroupContainer>
  );
};

export default ButtonsToggleGroup;
