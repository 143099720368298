import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components';
import { IPublicModel, ModelHelpers } from "models/Model";
import { IAppContext } from "../../interfaces";
import { FileService } from "services/FileService";
import { useTranslation } from "react-i18next";
import {
  AnalyticService,
  AnalyticEventCategory,
} from "services/AnalyticService";
import Button from "../../shared/buttons/Button";
import { ImageService } from "../../../services/ImageService";
import Box from "../../shared/components/Box";
import NoVideoIcon from "../../shared/Icons/NoVideoIcon";
import Description from "../../shared/typography/Description";

interface IModelVideoPropTypes {
  model: IPublicModel;
  loading?: boolean;
  listView?: boolean;
}

const VideoWrapperr = styled.div`
  position: relative;
  width: 100%;
`;

const ModelVideoContainer = styled.div`
  width: 100%;
  flex: 1;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};

  h2 {
    opacity: 0.5;
  }
  video {
    width: 100%;
  }
  &:before {
    content: "";
    display: block;
  }
`;
const Video = styled.video`
  width: 100%;
  height: 100%;
`;
const FrameButtonWrapper = styled(Box)`
  margin: ${(p: IAppContext) => p.theme.skye.sizes.average} 0;
`;
const NoVideoContainer = styled(Box)`
  width: 100%;
  height: 240px;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
const NoVideoIconWrapper = styled(Box)`
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

const ModelVideo = ({ model, listView = false }: IModelVideoPropTypes) => {
  const { t } = useTranslation();
  const videoRef = useRef(null);
  const videoExists = Boolean(videoRef && model.assets.vidUrl);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [saveLoad, setSaveLoad] = useState(false);

  const renderVideo = () => {
    return ModelHelpers.videosUploaded(model) ? (
      <Video
        controls
        src={model.assets.vidUrl}
        ref={videoRef}
        onLoadedMetadata={() => {
          setVideoLoaded(true);
        }}
        onPlay={() => {
          AnalyticService.event(
            AnalyticEventCategory.ModelDetailsPage,
            "play_video"
          );
        }}
      />
    ) : (
      <NoVideoContainer>
        <NoVideoIconWrapper>
          <NoVideoIcon />
        </NoVideoIconWrapper>
        <Description>{t("videoNotUploadedInformation")}</Description>
      </NoVideoContainer>
    );
  };

  const saveFrame = async () => {
    AnalyticService.event(
      AnalyticEventCategory.ModelDetailsPage,
      "save_current_frame"
    );

    if (videoExists) {
      try {
        setSaveLoad(true);
        const video = videoRef.current;
        const canvas = document.createElement("canvas");
        const width = video.videoWidth;
        const height = video.videoHeight;
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext("2d");
        video.crossorigin = "anonymous";
        ctx.fillRect(0, 0, width, height);
        ctx.drawImage(video, 0, 0, canvas.width, canvas.height);

        const imageURI = canvas.toDataURL("image/jpg");
        const watermarkedImgURI = await ImageService.createWatermark(imageURI);
        FileService.saveImage(watermarkedImgURI, "skyebrowse_frame");
        setSaveLoad(false);
      } catch (error) {
        setSaveLoad(false);
      }
    }
  };

  useEffect(() => {
    if (videoRef.current) {
      // Fix issue with
      // 'Uncaught DOMException: Failed to execute 'toDataURL' on 'HTMLCanvasElement': Tainted canvases may not be exported.'
      // When saving frame
      videoRef.current.crossOrigin = "anonymous";
    }
  });

  const saveButtonDisabled = !(videoExists && videoLoaded);

  return (
    <VideoWrapperr>
      <ModelVideoContainer className="model-video-container">
        {renderVideo()}
      </ModelVideoContainer>
      {!listView && (
        <FrameButtonWrapper className="d-flex justify-content-end w-100 mt-2">
          <Button
            size="small"
            disabled={saveButtonDisabled}
            loading={saveLoad}
            onClick={saveFrame}
          >
            {t("saveFrame")}
          </Button>
        </FrameButtonWrapper>
      )}
    </VideoWrapperr>
  );
};

export default ModelVideo;
