import styled from 'styled-components';
import { IAppContext } from "../../interfaces";

const Input = styled.input`
  border: none;
  padding-left: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  height: 40px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  background-color: transparent;
  outline: none;

  &::placeholder {
    color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
  }
`;

export const Textarea = styled.textarea`
  border: none;
  padding-left: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  padding-top: 10px;
  padding-bottom: 10px;
  min-height: 80px;
  width: 100%;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  background-color: transparent;
  outline: none;

  &::placeholder {
    color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
  }
`;

export default Input;