import React from "react";
import styled from "styled-components";
import { inject, observer } from "mobx-react";
import { STORE_NAMES } from "../../../const/global";
import SessionViewerToolIcon from "../components/SessionViewerToolIcon";
import StreamIcon from "../../shared/Icons/StreamIcon";
import { ISessionStreamStore } from "../../../stores/SessionStreamStore";
import classNames from "classnames";
import { BoxCentered } from "../../shared/components/Box";
import { IAppContext } from "../../interfaces";

interface ISessionViewerStreamToolIconPropTypes {
  SessionStreamStore?: ISessionStreamStore;
}

const Wrapper = styled.div`
  position: relative;

  .tool-icon {
    border-radius: 4px;
  }

  &.active {
    .tool-icon {
      background-color: ${(p: IAppContext) => p.theme.skye.colors.primary300};
    }

    .icon-inner-wrapper {
      svg {
        path {
          stroke: ${(p: IAppContext) => p.theme.skye.colors.primary};
        }
      }
    }
  }

  &.disabled {
    .icon-inner-wrapper {
      opacity: 0.5;
    }
  }
`;
const CountIcon = styled(BoxCentered)`
  width: 16px;
  height: 16px;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.primary};
  color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  position: absolute;
  right: -8px;
  top: -8px;
  border-radius: 16px;
`;

const SessionViewerStreamToolIcon = ({
  SessionStreamStore,
}: ISessionViewerStreamToolIconPropTypes) => {
  const streamsCount = SessionStreamStore.streams.size;
  const disabled = !streamsCount;

  const handleClick = () => {
    if (disabled) return;
    SessionStreamStore.setStreamPanelOpen(!SessionStreamStore.streamPanelOpen);
  };

  return (
    <Wrapper className={classNames([{ disabled, active: !disabled }])}>
      {!disabled && <CountIcon>{streamsCount}</CountIcon>}
      <SessionViewerToolIcon
        colorMethod="stroke"
        icon={<StreamIcon />}
        name={disabled ? "streamsNotAvailable" : "streams"}
        onClick={handleClick}
      />
    </Wrapper>
  );
};

export default inject(STORE_NAMES.SessionStreamStore)(
  observer(SessionViewerStreamToolIcon)
);
