import React, { useEffect, useState } from "react";
import Box, { BoxAlignedCenter } from "views/shared/components/Box";
import styled from "styled-components";
import Heading from "views/shared/typography/Heading";
import DateComponent from "views/shared/components/Date";
import { IAppContext } from "views/interfaces";
import DateLabel from "views/shared/typography/DateLabel";
import { useTranslation } from "react-i18next";
import { Model } from "models/Model";
import UserAvatarGroup from "views/shared/components/UserAvatarGroup";
import Button from "views/shared/buttons/Button";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { MODEL_ROUTES, SESSION_ROUTES } from "views/routes";
import ModelService from "views/models/services/ModelService";
import { trimStringIfToLong } from "utils/stringUtils";
import VisibilityBadge from "views/shared/components/badges/VisibilityBadge/VisibilityBadge";
import EditIcon from "views/shared/Icons/EditIcon";
import MoreIcon from "views/shared/Icons/MoreIcon";
import SkyeDropdown, {
  DropdownWrapper,
} from "views/shared/components/Dropdown/Dropdown";
import DropdownItem from "views/shared/components/Dropdown/DropdownItem";
import ModelThumbnail from "views/models/components/ModelThumbnail";
import CopyButton from "views/shared/components/CopyButton";
import { LoggedUser } from "models/User";
import { inject, observer } from "mobx-react";
import { STORE_NAMES } from "const/global";
import OrganizationStore, {
  IOrganizationStore,
} from "stores/OrganizationStore";
import { ISession } from "models/Session";
import breakpoint from "styled-components-breakpoint";

interface ISessionListElementPropTypes extends RouteComponentProps {
  session: ISession;
  OrganizationStore?: IOrganizationStore;
}

interface InformationBoxProps {
  title: string;
  value: string;
}

const Row = styled(BoxAlignedCenter)`
  width: 100%;
`;
const Side = styled(Box)`
  flex-direction: column;
  width: 100%;

  ${breakpoint("md")`
    width: auto;
    align-items: center;
    justify-content: center;
  `}
`;
const LeftSide = styled(Side)`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.large};

  ${breakpoint("md")`
    flex-direction: row;
    justify-content: flex-start;
    margin-bottom: 0;
  `}
`;
const RightSide = styled(Side)`
  ${breakpoint("md")`
    justify-content: flex-end;
  `}
`;
const ElementContainer = styled(Box)`
  border: ${(p: IAppContext) => p.theme.skye.borders.primary};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radiusMedium};
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
  flex-direction: column;
  padding: ${(p: IAppContext) => p.theme.skye.sizes.average};

  ${breakpoint("md")`
    padding: 34px;
  `}

  &:hover {
    box-shadow: ${(p: IAppContext) => p.theme.skye.shadows.light};
  }
`;
const TopHeadingRow = styled(Row)`
  justify-content: space-between;
  width: 100%;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
  flex-direction: column-reverse;

  ${breakpoint("md")`
    flex-direction: row;
  `}
`;
const TopRow = styled(Row)`
  width: 100%;

  ${breakpoint("md")`
    width: auto;
  `}
`;
const TopRowLeftWrapper = styled(TopRow)``;
const TopRowRightWrapper = styled(TopRow)`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  justify-content: space-between;

  ${breakpoint("md")`
    margin-bottom: 0;
    justify-content: flex-end;
  `}
`;
const VisibilityRow = styled(Row)`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;
const BottomRow = styled(Row)`
  justify-content: space-between;
  flex-direction: column;

  ${breakpoint("md")`
    flex-direction: row;
  `}
`;
const OptionsIconButton = styled(Box)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  margin-left: 18px;

  &:hover {
    svg {
      path {
        fill: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
      }
    }
  }

  svg {
    path {
      fill: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
    }
  }
`;

const DateWrapper = styled(DateLabel)``;

const InformationBoxWrapper = styled(Box)`
  min-width: 190px;
  align-items: flex-top;
  flex-direction: column;
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;
const InformationTitle = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
`;
const InformationValue = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.radius};
`;
const ViewSessionWrapper = styled(Box)`
  .btn {
    width: 100%;

    ${breakpoint("md")`
      width: auto;
    `}
  }
`;
const ModelInformationBoxWrapper = styled(BoxAlignedCenter)`
  cursor: pointer;
  flex-direction: row-reverse;

  ${breakpoint("md")`
    flex-direction: row;
  `}
`;
const ThumbnailWrapper = styled.div`
  position: relative;
  height: 42px;
  width: 68px;
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.average};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  border-radius: 2px;

  .thumbnail-image {
    max-height: 42px;
  }

  .thumbnail-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
const SessionAddressWrapper = styled.div`
  margin-left: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

const InformationBox = ({ title, value }: InformationBoxProps) => (
  <InformationBoxWrapper>
    <InformationTitle>{title}</InformationTitle>
    <InformationValue>{value}</InformationValue>
  </InformationBoxWrapper>
);

const SessionListElement = ({
  session,
  history,
}: ISessionListElementPropTypes) => {
  const { t } = useTranslation();
  const [owner, setOwner] = useState<LoggedUser>(null);
  const [model, setModel] = useState<Model>(null);
  const [users, setUsers] = useState<LoggedUser[]>([]);

  const loadModel = () => {
    if (session?.modelId) {
      ModelService.getModelDetails(session.modelId).then((res) =>
        setModel(res.data)
      );
    }
  };

  const loadUsers = async () => {
    const userIds = (session.invited.users as number[]).filter(
      (userId) => !Number.isNaN(Number(userId)) && userId
    );
    const [ownerResult, ...usersResults] = await Promise.allSettled([
      OrganizationStore.getOrganizationUser(Number(session.host.id)),
      ...userIds.map((userId) => OrganizationStore.getOrganizationUser(userId)),
    ]);
    const newOwner =
      ownerResult.status === "fulfilled" ? ownerResult.value : null;
    const newUsers = usersResults
      .filter(
        (result) =>
          result.status === "fulfilled" && result.value.email !== newOwner.email
      )
      .map((result) => (result as PromiseFulfilledResult<LoggedUser>).value);

    if (newOwner) setOwner(newOwner);
    setUsers(newUsers);
  };

  const loadData = () => {
    loadModel();
    loadUsers();
  };

  const handleViewModel = () => {
    history.push(MODEL_ROUTES.details.replace(":id", model.id));
  };

  const handleViewSession = () => {
    history.push(
      SESSION_ROUTES.sessionDetails.replace(":id", session.publicId)
    );
  };

  const goToSessionViewer = () => {
    history.push(SESSION_ROUTES.sessionViewer.replace(":id", session.publicId));
  };

  useEffect(() => {
    loadData();
  }, []);

  return (
    <ElementContainer>
      <TopHeadingRow>
        <TopRowLeftWrapper>
          <Heading>{session.name}</Heading>
        </TopRowLeftWrapper>
        <TopRowRightWrapper>
          <DateWrapper>
            <DateComponent>{session.createdAt}</DateComponent>
          </DateWrapper>
          <SkyeDropdown
            onChange={() => {}}
            overlay={
              <DropdownWrapper>
                <DropdownItem
                  startIcon={<EditIcon />}
                  onClick={handleViewSession}
                >
                  {t("edit")}
                </DropdownItem>
              </DropdownWrapper>
            }
          >
            <OptionsIconButton>
              <MoreIcon />
            </OptionsIconButton>
          </SkyeDropdown>
        </TopRowRightWrapper>
      </TopHeadingRow>
      <VisibilityRow>
        <VisibilityBadge
          type={session.open ? "public" : "private"}
        ></VisibilityBadge>
        <SessionAddressWrapper>
          <CopyButton copyContent={session.publicId}>
            {t("copySessionAddress")}
          </CopyButton>
        </SessionAddressWrapper>
      </VisibilityRow>
      <BottomRow>
        <LeftSide>
          <InformationBox
            title={t("sessionOwner")}
            value={owner ? trimStringIfToLong(owner.email, 20) : ""}
          />
          <ModelInformationBoxWrapper
            onClick={handleViewModel}
            className="model-information-box"
            role="button"
          >
            <ThumbnailWrapper>
              <ModelThumbnail
                showNoVideoIcon
                showVideoDuration={false}
                modelId={model?.id}
                thumbnailsUrls={model?.assets?.thumbnailsUrls}
                onClick={() => {}}
              />
            </ThumbnailWrapper>
            <InformationBox
              title={t("model")}
              value={model ? trimStringIfToLong(model.name, 20) : ""}
            />
          </ModelInformationBoxWrapper>
          <UserAvatarGroup
            showAddButton={false}
            users={users.map((user) => ({
              userName: user.firstName,
            }))}
          ></UserAvatarGroup>
        </LeftSide>
        <RightSide>
          <ViewSessionWrapper>
            <Button onClick={goToSessionViewer}>{t("viewSession")}</Button>
          </ViewSessionWrapper>
        </RightSide>
      </BottomRow>
    </ElementContainer>
  );
};

export default inject(STORE_NAMES.OrganizationStore)(
  observer(withRouter(SessionListElement))
);
