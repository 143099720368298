export enum ServiceType {
  Widebrowse = "widebrowse",
  Skyebrowse = "skyebrowse",
  Ultra = "ultra",
  NightVision = "night_vision",
  DownloadLazFile = "download_laz_file",
  InteriorMapping = "interior_mapping",
  Tacbrowse = "tacbrowse",
  AutelUpload = "autel_upload",
  GeoTiff = "geotiff",
  GeoTiffAlways = "geotiff_always",
  Zip = "zip",
  Light = "light",
}

export interface Service {
  id: number;
  type: ServiceType;
}

export interface ServicePlan {
  id: number;
  name: string;
  services: Service[];
}
