import React from "react";

const SubscriptionIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3032_8107)">
        <path
          d="M12.0001 4.8001V2.4001C12.0001 2.18792 11.9158 1.98444 11.7658 1.83441C11.6158 1.68438 11.4123 1.6001 11.2001 1.6001H3.2001C2.77575 1.6001 2.36878 1.76867 2.06873 2.06873C1.76867 2.36878 1.6001 2.77575 1.6001 3.2001M1.6001 3.2001C1.6001 3.62444 1.76867 4.03141 2.06873 4.33147C2.36878 4.63153 2.77575 4.8001 3.2001 4.8001H12.8001C13.0123 4.8001 13.2158 4.88438 13.3658 5.03441C13.5158 5.18444 13.6001 5.38792 13.6001 5.6001V8.0001M1.6001 3.2001V12.8001C1.6001 13.2244 1.76867 13.6314 2.06873 13.9315C2.36878 14.2315 2.77575 14.4001 3.2001 14.4001H12.8001C13.0123 14.4001 13.2158 14.3158 13.3658 14.1658C13.5158 14.0158 13.6001 13.8123 13.6001 13.6001V11.2001"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M14.4001 8V11.2H11.2001C10.7758 11.2 10.3688 11.0314 10.0687 10.7314C9.76867 10.4313 9.6001 10.0243 9.6001 9.6C9.6001 9.17565 9.76867 8.76869 10.0687 8.46863C10.3688 8.16857 10.7758 8 11.2001 8H14.4001Z"
          stroke="#1A1A1A"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
      <defs>
        <clipPath id="clip0_3032_8107">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default SubscriptionIcon;
