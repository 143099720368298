import { STORE_NAMES } from "const/global";
import { inject, observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { IViewerStore, TexturesMode } from "stores/ViewerStore";
import Box, { BoxCentered } from "views/shared/components/Box";
import SkyeSwitch from "views/shared/form/SkyeSwitch";
import styled from "styled-components";
import { IPublicModel, ModelHelpers, ModelStatus } from "models/Model";
import { useTranslation } from "react-i18next";
import { IAppContext } from "views/interfaces";
import classNames from "classnames";
import SkyeTooltip from "views/shared/components/SkyeTooltip";
import CircleLoader from "../../shared/components/CircleLoader";
import useTextureManager from "../../models/containers/ModelViewer/hooks/useTextureManager";

interface IViewerTexturePanelPropTypes {
  model: IPublicModel;
  ViewerStore?: IViewerStore;
}

const Panel = styled(Box)`
  &.disabled {
    opacity: 0.5;

    .skye-switch {
      pointer-events: none;
    }
  }

  .skye-switch {
    margin-right: ${(p: IAppContext) => p.theme.skye.sizes.medium};

    .label {
      color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
    }
  }
`;

const LoaderWrapper = styled(BoxCentered)`
  margin-right: 0.5em;
`;

const ViewerTexturePanel = ({
  model,
  ViewerStore,
}: IViewerTexturePanelPropTypes) => {
  const { t } = useTranslation();
  const [texture] = useTextureManager({ ViewerStore });
  const lowQualityTextureLoading =
    ViewerStore.texturesLoading && !ViewerStore.texturesLoaded;
  const textureModel = ModelHelpers.getTexturedModel(model);
  // TODO: fixme
  // Comment this temporarily to get rid of the error
  // For unknown reason, textureModel.status is initialized even if model is finished, same for pointlcoud model
  // const textureModelUploaded = textureModel?.status === ModelStatus.Uploaded;
  const toggleDisabled =
    // !textureModelUploaded ||
    !ViewerStore.viewerInitialized || lowQualityTextureLoading;

  const toggle = (newChecked: boolean) => {
    if (newChecked) {
      texture.showTextures();
      texture.setLastTexturesMode(TexturesMode.TEXTURES);
    } else {
      texture.hideTextures();
      texture.setLastTexturesMode(TexturesMode.POINTCLOUD);
    }
  };

  const renderSwitch = () => (
    <Panel className={classNames([{ disabled: toggleDisabled }])}>
      {ViewerStore.texturesLoading && (
        <LoaderWrapper>
          <CircleLoader size={15} />
        </LoaderWrapper>
      )}
      <SkyeSwitch
        reverse
        checked={textureModel && ViewerStore.texturesVisible}
        onChange={toggle}
        bottomPadding={false}
      >
        {t("textureLabel")}
      </SkyeSwitch>
    </Panel>
  );

  if (!toggleDisabled) {
    return renderSwitch();
  }

  let msg = "";
  if (!textureModel) {
    msg = t("texturesNotAvailable");
  } else if (lowQualityTextureLoading) {
    msg = t("textureLoading");
  }

  return <SkyeTooltip title={msg}>{renderSwitch()}</SkyeTooltip>;
};

export default inject(STORE_NAMES.ViewerStore)(observer(ViewerTexturePanel));
