import { useTranslation } from "react-i18next";
import Button from "../../../buttons/Button";
import SubscriptionInformationRow from "../../SubscriptionInformationRow/SubscriptionInformationRow";
import { useSubscriptionPaymentDialog } from "../../../../models/payments/useSubscriptionPaymentDialog";
import { PRICING_REQUEST_QUOTE_URL } from "../../../../../const/global";

interface ILiteSubscriptionInfoPropTypes {
  daysToExpire: number;
}

const PremiumExpiringSubscriptionInfo = ({
  daysToExpire,
}: ILiteSubscriptionInfoPropTypes) => {
  const subscription = useSubscriptionPaymentDialog();
  const pricingDialog = subscription.content;
  const { t } = useTranslation();

  const redirectToContact = () => {
    window.location.href = PRICING_REQUEST_QUOTE_URL;
  };

  return (
    <div id="subscription-expiring-info" className="subscription-info">
      {pricingDialog}
      <SubscriptionInformationRow
        color="danger"
        iconColor="danger"
        icon="clock"
        primaryText={`Your premium subscription expires in ${daysToExpire} days.`}
        // secondaryText={t("subscription.upgradeInfoDescription")}
        button={
          <Button
            className="w-max"
            color="primary"
            size="small"
            onClick={redirectToContact}
          >
            {t("subscription.renewNow")}
          </Button>
        }
      ></SubscriptionInformationRow>
    </div>
  );
};

export default PremiumExpiringSubscriptionInfo;
