import React from "react";
import styled from "styled-components";
import {
  BrowseType,
  IPublicModel,
  Model,
  ModelResolution,
  ModelStatus,
  SkyebrowseSettings,
  WidebrowseSettings,
} from "models/Model";
import InformationRow from "views/shared/components/InformationRow/InformationRow";
import { useTranslation } from "react-i18next";
import { IAppContext } from "views/interfaces";

interface IModelFlightSettingsSectionPropTypes {
  model: IPublicModel | Model;
}

const InformationSection = styled.div`
  padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

const imperial = (meters: number) => {
  return meters * 3.281;
};

const ModelFlightSettingsSection = ({
  model,
}: IModelFlightSettingsSectionPropTypes) => {
  const { t } = useTranslation();
  const sbSettings = model.settings as SkyebrowseSettings;
  const wbSettings = model.settings as WidebrowseSettings;
  const interior = model.browseType === BrowseType.Interior;

  const renderValue = (value?: any) => {
    const isEmptyString = !value && typeof value === "string";
    if (typeof value === "undefined" || isEmptyString) return t("unknown");
    if (typeof value === "boolean") return String(value);
    if (typeof value === "number") return `${imperial(value).toFixed(1)} ft`;
    return value;
  };

  return (
    <div className="model-flight-settings">
      <InformationSection className="base-details">
        <InformationRow
          title={t("browseType")}
          description={renderValue(model.browseType)}
        />
        {!interior && (
          <>
            <InformationRow
              title={t("droneType")}
              description={renderValue(
                model.droneType === "Mavic 2 Enterprise"
                  ? "Mavic 2 Enterprise Zoom"
                  : model.droneType
              )}
            />
            <InformationRow
              title={t("cameraType")}
              description={renderValue(model.cameraType)}
            />
            <InformationRow
              title={t("nightVision")}
              description={renderValue(!!model.nightVision)}
            />
            <InformationRow
              title={t("ultra")}
              description={renderValue(
                model.resolution === ModelResolution.fourK
              )}
            />
          </>
        )}
        {model.browseType === BrowseType.Widebrowse && !!wbSettings && (
          <>
            <InformationRow
              title={t("altitude")}
              description={renderValue(wbSettings.wideBrowseAltitude)}
            />
            <InformationRow
              title={t("sideScan")}
              description={renderValue(wbSettings.sideScan)}
            />
          </>
        )}
        {model.browseType === BrowseType.Skyebrowse && !!sbSettings && (
          <>
            {!!sbSettings.firstOrbitRadius && (
              <InformationRow
                title={t("firstOrbitRadius")}
                description={renderValue(sbSettings.firstOrbitRadius)}
              />
            )}
            {!!sbSettings.firstOrbitAltitude && (
              <InformationRow
                title={t("firstOrbitAltitude")}
                description={renderValue(sbSettings.firstOrbitAltitude)}
              />
            )}
            {!!sbSettings.secondOrbitRadius && (
              <InformationRow
                title={t("secondOrbitRadius")}
                description={renderValue(sbSettings.secondOrbitRadius)}
              />
            )}
            {!!sbSettings.secondOrbitAltitude && (
              <InformationRow
                title={t("secondOrbitAltitude")}
                description={renderValue(sbSettings.secondOrbitAltitude)}
              />
            )}
            {!!sbSettings.orbitRadius && (
              <InformationRow
                title={t("orbitRadius")}
                description={renderValue(sbSettings.orbitRadius)}
              />
            )}
          </>
        )}
        <InformationRow
          title={t("inQueue")}
          description={renderValue(model.status === ModelStatus.InQueue)}
        />
        <InformationRow
          title={t("processing")}
          description={renderValue(model.status === ModelStatus.Processing)}
        />
        <InformationRow
          title={t("modelCreated")}
          description={renderValue(model.status === ModelStatus.Finished)}
        />
      </InformationSection>
    </div>
  );
};

export default ModelFlightSettingsSection;
