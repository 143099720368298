import React from 'react'

interface IWeatherIconPropTypes {
  
}

const WeatherIcon = ({
  
}: IWeatherIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M20.5217 16.3768C23.019 16.3768 25.0435 14.3523 25.0435 11.8551C25.0435 9.35781 23.019 7.33334 20.5217 7.33334C18.2892 7.33334 16.4345 8.95134 16.0664 11.0787" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M22.8332 3.33333C22.8332 3.79358 22.4601 4.16667 21.9998 4.16667C21.5396 4.16667 21.1665 3.79358 21.1665 3.33333C21.1665 2.87309 21.5396 2.5 21.9998 2.5C22.4601 2.5 22.8332 2.87309 22.8332 3.33333Z" fill="#1A1A1A" stroke="#1A1A1A"/>
      <path d="M28.8332 6.66668C28.8332 7.12693 28.4601 7.50001 27.9998 7.50001C27.5396 7.50001 27.1665 7.12693 27.1665 6.66668C27.1665 6.20643 27.5396 5.83334 27.9998 5.83334C28.4601 5.83334 28.8332 6.20643 28.8332 6.66668Z" fill="#1A1A1A" stroke="#1A1A1A"/>
      <path d="M30.1667 12.6667C30.1667 13.1269 29.7936 13.5 29.3333 13.5C28.8731 13.5 28.5 13.1269 28.5 12.6667C28.5 12.2064 28.8731 11.8333 29.3333 11.8333C29.7936 11.8333 30.1667 12.2064 30.1667 12.6667Z" fill="#1A1A1A" stroke="#1A1A1A"/>
      <path d="M15.5002 5.33333C15.5002 5.79358 15.1271 6.16667 14.6668 6.16667C14.2066 6.16667 13.8335 5.79358 13.8335 5.33333C13.8335 4.87309 14.2066 4.5 14.6668 4.5C15.1271 4.5 15.5002 4.87309 15.5002 5.33333Z" fill="#1A1A1A" stroke="#1A1A1A"/>
      <path d="M6.30287 26.6628C4.09478 24.974 2.6665 22.2852 2.6665 19.2567C2.6665 14.1444 6.73664 10 11.7574 10C15.9554 10 19.4889 12.8975 20.5342 16.8355C21.3567 16.4106 22.2872 16.1711 23.2726 16.1711C26.6198 16.1711 29.3332 18.9341 29.3332 22.3423C29.3332 24.8729 27.8373 27.0477 25.6968 28" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default WeatherIcon;
