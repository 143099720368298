import React from "react";

interface IAnnotationIconPropTypes {}

const AnnotationIcon = ({}: IAnnotationIconPropTypes) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.8335 6.00006C6.8335 5.72392 7.05735 5.50006 7.3335 5.50006H15.9982H24.6668C24.943 5.50006 25.1668 5.72392 25.1668 6.00006V8.66665C25.1668 8.94279 24.943 9.16665 24.6668 9.16665C24.3907 9.16665 24.1668 8.94279 24.1668 8.66665V6.50006H16.4982V25.5H19.3335C19.6096 25.5 19.8335 25.7238 19.8335 26C19.8335 26.2761 19.6096 26.5 19.3335 26.5H15.9982H12.6668C12.3907 26.5 12.1668 26.2761 12.1668 26C12.1668 25.7238 12.3907 25.5 12.6668 25.5H15.4982V6.50006H7.8335V8.66665C7.8335 8.94279 7.60964 9.16665 7.3335 9.16665C7.05735 9.16665 6.8335 8.94279 6.8335 8.66665V6.00006Z"
        fill="#1A1A1A"
      />
      <path
        d="M23.5004 19.5005L26.5006 16.5003"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M24.5004 15.0002L24.7319 14.7322C25.2008 14.2633 25.8368 14 26.4999 14C27.1631 14 27.799 14.2635 28.2679 14.7325C28.7367 15.2014 29.0001 15.8374 29.0001 16.5005C29 17.1636 28.7366 17.7996 28.2676 18.2684L28.0006 18.5005"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M25.5004 21.0006L25.3019 21.2677C24.8275 21.7368 24.1872 21.9999 23.52 21.9999C22.8528 21.9999 22.2125 21.7368 21.7381 21.2677C21.5043 21.0364 21.3186 20.7611 21.1919 20.4577C21.0652 20.1542 21 19.8286 21 19.4998C21 19.1709 21.0652 18.8454 21.1919 18.5419C21.3186 18.2384 21.5043 17.9631 21.7381 17.7319L22.0001 17.5004"
        stroke="black"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default AnnotationIcon;
