import { memo, useEffect, useRef } from "react";
import { TacbrowseEntity } from "../../../../models/SessionData";
import Locator from "../../../viewer/services/tools/Locator";
import {
  getIcon,
  LOCATOR_ICONS_PATH,
  SharedLocatorProps,
} from "./SessionLocatorUtils";

interface ISessionLocatorComponentPropTypes extends SharedLocatorProps {
  userId: string;
  altitude: number;
}

const SessionViewerLocatorComponent = ({
  label,
  longitude,
  latitude,
  altitude,
  userId,
  active,
  findingType,
  senderType = TacbrowseEntity.Mobile,
}: ISessionLocatorComponentPropTypes) => {
  const measurement = useRef(null);
  const iconPath = `${LOCATOR_ICONS_PATH}/${getIcon(
    active,
    findingType,
    senderType
  )}`;

  const changeLocatorPosition = () => {
    if (measurement.current) {
      Locator.updateLocator(measurement.current, latitude, longitude, altitude);
    } else {
      measurement.current = Locator.addLocator(
        userId,
        label,
        latitude,
        longitude,
        altitude,
        senderType !== TacbrowseEntity.Mobile && iconPath
      );
    }

    if (active) {
      Locator.setLocatorActive(userId);
    }
  };

  useEffect(() => {
    changeLocatorPosition();
  }, [longitude, latitude]);

  useEffect(() => {
    changeLocatorPosition();

    return () => {
      Locator.removeLocator(measurement.current);
    };
  }, []);

  return null;
};

export default memo(SessionViewerLocatorComponent);
