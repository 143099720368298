import classNames from "classnames";
import { LoggedUser, UserHelpers } from "models/User";
import React from "react";
import styled from "styled-components";
import { IAppContext } from "views/interfaces";
import UserEmail from "../typography/UserEmail";
import UserName from "../typography/UserName";
import Box from "./Box";
import UserAvatar from "./UserAvatar";

interface IUserDetailsPropTypes {
  user: LoggedUser;
  selected?: boolean;
  statusIcon?: any;
}

const Wrapper = styled(Box)`
  align-items: center;
`;
const UserInfo = styled(Box)`
  flex-direction: column;
  margin-left: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;
const AvatarWrapper = styled.div`
  position: relative;
`;
const StatusIconWrapper = styled(Box)`
  position: absolute;
  right: -5px;
  bottom: -5px;
`;

const UserDetails = ({ user, selected, statusIcon }: IUserDetailsPropTypes) => {
  return (
    <Wrapper className={classNames(["user-details", { selected }])}>
      <AvatarWrapper>
        <UserAvatar
          userName={UserHelpers.getUserName(user)}
          selected={selected}
        />
        {Boolean(statusIcon) && (
          <StatusIconWrapper className="status-icon-wrapper">
            {statusIcon}
          </StatusIconWrapper>
        )}
      </AvatarWrapper>
      <UserInfo>
        <UserName>{user.firstName}</UserName>
        <UserEmail>{user.email}</UserEmail>
      </UserInfo>
    </Wrapper>
  );
};

export default UserDetails;
