import React from "react";
import NavbarBase from "../../components/NavbarBase";
import Heading from "../../../../typography/Heading";
import { useTranslation } from "react-i18next";
import NavbarSignInInfo from "../../components/NavbarSignInInfo";

interface DesktopNonAuthorizedNavbarProps {}

const DesktopNonAuthorizedNavbar = ({}: DesktopNonAuthorizedNavbarProps) => {
  const { t } = useTranslation();
  return (
    <div className="w-full desktop-non-authorized-navbar">
      <NavbarBase rightSide={<NavbarSignInInfo />}>
        <Heading>{t("model")}</Heading>
      </NavbarBase>
    </div>
  );
};

export default DesktopNonAuthorizedNavbar;
