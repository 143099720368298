import React from 'react'

interface IAlertIconPropTypes {
  
}

const AlertIcon = ({
  
}: IAlertIconPropTypes) => {
  return (
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M9.7002 8V12.1379" stroke="#DF2323" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M8.76591 3.38235L1.89277 15.2568C1.78279 15.4468 1.72479 15.6624 1.72461 15.8819C1.72443 16.1015 1.78207 16.3172 1.89173 16.5074C2.0014 16.6975 2.15922 16.8555 2.34931 16.9653C2.53941 17.0751 2.75508 17.133 2.97461 17.133H16.7209C16.9404 17.133 17.1561 17.0751 17.3462 16.9653C17.5363 16.8555 17.6941 16.6975 17.8038 16.5074C17.9134 16.3172 17.9711 16.1015 17.9709 15.8819C17.9707 15.6624 17.9127 15.4468 17.8027 15.2568L10.9296 3.38235C10.8198 3.19265 10.662 3.03516 10.4722 2.92567C10.2823 2.81618 10.0669 2.75854 9.84776 2.75854C9.62857 2.75854 9.41323 2.81618 9.22335 2.92567C9.03347 3.03516 8.87572 3.19265 8.76591 3.38235V3.38235Z" stroke="#DF2323" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.75 15.2068C10.1642 15.2068 10.5 14.871 10.5 14.4568C10.5 14.0426 10.1642 13.7068 9.75 13.7068C9.33579 13.7068 9 14.0426 9 14.4568C9 14.871 9.33579 15.2068 9.75 15.2068Z" fill="#DF2323"/>
    </svg>
  )
}

export default AlertIcon;
