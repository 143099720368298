import httpService from "services/HttpService";
import { MODEL_V3_URL } from "../routes";

export interface UploadPart {
  eTag: string;
  partNumber: number;
}

const videoPath = (modelId: string) => `${MODEL_V3_URL}/${modelId}/videos`;

export class ModelVideoService {
  static initUpload(modelId: string, inputId: string, fileName: string) {
    const url = `${videoPath(modelId)}/${inputId}`;
    return httpService.post(url, { fileName });
  }

  static signUploadPart(
    modelId: string,
    inputId: string,
    uploadId: string,
    partNumber: number,
    fileName: string
  ) {
    const url = `${videoPath(
      modelId
    )}/${inputId}/uploadId/${uploadId}/partNumber/${partNumber}`;
    return httpService.post(url, { fileName });
  }

  static completeUpload(
    modelId: string,
    inputId: string,
    uploadId: string,
    fileName: string,
    parts: UploadPart[]
  ) {
    const sortedAndFormatted = parts
      .sort((a, b) => a.partNumber - b.partNumber)
      .map((part) => ({
        ...part,
        eTag: RegExp(/\w+/).exec(part.eTag)[0],
      }));
    const url = `${videoPath(modelId)}/${inputId}/uploadId/${uploadId}`;
    return httpService.post(url, {
      parts: sortedAndFormatted,
      fileName,
    });
  }
}
