import React from "react";

const StreamIcon = () => {
  return (
    <svg
      width="19"
      height="16"
      viewBox="0 0 19 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.9556 0.749999C14.9596 0.749999 18.2056 3.99594 18.2056 8C18.2056 12.0041 14.9596 15.25 10.9556 15.25"
        stroke="#000"
        strokeLinecap="square"
      />
      <path
        d="M10.9557 3.16732C13.6251 3.16732 15.7891 5.33127 15.7891 8.00065C15.7891 10.67 13.6251 12.834 10.9557 12.834"
        stroke="#000"
        strokeLinecap="square"
      />
      <path
        d="M10.9554 5.58268C11.5963 5.58268 12.211 5.83729 12.6642 6.29051C13.1175 6.74372 13.3721 7.35841 13.3721 7.99935C13.3721 8.64029 13.1175 9.25498 12.6642 9.70819C12.211 10.1614 11.5963 10.416 10.9554 10.416"
        stroke="#000"
        strokeLinecap="square"
      />
      <path
        d="M1.26908 2.51519C0.704743 2.12298 0 2.51519 0 3.29962L0.00275285 12.7127C0.00275285 13.4174 0.707482 13.8893 1.27183 13.4971L7.68599 8.79057C8.17875 8.39836 8.17875 7.61394 7.68599 7.22173L1.26908 2.51519Z"
        fill="#000"
      />
    </svg>
  );
};

export default StreamIcon;
