import { memo } from "react";
import styles from "./BorderedBadge.module.css";
import classNames from "classnames";

interface IPremiumBadgePropTypes {
  children: string;
  color?: "danger" | "success" | "primary" | "grey";
}

const BorderedBadge = ({
  children,
  color = "grey",
}: IPremiumBadgePropTypes) => {
  return (
    <div className={classNames([styles.badge, styles[color]])}>{children}</div>
  );
};

export default memo(BorderedBadge);
