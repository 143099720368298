import { useState } from "react";
import { useTranslation } from "react-i18next";
import {
  AnalyticEventCategory,
  AnalyticService,
} from "../../../../services/AnalyticService";
import { ModelDownloadService } from "../../services/ModelDownloadService";
import { DocumentService } from "../../../../services/DocumentService";
import { MessageService } from "../../../../services/MessageService";
import { IUseDownloadModel, IUseDownloadModelProps } from "./useDownload.model";
import { ModelStatus } from "../../../../models/Model";

interface IUseDownloadGeoTiff extends IUseDownloadModel {
  fetchGeoTiff: () => Promise<Blob>;
}

export const useDownloadGeoTiff = ({
  model,
  hasAccess,
}: IUseDownloadModelProps): IUseDownloadGeoTiff => {
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const fetchGeoTiff = async (): Promise<Blob> => {
    const presignedUrl = await ModelDownloadService.geoTiffPresignedUrl(
      model.id
    );
    return await fetch(presignedUrl).then((res) => res.blob());
  };

  const getUrlAndDownload = async () => {
    if (!hasAccess) return;

    setLoading(true);
    AnalyticService.event(
      AnalyticEventCategory.ModelDetailsPage,
      "tiff_download"
    );

    const presignedUrl = await ModelDownloadService.geoTiffPresignedUrl(
      model.id
    );
    try {
      await DocumentService.fileExists(presignedUrl);
    } catch (e: any) {
      MessageService.info(t("geoTiffNotAvailable"));
      setLoading(false);
      return;
    }
    DocumentService.downloadFile(presignedUrl, t("downloadTiffFileName"));
    setTimeout(() => {
      setLoading(false);
    }, 1000);
  };

  const handleDownload = async () => {
    try {
      await getUrlAndDownload();
    } catch (error) {
      MessageService.error(t("requestFailed"));
    }
  };

  return {
    handleDownload,
    fetchGeoTiff,
    loading,
    isAvailable: model.status === ModelStatus.Finished && hasAccess,
  };
};
