import React, { Component } from 'react';
import ResetPasswordForm from '../forms/ResetPasswordForm';
import InputGroup from "../../../views/shared/form/InputGroup";
import { observer } from "mobx-react";
import Button from "../../../views/shared/buttons/Button";
import { withTranslation } from "react-i18next";
import styled from 'styled-components';
import Label from "../../../views/shared/typography/Label";
import authRequests from "../../../requests/auth";
import { withRouter } from "react-router-dom";
import { AUTH_ROUTES } from "../../../views/routes";
import { getResponseErrorTranslation } from "../../../utils/globalUtils";
import { ITranslationFields } from "../../../locale/dictionary";
import { IAppContext } from "../../../views/interfaces";
import GlobalStore from "../../../stores/GlobalStore";

const StyledForm = styled.form`
  width: 100%;
  min-width: 300px;

  .btn {
    margin-top: ${(p: IAppContext) => p.theme.skye.sizes.large};
  }
`;

interface IMobxForm {
  isValid?: boolean,
  submitted?: boolean,
  validateError?: undefined | string,
  submit: () => void,
}

interface IResetPasswordForm extends IMobxForm {
  newPassword: string,
  newPasswordRepeat: string,
}

interface IPathParams {
  token: string,
}

interface State {
  requestError: false | ITranslationFields;
  formSent: boolean;
  loading: boolean;
}

@observer
class ResetPasswordContainer extends Component<any, State> {
  form: IResetPasswordForm = new ResetPasswordForm();

  constructor(props) {
    super(props);

    this.state = {
      formSent: false,
      requestError: false,
      loading: false,
    };
  }

  handleSubmit = () => {
    const { match, history } = this.props;
    this.form.submit();
    this.setState({ requestError: false });

    if (this.form.isValid) {
      const { t } = this.props;
      const { token } = match.params;
      const { newPassword, newPasswordRepeat } = this.form;
      const data = { newPassword, newPasswordRepeat };

      this.setState({ loading: true });
      authRequests
        .resetPassword(token, data)
        .then(() => {
          GlobalStore.addToast(t("passwordChanged"));
          this.setState({ formSent: true });
          history.push(AUTH_ROUTES.login);
        })
        .catch((err) =>
          this.setState({ requestError: getResponseErrorTranslation(err) })
        )
        .finally(() => this.setState({ loading: false }));
    }
  };

  render() {
    const { t } = this.props;
    const { requestError, loading } = this.state;
    const { isValid, submitted, validateError } = this.form;

    return (
      <StyledForm>
        <InputGroup
          bordered
          label="newPasswordLabel"
          inputProps={{
            type: "password",
            value: this.form.newPassword,
            placeholder: "newPasswordPlaceholder",
            onChange: (e: any) => (this.form.newPassword = e.target.value),
          }}
        />
        <InputGroup
          bordered
          label="newPasswordRepeatLabel"
          inputProps={{
            type: "password",
            value: this.form.newPasswordRepeat,
            placeholder: "newPasswordRepeatPlaceholder",
            onChange: (e: any) =>
              (this.form.newPasswordRepeat = e.target.value),
          }}
        />
        {submitted && !isValid && (
          <Label color="danger">{t(validateError)}</Label>
        )}
        {!!requestError && <Label color="danger">{t(requestError)}</Label>}
        <Button loading={loading} onClick={this.handleSubmit}>
          {t("resetPassword")}
        </Button>
      </StyledForm>
    );
  }
}

export default withTranslation()(withRouter(ResetPasswordContainer));
