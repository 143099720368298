interface IHookProps {
  streamsOpen: boolean;
}

const PADDING = 20;
const STREAMS_HEIGHT = 160;
const SIDE_PANEL_TOP_POSITION = 65;
const SIDE_PANEL_BOTTOM_POSITION = 95;

const sessionViewerUIHook = ({ streamsOpen }: IHookProps) => {
  const bottomControlsPosition = streamsOpen
    ? PADDING * 1.5 + STREAMS_HEIGHT
    : PADDING;

  const sidePanelHeight = () => {
    const paddingValues =
      SIDE_PANEL_BOTTOM_POSITION +
      SIDE_PANEL_TOP_POSITION +
      bottomControlsPosition;
    return `calc(100% - ${paddingValues}px)`;
  };

  const streamingRowStyles = {
    display: streamsOpen ? "flex" : "none",
    bottom: `${PADDING}px`,
    width: `calc(100% - ${2 * PADDING}px)`,
    left: `${PADDING}px`,
  };
  const viewerControlsRowStyles = {
    bottom: `${bottomControlsPosition}px`,
  };
  const rightSidePanelsStyles = {
    right: PADDING,
    bottom: `${2 * PADDING * 1.5 + STREAMS_HEIGHT}px`,
    height: sidePanelHeight(),
    top: `${SIDE_PANEL_TOP_POSITION}px`,
  };

  return [viewerControlsRowStyles, streamingRowStyles, rightSidePanelsStyles];
};

export default sessionViewerUIHook;
