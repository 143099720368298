import React, { useEffect, useState } from "react";
import axios from "axios";
import { inject, observer } from "mobx-react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { MessageService } from "../../../../../services/MessageService";
import Box from "../../../../shared/components/Box";
import DropZone from "../../../../shared/components/DropZone";
import Title from "../../../../shared/typography/Title";
import { IModelUploadContainer } from "../interface";
import { withRouter } from "react-router-dom";
import ModelNameInputGroup from "../components/ModelNameInputGroup";
import { FormGroup } from "../../../../shared/form/FormGroup";
import Button from "../../../../shared/buttons/Button";
import { BrowseType, Model } from "../../../../../models/Model";
import { IAppContext } from "../../../../interfaces";
import ModelService from "../../../services/ModelService";
import { ModelFlightLogService } from "../../../services/ModelFlightLogService";
import breakpoint from "styled-components-breakpoint";
import { useGlobalEventEmitter } from "../../../../shared/context/GlobalEventEmitter";
import { EventTypes } from "../../../../../const/events";
import { ModelUploadFileValidator } from "../utils/ModelUploadFileValidator";
import { SubscriptionType } from "../../../../payments/payments.model";

interface IUniversalUploadPropTypes extends IModelUploadContainer {
  interiorChecked?: boolean;
  defaultModelName?: string;
}

const Wrapper = styled(Box)`
  flex-direction: column;

  .upload-title {
    margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};
  }
`;

const UploadRow = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${(p: IAppContext) => p.theme.skye.sizes.medium};

  ${breakpoint("md")`
    flex-direction: row;
  `}
`;

const UploadColumn = styled(Box)`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

const SubTitle = styled.h4`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;

const AllowedExtensions = styled.p`
  font-size: 0.8em;
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;

const FileInfo = styled.div`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  padding: ${(p: IAppContext) => p.theme.skye.sizes.small};
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;

const FileName = styled.span`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveButton = styled.button`
  background: none;
  border: none;
  color: ${(p: IAppContext) => p.theme.skye.colors.redOrange};
  cursor: pointer;
`;

const FileDisplay = ({ file, onRemove, removeText }) => (
  <FileInfo>
    <FileName>{file.name}</FileName>
    <RemoveButton onClick={onRemove}>{removeText}</RemoveButton>
  </FileInfo>
);

const UniversalUpload = ({
  interiorChecked,
  defaultModelName,
  onLoad,
  VideoUploadStore,
  GlobalStore,
}: IUniversalUploadPropTypes) => {
  const eventEmitter = useGlobalEventEmitter();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [videoFile, setVideoFile] = useState<File | null>(null);
  const [logsFile, setLogsFile] = useState<File | null>(null);
  const [modelName, setModelName] = useState(
    defaultModelName || (t("upload.defaultUniversalModelName") as string)
  );

  useEffect(() => {
    setModelName(
      defaultModelName || (t("upload.defaultUniversalModelName") as string)
    );
  }, [defaultModelName, setModelName, t]);

  const resetState = () => {
    setVideoFile(null);
    setLogsFile(null);
    setModelName(defaultModelName);
  };

  const onDrop = async (files: File[], type: "video" | "gps") => {
    if (!files.length) return;
    const subscriptionType = GlobalStore.user.organization.active
      ? GlobalStore.user.organization.subscription.type.value
      : SubscriptionType.Freemium;
    const result = ModelUploadFileValidator(files, subscriptionType);

    if (result.sizeExceededFiles.length) {
      let errorMessage: string;
      switch (subscriptionType) {
        case SubscriptionType.Freemium:
          errorMessage = "upload.freemiumSizeExceeded";
          break;
        case SubscriptionType.Lite:
          errorMessage = "upload.liteSizeExceeded";
          break;
        case SubscriptionType.Premium:
          errorMessage = "upload.premiumSizeExceeded";
          break;
      }
      MessageService.error(t(errorMessage));
      return;
    }

    if (type === "video") {
      if (result.videoFiles.length) {
        const videoFile = result.videoFiles[result.videoFiles.length - 1];
        if (videoFile.type.startsWith("video/")) {
          setVideoFile(videoFile);
        }
      } else {
        MessageService.error(t("toastWrongExtensionCount"));
      }
    } else if (type === "gps") {
      if (result.logsFiles.length) {
        const logsFile = result.logsFiles[result.logsFiles.length - 1];
        const validExtensions = [".srt", ".ass", ".txt", ".csv"];
        const fileExtension =
          "." + logsFile.name.split(".").pop()?.toLowerCase();
        if (validExtensions.includes(fileExtension)) {
          setLogsFile(logsFile);
        }
      } else {
        MessageService.error(t("toastWrongExtensionCount"));
      }
    }
  };

  const uploadLogs = async (model: Model) => {
    // Obtaining presigned url
    const { presignedUrl } = await ModelFlightLogService.getPresignedUrlForLogs(
      model.id,
      {
        fileName: logsFile.name.toLocaleLowerCase(),
      }
    );

    const blob = new Blob([logsFile], { type: logsFile.type });
    await axios.put(presignedUrl, blob, {
      headers: {
        "Content-Type": logsFile.type,
      },
    });
  };

  const handleSubmit = async () => {
    if (!videoFile) {
      MessageService.error(t("pleaseSelectVideoFile"));
      return;
    }

    onLoad(true);
    setLoading(true);

    try {
      const model = await createModel();
      eventEmitter.trigger(EventTypes.ModelCreated, model);
      if (logsFile) await uploadLogs(model);

      VideoUploadStore.addToQueue(
        videoFile,
        videoFile.name,
        model.id,
        String(model.inputs[0].id)
      );
      GlobalStore.uploadDialogOpen = false;
      resetState();
    } catch (error) {
      MessageService.error(t("requestFailed"));
    } finally {
      setLoading(false);
      onLoad(false);
    }
  };

  const createModel = async (): Promise<Model> => {
    const res = await ModelService.createModel({
      name: modelName,
      browseType: interiorChecked ? BrowseType.Interior : BrowseType.Universal,
      videoSize: videoFile?.size,
      external: true,
      droneManufacturer: undefined,
      droneType: undefined,
    });
    return res.data as Model;
  };

  const renderUploadBox = (type: "video" | "gps") => {
    const file = type === "video" ? videoFile : logsFile;
    const setFile = type === "video" ? setVideoFile : setLogsFile;
    const title =
      type === "video"
        ? t("chooseUniversalUploadFilesTitleDescription")
        : t("GPS Files (optional)");
    const allowedExtensions =
      type === "video"
        ? t("allowedVideoExtensions")
        : t("allowedGPSExtensions");

    return (
      <UploadColumn>
        <SubTitle>{title}</SubTitle>
        {file ? (
          <FileDisplay
            file={file}
            onRemove={() => setFile(null)}
            removeText={t("Remove")}
          />
        ) : (
          <DropZone
            onDrop={(files) => onDrop(files, type)}
            backgroundColor="white"
            multiple={false}
          />
        )}
        <AllowedExtensions>{allowedExtensions}</AllowedExtensions>
      </UploadColumn>
    );
  };

  return (
    <Wrapper>
      <FormGroup>
        <ModelNameInputGroup value={modelName} onChange={setModelName} />
      </FormGroup>
      <FormGroup>
        <Title className="upload-title">
          {t("chooseUniversalUploadFilesTitle")}
        </Title>
        <UploadRow>
          {renderUploadBox("video")}
          {renderUploadBox("gps")}
        </UploadRow>
      </FormGroup>
      <FormGroup>
        <Box justifyContent="flex-end">
          <Button
            loading={loading}
            disabled={!videoFile || loading}
            onClick={handleSubmit}
          >
            {t("upload")}
          </Button>
        </Box>
      </FormGroup>
    </Wrapper>
  );
};

export default withRouter(
  inject("GlobalStore", "VideoUploadStore")(observer(UniversalUpload))
);
