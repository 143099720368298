import React from 'react'

interface IEmailIconPropTypes {
  
}

const EmailIcon = ({
  
}: IEmailIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29 5L3 14.1667L10.4286 17.8333L21.5714 10.5L14.1429 19.6667L25.2857 27L29 5Z" stroke="#1A1A1A" strokeLinejoin="round"/>
    </svg>
  )
}

export default EmailIcon;
