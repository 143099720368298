import { ReactElement, useState } from "react";
import { ensureHttps } from "../../../utils/stringUtils";
import ViewerAnnotationToolDialog, {
  IViewerAnnotationDialogValues,
} from "../components/ViewerAnnotationToolDialog";
import { Annotation, AnnotationInsert } from "../services/tools/Annotation";
import TextLabel from "../services/tools/TextLabel";
import useAnnotations from "./useAnnotations";

const INITIAL_VALUES: IViewerAnnotationDialogValues = {
  title: "",
  description: "",
  url: "",
};

export const useAnnotationTool = (): [ReactElement, () => void] => {
  const { addAnnotation } = useAnnotations();
  const [_open, setOpen] = useState(false);
  const [values, setValues] =
    useState<IViewerAnnotationDialogValues>(INITIAL_VALUES);

  const init = () => {
    setValues(INITIAL_VALUES);
    setOpen(true);

    setTimeout(() => {
      const annotationInputRef = document.getElementById(
        "annotation-title-input"
      );
      if (annotationInputRef) {
        annotationInputRef.focus();
      }
    }, 200);
  };

  const createAnnotation = () => {
    const data = {
      uuid: "",
      title: values.title,
      description: values.description,
      url: ensureHttps(values.url),
    };
    const newAnnotation = Annotation.startInsertion(data);
    addAnnotation({ ...data, uuid: newAnnotation.uuid });
  };

  const createTextLabel = () => {
    TextLabel.add(values.title);
  };

  const handleSubmit = () => {
    setOpen(false);

    if (values.description || values.url) {
      createAnnotation();
      return;
    }

    createTextLabel();
  };

  const dialog = () => (
    <ViewerAnnotationToolDialog
      open={_open}
      values={values}
      onClose={() => setOpen(false)}
      onChange={setValues}
      onSubmit={handleSubmit}
    />
  );

  return [dialog(), init];
};
