import React from "react";
import useSubscription from "../../../../../../account/hooks/useSubscription";
import useCanUpload from "../../../../../../models/hooks/useCanUpload";
import ProgressBar from "../../../../ProgressBar";

interface UploadLimitInfoProps {}

const LimitInfo = ({}: UploadLimitInfoProps) => {
  const { canUpload, loaded, currentModelsCount, maxModelsCount } =
    useCanUpload();

  if (!loaded || canUpload) {
    return null;
  }

  const percent = Math.floor((currentModelsCount / maxModelsCount) * 100);

  if (!canUpload) {
    return (
      <div className="upload-limit-info flex items-start flex-col justify-center bg-danger-50 h-10 p-2 rounded-md w-[200px]">
        <div className="text-xs mb-0.5 w-full flex items-center justify-between">
          <div>Models limit reached:</div>
          <div>
            <b className="text-danger-500">
              {currentModelsCount}/{maxModelsCount}
            </b>{" "}
          </div>
        </div>
        <ProgressBar variant="danger" percent={percent} />
      </div>
    );
  }

  return (
    <div className="upload-limit-info flex items-start flex-col justify-center bg-danger-50 h-10 p-2 rounded-md w-[200px]">
      <div className="text-xs mb-0.5 w-full flex items-center justify-between">
        <div>Used models:</div>
        <div>
          <b>
            {currentModelsCount}/{maxModelsCount}
          </b>{" "}
        </div>
      </div>
      <ProgressBar percent={percent} />
    </div>
  );
};

const UploadLimitInfo = ({}: UploadLimitInfoProps) => {
  const subscription = useSubscription();

  if (subscription.active) {
    return;
  }

  return <LimitInfo />;
};

export default UploadLimitInfo;
