import React, { ReactNode, DOMAttributes } from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";
import classNames from 'classnames';
import Box from '../components/Box';

type IButtonColorTypes = 'primary' | 'description'

interface ILinkButtonPropTypes extends DOMAttributes<HTMLDivElement> {
  children: ReactNode,
  className?: string,
  color?: IButtonColorTypes,
}

const StyledLinkButton = styled(Box)`
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  align-items: center;

  &.primary {
    color: ${(p: IAppContext) => p.theme.skye.colors.primary};
  }

  &.description {
    color: ${(p: IAppContext) => p.theme.skye.colors.textDescription};
  }
`;

const LinkButton = ({
  children,
  className,
  color = 'primary',
  ...props
}: ILinkButtonPropTypes) => {
  return (
    <StyledLinkButton
      className={classNames([className, color, 'link-button'])}
      role="button" {...props}
    >
      {children}
    </StyledLinkButton>
  )
}

export default LinkButton;
