import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import Box from "../../shared/components/Box";

const BaseBar = styled(Box)`
  align-items: center;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border-radius: 2px;
  min-height: 27px;
  justify-content: center;
  padding: 0 10px;
  border: ${(p: IAppContext) => p.theme.skye.borders.primary};
`;

export default BaseBar;
