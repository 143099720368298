import { ReactNode, memo } from "react";
import styled from "styled-components";
import { IAppContext } from "../../../interfaces";
import { BoxCentered } from "../../../shared/components/Box";
import SubscriptionTypeBadge from "../../../shared/components/badges/SubscriptionTypeBadge/SubscriptionTypeBadge";
import Title from "../../../shared/typography/Title";
import Description from "../../../shared/typography/Description";
import Button, { IButtonPropTypes } from "../../../shared/buttons/Button";
import { useTranslation } from "react-i18next";

export interface IPaidAccessInfoBoxProps {
  title: string;
  description: string;
  payPerModelButtonProps?: IButtonPropTypes;
  subscriptionModelButtonProps?: IButtonPropTypes;
  showPayPerModelButton?: boolean;
  showSubscriptionButton?: boolean;
  subscriptionButtonDescription?: ReactNode;
}
const InnerBox = styled(BoxCentered)`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  flex-direction: column;
  width: 100%;
  padding: ${(p: IAppContext) => p.theme.skye.sizes.large};
  text-align: center;
  z-index: 1000;

  .subscription-type-badge {
    margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  }

  .description {
    margin: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  }
`;

const Image = styled.img`
  height: 112px;
  width: auto;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
`;

const PaidAccessBox = ({
  showPayPerModelButton,
  showSubscriptionButton,
  description,
  title,
  payPerModelButtonProps,
  subscriptionModelButtonProps,
  subscriptionButtonDescription,
}: IPaidAccessInfoBoxProps) => {
  const { t } = useTranslation();
  return (
    <InnerBox>
      <SubscriptionTypeBadge />
      <Title>{title}</Title>
      <Image src="/images/subscription_rocket.svg" alt="upgrade subscription" />
      <Description>{description}</Description>
      {showPayPerModelButton && <Button fullSize {...payPerModelButtonProps} />}
      {showPayPerModelButton && showSubscriptionButton && (
        <div className="flex items-center my-2">{t("or")}</div>
      )}
      {showSubscriptionButton && (
        <div>
          <Button
            fullSize
            color="primary"
            type="light"
            {...subscriptionModelButtonProps}
          />
          {!!subscriptionButtonDescription && (
            <div className="w-full">{subscriptionButtonDescription}</div>
          )}
        </div>
      )}
    </InnerBox>
  );
};

export default memo(PaidAccessBox);
