import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import { IAppContext } from "../../../../interfaces";
import { OrganizationService } from "services/OrganizationService";
import SkyeTable from "../../../../shared/components/SkyeTable";
import Button from "../../../../shared/buttons/Button";
import UserAvatar from "../../../../shared/components/UserAvatar";
import Box, {
  BoxAlignedCenter,
  BoxCentered,
} from "../../../../shared/components/Box";
import Title from "../../../../shared/typography/Title";
import AddUserIcon from "../../../../shared/Icons/AddUserIcon";
import {
  IOpenUserRole,
  IOrganizationUser,
  LoggedUser,
  UserHelpers,
} from "models/User";
import SkyeTooltip from "../../../../shared/components/SkyeTooltip";
import InviteUserDialogContainer, {
  IInviteUserDialogInvite,
} from "../../../containers/InviteUserDialogContainer";
import { getResponseErrorTranslation } from "../../../../../utils/globalUtils";
import { IResponse } from "../../../../../services/HttpService";
import SettingsIcon from "../../../../shared/Icons/SettingsIcon";
import useChangePermissionHook from "../../../hook/useChangePermissionHook";
import { Organization } from "../../../../../models/Organization";
import useCurrentUser from "../../../../account/hooks/useCurrentUser";
import GlobalStore from "../../../../../stores/GlobalStore";

interface IOrganizationUsersCardContainerPropTypes {}

const UserRow = styled.tr``;
const Wrapper = styled.div``;
const Cell = styled.td`
  min-width: 140px;
`;
const EmailCell = styled(Cell)`
  color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
`;
const UserNameWrapper = styled(BoxAlignedCenter)`
  font-weight: 500;

  .skye-avatar {
    margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
  }
`;
const TopRow = styled(BoxAlignedCenter)`
  justify-content: space-between;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.xLarge};
`;
const StatusBadge = styled(BoxCentered)`
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.2px;
  height: 20px;
  padding: 0 8px;
  color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  text-transform: uppercase;
  border-radius: 2px;
`;
const ActiveBadge = styled(StatusBadge)`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.successSecondary};
`;
const PendingBadge = styled(StatusBadge)`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.warning};
`;

const roleDictionary = (
  user: IOrganizationUser,
  currentOrganization: Organization
): string => {
  if (!user || !currentOrganization) {
    return;
  }

  if (user.id === currentOrganization.ownerId) {
    return "owner";
  }

  if (UserHelpers.hasRole(user, IOpenUserRole.OrganizationMaintainer)) {
    return "organizationMaintainer";
  }

  return "user";
};

const OrganizationUsersContainer =
  ({}: IOrganizationUsersCardContainerPropTypes) => {
    const { user, isOwner, hasRole } = useCurrentUser();
    const { t } = useTranslation();
    const [invitationsLoading, setInvitationLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
    const [users, setUsers] = useState<Array<LoggedUser>>([]);
    const canEditPermissions =
      isOwner || hasRole(IOpenUserRole.OrganizationMaintainer);

    const getUsers = async () => {
      setLoading(true);
      setUsers(await OrganizationService.getUsers());
      setLoading(false);
    };

    const [editPermissionsDialog, openPermissionsDialog] =
      useChangePermissionHook({ onChange: getUsers });

    const usersLimit = user?.organization?.subscription?.pilotsLimit;
    const organisationCanInvite =
      user?.organization?.active && users.length < usersLimit;

    const handleOpenInviteModalClick = () => {
      if (!organisationCanInvite) return;
      setInviteDialogOpen(true);
    };

    const handleUserInvited = () => {
      GlobalStore.addToast(t("emailSent"));
      onClose();
    };

    const onClose = () => {
      setInviteDialogOpen(false);
    };

    const catchInviteError = (err: IResponse) => {
      const msg =
        err.data.statusCode === 402
          ? "usersLimitExceededInformation"
          : getResponseErrorTranslation(err);
      onClose();
      GlobalStore.addToast(t(msg), "error");
    };

    const handleInvite = (data: IInviteUserDialogInvite) => {
      const { email, firstName, lastName, organizationAccess } = data;
      setInvitationLoading(true);

      OrganizationService.inviteUser(
        email,
        firstName,
        lastName,
        organizationAccess
      )
        .then(handleUserInvited)
        .catch(catchInviteError)
        .finally(() => setInvitationLoading(false));
    };

    const handleOpenPermissionDialog = (user: IOrganizationUser) => () => {
      openPermissionsDialog(user);
    };

    useEffect(() => {
      getUsers();
    }, []);

    const inviteBtn = (
      <Button
        disabled={!organisationCanInvite || !canEditPermissions}
        iconColorBy="fill"
        startIcon={<AddUserIcon />}
        onClick={handleOpenInviteModalClick}
      >
        {t("invite")}
      </Button>
    );

    return (
      <Wrapper>
        <TopRow>
          <Title>
            {t("members")} {`(${users.length}/${usersLimit})`}
          </Title>
          {organisationCanInvite ? (
            inviteBtn
          ) : (
            <SkyeTooltip title={t("usersLimitExceededInformation")}>
              {/* div to fix issue with tooltip for disabled button when events disabled */}
              <div>{inviteBtn}</div>
            </SkyeTooltip>
          )}
        </TopRow>
        <SkyeTable>
          <thead>
            <tr>
              <td>{t("name")}</td>
              <td>{t("email")}</td>
              <td>{t("status")}</td>
              <td>{t("role")}</td>
              {canEditPermissions && <td></td>}
            </tr>
          </thead>
          <tbody>
            {users.map((user) => {
              const userName = UserHelpers.getUserName(user);

              return (
                <UserRow key={user.id}>
                  <Cell>
                    <UserNameWrapper>
                      <UserAvatar userName={userName} />
                      {userName}
                    </UserNameWrapper>
                  </Cell>
                  <EmailCell>{user.email}</EmailCell>
                  <Cell>
                    <Box>
                      {user.confirmed ? (
                        <ActiveBadge>{t("active")}</ActiveBadge>
                      ) : (
                        <PendingBadge>{t("pending")}</PendingBadge>
                      )}
                    </Box>
                  </Cell>
                  <Cell>
                    <Box>{t(roleDictionary(user, user.organization))}</Box>
                  </Cell>
                  {canEditPermissions && (
                    <Cell>
                      <Box onClick={handleOpenPermissionDialog(user)}>
                        <SettingsIcon />
                      </Box>
                    </Cell>
                  )}
                </UserRow>
              );
            })}
          </tbody>
        </SkyeTable>
        {canEditPermissions && editPermissionsDialog}
        {inviteDialogOpen && (
          <InviteUserDialogContainer
            loading={invitationsLoading}
            open={inviteDialogOpen}
            onClose={onClose}
            onInvite={handleInvite}
          />
        )}
      </Wrapper>
    );
  };

export default OrganizationUsersContainer;
