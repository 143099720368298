import { getEnvVariables } from "../../../env";

const env = getEnvVariables();

export const useStripeSubscription = () => {
  const openSubscriptionPage = (userEmail: string) => {
    window.open(
      `https://billing.stripe.com/p/login/${
        env.STRIPE_CUSTOMER_PORTAL_ID
      }?prefilled_email=${encodeURIComponent(userEmail)}`
    );
  };

  return {
    openSubscriptionPage,
  };
};
