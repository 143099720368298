import localEnvVariables from "./local.env";
import envVariables from "./env";

export interface EnvVariables {
  API_URL: string;
  API_V3_URL: string;
  UPLOAD_BUCKET_URL: string;
  APP_DOMAIN: string;
  MAP_URL: string;
  GOOGLE_MAPS_API_KEY: string;
  GOOGLE_ANALYTIC_API_KEY: string;
  STRIPE_PRICING_TABLE_ID: string;
  STRIPE_PUBLISHABLE_KEY: string;
  STRIPE_CUSTOMER_PORTAL_ID: string;
  FACEBOOK_CONVERSION_API_KEY: string;
  FACEBOOK_PIXEL_ID: string;
}

enum Envs {
  Staging = "staging",
  Dev = "dev",
  Local = "local",
  Production = "prod",
}

export const getEnvVariables = (): EnvVariables => {
  /**
   * Variables passed by script to client App
   */
  let envVars = {};

  switch (getEnv()) {
    case "local":
      envVars = localEnvVariables;
      break;

    default:
      envVars = envVariables;
      break;
  }

  return envVars as EnvVariables;
};

// there's no local stage in cloud, this is a function that provides stage for cloud resources
// on local one should use dev
export const getCloudStage = () => {
  return getEnv() == Envs.Local ? Envs.Dev : getEnv();
};

export const getEnv = () => {
  switch (window.origin) {
    case "https://dev.map.skyebrowse.com":
      return Envs.Dev;
    case "https://staging.map.skyebrowse.com":
      return Envs.Staging;
    case "https://map.skyebrowse.com":
      return Envs.Production;
    default:
      return Envs.Local;
  }
};

export const isLocal = (): boolean => {
  return getEnv() === Envs.Local;
};

export const isDev = (): boolean => {
  return getEnv() === Envs.Dev;
};

export const isStaging = (): boolean => {
  return getEnv() === Envs.Staging;
};

export const isProduction = (): boolean => {
  return getEnv() === Envs.Production;
};

export default {};
