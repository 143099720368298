import React from 'react'

interface IReferencePointIconPropTypes {
  
}

const ReferencePointIcon = ({
  
}: IReferencePointIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M13.3335 8L16.0002 10.6667L18.6668 8" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 10.6667V2.66669" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M13.3335 24L16.0002 21.3333L18.6668 24" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 21.3333V29.3333" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.0002 13.3333L21.3335 16L24.0002 18.6666" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M21.3335 16H29.3335" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8 13.3333L10.6667 16L8 18.6666" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M10.6665 16H2.6665" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M15.9998 17.3334C16.7362 17.3334 17.3332 16.7364 17.3332 16C17.3332 15.2636 16.7362 14.6667 15.9998 14.6667C15.2635 14.6667 14.6665 15.2636 14.6665 16C14.6665 16.7364 15.2635 17.3334 15.9998 17.3334Z" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
  )
}

export default ReferencePointIcon;
