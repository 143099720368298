import React, { useEffect } from 'react';
import { Prompt } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

interface ISkyePromptPropTypes {
  active?: boolean,
}

const SkyePrompt = ({
  active = true,
}: ISkyePromptPropTypes) => {
  const { t } = useTranslation();

  useEffect(() => {
    window.onbeforeunload = () => {
      if (active) return t('promptUploadingVideos');
    }

    return () => {
      window.onbeforeunload = () => {
        // Overwriting function to remove effect
      }
    }
  }, [t, active]);

  if (!active) return null;

  return (
    <Prompt message={t('promptUploadingVideos')} />
  );
};

export default SkyePrompt;
