import { useCallback, useContext } from "react";
import { ViewerAnnotationsContext } from "../context/ViewerAnnotationsContext";
import { IAnnotation } from "../interfaces";
import { Annotation } from "../services/tools/Annotation";

const useAnnotations = () => {
  const context = useContext(ViewerAnnotationsContext);
  if (!context) throw new Error("Annotation context not provided");

  const { annotations, setAnnotations } = context;

  const reloadAnnotations = useCallback(() => {
    setAnnotations(Annotation.getAll());
  }, [setAnnotations]);

  const addAnnotation = (data: IAnnotation) => {
    setAnnotations([...annotations, data]);
  };

  const removeAnnotation = (annotation: IAnnotation) => {
    setAnnotations(annotations.filter((a) => a.uuid !== annotation.uuid));
    const potreeAnnotation = Annotation.getByUuid(annotation.uuid);
    Annotation.remove(potreeAnnotation);
  };

  const removeAllAnnotations = () => {
    setAnnotations([]);
    Annotation.removeAll();
  };

  const updateAnnotation = (annotation: IAnnotation) => {
    // TODO: implement
    // setAnnotations(
    //   annotations.map((a) => (a.uuid === annotation.uuid ? annotation : a))
    // );
  };

  if (!context) {
    throw new Error(
      "useAnnotations must be used within a ViewerAnnotationsProvider"
    );
  }

  return {
    annotations,
    addAnnotation,
    removeAnnotation,
    updateAnnotation,
    setAnnotations,
    reloadAnnotations,
    removeAllAnnotations,
  };
};

export default useAnnotations;
