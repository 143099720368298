import { Model } from "models/Model";
import React from "react";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import breakpoint from "styled-components-breakpoint";
import { IAppContext } from "views/interfaces";
import Box from "views/shared/components/Box";
import NoVideoIcon from "views/shared/Icons/NoVideoIcon";
import ModelThumbnail from "../../../../../components/ModelThumbnail";
import ModelVideo from "../../../../../components/ModelVideo";
import ModelVideoDuration from "../../../../../components/ModelVideoDuration";
import { noOp } from "../../../../../../../utils/globalUtils";

interface IModelListElementVideoSectionPropTypes extends RouteComponentProps {
  modelId: string;
  thumbnailsUrls: string[];
  videoDuration: number;
  loading?: boolean;
  detailsPageRoute: string;
}

const ThumbnailsContainer = styled(Box)`
  height: 100%;
  width: 100%;
  align-items: center;
  justify-content: center;
  position: relative;
`;
const NoVideoWrapper = styled(Box)`
  cursor: pointer;
  width: 100%;
  height: 100%;
  min-height: 280px;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-color: ${(p: IAppContext) =>
    p.theme.skye.colors.backgroundThumbnail};

  svg {
    font-size: 60px;

    path {
      fill: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
    }

    line {
      stroke: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
    }
  }
`;
const VideoWrapper = styled(Box)`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  width: 100%;
  overflow: hidden;
  position: relative;
  align-items: center;
  justify-content: center;
  min-height: 152px;

  .video-duration {
    position: absolute;
    right: 6px;
    bottom: 6px;

    ${breakpoint("md")`
      bottom: 12px;
    `}
  }

  ${breakpoint("md")`
    margin-bottom: 0;
    max-width: 410px;
    min-width: 410px;
    min-height: 230px;
  `}
  .thumbnail-wrapper {
    width: 100%;
    height: fit-content;

    ${breakpoint("md")`
      height: 230px;
    `}
    img {
      width: 100%;
      height: auto;
    }
  }

  .no-video {
    height: 230px;
    min-height: 230px;
  }
`;

const ModelListElementVideoSection = ({
  modelId,
  videoDuration,
  thumbnailsUrls,
  loading,
}: IModelListElementVideoSectionPropTypes) => {
  return (
    <VideoWrapper className="relative">
      {!thumbnailsUrls?.length ? (
        <NoVideoWrapper className="no-video" onClick={noOp}>
          <NoVideoIcon />
        </NoVideoWrapper>
      ) : (
        <>
          {thumbnailsUrls && (
            <>
              <ThumbnailsContainer className="thumbnails-container">
                <ModelThumbnail
                  modelId={modelId}
                  thumbnailsUrls={thumbnailsUrls}
                  onClick={noOp}
                />
              </ThumbnailsContainer>
              <ModelVideoDuration videoDuration={videoDuration} />
            </>
          )}
        </>
      )}
    </VideoWrapper>
  );
};

export default ModelListElementVideoSection;
