export default class Area {
  static add(): void {
    window.viewer?.measuringTool.startInsertion({
      showDistances: true,
      showArea: true,
      closed: true,
      name: "Area",
    });
  }
}
