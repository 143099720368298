import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IAppContext } from "../../../interfaces";
import SelectDropdown from "../../../shared/components/Dropdown/SelectDropdown";
import { Textarea } from "../../../shared/form/Input";
import InputGroup from "../../../shared/form/InputGroup";
import { Label } from "../../../shared/form/Label";
import { IModelIssueForm, ModelIssueType, Platform } from "../model/Issue";
import SkyeCheckbox from "../../../shared/form/SkyeCheckbox";

interface IReportIssueFormPropTypes {
  currentForm: IModelIssueForm;
  onChange: (form: IModelIssueForm, valid: boolean) => void;
}

const StyledForm = styled.form`
  .skye-dropdown {
    margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
  }
`;

const ReportIssueForm = ({
  currentForm,
  onChange,
}: IReportIssueFormPropTypes) => {
  const { t } = useTranslation();
  const isWeb = Platform.Web === currentForm.platform;

  const mapToDropdownOption = (enumObj: any) =>
    Object.values(enumObj).map((value: any) => ({
      title: t(value),
      value: value,
    }));

  const PLATFORM_OPTIONS = mapToDropdownOption(Platform);
  const REASON_OPTIONS = mapToDropdownOption(ModelIssueType);

  const emitChanges = (newForm: IModelIssueForm) => {
    const valid = !!(newForm.description && newForm.platform);
    onChange(newForm, valid);
  };

  const handlePlatformChange = (platform: Platform) => {
    emitChanges({ ...currentForm, platform });
  };

  const handleReasonChange = (reason: ModelIssueType) => {
    emitChanges({ ...currentForm, reason });
  };

  const handleDescriptionChange = (e: any) => {
    emitChanges({ ...currentForm, description: e.target.value });
  };

  const handleShareWithSupportChange = (shareWithSupport: boolean) => {
    emitChanges({ ...currentForm, shareWithSupport });
  };

  const showDescription = (): boolean => {
    if (currentForm.platform === Platform.Web) {
      return !!currentForm.reason;
    }

    return !!currentForm.platform;
  };

  return (
    <StyledForm className="report-issue-form">
      <Label>{t("platform")}</Label>
      <SelectDropdown
        onSelect={handlePlatformChange}
        currentValue={currentForm.platform}
        options={PLATFORM_OPTIONS}
        placeholder={t("choosePlatform")}
      />
      {isWeb && (
        <>
          <Label>{t("issueReason")}</Label>
          <SelectDropdown
            onSelect={handleReasonChange}
            currentValue={currentForm.reason}
            options={REASON_OPTIONS}
            placeholder={t("chooseIssueReason")}
          />
        </>
      )}
      {showDescription() && (
        <InputGroup
          bordered
          CustomInput={Textarea}
          label="description"
          inputProps={{
            placeholder: t("descriptionPlaceholder"),
            value: currentForm.description,
            onChange: handleDescriptionChange,
          }}
        />
      )}
      <SkyeCheckbox
        checked={currentForm.shareWithSupport}
        onChange={handleShareWithSupportChange}
      >
        {t("reportIssueShareModelWithSupport")}
      </SkyeCheckbox>
    </StyledForm>
  );
};

export default ReportIssueForm;
