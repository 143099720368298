import i18n from "i18next";
import translations from "./locale/allTranslations";

i18n.init({
  // we init with resources
  resources: translations,
  fallbackLng: "en",
  debug: true,

  // have a common namespace used around the full app
  keySeparator: false, // we use content as keys
  interpolation: {
    escapeValue: false, // not needed for react!!
  },

  react: {
    wait: true,
    useSuspense: false,
  },
});

export default i18n;
