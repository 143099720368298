import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Description from "../../shared/typography/Description";
import { IPublicModel, Model } from "models/Model";
import Alert from "views/shared/components/Alert";
import { IAppContext } from "views/interfaces";
import { ModelHelpers } from "models/Model";
import { formatDate } from "utils/globalUtils";
import Button from "views/shared/buttons/Button";
import AddIconCircle from "views/shared/Icons/AddIconCircle";
import Box from "views/shared/components/Box";
import EmergencyUploadContainer, {
  EmergencyUploadType,
} from "../containers/EmergencyUploadContainer";
import Title from "views/shared/typography/Title";
import { IGlobalStore } from "../../../stores/GlobalStore";

const UploadMainWrapper = styled.div`
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

const CreatePartButtonWrapper = styled(Box)`
  margin-top: -${(p: IAppContext) => p.theme.skye.sizes.small};

  svg {
    path {
      stroke: ${(p: IAppContext) =>
        p.theme.skye.colors.backgroundCard} !important;
    }
  }
`;

const UploadWrapperTitle = styled.div`
  padding-top: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  padding-left: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  padding-right: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  margin: 0 -${(p: IAppContext) => p.theme.skye.sizes.medium} 0 -${(
      p: IAppContext
    ) => p.theme.skye.sizes.medium};
  border-bottom: ${(p: IAppContext) => p.theme.skye.borders.secondary};
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};

  .title {
    margin-bottom: 0;
  }
`;

const ModelPartUploadContainer = styled.div`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

const PartName = styled.h3`
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;

const UploadsWrapper = styled.div`
  .create-part-button-wrapper {
    margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

interface IEmergencyUploadSectionPropTypes {
  GlobalStore?: IGlobalStore;
  model: IPublicModel;
  onModelUpdate: () => void;
}

const getPartUploadText = (
  fileName,
  vidUploaded,
  videoCreatedAt,
  index
): string => {
  let fileNameTxt = "";

  if (fileName) {
    if (!vidUploaded) {
      fileNameTxt = `, probable file name: ${fileName}`;
    }
  }

  return `Part ${index + 1} - Created at ${formatDate(
    videoCreatedAt
  )}${fileNameTxt}`;
};

const EmergencyUploadSection = ({
  model,
  onModelUpdate = () => {},
}: IEmergencyUploadSectionPropTypes) => {
  const { t } = useTranslation();
  const [showAddPartField, setShowAddPartField] = useState(false);
  const videosUploaded = ModelHelpers.videosUploaded(model);

  const UploadSection = (
    <>
      {model.inputs
        .sort((partA, partB) => partA.id - partB.id)
        .map((input, idx) => (
          <ModelPartUploadContainer>
            <PartName>
              {getPartUploadText(
                input.fileName,
                input.vidUploaded,
                input.videoCreatedAt,
                idx
              )}
            </PartName>
            <EmergencyUploadContainer
              model={model}
              input={input}
              uploadType={EmergencyUploadType.ModelPart}
              onUpdate={onModelUpdate}
            />
          </ModelPartUploadContainer>
        ))}
      {showAddPartField && (
        <ModelPartUploadContainer>
          <PartName>{t("createPartLabel")}</PartName>
          <EmergencyUploadContainer
            model={model}
            input={null}
            uploadType={EmergencyUploadType.NewModelPart}
            onUpdate={onModelUpdate}
          />
        </ModelPartUploadContainer>
      )}
      <CreatePartButtonWrapper className="create-part-button-wrapper">
        {!showAddPartField && (
          <Button
            type="transparent"
            startIcon={<AddIconCircle />}
            onClick={() => setShowAddPartField(true)}
          >
            {t("createPart")}
          </Button>
        )}
      </CreatePartButtonWrapper>
    </>
  );

  return (
    <section className="emergency-upload-section">
      <Description>{t("emergencyUploadDescription")}</Description>
      <UploadMainWrapper>
        {videosUploaded ? (
          <Alert type="warning">{t("emergencyUploadNotAvailable")}</Alert>
        ) : (
          <UploadsWrapper>
            <UploadWrapperTitle>
              <Title>{t("uploadFiles")}</Title>
            </UploadWrapperTitle>
            {UploadSection}
          </UploadsWrapper>
        )}
      </UploadMainWrapper>
    </section>
  );
};

export default EmergencyUploadSection;
