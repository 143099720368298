import { useWebSocket } from "../../../web-socket/useWebSockets";
import { ModelWebSocketEmitEvent } from "../../model";

export const useModelWebSocket = () => {
  const ws = useWebSocket();

  return {
    subscribeToUserModelChanges: (userId: string) => {
      ws.emit(ModelWebSocketEmitEvent.SubscribeToUserModels, {
        userId,
      });
    },
  };
};
