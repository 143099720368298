import { Trans } from "react-i18next";
import styled from "styled-components";
import BaseBar from "./BaseBar";
import CloseIcon from "../../shared/Icons/CloseIcon";

interface IProps {
  translation: string;
  showCloseIcon?: boolean;
  onClose?: () => void;
}

const PreviewModeBar = styled(BaseBar)`
  background-color: #4b4b4b;
  border: 1px solid #4b4b4b;
  min-height: 32px;
  color: #d0d0d0;

  strong {
    margin-left: 0.3em;
    font-weight: 800;
  }
`;

const InfoBar = ({
  translation,
  onClose: onClick = () => {},
  showCloseIcon,
}: IProps) => {
  return (
    <PreviewModeBar className="px-2 md:px-4 flex justify-between items-center">
      <Trans>{translation}</Trans>
      {showCloseIcon && (
        <div onClick={onClick} className="close-btn-wrapper text-white ml-2">
          <CloseIcon size={24} />
        </div>
      )}
    </PreviewModeBar>
  );
};

export default InfoBar;
