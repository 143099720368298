import React, { useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import Box, { BoxCentered } from "../Box";
import { IAppContext } from "../../../interfaces";
import Label from "../../typography/Label";
import { useTranslation } from "react-i18next";
import CollapseIcon from "../../Icons/CollapseIcon";
import CircleLoader from "../CircleLoader";
import UploadIndicatorToast, {
  IUploadProgress,
  UploadStatus,
} from "./FilesUploadProgresElement";

interface IMultiUploadIndicatorToastPropTypes {
  loading: boolean;
  overallProgress: number;
  uploads: IUploadProgress[];
  onCancel: (id: string) => void;
  onDelete: (id: string) => void;
}

const Wrapper = styled(Box)`
  flex-direction: column;
  box-shadow: ${(p: IAppContext) => p.theme.skye.shadows.primary};

  .upload-indicator-toast {
    box-shadow: none;
    border-bottom: 1px solid
      ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};

    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border-bottom: none;
    }
  }

  &.collapsed {
    .upload-indicator-toast {
      &:first-child {
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
      }
    }
  }

  .loader {
    margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
  }
`;

const Text = styled(Label)`
  margin-bottom: 0;
`;

const HeadRow = styled(BoxCentered)`
  cursor: pointer;
  padding: 10px;
  justify-content: space-between;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundLight};
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
`;
const ProgressBox = styled(Box)`
  align-items: center;
  justify-content: flex-start;
`;
const OverallProgressWrapper = styled.div`
  cursor: pointer;
`;
const CollapseIconWrapper = styled(BoxCentered)`
  svg {
    width: 26px;
    height: 26px;
  }
`;

const FilesUploadProgres = ({
  loading,
  uploads,
  overallProgress,
  onCancel,
  onDelete,
}: IMultiUploadIndicatorToastPropTypes) => {
  const { t } = useTranslation();
  const [collapsed, setCollapsed] = useState(false);

  return (
    <Wrapper className={classNames([{ collapsed }])}>
      {!collapsed && (
        <HeadRow onClick={() => setCollapsed(true)}>
          <ProgressBox>
            {loading && collapsed && <CircleLoader color="primary" />}
            <Text>{t("overallProgress")}</Text>
          </ProgressBox>
          <CollapseIconWrapper>
            <CollapseIcon />
          </CollapseIconWrapper>
        </HeadRow>
      )}
      {!collapsed &&
        uploads.map(
          (upload) =>
            (upload.status === UploadStatus.InProgress ||
              upload.status === UploadStatus.Pending) && (
              <UploadIndicatorToast
                upload={upload}
                key={upload.id}
                onCancel={onCancel}
                onDelete={onDelete}
                hideRefreshButton
              />
            )
        )}
      {collapsed && (
        <OverallProgressWrapper onClick={() => setCollapsed(false)}>
          <UploadIndicatorToast
            upload={{
              id: "overall",
              status: UploadStatus.InProgress,
              progress: overallProgress,
              name: t("overallProgress"),
            }}
            hideCancelButton
            hideRefreshButton
            customIcons={<CollapseIcon open />}
          />
        </OverallProgressWrapper>
      )}
    </Wrapper>
  );
};

export default FilesUploadProgres;
