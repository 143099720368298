import { IPublicModel } from 'models/Model';
import React, { useState, useEffect } from 'react'
import { AnalyticEventCategory, AnalyticService } from 'services/AnalyticService';
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";
import Dialog from "views/shared/components/Dialog/Dialog";
import Button from "../../shared/buttons/Button";
import InputGroup from "../../shared/form/InputGroup";
import { IPotreeMeasurement, Point } from "../interfaces";
import { ViewerService } from "../services/ViewerService";
import ViewerToolIcon from './ViewerToolIcon';
import ToolIconSection from '../components/ToolIconSection';
import { useTranslation } from 'react-i18next';
import { MessageService } from 'services/MessageService';
import { IAnnotationDTO, ReportService } from 'services/ReportService';
import { FileService } from 'services/FileService';
import { ANNOTATION_TOOL, CENTER_POINT } from "../const";
import GlobalStore from "stores/GlobalStore";
import CenterPoint from "../services/tools/CenterPoint";
import TextLabel from "../services/tools/TextLabel";
import ToolAvailabilityHoc from "../hoc/ToolAvailabilityHoc";
import { useReportTool } from "../context/ViewerReportToolContext";

interface IViewerReportsPanelPropTypes {
  model: IPublicModel;
}

interface IAnnotationFormValues {
  uuid: string;
  annotation: string;
  longitude: string | number;
  latitude: string | number;
  pinColor: string;
  distanceToCenterPoint: Point | null;
  open: boolean;
}

const StyledNav = styled.div`
  width: 100%;
`;

const InputsWrapper = styled.div`
  margin-top: 40px;

  .input-group {
    margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  }
`;

const viewerService = new ViewerService();

const getAnnotationFromMeasurement = (
  measurement: IPotreeMeasurement
): IAnnotationFormValues => {
  const coordinates = measurement.getRealCoordinates();
  return {
    uuid: measurement.uuid,
    annotation: measurement.annotation,
    pinColor: `#${measurement.color.getHexString()}`,
    longitude: coordinates.longitude || 0,
    latitude: coordinates.latitude || 0,
    distanceToCenterPoint: CenterPoint.getDistanceToCenterPoint(measurement),
    open: false,
  };
};

const getAnnotations = (): IAnnotationDTO[] => {
  const annotations: IAnnotationDTO[] = TextLabel.getAnnotations()
    .map(getAnnotationFromMeasurement)
    .map((annotation) => {
      return {
        name: annotation.annotation,
        longitude: annotation.longitude,
        latitude: annotation.latitude,
        pinColor: annotation.pinColor,
        distanceToCenterPoint: annotation.distanceToCenterPoint,
      };
    });
  return annotations;
};

const ToolIconSectionHoc = ToolAvailabilityHoc(ToolIconSection);

const ViewerReportsPanel = ({ model }: IViewerReportsPanelPropTypes) => {
  const reportToolCtx = useReportTool();
  const { blocked } = reportToolCtx;

  const [annotationModalOpen, setAnnotationModalOpen] = useState(false);
  const [annotationName, setAnnotationName] = useState("");
  const [description, setDescription] = useState("");
  const [caseNumber, setCaseNumber] = useState("");
  const [annotations, setAnnotations] = useState<IAnnotationFormValues[]>([]);
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const handleMeasurementRemove = () => {};

  const addAnnotation = () => {
    TextLabel.add(annotationName);
    AnalyticService.event(
      AnalyticEventCategory.ViewerMeasurements,
      `measurement_${ANNOTATION_TOOL.id}`
    );
    setAnnotationName("");
    setAnnotationModalOpen(false);
  };

  const cancelAddAnnotation = () => {
    setAnnotationModalOpen(false);
  };

  const handleAnnotationAdd = () => {
    setAnnotationModalOpen(true);
    setTimeout(() => {
      const annotationInputRef = document.getElementById("annotationInput");
      if (annotationInputRef) {
        annotationInputRef.focus();
      }
    }, 200);
  };

  const handleAnnotationKeyUp = (e: any) => {
    if (e.key === "Enter") {
      addAnnotation();
    }
  };

  const handleCenterPointClick = () => {
    const centerPoint = window.viewer.scene.getCenterPoint();

    if (centerPoint) {
      window.viewer.scene.removeMeasurement(centerPoint);
    }

    AnalyticService.event(
      AnalyticEventCategory.ViewerMeasurements,
      "center_point_added"
    );
    CenterPoint.add();
  };

  const handleMeasurementAdd = (data: any) => {
    const measurement: IPotreeMeasurement = data.measurement;

    if (TextLabel.isTextLabel(measurement)) {
      setAnnotations([
        ...annotations,
        ...TextLabel.getAnnotations().map(getAnnotationFromMeasurement),
      ]);
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleCaseNumberChange = (e) => {
    setCaseNumber(e.target.value);
  };

  const handleAnnotationNameChange = (e: any) => {
    setAnnotationName(e.target.value);
  };

  useEffect(() => {
    // Handle events
    window.viewer.scene.addEventListener(
      "measurement_added",
      handleMeasurementAdd
    );
    window.viewer.scene.addEventListener(
      "measurement_removed",
      handleMeasurementRemove
    );

    setAnnotations(
      TextLabel.getAnnotations()
        .filter((a) => !!a.annotation)
        .map(getAnnotationFromMeasurement)
    );

    return () => {
      window.viewer.scene.removeEventListener(
        "measurement_added",
        handleMeasurementAdd
      );
      window.viewer.scene.removeEventListener(
        "measurement_removed",
        handleMeasurementRemove
      );
    };
  }, []);

  const generateReport = async () => {
    if (!CenterPoint.getCenterPoint()) {
      MessageService.warn(t("toastSetCenterPoint"));
      AnalyticService.event(
        AnalyticEventCategory.ViewerReports,
        "toastCenterPointNotSet"
      );
      CenterPoint.add();
      return;
    }

    AnalyticService.event(
      AnalyticEventCategory.ViewerReports,
      "generate_report"
    );
    setLoading(true);
    GlobalStore.showViewerAreaLoader = true;
    await viewerService.toggleReportGenerationMode(true);

    const reportService = new ReportService();
    const screenshot = await viewerService.getMainScreenshot();

    reportService
      .generateReport(
        model.id,
        "",
        screenshot,
        getAnnotations(),
        description,
        caseNumber,
        viewerService.getLengthUnit()
      )
      .then(async (res: any) => {
        var blob = new Blob([res.rawData], { type: "application/pdf" });

        FileService.downloadPdf(blob, `Report.pdf`);
        MessageService.success(t("toastReportGenerated"));
        await viewerService.toggleReportGenerationMode(false);
      })
      .catch(() => {
        AnalyticService.event(
          AnalyticEventCategory.ViewerReports,
          "generate_report_error"
        );
        MessageService.error(t("toastReportNotGenerated"));
      })
      .finally(() => {
        setLoading(false);
        viewerService.toggleReportGenerationMode(false);

        setTimeout(() => {
          GlobalStore.showViewerAreaLoader = false;
        }, 300);
      });
  };

  return (
    <StyledNav>
      <ToolIconSectionHoc
        notAvailableMessage={t(
          "subscription.featureNotAvailableForCurrentPlan"
        )}
        available={!blocked}
        titleProps={{ title: t("tools") }}
        iconsSection={
          <>
            <ViewerToolIcon
              active={false}
              id={ANNOTATION_TOOL.id}
              tooltip="tooltipAnnotationPoint"
              onClick={handleAnnotationAdd}
            />
            <ViewerToolIcon
              active={false}
              id={CENTER_POINT.id}
              tooltip="tooltipCenterPoint"
              onClick={handleCenterPointClick}
            />
          </>
        }
      >
        <>
          <InputsWrapper>
            <InputGroup
              inputProps={{
                placeholder: "caseNumberPlaceholder",
                value: caseNumber,
                onChange: handleCaseNumberChange,
              }}
            />
            <InputGroup
              inputProps={{
                placeholder: "descriptionPlaceholder",
                value: description,
                onChange: handleDescriptionChange,
              }}
            />
          </InputsWrapper>
          <Button
            fullSize
            type="light"
            loading={loading}
            onClick={generateReport}
            size="small"
          >
            {t("generateReport")}
          </Button>
        </>
      </ToolIconSectionHoc>
      <Dialog
        title={t("addAnnotation")}
        open={annotationModalOpen}
        confirmButtonProps={{
          onClick: addAnnotation,
        }}
        cancelButtonProps={{
          onClick: cancelAddAnnotation,
        }}
        onClose={cancelAddAnnotation}
      >
        <InputGroup
          label="annotationName"
          inputProps={{
            id: "annotationInput",
            value: annotationName,
            placeholder: "annotationNamePlaceholder",
            onChange: handleAnnotationNameChange,
            onKeyUp: handleAnnotationKeyUp,
          }}
        />
      </Dialog>
    </StyledNav>
  );
};

export default ViewerReportsPanel;
