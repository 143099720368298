import React, { useState } from "react";
import Dialog from "../../shared/components/Dialog/Dialog";
import { useTranslation } from "react-i18next";
import sharedModelRequests from "requests/sharedModel";
import { IErrorResponse } from "services/HttpService";
import GlobalStore from "stores/GlobalStore";

interface IUnshareDialogContainerPropTypes {
  open: boolean;
  onClose: () => void;
  onUnshared: () => void;
  modelId: string;
  shareToEmail: string;
}

const UnshareDialogContainer = ({
  open,
  modelId,
  shareToEmail,
  onClose,
  onUnshared,
}: IUnshareDialogContainerPropTypes) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const shareModel = () => {
    if (!shareToEmail) return;

    setLoading(true);

    sharedModelRequests
      .unshareModel(modelId, shareToEmail)
      .then(() => {
        onUnshared();
        GlobalStore.addToast(t("toastModelUnshared"));
      })
      .catch((err: IErrorResponse) => {
        GlobalStore.addToast(
          t(err.localizationKey || "requestFailed"),
          "error"
        );
      })
      .finally(() => setLoading(false));
  };

  return (
    <Dialog
      title={t("unshareModel")}
      description={t("unshareModelDescription")}
      open={open}
      onClose={onClose}
      confirmButtonProps={{
        loading,
        disabled: loading,
        onClick: shareModel,
      }}
    ></Dialog>
  );
};

export default UnshareDialogContainer;
