import React, { useState } from "react";
import styled from "styled-components";
import { IPublicModel } from "models/Model";
import { Marker, Polyline } from "@react-google-maps/api";
import { ModelHelpers } from "models/Model";
import {
  IGoogleMapLocation,
  GoogleMapService,
  POLYLINE_OPTIONS,
} from "services/GoogleMapService";

import { ModelGpsService } from "views/models/services/ModelGpsService";
import Map, { DEFAULT_ZOOM } from "../../shared/components/Map";

interface MarkerPoint {
  lat: number;
  lng: number;
  label: string;
}

interface IModelMapViewPropTypes {
  model: IPublicModel;
  points?: MarkerPoint[];
  zoom?: number;
}

/**
 * https://www.latlong.net/place/center-nd-usa-24567.html
 */
const FALLBACK_CENTER_POINT = {
  lat: 47.116386,
  lng: -101.299591,
};

const ModelMapViewWrapper = styled.div`
  width: 100%;
  height: 100%;
`;

const ModelMapView = ({ model, points = [] }: IModelMapViewPropTypes) => {
  const [locations, setLocations] = useState<IGoogleMapLocation[]>([]);
  const missionStartPoint = model.location
    ? {
        lat: ModelHelpers.getLocation(model).latitude,
        lng: ModelHelpers.getLocation(model).longitude,
      }
    : null;
  const missionCenterPoint = model.centerLocation
    ? {
        lat: ModelHelpers.getLocation(model, "centerLocation").latitude,
        lng: ModelHelpers.getLocation(model, "centerLocation").longitude,
      }
    : null;
  const modelLocation = missionCenterPoint || missionStartPoint;
  const mapCenterPoint = modelLocation || FALLBACK_CENTER_POINT;
  const zoom = modelLocation ? DEFAULT_ZOOM : 4;

  const loadPaths = async () => {
    if (model?.assets?.gpsUrl) {
      ModelGpsService.getModelGpsPath(model).then((locations) =>
        setLocations(locations.map(GoogleMapService.getLocationPoint))
      );
    }
  };

  return (
    <ModelMapViewWrapper id="model-map-view">
      <Map
        zoom={zoom}
        centerPoint={mapCenterPoint}
        onLoad={() => {
          loadPaths();
        }}
      >
        <Marker position={missionStartPoint} />
        {missionCenterPoint && <Marker position={missionCenterPoint} />}
        {Boolean(points.length) &&
          points.map((point: MarkerPoint) => (
            <Marker key={point.label} position={point} label={point.label} />
          ))}
        {!!locations.length && (
          <Polyline path={locations} options={POLYLINE_OPTIONS} />
        )}
      </Map>
    </ModelMapViewWrapper>
  );
};

export default ModelMapView;
