import React from 'react'

interface ITutorialIconPropTypes {
  
}

const TutorialIcon = ({
  
}: ITutorialIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M16 2.50009C8.54416 2.50009 2.5 8.54425 2.5 16.0001C2.5 23.4559 8.54416 29.5001 16 29.5001C23.4558 29.5001 29.5 23.4559 29.5 16.0001C29.5 8.54425 23.4558 2.50009 16 2.50009ZM1.5 16.0001C1.5 7.99196 7.99187 1.50009 16 1.50009C24.0081 1.50009 30.5 7.99196 30.5 16.0001C30.5 24.0082 24.0081 30.5001 16 30.5001C7.99187 30.5001 1.5 24.0082 1.5 16.0001ZM12.4256 10.2288C12.5852 10.1408 12.78 10.1466 12.934 10.2439L21.3784 15.5773C21.5235 15.6689 21.6114 15.8285 21.6114 16C21.6114 16.1716 21.5235 16.3311 21.3784 16.4228L12.934 21.7561C12.78 21.8534 12.5852 21.8592 12.4256 21.7712C12.2661 21.6833 12.167 21.5155 12.167 21.3333V10.6667C12.167 10.4845 12.2661 10.3167 12.4256 10.2288ZM13.167 11.5738V20.4262L20.1751 16L13.167 11.5738Z" fill="#1A1A1A"/>
    </svg>
  )
}

export default TutorialIcon;
