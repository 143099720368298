import { ServiceType } from "models/ServicePlan";
import { LoggedUser } from "models/User";

export class PermissionService {
  static hasService(user: LoggedUser, serviceType: ServiceType): boolean {
    try {
      return !!user?.organization.subscription.servicePlan.services.find(
        (service) => service.type === serviceType
      );
    } catch (error) {
      return false;
    }
  }
}