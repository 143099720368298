import { createContext, useContext } from "react";

interface IViewerMeasuringToolsContext {
  blockedTools: string[];
}

interface IViewerContextInitialState {
  children: React.ReactNode;
  blockedTools?: string[];
}

/**
 * Context to block measuring tools
 */
export const ViewerMeasuringToolsContext =
  createContext<IViewerMeasuringToolsContext>(null);

/**
 *  Provider to block measuring tools
 */
export const ViewerMeasuringToolsProvider = ({
  children,
  blockedTools,
}: IViewerContextInitialState) => {
  return (
    <ViewerMeasuringToolsContext.Provider
      value={{ blockedTools: blockedTools || [] }}
    >
      {children}
    </ViewerMeasuringToolsContext.Provider>
  );
};

/**
 * Hook to get measuring tools context
 */
export const useMeasuringTools = () => {
  const ctx = useContext(ViewerMeasuringToolsContext);

  if (!ctx) {
    throw new Error(
      "useMeasuringTools must be used within a ViewerMeasuringToolsProvider"
    );
  }

  const { blockedTools } = ctx;

  const isToolBlocked = (toolId: string) => {
    return blockedTools.includes(toolId);
  };

  return { isToolBlocked };
};
