import React from 'react'

interface ILinkedInIconPropTypes {
  
}

const LinkedInIcon = ({
  
}: ILinkedInIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M4.2381 9.19048V29M29 29V19.0952C29 14.3088 25.1198 10.4286 20.3333 10.4286C15.5469 10.4286 11.6667 14.3088 11.6667 19.0952V29V9.19048M3 3H5.47619" stroke="#1A1A1A"/>
    </svg>
  )
}

export default LinkedInIcon;
