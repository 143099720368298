import { memo } from "react";
import Button from "../../shared/buttons/Button";
import { ModelAccessType, IPublicModel } from "../../../models/Model";
import { useModelPayment } from "./useModelPayment";

interface IModelRenewActionButtonPropTypes {
  model: IPublicModel;
}

const ModelRenewActionButton = ({
  model,
}: IModelRenewActionButtonPropTypes) => {
  const paymentWidget = useModelPayment();
  const archived = model.accessType === ModelAccessType.Archived;

  const handleClick = () => {
    paymentWidget.openWidget(model.id, model.accessType);
  };

  return (
    <Button fullSize color="primary" onClick={handleClick} className="mt-2">
      {archived ? "Restore" : "Renew"}
    </Button>
  );
};

export default memo(ModelRenewActionButton);
