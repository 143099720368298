import React from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";
import AddIcon from "../Icons/AddIcon";
import Box, { BoxCentered } from "./Box";
import SkyeTooltip from "./SkyeTooltip";
import UserAvatar from "./UserAvatar";

const AVATAR_SIZE = 32;
const AVATAR_OFFSET = 10;
const MAX_AVATARS = 2;

interface User {
  userName: string;
}

interface IUserAvatarGroupPropTypes {
  users: User[];
  showAddButton: boolean;
  onAdd?: () => void;
}

const AvatarWrapper = styled.div``;
const GroupWrapper = styled(Box)`
  position: relative;
  width: 100%;
  align-items: center;
  padding-left: ${AVATAR_OFFSET}px;

  .avatar-wrapper {
    margin-left: -${AVATAR_OFFSET}px;
    border: 1px solid ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
    border-radius: 50%;
  }
`;
const AddUserButton = styled(BoxCentered)`
  cursor: pointer;
  width: ${AVATAR_SIZE}px;
  height: ${AVATAR_SIZE}px;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border: 1px dashed ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
  border-radius: 50%;
  margin-left: -${AVATAR_OFFSET}px;

  svg {
    path {
      stroke: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
    }
  }
`;
const UserName = styled.div``;
const MoreUsersInformaionWrapper = styled(BoxCentered)`
  width: ${AVATAR_SIZE + 2}px;
  height: ${AVATAR_SIZE + 2}px;
  border-radius: 50%;
  margin-left: -${AVATAR_OFFSET}px;
  border: 1px solid ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  color: ${(p: IAppContext) => p.theme.skye.colors.silverSand};
  font-size: ${AVATAR_SIZE * 0.375}px;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
`;



const UserAvatarGroup = ({
  showAddButton,
  onAdd = () => {},
  users
}: IUserAvatarGroupPropTypes) => {
  const visibleUsers = [...users];
  const restUsers = visibleUsers.splice(MAX_AVATARS);
  const showMoreUsersIdentifier = Boolean(restUsers.length);

  return (
    <GroupWrapper>
      {visibleUsers.map((user, index) => {
        if (index > 2) {
          return null;
        }

        return (
          <SkyeTooltip key={user.userName} title={user.userName}>
            <AvatarWrapper className="avatar-wrapper" key={user.userName}>
              <UserAvatar size={AVATAR_SIZE} userName={user.userName} />
            </AvatarWrapper>
          </SkyeTooltip>
        );})}
      {showMoreUsersIdentifier && (
          <SkyeTooltip title={(
            <div>
              {restUsers.map((user, index) => (
                <UserName key={`${user.userName}_${index}`}>{user.userName}</UserName>
              ))}
            </div>
          )}>
            <MoreUsersInformaionWrapper>
              {`${restUsers.length}+`}
            </MoreUsersInformaionWrapper>
          </SkyeTooltip>
      )}
      {showAddButton && (
        <AddUserButton onClick={onAdd}>
          <AddIcon />
        </AddUserButton>
      )}
    </GroupWrapper>
  )
}

export default UserAvatarGroup;
