import React, { useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import ViewerMeasurementsPanel from "views/viewer/components/ViewerMeasurementsPanel";
import ViewerVolumePanel from "views/viewer/components/ViewerVolumePanel";
import ViewerScreenshotPanel from "views/viewer/components/ViewerScreenshotPanel";
import ViewerReportsPanel from "views/viewer/components/ViewerReportsPanel";
import classNames from "classnames";
import ButtonsGroup from "views/shared/buttons/ButtonsGroup";
import Button from "views/shared/buttons/Button";
import { IAppContext } from "../../../../interfaces";
import { IPublicModel } from "../../../../../models/Model";
import useBreakpoint from "../../../../shared/hook/useBreakpointHook";
import useModelViewer from "../hooks/useModelViewer";
import { inject, observer } from "mobx-react";
import { STORE_NAMES } from "../../../../../const/global";
import { IViewerStore } from "../../../../../stores/ViewerStore";

export interface IModelViewerToolsPanelPropTypes {
  model: IPublicModel;
  showPremiumContent: boolean;
  paymentOverlay?: React.ReactNode;
  ViewerStore?: IViewerStore;
}

const ViewerSidePanel = styled.div`
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  padding: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  position: relative;
  height: 100%;
  overflow: hidden;
  width: 340px;
  min-width: 340px;
  margin-right: 30px;
  overflow-y: auto;
`;
const ViewerSidePanelContent = styled.div`
  .disabled {
    opacity: 0.5;
    pointer-events: none;
  }
`;
const PanelTypeSwitchWrapper = styled.div`
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
`;

enum PanelType {
  Measurements = "measurements",
  FactualDiagram = "factualDiagram",
}

const ModelViewerToolsSidePanel = ({
  ViewerStore,
  model,
  showPremiumContent,
  paymentOverlay,
}: IModelViewerToolsPanelPropTypes) => {
  const [state] = useModelViewer({ ViewerStore, model });
  const { t } = useTranslation();
  const { breakpoint } = useBreakpoint();
  const desktopView = breakpoint("md");
  const [panelType, setPanelType] = useState(PanelType.Measurements);
  const isMeasurementPanel = PanelType.Measurements === panelType;

  const changePanelType = (type: PanelType) => () => {
    setPanelType(type);
  };

  if (!desktopView) {
    return null;
  }

  return (
    <ViewerSidePanel className="viewer-side-panel">
      {!showPremiumContent && paymentOverlay}
      {
        <ViewerSidePanelContent
          className={classNames([{ disabled: !showPremiumContent }])}
        >
          <PanelTypeSwitchWrapper>
            <ButtonsGroup fullSize>
              <Button
                onClick={changePanelType(PanelType.Measurements)}
                className={classNames({ active: isMeasurementPanel })}
              >
                {t("measurements")}
              </Button>
              <Button
                onClick={changePanelType(PanelType.FactualDiagram)}
                className={classNames({ active: !isMeasurementPanel })}
              >
                {t("factualDiagram")}
              </Button>
            </ButtonsGroup>
          </PanelTypeSwitchWrapper>
          <div>
            {state.viewerInitialized && isMeasurementPanel && (
              <>
                <ViewerMeasurementsPanel model={model} />
                <ViewerVolumePanel model={model} />
                <ViewerScreenshotPanel />
              </>
            )}
            {state.viewerInitialized && !isMeasurementPanel && (
              <ViewerReportsPanel model={model} />
            )}
          </div>
        </ViewerSidePanelContent>
      }
    </ViewerSidePanel>
  );
};

export default inject(STORE_NAMES.ViewerStore)(
  observer(ModelViewerToolsSidePanel)
);
