import { IViewerStore, TexturesMode } from "../../../stores/ViewerStore";
import useTextureManager from "../../models/containers/ModelViewer/hooks/useTextureManager";
import { TopographyMap } from "../services/TopographyMap";
import { ViewerService } from "../services/ViewerService";

const viewerService = new ViewerService();

const useTopographyMap = ({ ViewerStore }: { ViewerStore: IViewerStore }) => {
  const [texture] = useTextureManager({ ViewerStore });

  const open = () => {
    texture.hideTextures();
    viewerService.enablePointCloudVisibility();
    TopographyMap.open();
  };

  const close = () => {
    if (ViewerStore.lastTexturesMode === TexturesMode.TEXTURES) {
      viewerService.setLowPointBudget();
      viewerService.disablePointCloudVisibility();
      texture.showTextures();
      TopographyMap.close();
      return;
    }

    viewerService.setMediumPointBudget();
    viewerService.enablePointCloudVisibility();
    texture.hideTextures();
    TopographyMap.close();
  };

  const toggle = () => {
    if (TopographyMap.isOpen()) {
      console.log("close");
      close();
    } else {
      console.log("open");
      open();
    }
  };

  return {
    toggle,
  };
};

export default useTopographyMap;
