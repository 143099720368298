export enum PricingOption {
  Applied = "applied",
  NotApplied = "notApplied",
  Optional = "optional",
}

export enum CloudStorage {
  OneYear = "oneYear",
  Month = "month",
  SixMonths = "sixMonths",
  FiveYears = "fiveYears",
}

export enum IPricingSupport {
  Basic = "basic",
  Email = "email",
  CeoPhone = "ceo_phone",
}

export enum PricingProcessingSpeed {
  Standard = "processing_speed_standard",
  Priority = "processing_speed_priority",
  Ultra = "processing_speed_ultra",
}

export enum PricingAccuracy {
  Medium = "accuracy_medium",
  High = "accuracy_high",
  Ultra = "accuracy_ultra",
}

export interface IPricingOptions {
  processingSpeed: PricingProcessingSpeed;
  accuracy: PricingAccuracy;
  toolsSharingAndExport: PricingOption;
  professionalTools: PricingOption;
  interiorAiDesigner: PricingOption;
  cjisCompliance: PricingOption;
  cloudStorage: CloudStorage;
  support: IPricingSupport;
}

export type IPricingTypeName =
  | "freemium"
  | "payPerModel"
  | "subscription"
  | "liteSubscription";

export interface IPricing {
  default?: boolean;
  name: IPricingTypeName;
  description: string;
  price: string;
  priceDescription?: string;
  buttonComponent?: any;
  options: IPricingOptions;
}

export interface IPricingTablePropTypes {
  featuredPricingName?: IPricingTypeName;
  pricings?: IPricing[];
}
