export class DocumentService {
  /**
   * As HEAD request not working for S3 assets finished workaround
   * Senging GET request, checking headers and aborting after check :)
   */
  static async fileExists(url): Promise<boolean> {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", url, true);
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 2) {
          if (xhr.status === 200) resolve(true);
          reject(false);
        } else if (xhr.readyState === 3) {
          xhr.abort();
        }
      };
      xhr.send();
    });
  }

  /**
   * This method could be used to download image files
   * Preventing from open of the image in new tab without download
   */
  static async downloadFileUsingFetch(url: string, fileName: string) {
    return new Promise((res, rej) => {
      fetch(url)
        .then((response) => response.blob())
        .then((blob) => {
          res(true);
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download = fileName;
          link.click();
        })
        .catch((err) => {
          console.error(err);
          rej();
        });
    });
  }

  static async downloadFile(url: string, fileName: string) {
    const link = document.createElement("a");
    link.download = fileName;
    link.href = url;
    link.setAttribute("_target", "blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }
}
