import React from 'react'

interface IDownloadIconPropTypes {
  
}

const DownloadIcon = ({
  
}: IDownloadIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16.0059 22.5997V4" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M24 14.6667L16 22.6667L8 14.6667" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M24 28H8" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default DownloadIcon;
