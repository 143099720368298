import InformationBox from "../../shared/components/InformationBox/InformationBox";
import Button from "../../shared/buttons/Button";
import { useStripeSubscription } from "../../payments/stripe/useStripeSubscription";
import { inject, observer } from "mobx-react";
import { IGlobalStore } from "../../../stores/GlobalStore";
import { useTranslation } from "react-i18next";

interface ISubscriptionSectionPropTypes {
  GlobalStore?: IGlobalStore;
}

const SubscriptionSection = ({
  GlobalStore,
}: ISubscriptionSectionPropTypes) => {
  const { t } = useTranslation();
  const { user } = GlobalStore;
  const stripe = useStripeSubscription();
  const isOwner = user.id === user.organization?.ownerId;

  const openStripe = () => {
    stripe.openSubscriptionPage(user.email);
  };

  return (
    <div>
      <InformationBox
        description={t(
          isOwner
            ? "Your subscription is managed by our secure payment processing system"
            : "You are not the owner of this organization, so you cannot manage the subscription."
        )}
        button={
          isOwner && (
            <Button color="primary" onClick={openStripe}>
              View Subscription
            </Button>
          )
        }
      />
    </div>
  );
};

export default inject("GlobalStore")(observer(SubscriptionSection));
