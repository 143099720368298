import React, { DOMAttributes } from 'react'
import styled from 'styled-components';
import classNames from 'classnames';
import { IAppContext } from "../../interfaces";

interface ITitlePropTypes extends DOMAttributes<HTMLHeadingElement> {
  className?: string;
}

const StyledTitle = styled.h2`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
`;

const Title = ({
  className,
  ...otherProps
}: ITitlePropTypes) => {
  return (
    <StyledTitle className={classNames(['title', className])} {...otherProps} />
  );
}

export default Title;
