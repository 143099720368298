import React from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";
import Box from "../../shared/components/Box";
import classNames from 'classnames';
import { ModelDetailsPageTabKeys } from "const/global";
import { useTranslation } from 'react-i18next';
import { SKYE_THEME } from 'theme';
import useBreakpoint from "../../shared/hook/useBreakpointHook";

export interface ISidebarTab {
  dictionary: string;
  key: ModelDetailsPageTabKeys;
  icon: any;
}

interface ISidebarTabPropTypes {
  active: boolean;
  onClick: (key: ModelDetailsPageTabKeys) => void;
  tab: ISidebarTab;
}

const TabWrapper = styled(Box)`
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  height: 42px;
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.small};
  padding: 0 0 0 ${(p: IAppContext) => p.theme.skye.sizes.medium};

  &:hover {
    color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};

    path {
      stroke: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    }

    &.video,
    &.flightDetails {
      path {
        stroke: none;
        fill: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
      }
    }
  }

  &.active {
    color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    background-color: ${(p: IAppContext) => p.theme.skye.colors.lavender};

    path {
      stroke: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    }

    &.video,
    &.flightDetails {
      path {
        stroke: none;
        fill: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
      }
    }
  }
`;
const TabIconWrapper = styled(Box)`
  width: 24px;
`;
const TabLabel = styled.div`
  margin-left: ${(p: IAppContext) => p.theme.skye.sizes.medium};
`;

const SidebarTab = ({ active, onClick, tab }: ISidebarTabPropTypes) => {
  const { t } = useTranslation();

  return (
    <TabWrapper
      key={tab.key}
      className={classNames([tab.key, { active }])}
      onClick={() => onClick(tab.key)}
    >
      <TabIconWrapper>
        <tab.icon
          color={
            active
              ? SKYE_THEME.colors["electricViolet"]
              : SKYE_THEME.colors["textPrimary"]
          }
        />
      </TabIconWrapper>
      {<TabLabel>{t(tab.dictionary)}</TabLabel>}
    </TabWrapper>
  );
};

export default SidebarTab;
