import React, { ReactNode } from "react";
import styled from "styled-components";
import Box from "../Box";
import { IAppContext } from "../../../interfaces";
import breakpoint from "styled-components-breakpoint";

interface IInformationRowPropTypes {
  description: ReactNode;
  title: ReactNode;
}

const StyledInformationRow = styled(Box)`
  width: 100%;
  padding: ${(p: IAppContext) => p.theme.skye.sizes.medium} 0;
  border-bottom: ${(p: IAppContext) => p.theme.skye.borders.primary};
  display: flex;
  justify-content: flex-start;
  flex-direction: column;

  ${breakpoint("md")`
    flex-direction: row;
  `}

  &:last-of-type {
    border-bottom: none;
  }

  &:first-of-type {
    border-top: none;
  }
`;

const Title = styled.h3`
  font-size: ${(p: IAppContext) => p.theme.skye.fontSizes.regular};
  min-width: 200px;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
`;

const Description = styled.h4`
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 17px;
  margin-bottom: 0;
`;

const InformationRow = ({ title, description }: IInformationRowPropTypes) => {
  return (
    <StyledInformationRow className="information-row">
      <Title>{title}</Title>
      <Description>{description}</Description>
    </StyledInformationRow>
  );
};

export default InformationRow;
