import { createContext, useState } from "react";
import { ViewerService } from "../services/ViewerService";

interface ViewerScaleContextType {
  scale: number;
  isRescaled: boolean;
  rescale: (scale: number) => void;
}

export const ViewerScaleContext = createContext<ViewerScaleContextType>({
  scale: 1,
  isRescaled: false,
  rescale: () => {},
});

export const ViewerScaleProvider = (props) => {
  const [scale, setScale] = useState(1);
  const isRescaled = scale && scale !== 1 && scale.toFixed(4) !== "0.9999";

  const rescale = (newScale: number) => {
    const viewerService = new ViewerService();
    viewerService.setMeasurementsScale(newScale);
    setScale(newScale);
  };

  return (
    <ViewerScaleContext.Provider value={{ scale, isRescaled, rescale }}>
      {props.children}
    </ViewerScaleContext.Provider>
  );
};
