import axios, { CancelTokenSource } from "axios";
import { wait } from "../../../../../utils/globalUtils";

export interface UploadProgress {
  progress: number;
  status: UploadStatus;
  uploadId: string;
  partNumber: number;
  parts: UploadPart[];
}

export interface MultipartUploadConfig {
  partSize: number;
  onProgress: (progress: UploadProgress) => void;
}

export enum UploadStatus {
  Pending = "PENDING",
  Failed = "FAILED",
  Initialized = "INITIALIZED",
  Uploading = "UPLOADING",
  Finished = "FINISHED",
  Canceled = "CANCELED",
}

interface InitializeRequestResponse {
  uploadId: string;
  presignedUrl: string;
}

export interface UploadPart {
  eTag: string;
  partNumber: number;
}

const INITIAL_PART_NUMBER = 1;
const TIME_BEFORE_RETRY = 10000; // 10 seconds
const MAX_RETRY_COUNT = 12; // 5 retries

export abstract class MultipartUploadService {
  file: File;
  config: MultipartUploadConfig;
  uploadId: string;
  partNumber: number;
  parts: UploadPart[] = [];
  status: UploadStatus;
  progress: number;
  protected putPartFailedCount: number;
  protected cancelToken: CancelTokenSource | null = null;

  constructor(
    file,
    config: MultipartUploadConfig,
    uploadProgress?: UploadProgress
  ) {
    this.config = config;
    this.file = file;

    this.partNumber = uploadProgress?.partNumber || INITIAL_PART_NUMBER;
    this.uploadId = uploadProgress?.uploadId || "";
    this.status = uploadProgress?.status || UploadStatus.Pending;
    this.progress = uploadProgress?.progress || 0;
    this.parts = uploadProgress?.parts || [];
    this.putPartFailedCount = 0;
  }

  abstract signPartRequest(): Promise<string>;
  abstract initializeRequest(): Promise<InitializeRequestResponse>;
  abstract finalizeUploadRequest(): Promise<void>;

  private emitProgress(status: UploadStatus) {
    const { onProgress } = this.config;
    this.status = status;
    this.progress =
      (this.partNumber / Math.ceil(this.file.size / this.config.partSize)) *
      100;

    onProgress({
      progress: this.progress,
      status,
      partNumber: this.partNumber,
      uploadId: this.uploadId,
      parts: this.parts,
    });
  }

  /**
   *
   * @returns eTag: string
   */
  private async putPart(presignedUrl: string): Promise<string> {
    const CancelToken = axios.CancelToken;
    const { partSize } = this.config;
    const partData = new Blob([
      this.file.slice(
        (this.partNumber - 1) * this.config.partSize,
        this.partNumber * partSize
      ),
    ]);

    const source = CancelToken.source();
    const cancelTokenSource = source;
    const response = await axios.put(presignedUrl, partData, {
      headers: { "Content-Type": this.file.type },
      cancelToken: cancelTokenSource.token,
    });
    this.cancelToken = cancelTokenSource;

    return response.headers.etag;
  }

  private async handlePutPartSuccess(eTag: string) {
    this.parts.push({ eTag, partNumber: this.partNumber });
    this.partNumber++;
    this.putPartFailedCount = 0;

    // Emitting changes with latest part with etag even if cancelled
    // Preventing from reuploading the same part again
    // which will cause issues when finalizing the upload
    if (this.status === UploadStatus.Canceled) {
      this.emitProgress(UploadStatus.Canceled);
      return;
    }

    this.emitProgress(UploadStatus.Uploading);
  }

  private async initializeUpload() {
    const { uploadId, presignedUrl } = await this.initializeRequest();
    this.uploadId = uploadId;
    this.emitProgress(UploadStatus.Initialized);

    const result = await this.putPart(presignedUrl);
    this.handlePutPartSuccess(result);
  }

  private partsStillExists() {
    const partsCount = Math.ceil(this.file.size / this.config.partSize);
    return this.partNumber - 1 < partsCount;
  }

  private canUpload() {
    return (
      this.partsStillExists() &&
      this.status !== UploadStatus.Canceled &&
      this.status !== UploadStatus.Finished
    );
  }

  private async uploadParts() {
    while (this.canUpload()) {
      try {
        const presignedUrl = await this.signPartRequest();
        const result = await this.putPart(presignedUrl);
        this.handlePutPartSuccess(result);
      } catch (error) {
        // Try to reconnect before failing
        if (this.putPartFailedCount >= MAX_RETRY_COUNT) {
          throw error;
        }

        this.putPartFailedCount++;
        await wait(TIME_BEFORE_RETRY);
      }
    }
  }

  private async finalizeUpload() {
    await this.finalizeUploadRequest();
    this.emitProgress(UploadStatus.Finished);
  }

  async cancel() {
    this.cancelToken?.cancel();
    this.emitProgress(UploadStatus.Canceled);
  }

  async upload() {
    try {
      // Ommiting initialization when resuming upload
      if (this.status === UploadStatus.Pending) {
        await this.initializeUpload();
      }
      await this.uploadParts();

      if (this.status !== UploadStatus.Canceled) {
        await this.finalizeUpload();
      }
    } catch (error) {
      this.emitProgress(UploadStatus.Failed);
    }
  }
}
