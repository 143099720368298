import React from 'react'
import { ISkyeColors, SKYE_THEME } from "../../../theme";

interface ILayersIconPropTypes {
  color?: ISkyeColors,
}

const LayersIcon = ({
  color = 'textPrimary',
}: ILayersIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M2.6665 7.94288L15.9998 12.6667L29.3332 7.94288L15.9998 3.33334L2.6665 7.94288Z" stroke={SKYE_THEME.colors[color]} strokeLinejoin="round"/>
      <path d="M2.6665 13.3333L15.9998 18L29.3332 13.3333" stroke={SKYE_THEME.colors[color]} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.6665 18.6666L15.9998 23.3333L29.3332 18.6666" stroke={SKYE_THEME.colors[color]} strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M2.6665 24L15.9998 28.6667L29.3332 24" stroke={SKYE_THEME.colors[color]} strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default LayersIcon;


