import { Model } from 'models/Model';
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import ModelService from "views/models/services/ModelService";
import styled from 'styled-components';
import InputGroup from "../../shared/form/InputGroup";
import Dialog from "../../shared/components/Dialog/Dialog";

interface IEditModelNameDialogContainerPropTypes {
  open: boolean,
  model: Model,
  onModelChange: (model: Model) => void,
  onClose: () => void,
}

const NAME_INPUT_ID = 'edit_name_input';

const EditModelNameDialogContainer = ({
  model,
  open,
  onModelChange,
  onClose,
}: IEditModelNameDialogContainerPropTypes) => {
  const { t } = useTranslation();
  const [modelName, setModelName] = useState(model.name || '');
  const [loading, setLoading] = useState(false);

  const handleSave = () => {
    setLoading(true)
    ModelService.patchModel(model.id, { name: modelName })
      .then(() => {
        onModelChange({ ...model, name: modelName });
      })
      .finally(() => {
        setLoading(false);
      });
  }

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        const input = document.getElementById(NAME_INPUT_ID);
        if (input) {
          input.focus();
        }
      }, 300);
    }
  }, [open])

  return (
    <Dialog
      title={t("editName")}
      open={open}
      onClose={onClose}
      confirmButtonProps={{
        loading,
        onClick: handleSave,
      }}
    >
      <InputGroup
        bordered
        label={"modelName"}
        inputProps={{
          id: NAME_INPUT_ID,
          value: modelName,
          placeholder: "modelNamePlaceholder",
          onChange: (e: any) => setModelName(e.target.value),
          onKeyDown: (e: any) => {
            if (e.key === "Enter") {
              handleSave();
            }
          },
        }}
      />
    </Dialog>
  );
}

export default EditModelNameDialogContainer;
