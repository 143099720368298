import httpService from "services/HttpService";
import { IModelMeasurement } from "../../../models/Model";
import modelEnv from "../model.env";

interface IModelScale {
  value: number;
}

export interface Rotation {
  x: number;
  y: number;
  z: number;
}

export interface IModelFeatures {
  volumes: Object;
  measurements: Object;
  scale?: IModelScale;
  rotation?: Rotation;
}

type PostModelMeasurements = Partial<IModelFeatures>;

export class ModelMeasurementService {
  static getModelMeasurements = async (
    modelId: string
  ): Promise<IModelMeasurement> => {
    const url = `${modelEnv.apiUrl}/${modelId}/measurements`;
    const res = await httpService.get(url);
    return res.data as IModelMeasurement;
  };

  static postModelMeasurements = (
    modelId: string,
    measurements: PostModelMeasurements
  ) => {
    const url = `${modelEnv.apiUrl}/${modelId}/measurements`;
    return httpService.post(url, { measurements });
  };
}
