import React from 'react'
import { useTranslation } from 'react-i18next';
import GlobalStore from "../../../stores/GlobalStore";
import styled from 'styled-components';
import { WindowUtils } from "../../../utils/windowUtils";
import { IAppContext } from "../../interfaces";
import LinkIcon from "../Icons/LinkIcon";
import Box, { BoxAlignedCenter, BoxCentered } from './Box';

interface ICopyButtonPropTypes {
  children: string,
  copyContent: string,
}

const ButtonWrapper = styled(BoxAlignedCenter)`
  cursor: pointer;
`;
const ButtonIcon = styled(BoxCentered)`
  width: 18px;
  height: 18px;
`;
const ButtonContent = styled(Box)`
  margin-left: 0.5em;
  font-weight: 500;
  font-size: 12px;
  line-height: 15px;
  text-decoration-line: underline;
  color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
`;

const CopyButton = ({
  children,
  copyContent,
}: ICopyButtonPropTypes) => {
  const { t } = useTranslation();

  const copy = () => {
    WindowUtils.copyToClipboard(copyContent);
    GlobalStore.addToast(t('copied'));
  }
  
  return (
    <ButtonWrapper role="button" onClick={copy}>
      <ButtonIcon>
        <LinkIcon />
      </ButtonIcon>
      <ButtonContent>
        {children}
      </ButtonContent>
    </ButtonWrapper>
  )
}

export default CopyButton;
