import classNames from "classnames";
import styles from "./index.module.css";
import { ReactNode } from "react";

interface Slide {
  imgUrl: string;
  imgAlt: string;
  title: string;
  description: ReactNode;
}

interface AuthPageLayoutProps {
  companyImg: string;
  companyImgAlt: string;
  children: React.ReactNode;
  slides: Slide[];
}

const AuthPageLayout = ({
  companyImg,
  companyImgAlt,
  slides,
  children,
}: AuthPageLayoutProps) => {
  return (
    <div className="min-h-screen lg:h-screen w-full h-full min-w-screen bg-gray-100 p-3">
      <div className="w-full h-full bg-white rounded-lg overflow-hidden flex justify-start items-start">
        <div
          className={classNames([
            "column hidden lg:block lg:w-2/5 h-full relative",
            styles["slider-column"],
          ])}
        >
          <div className="flex flex-col justify-center items-center h-full">
            <a href="/">
              <img
                src={companyImg}
                alt={companyImgAlt}
                className="h-10 w-auto absolute left-10 top-10"
              />
            </a>
            <div className="slider-wrapper w-full h-full p-10 flex items-end justify-center">
              {slides.map((slide) => (
                <div key={slide.title} className="slide text-left text-white">
                  <div className="slide-img-wrapper relative z-10">
                    <img src={slide.imgUrl} alt={slide.imgAlt} />
                  </div>
                  <h4 className="text-3xl mb-4 font-bold px-4">
                    {slide.title}
                  </h4>
                  <p className="text-base mb-4 px-4">{slide.description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="column lg:w-3/5 h-full w-full text-black overflow-scroll">
          {children}
        </div>
      </div>
    </div>
  );
};

export default AuthPageLayout;
