import React from 'react'
import styled from 'styled-components';
import Label from '../typography/Label';
import Box from '../components/Box';
import { IAppContext } from "../../interfaces";
import classNames from 'classnames';

interface ISkyeCheckboxPropTypes {
  checked: boolean,
  onChange: (checked: boolean, e: any) => void,
  reverse?: boolean,
  loading?: boolean,
  children?: string,
  bottomPadding?: boolean,
}

const SWITCH_HEIGHT = 16;
const SWITCH_WIDTH = 34;
const SWITCH_PADDING = 2;
const SWITCH_BALL_SIZE = 20;

const SwitchBox = styled.div`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.textDisabled};
  border-radius: ${SWITCH_HEIGHT}px;
  height: ${SWITCH_HEIGHT}px;
  width: ${SWITCH_WIDTH}px;
  position: relative;
`;

const SwitchBall = styled.div`
  transition: left 0.5s;
  position: absolute;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border-radius: 50%;
  height: ${SWITCH_BALL_SIZE}px;
  width: ${SWITCH_BALL_SIZE}px;
  left: -${SWITCH_PADDING}px;
  top: -${SWITCH_PADDING}px;
  box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
`;

const StyledLabel = styled(Label)`
  margin-left: ${(p: IAppContext) => p.theme.skye.sizes.small};
  padding-bottom: 0;
  margin-bottom: 0;
`;

const Group = styled(Box)`
  align-items: center;
  cursor: pointer;

  &.checked {
    .switch-box {
      background-color: ${(p: IAppContext) => p.theme.skye.colors.electricVioletLight};
    }

    .switch-ball {
      background-color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
      left: 15px;
      box-shadow: 0px 0.1px 0.3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.2);
    }
  }

  &.bottom-padding {
    margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  }

  .ant-switch-checked {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.success};
  }

  &.reverse {
    flex-direction: row-reverse;

    ${StyledLabel} {
      margin-left: 0;
      margin-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
    }
  }
`;

const SkyeSwitch = ({
  children,
  bottomPadding = true,
  checked,
  reverse,
  onChange
}: ISkyeCheckboxPropTypes) => {
  return (
    <Group
      className={classNames(['skye-switch', { 'bottom-padding': bottomPadding, checked, reverse }])}
      onClick={(e) => onChange(!checked, e)}
    >
      <SwitchBox className="switch-box">
        <SwitchBall className="switch-ball" />
      </SwitchBox>
      {!!children && (
        <StyledLabel>{children}</StyledLabel>
      )}
    </Group>
  )
}

export default SkyeSwitch;
