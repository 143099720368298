import { IPublicModel } from 'models/Model';
import classNames from 'classnames';
import { inject, observer } from 'mobx-react';
import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { AnalyticEventCategory, AnalyticService } from 'services/AnalyticService';
import { MessageService } from 'services/MessageService';
import ModelDetailsPageStore, {
  IModelDetailsPageStore,
} from "stores/ModelDetailsPageStore";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import Box from "../../shared/components/Box";
import CommentComponent from "../../shared/components/CommentComponent";
import CommentInput from "../../shared/components/CommentInput";
import InformationBox from "../../shared/components/InformationBox/InformationBox";
import ViewerSidePanel from "../../shared/components/ViewerSidePanel";
import AddCommentIcon from "../../shared/Icons/AddCommentIcon";

interface IModelCommentsContainerPropTypes {
  model: IPublicModel;
  ModelDetailsPageStore?: IModelDetailsPageStore;
}

const MainContent = styled(Box)`
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;
`;
const CommentsWrapper = styled(Box)`
  height: 100%;
  flex-direction: column;

  &.empty {
    align-items: center;
    justify-content: center;
  }

  .information-box-description {
    color: ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
  }
`;
const InputWrapper = styled.div`
  position: absolute;
  width: 100%;
  bottom: 0;
  left: 0;
  padding: ${(p: IAppContext) => {
    const size = p.theme.skye.sizes.average;
    return `0 ${size} ${size} ${size}`;
  }};
`;

const ModelCommentsContainer = ({
  model,
  ModelDetailsPageStore,
}: IModelCommentsContainerPropTypes) => {
  const { t } = useTranslation();
  const { commentsLoaded, comments, commentService, commentsOpen } =
    ModelDetailsPageStore;
  const [newCommentValue, setNewCommentValue] = useState("");
  const mainContentContainer = useRef<HTMLDivElement | null>(null);

  const getComments = async () => {
    await ModelDetailsPageStore.loadComments(model.id);
    setTimeout(() => {
      scrollCommentsToBottom();
    }, 100);
  };

  const handleCreateComment = async () => {
    AnalyticService.event(
      AnalyticEventCategory.ModelDetailsPage,
      "comment_create_new"
    );
    const comment = await commentService.createComment(
      model.id,
      newCommentValue
    );

    if (comment) {
      setNewCommentValue("");
      getComments();
      return;
    }
    MessageService.error(t("toastCommentNotCreated"));
  };

  const handleNewCommentValueChange = (newValue: string) => {
    setNewCommentValue(newValue);
  };

  const handlePanelClose = () => {
    AnalyticService.event(
      AnalyticEventCategory.ModelDetailsPage,
      "comment_close_panel"
    );
    ModelDetailsPageStore.closeCommentsPanel();
  };

  const scrollCommentsToBottom = () => {
    if (mainContentContainer && mainContentContainer.current) {
      mainContentContainer.current.scrollTop =
        mainContentContainer.current.scrollHeight;
    }
  };

  useEffect(() => {
    if (commentsOpen) {
      scrollCommentsToBottom();
    }
  }, [commentsOpen]);

  return (
    <ViewerSidePanel
      title={t("comments")}
      open={commentsOpen}
      onClose={handlePanelClose}
    >
      <MainContent
        ref={mainContentContainer}
        className="comment-container-main-box"
      >
        <CommentsWrapper
          className={classNames([
            { empty: commentsLoaded && !comments.length },
          ])}
        >
          {commentsLoaded && !comments.length && (
            <InformationBox
              transparent
              description={t("commentsEmpty")}
              icon={<AddCommentIcon />}
            />
          )}
          {comments.map((comment) => (
            <CommentComponent
              key={comment.id}
              text={comment.text}
              userName={comment.ownerName}
              createdAt={comment.createdAt}
            />
          ))}
        </CommentsWrapper>
      </MainContent>
      <InputWrapper>
        <CommentInput
          value={newCommentValue}
          onChange={handleNewCommentValueChange}
          onSend={handleCreateComment}
        />
      </InputWrapper>
    </ViewerSidePanel>
  );
};

export default inject('ModelDetailsPageStore')(observer(ModelCommentsContainer));
