import classNames from "classnames";
import { ReactElement, memo } from "react";
import styles from "./AccessBadge.module.css";

interface IAccessBadgePropTypes {
  children: string;
  color?: "black" | "danger";
  startIcon?: ReactElement;
  endIcon?: ReactElement;
}

const AccessBadge = ({
  children,
  color = "black",
  startIcon,
  endIcon,
}: IAccessBadgePropTypes) => {
  const colorClass = color === "black" ? "text-black" : "text-red-500";

  return (
    <div
      className={classNames([
        colorClass,
        "access-type-badge inline-flex flex-row items-center text-xs bg-white py-1 px-2 rounded-sm shadow-md flex items-center justify-center hover:opacity-100 focus:opacity-100 border border-gray-300",
      ])}
    >
      {!!startIcon && (
        <div className={classNames(["mr-2", styles.icon])}>{startIcon}</div>
      )}
      <span className={classNames(["badge-text font-semibold"])}>
        {children}
      </span>
      {!!endIcon && (
        <div className={classNames(["mr-2", styles.icon])}>{endIcon}</div>
      )}
    </div>
  );
};

export default memo(AccessBadge);
