import React, { useState } from "react";
import ToolIconSection from "./ToolIconSection";
import {
  VOLUME_TOOLS,
  CREATE_VOLUME_TOOL,
  VOLUME_TYPE_BUTTONS,
} from "../const";
import ViewerToolIcon from "./ViewerToolIcon";
import { ViewerService } from "../services/ViewerService";
import {
  AnalyticService,
  AnalyticEventCategory,
} from "services/AnalyticService";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import ButtonsToggleGroup from "../../shared/buttons/ButtonsToggleGroup";
import Button from "../../shared/buttons/Button";
import Box from "../../shared/components/Box";
import TrashIcon from "../../shared/Icons/TrashIcon";
import { inject, observer } from "mobx-react";
import { STORE_NAMES } from "const/global";
import { IViewerStore } from "stores/ViewerStore";
import ToolAvailabilityHoc from "../hoc/ToolAvailabilityHoc";
import { IPublicModel } from "../../../models/Model";
import useTextureManager from "../../models/containers/ModelViewer/hooks/useTextureManager";
import { useVolumeTool } from "../context/ViewerVolumeToolContext";

interface IViewerVolumePanelPropTypes {
  ViewerStore?: IViewerStore;
  model: IPublicModel;
}

const VolumeTypeSwitchContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: ${(p: IAppContext) => p.theme.skye.sizes.medium};
  margin-bottom: 6px;
  width: 100%;
`;
const DeleteButtonWrapper = styled(Box)`
  justify-content: center;
`;

const ToolIconSectionHoc = ToolAvailabilityHoc(ToolIconSection);

const ViewerVolumePanel = ({
  ViewerStore,
  model,
}: IViewerVolumePanelPropTypes) => {
  const volumeCtx = useVolumeTool();

  const viewerService = new ViewerService();
  const [textureManager] = useTextureManager({ ViewerStore });
  const { t } = useTranslation();
  const [volumeType, setVolumeType] = useState(VOLUME_TYPE_BUTTONS[0].id);

  const handleVolumeTypeChange = (id: number | string) => {
    setVolumeType(Number(id));
    viewerService.changeVolumeType(Number(id));
  };

  const handleClick = (id: string) => {
    switch (id) {
      case CREATE_VOLUME_TOOL.id:
        AnalyticService.event(
          AnalyticEventCategory.ViewerMeasurements,
          "add_volume"
        );
        textureManager.hideTextures();
        viewerService.addVolume();
        break;

      default:
        return;
    }
  };

  return (
    <ToolIconSectionHoc
      available={!volumeCtx.blocked}
      notAvailableMessage={t("subscription.featureNotAvailableForCurrentPlan")}
      titleProps={{ title: t("clipping_tool") }}
      iconsSection={
        <>
          {VOLUME_TOOLS.map((volumeTool) => (
            <ViewerToolIcon
              key={volumeTool.id}
              id={volumeTool.id}
              onClick={handleClick}
              active={false}
              tooltip={volumeTool.tooltip}
            />
          ))}
        </>
      }
    >
      <div id="viewer-volumes-panel">
        <VolumeTypeSwitchContainer>
          <ButtonsToggleGroup
            fullSize
            elements={VOLUME_TYPE_BUTTONS}
            selected={volumeType}
            onChange={handleVolumeTypeChange}
          />
        </VolumeTypeSwitchContainer>
        <DeleteButtonWrapper>
          <Button
            type="transparent"
            size="small"
            onClick={viewerService.removeAllClipVolumes}
            endIcon={<TrashIcon />}
          >
            {t("removeAllVolumes")}
          </Button>
        </DeleteButtonWrapper>
      </div>
    </ToolIconSectionHoc>
  );
};

export default inject(STORE_NAMES.ViewerStore)(observer(ViewerVolumePanel));
