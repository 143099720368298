import React from 'react'

interface ITextIconPropTypes {
  
}

const TextIcon = ({
  
}: ITextIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M6.8335 6.00006C6.8335 5.72392 7.05735 5.50006 7.3335 5.50006H15.9982H24.6668C24.943 5.50006 25.1668 5.72392 25.1668 6.00006V8.66665C25.1668 8.94279 24.943 9.16665 24.6668 9.16665C24.3907 9.16665 24.1668 8.94279 24.1668 8.66665V6.50006H16.4982V25.5H19.3335C19.6096 25.5 19.8335 25.7238 19.8335 26C19.8335 26.2761 19.6096 26.5 19.3335 26.5H15.9982H12.6668C12.3907 26.5 12.1668 26.2761 12.1668 26C12.1668 25.7238 12.3907 25.5 12.6668 25.5H15.4982V6.50006H7.8335V8.66665C7.8335 8.94279 7.60964 9.16665 7.3335 9.16665C7.05735 9.16665 6.8335 8.94279 6.8335 8.66665V6.00006Z" fill="#1A1A1A"/>
    </svg>
  )
}

export default TextIcon;
