import classNames from 'classnames';
import React from 'react'
import styled from 'styled-components';
import { IAppContext } from "../../interfaces";
import { BoxCentered } from '../components/Box';

interface IConnectionStatusIconPropTypes {
  status: 'connected' | 'disconnected',
}

const BULLET_SIZE = '8px';
const ICON_SIZE = '16px';

const StatusBullet = styled.div`
  content: '';
  position: relative;
  width: ${BULLET_SIZE};
  height: ${BULLET_SIZE};
  border-radius: 50%;
  z-index: 200;
`;
const AnimatedBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  transform: translate(-50%, -50%);
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};
  animation-name: example;
  animation-duration: 5s;
  animation-iteration-count: infinite;

  @keyframes example {
    0%   {
      padding: 5px;
    }
    10%  {
      padding: 0px;
    }
    20% {
      padding: 5px;
    }
    30% {
      padding: 0px;
    }
  }
`;
const AnimatedBackgroundContent = styled.div`
  width: 100%;
  height: 100%;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  border-radius: 50%;
  content: '';
`;
const IconWrapper = styled(BoxCentered)`
  position: relative;
  width: ${ICON_SIZE};
  height: ${ICON_SIZE};

  &.connected {
    .status-circle {
      background-color: ${(p: IAppContext) =>
        p.theme.skye.colors.successSecondary};
    }
  }

  &.disconnected {
    .status-circle {
      background-color: ${(p: IAppContext) => p.theme.skye.colors.dangerHeavy};
    }
  }

  &.connecting {
    .status-circle {
      background-color: ${(p: IAppContext) => p.theme.skye.colors.accent};
    }
  }
`;
const ConnectionStatusIcon = ({
  status,
}: IConnectionStatusIconPropTypes) => {
  return (
    <IconWrapper className={classNames([status])}>
      <AnimatedBackground>
        <AnimatedBackgroundContent></AnimatedBackgroundContent>
      </AnimatedBackground>
      <StatusBullet className="status-circle"></StatusBullet>
    </IconWrapper>
  );
}

export default ConnectionStatusIcon;
