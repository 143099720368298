import { ModelDetailsPageTabKeys } from "../../../const/global";
import DownloadIcon from "../../shared/Icons/DownloadIcon";
import DroneIcon from "../../shared/Icons/DroneIcon";
import InfoIcon from "../../shared/Icons/InfoIcon";
import UploadIcon from "../../shared/Icons/UploadIcon";
import VideoIcon from "../../shared/Icons/VideoIcon";
import WeatherIcon from "../../shared/Icons/WeatherIcon";
import {
  FIELD_OF_VIEW_TOOL,
  OPEN_PROFILE_PANEL,
  PERSPECTIVE_TOOL,
  SWITCH_TOPOGRAPHY_MAP,
} from "../../viewer/const";
import { ISidebarTab } from "../components/SidebarTab";

const GENERAL_TAB = {
  dictionary: "general",
  key: ModelDetailsPageTabKeys.General,
  icon: InfoIcon,
};

const FLIGHT_DETAILS = {
  dictionary: "flightDetails",
  key: ModelDetailsPageTabKeys.FlightDetails,
  icon: DroneIcon,
};

const WEATHER = {
  dictionary: "weather",
  key: ModelDetailsPageTabKeys.Weather,
  icon: WeatherIcon,
};

const DOWNLOAD = {
  dictionary: "download",
  key: ModelDetailsPageTabKeys.Download,
  icon: DownloadIcon,
};

const VIDEO = {
  dictionary: "videoDetails",
  key: ModelDetailsPageTabKeys.Video,
  icon: VideoIcon,
};

const EMERGENCY_UPLOAD = {
  dictionary: "emergencyUpload",
  key: ModelDetailsPageTabKeys.EmergencyUpload,
  icon: UploadIcon,
};

export const MODEL_SHARED_DETAILS_PAGE_TAB_KEYS: ISidebarTab[] = [
  GENERAL_TAB,
  FLIGHT_DETAILS,
  VIDEO,
  WEATHER,
  DOWNLOAD,
];

export const MODEL_DETAILS_PAGE_TABS_KEYS: ISidebarTab[] = [
  GENERAL_TAB,
  FLIGHT_DETAILS,
  WEATHER,
  DOWNLOAD,
  EMERGENCY_UPLOAD,
];

export const MODEL_PUBLIC_DETAILS_PAGE_TAB_KEYS: ISidebarTab[] = [
  GENERAL_TAB,
  FLIGHT_DETAILS,
  WEATHER,
];

export const MODEL_LITE_SUBSCRIPTION_BLOCKED_MEASURING_TOOLS_IDS = [
  PERSPECTIVE_TOOL.id,
  OPEN_PROFILE_PANEL.id,
  SWITCH_TOPOGRAPHY_MAP.id,
  FIELD_OF_VIEW_TOOL.id,
];
