import * as React from "react";
import Button from "views/shared/buttons/Button";
import { useTranslation } from "react-i18next";
import LinkButton from "views/shared/buttons/LinkButton";
import Dialog from "views/shared/components/Dialog/Dialog";
import InputGroup from "views/shared/form/InputGroup";
import Label from "views/shared/typography/Label";
import usePage from "./usePage";
import { withRouter } from "react-router-dom";
import SkyebrowseAuthPageLayout from "../layout";

const LoginPage = ({ history }) => {
  const {
    state,
    login,
    setState,
    onEmailChange,
    handleEmailRequest,
    closeDialog,
  } = usePage({ history });
  const { t } = useTranslation();
  const { resetPasswordDialogOpen } = state;

  return (
    <SkyebrowseAuthPageLayout
      title="Welcome back!"
      description="Please enter your details to sign in"
      showSwitch
      pageType="login"
    >
      <div className="w-full h-full flex items-center justify-center">
        <form className="w-full text-left">
          <InputGroup
            bordered
            fullWidth
            label={"email"}
            inputProps={{
              id: "login-email",
              value: state.email,
              placeholder: "emailPlaceholder",
              onKeyPress: (ev) => {
                if (ev.key === "Enter") {
                  login();
                  ev.preventDefault();
                }
              },
              onChange: onEmailChange,
            }}
          />
          <InputGroup
            bordered
            fullWidth
            label={"passwordLabel"}
            inputProps={{
              id: "login-password",
              value: state.password,
              placeholder: "passwordPlaceholder",
              type: "password",
              onKeyPress: (ev) => {
                if (ev.key === "Enter") {
                  login();
                  ev.preventDefault();
                }
              },
              onChange: (e: any) =>
                setState((old) => ({ ...old, password: e.target.value })),
            }}
          />
          <div className="mt-8 mb-2">
            <LinkButton
              onClick={() =>
                setState((old) => ({ ...old, resetPasswordDialogOpen: true }))
              }
            >
              <>{t("forgotPasswordQuestion")}</>
            </LinkButton>
          </div>
          <div className="flex items-start align-center">
            <Button
              id="login-button"
              fullSize
              onClick={login}
              loading={state.isLoading}
            >
              <>{t("login")}</>
            </Button>
          </div>
          <Dialog
            description={t("resetPasswordWriteEmailInstruction")}
            open={resetPasswordDialogOpen}
            onClose={closeDialog}
            title={t("resetPassword")}
            confirmButtonProps={{
              onClick: handleEmailRequest,
              loading: state.resetPasswordLoading,
            }}
          >
            <InputGroup
              bordered
              label={"emailLabel"}
              inputProps={{
                placeholder: "emailPlaceholder",
                value: state.email,
                onChange: (e: any) =>
                  setState((old) => ({ ...old, email: e.target.value })),
              }}
            />
            {!!state.resetPasswordRequestError && (
              <Label color="danger">{t(state.resetPasswordRequestError)}</Label>
            )}
          </Dialog>
        </form>
      </div>
    </SkyebrowseAuthPageLayout>
  );
};

export default withRouter(LoginPage);
