import classNames from "classnames";
import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { IAppContext } from "views/interfaces";
import PrivateIcon from "../../../Icons/PrivateIcon";
import PublicIcon from "../../../Icons/PublicIcon";
import { BoxAlignedCenter, BoxCentered } from "../../Box";

interface IVisibilityBadgePropTypes {
  type: "public" | "private";
}

const VisibilityBadgeWrapper = styled(BoxAlignedCenter)`
  padding: 0 8px;
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  height: ${(p: IAppContext) => p.theme.skye.sizes.large};

  &.private {
    color: ${(p: IAppContext) => p.theme.skye.colors.redOrange};
    background-color: ${(p: IAppContext) => p.theme.skye.colors.redOrangeLight};

    svg {
      path {
        stroke: ${(p: IAppContext) => p.theme.skye.colors.redOrange};
      }
    }
  }

  &.public {
    color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    background-color: ${(p: IAppContext) => p.theme.skye.colors.lavender};

    svg {
      path {
        stroke: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
      }
    }
  }
`;
const IconWrapper = styled(BoxCentered)`
  margin-right: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  width: 14px;
`;

const VisibilityBadge = ({ type }: IVisibilityBadgePropTypes) => {
  const { t } = useTranslation();

  return (
    <VisibilityBadgeWrapper className={classNames(["visibility-badge", type])}>
      <IconWrapper>
        {type === "private" ? <PrivateIcon /> : <PublicIcon />}
      </IconWrapper>
      {t(type)}
    </VisibilityBadgeWrapper>
  );
};

export default VisibilityBadge;
