import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import { SessionService } from 'services/SessionService';
import Button from "../../shared/buttons/Button";
import BasePage from "views/shared/layouts/BasePage";
import { Col, Row } from "styled-bootstrap-grid";
import styled from "styled-components";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { SESSION_ROUTES } from "views/routes";
import SessionListElement from "../components/SessionListElement";
import AddIcon from "../../shared/Icons/AddIcon";
import { BoxCentered } from "../../shared/components/Box";
import EmptyListInformation from "../../shared/components/EmptyListInformation";
import PageLoader from "../../shared/components/PageLoader";
import { inject, observer } from 'mobx-react';
import { STORE_NAMES } from 'const/global';
import { IOrganizationStore } from 'stores/OrganizationStore';
import { ISession } from 'models/Session';
import SkyePagination from "../../shared/components/SkyePagination";

interface ISessionsPagePropTypes extends RouteComponentProps {
  OrganizationStore: IOrganizationStore;
}

const tacbrowseService = new SessionService();
const NewSessionButtonWrapper = styled(BoxCentered)`
  margin: 32px 0 40px 0;
`;
const LoaderWrapper = styled.div`
  height: 500px;
`;
const Image = styled.img`
  max-width: 100%;
`;

const SessionsListPage = ({
  history,
  OrganizationStore,
}: ISessionsPagePropTypes) => {
  const { t } = useTranslation();
  const [paginationParams, setPaginationParams] = useState({
    page: 1,
    total: 0,
  });
  const [ownedSessions, setOwnedSessions] = useState<ISession[]>([]);
  const [loading, setLoading] = useState(false);
  const [sessionsLoaded, setSessionsLoaded] = useState(false);
  const allowRenderList = !loading && !!ownedSessions.length;

  const getSessions = async (newPage: number) => {
    setLoading(true);

    const response = await tacbrowseService.getOwnedSessions(newPage);
    const orderedSessions = response.results;
    const total = response.total;

    setOwnedSessions(orderedSessions);
    setSessionsLoaded(true);
    setPaginationParams({ total, page: newPage });
    setLoading(false);
  };

  const getUsers = () => {
    // Loading users than specific list elements will not need them
    OrganizationStore.getOrganizatioUsersArray();
  };

  const handleCreateSession = () => {
    history.push(SESSION_ROUTES.sessionCreate);
  };

  const handlePaginationChange = (newPage: number) => {
    getSessions(newPage);
  };

  useEffect(() => {
    getSessions(paginationParams.page);
    getUsers();
  }, []);

  const renderList = () => {
    return (
      <>
        <Row>
          <Col sm={12}>
            <h3>{t("sessions")}</h3>
            {ownedSessions.map((session) => (
              <SessionListElement key={session.id} session={session} />
            ))}
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <SkyePagination
              loading={false}
              currentPage={paginationParams.page}
              total={paginationParams.total}
              onChange={handlePaginationChange}
            />
          </Col>
        </Row>
      </>
    );
  };

  return (
    <BasePage backgroundColor="white">
      <Row>
        <Col sm={4} smOffset={4}>
          <Image src="/img/new-session.svg" alt="new-session-image" />
          <NewSessionButtonWrapper>
            <Button startIcon={<AddIcon />} onClick={handleCreateSession}>
              {t("createSession")}
            </Button>
          </NewSessionButtonWrapper>
        </Col>
      </Row>
      {!loading && sessionsLoaded && !allowRenderList && (
        <EmptyListInformation
          title="sessionsListEmptyTitle"
          description="sessionsListEmptyDescription"
          showButton
          buttonProps={{
            children: t("createSession"),
            onClick: handleCreateSession,
          }}
        />
      )}
      {allowRenderList && renderList()}
      {loading && (
        <LoaderWrapper>
          <PageLoader />
        </LoaderWrapper>
      )}
    </BasePage>
  );
};

export default inject(STORE_NAMES.OrganizationStore)(observer(withRouter(SessionsListPage)));
