import API from "const/api";
import httpService from "./HttpService";

export interface IAnnotationDTO {
  name: string,
  longitude: string | number,
  latitude: string | number,
  pinColor: string,
}

export class ReportService {
  private _getAnnotationString(annotations: IAnnotationDTO[]) {
    try {
      return JSON.stringify(annotations);
    } catch (error) {
      return '';
    }
  }

  async generateReport(
    modelId: string,
    name: string,
    mainImage: string,
    annotations: IAnnotationDTO[],
    description: string,
    caseNumber: string,
    lengthUnit: string,
  ): Promise<any> {
		const formData = new FormData();

    formData.append('name', name);
		formData.append('mainImage', mainImage);
    formData.append('annotations', this._getAnnotationString(annotations));
    formData.append('description', description);
    formData.append('caseNumber', caseNumber);
    formData.append('lengthUnit', lengthUnit);
    formData.append('reportDate', new Date().toISOString());

    return httpService.post(API.report.replace(':id', modelId), formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'arraybuffer'
    });
  }
}