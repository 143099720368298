import { makeAutoObservable } from "mobx";
import { ViewerService } from "views/viewer/services/ViewerService";

export enum TexturesMode {
  POINTCLOUD,
  TEXTURES,
}

export interface IViewerStore {
  viewerInitialized: boolean;
  texturesLoaded: boolean;
  texturesVisible: boolean;
  texturesLoading: boolean;
  lastTexturesMode: TexturesMode;
  resetStore: () => void;
  setTexturesLoading: (loading: boolean) => void;
  setTexturesLoaded: (loaded: boolean) => void;
  setTexturesVisible: (visible: boolean) => void;
  setLastTexturesMode: (mode: TexturesMode) => void;
}

class ViewerStore implements IViewerStore {
  viewerInitialized = false;
  texturesLoaded = false;
  texturesVisible = false;
  texturesLoading = false;
  lastTexturesMode = TexturesMode.POINTCLOUD; // Helpfull when switching between textures and pointcloud

  vs = new ViewerService();

  constructor() {
    makeAutoObservable(this);
  }

  setTexturesLoading(loading: boolean) {
    this.texturesLoading = loading;
  }

  setTexturesLoaded(loaded: boolean) {
    this.texturesLoaded = loaded;
  }

  setTexturesVisible(visible: boolean) {
    this.texturesVisible = visible;
  }

  resetStore() {
    this.viewerInitialized = false;
    this.texturesLoaded = false;
    this.texturesVisible = false;
    this.texturesLoading = false;
  }

  setLastTexturesMode(mode: TexturesMode) {
    this.lastTexturesMode = mode;
  }
}

export default new ViewerStore();
