import { ModelPageViewTypes } from "../../../const/global";
import {
  getModelListPageUrl,
  getModelPageUrl,
} from "../../../utils/globalUtils";
import { ModelType } from "../../interfaces";
import { PAYMENT_REDIRECT_QUERY_PARAMNAME } from "./payments.const";

const getModelListPaymentRedirectUrl = () => {
  return `${window.location.origin}${getModelListPageUrl(
    ModelType.Owned
  )}?${PAYMENT_REDIRECT_QUERY_PARAMNAME}=true`;
};

const getModelPaymentRedirectUrl = (modelId: string) => {
  return `${window.location.origin}${getModelPageUrl(
    modelId,
    ModelPageViewTypes.Model
  )}?${PAYMENT_REDIRECT_QUERY_PARAMNAME}=true`;
};

const isPaymentRedirectSuccessRoute = () => {
  return (
    window.location.href.includes(PAYMENT_REDIRECT_QUERY_PARAMNAME) ||
    window.location.href.includes("payment_intent")
  );
};

const paymentUtils = {
  getModelPaymentRedirectUrl,
  getModelListPaymentRedirectUrl,
  isPaymentRedirectSuccessRoute,
};

export default paymentUtils;
