import { useRef } from "react";
import { ModelVideoUploadService } from "../service/ModelVideoUploadService";
import {
  UploadProgress,
  UploadStatus,
} from "../service/MultipartUploadService";
import UploadLocalStorageService from "../service/UploadLocalStorageService";
import { useGlobalEventEmitter } from "../../../../shared/context/GlobalEventEmitter";
import { EventTypes } from "../../../../../const/events";

const PART_SIZE = 1024 * 1024 * 10; // 7 MB

interface HookProps {
  onProgress: (id: string, progress: number, status: UploadStatus) => void;
  onUploadComplete: (id: string) => void;
}

type IInitFunction = (input: IUploadInput) => void;
type ICancelFunction = (id: string) => void;
type HookResult = [IInitFunction, ICancelFunction];

interface IBaseInput {
  id: string;
  modelId: string;
  modelInputId: string;
}

interface IUploadInput extends IBaseInput {
  file: File;
}

interface Uploads {
  [id: string]: {
    service: ModelVideoUploadService;
    file: File;
  };
}

/**
 * Hook finished to manage multiple uploads of a model videos.
 * Storing uploads data in local storage to resume upload if needed.
 *
 * @returns [startUpload, cancelUpload] functions
 */
const useUploadModelVideo = ({
  onProgress,
  onUploadComplete,
}: HookProps): HookResult => {
  const eventEmitter = useGlobalEventEmitter();
  const uploadsRef = useRef<Uploads>({});

  const handleFailed = (input: IUploadInput, progress: UploadProgress) => {
    onProgress(input.id, progress.progress, progress.status);
  };

  const handleCancelled = (input: IUploadInput, progress: UploadProgress) => {
    onProgress(input.id, progress.progress, progress.status);
  };

  const handleUpdate = (input: IUploadInput, progress: UploadProgress) => {
    const { modelId, modelInputId, file } = input;
    const getModelInput = { modelId, modelInputId, fileName: file.name };
    UploadLocalStorageService.addUpload(getModelInput, progress);
    onProgress(input.id, progress.progress, progress.status);
  };

  const handleProgress =
    (input: IUploadInput) => (progress: UploadProgress) => {
      switch (progress.status) {
        case UploadStatus.Finished:
          return handleComplete(input);
        case UploadStatus.Failed:
          return handleFailed(input, progress);
        case UploadStatus.Canceled:
          return handleCancelled(input, progress);
        default:
          return handleUpdate(input, progress);
      }
    };

  const removeUpload = (id: string) => {
    if (uploadsRef.current[id]) {
      delete uploadsRef.current[id];
    }
  };

  const handleComplete = (input: IUploadInput) => {
    const { modelId, modelInputId, file } = input;
    const getModelInput = { modelId, modelInputId, fileName: file.name };
    UploadLocalStorageService.removeUpload(getModelInput);
    onUploadComplete(input.id);
    removeUpload(input.id);
    eventEmitter.trigger(EventTypes.VideoUploaded, {});
  };

  // Initializing uploads from local storage,
  // if already started, resumes current upload
  const initUpload = (input: IUploadInput, uploadProgress?: UploadProgress) => {
    const { id, modelId, modelInputId, file } = input;
    const service = new ModelVideoUploadService(
      file,
      {
        partSize: PART_SIZE,
        onProgress: handleProgress(input),
        modelId,
        modelInputId,
      },
      uploadProgress
    );

    uploadsRef.current[id] = {
      service,
      file,
    };

    service.upload();
  };

  const uploadFile = (input: IUploadInput) => {
    const { modelId, modelInputId, file } = input;
    const storedProgress = UploadLocalStorageService.getUpload({
      modelId,
      modelInputId,
      fileName: file.name,
    }) as UploadProgress | undefined;
    initUpload(input, storedProgress);
  };

  const cancelUpload = (id: string) => {
    if (uploadsRef?.current?.[id]?.service) {
      uploadsRef.current[id].service.cancel();
    }
    removeUpload(id);
  };

  /**
   * Returns start/stop functions to manage uploads
   */
  return [uploadFile, cancelUpload];
};

export default useUploadModelVideo;
