import React, { memo, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import Box, { BoxAlignedCenter } from "../../../shared/components/Box";
import CloseIcon from "../../../shared/Icons/CloseIcon";
import classNames from "classnames";
import { IAppContext } from "../../../interfaces";
import { IPublicModel } from "../../../../models/Model";
import ModelIcon from "../../../shared/Icons/ModelIcon";
import SkyeTabs from "../../../shared/components/SkyeTab";
import LayersIcon from "../../../shared/Icons/LayersIcon";
import ObjectTreeLayers from "./ObjectTreeLayers";
import AdjustmentIcon from "../../../shared/Icons/AdjustmentIcon";
import { ViewerService, Volume } from "../../services/ViewerService";
import { IPotreeMeasurement } from "../../interfaces";
import BaseMeasure from "../../services/tools/BaseMeasure";
import { IViewerStore } from "../../../../stores/ViewerStore";
import ObjectTreeAdjustments from "./ObjectTreeAdjustments";
import useChangePerspectiveModelTool from "../../hook/useChangePerspectiveModelTool";
import Locator from "../../services/tools/Locator";
import { ViewerScaleContext } from "../../context/ViewerScaleContext";

interface IViewerObjectTreePropTypes {
  model: IPublicModel;
  ViewerStore?: IViewerStore;
}

const ObjectTreeContainer = styled.div``;
const MeasurementWindowButton = styled(Box)`
  cursor: pointer;
  width: 56px;
  height: 56px;
  align-items: center;
  justify-content: center;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};

  &.highlighted {
    background-color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};

    svg {
      path {
        stroke: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
      }
    }
  }
`;
const MeasurementWindow = styled.div`
  width: 216px;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  padding-top: ${(p: IAppContext) => p.theme.skye.sizes.small};
  padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
  padding-right: ${(p: IAppContext) => p.theme.skye.sizes.small};
  padding-left: ${(p: IAppContext) => p.theme.skye.sizes.small};
`;
const TreeLabel = styled.div`
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
`;
const WindowHeadRow = styled(Box)`
  justify-content: space-between;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
`;
const CloseButton = styled.div`
  cursor: pointer;
`;

const TabContent = styled(BoxAlignedCenter)`
  svg {
    width: 14px;
    margin-right: 4px;
    font-size: 14px;
  }
`;

const TabsWrapper = styled.div`
  .ant-tabs-nav-list {
    width: 100%;
    text-align: center;
  }

  .ant-tabs-tab {
    margin: 0 !important;
    width: 100%;
    padding-top: 0;
    padding-bottom: 5px;
    display: flex;
    justify-content: center;

    &.ant-tabs-tab-active svg path {
      stroke: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    }
  }
`;

const ElementsWrapper = styled.div`
  overflow-y: auto;
  min-height: 200px;
  max-height: 200px;
`;

const viewerService = new ViewerService();

const ViewerObjectTree = ({ model }: IViewerObjectTreePropTypes) => {
  const { t } = useTranslation();
  const { perspectiveChanged } = useChangePerspectiveModelTool({
    viewerInitialized: true,
    modelId: model.id,
  });
  const { isRescaled } = useContext(ViewerScaleContext);
  const [activeKey, setActiveKey] = useState("layers");
  const [initialized, setInitialized] = useState(false);

  const [open, setOpen] = useState(false);
  const [volumes, setVolumes] = useState<Volume[]>([]);
  const [measurements, setMeasurements] = useState<IPotreeMeasurement[]>();
  const measurementsExists = measurements?.length > 0;
  const adjustmentsExists = isRescaled || perspectiveChanged;
  const layersOrAdjustmentsExist = measurementsExists || adjustmentsExists;
  const highlightObjectTreeButton = initialized && layersOrAdjustmentsExist;

  const updateState = () => {
    const oldMeasurementsCount: number =
      (measurements && measurements.length) || 0;
    const newMeasurements = [
      ...BaseMeasure.getAll().filter((m) => !Locator.isLocator(m)),
    ];
    const newMeasuementsCount: number =
      (newMeasurements && newMeasurements.length) || 0;
    const firstMeasureCreated = !oldMeasurementsCount && newMeasuementsCount;

    setMeasurements(newMeasurements);

    // Opening ObjectTree when first measurement finished
    if (firstMeasureCreated) {
      setOpen(true);
    }
  };

  const onInitialLoad = () => {
    const newMeasurements = [
      ...BaseMeasure.getAll().filter((m) => !Locator.isLocator(m)),
    ];
    setMeasurements(newMeasurements);
    setInitialized(true);
  };

  const TAB_ITEMS = [
    {
      key: "layers",
      label: (
        <TabContent>
          <LayersIcon />
          {t("layers")}
        </TabContent>
      ),
    },
    {
      key: "adjustments",
      label: (
        <TabContent>
          <AdjustmentIcon />
          {t("adjustments")}
        </TabContent>
      ),
    },
  ];

  const handleMeasurementChangeEvent = () => {
    updateState();
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleVolumesChangeEvent = () => {
    const volumes = viewerService.getVolumes();
    setVolumes([...viewerService.getVolumes()]);

    if (volumes.length) {
      setOpen(true);
    }
  };

  const addEventListeners = () => {
    window.viewer.scene.addEventListener(
      "measurement_added",
      handleMeasurementChangeEvent
    );
    window.viewer.scene.addEventListener(
      "measurement_removed",
      handleMeasurementChangeEvent
    );
    window.viewer.scene.addEventListener(
      "volume_added",
      handleVolumesChangeEvent
    );
    window.viewer.scene.addEventListener(
      "volume_removed",
      handleVolumesChangeEvent
    );
  };

  const removeEventListeners = () => {
    window.viewer.scene.removeEventListener(
      "measurement_added",
      handleMeasurementChangeEvent
    );
    window.viewer.scene.removeEventListener(
      "measurement_removed",
      handleMeasurementChangeEvent
    );
    window.viewer.scene.removeEventListener(
      "volume_added",
      handleVolumesChangeEvent
    );
    window.viewer.scene.removeEventListener(
      "volume_removed",
      handleVolumesChangeEvent
    );
  };

  useEffect(() => {
    onInitialLoad();
    addEventListeners();

    return () => {
      removeEventListeners();
    };
  }, []);

  const handleTabChange = (key: string) => {
    setActiveKey(key);
  };

  return (
    <ObjectTreeContainer className="viewer-object-tree">
      {!open && (
        <MeasurementWindowButton
          id="object-tree-open-button"
          onClick={handleOpen}
          className={classNames([{ highlighted: highlightObjectTreeButton }])}
        >
          <ModelIcon />
        </MeasurementWindowButton>
      )}
      {open && (
        <MeasurementWindow>
          <WindowHeadRow>
            <TreeLabel>{t("model")}</TreeLabel>
            <CloseButton onClick={() => setOpen(false)}>
              <CloseIcon size={20}></CloseIcon>
            </CloseButton>
          </WindowHeadRow>
          <TabsWrapper>
            <SkyeTabs
              activeKey={activeKey}
              items={TAB_ITEMS}
              onChange={handleTabChange}
            />
            <ElementsWrapper>
              {activeKey === "layers" ? (
                <ObjectTreeLayers
                  model={model}
                  volumes={volumes}
                  measurements={measurements}
                  onChange={updateState}
                />
              ) : (
                <ObjectTreeAdjustments />
              )}
            </ElementsWrapper>
          </TabsWrapper>
        </MeasurementWindow>
      )}
    </ObjectTreeContainer>
  );
};

export default memo(ViewerObjectTree);
