export class FacebookAnalyticUtils {
  static hashValue(val: string): Promise<string> {
    return new Promise((resolve, reject) => {
      crypto.subtle
        .digest("SHA-256", new TextEncoder().encode(val))
        .then((h) => {
          let hexes = [],
            view = new DataView(h);
          for (let i = 0; i < view.byteLength; i += 4)
            hexes.push(("00000000" + view.getUint32(i).toString(16)).slice(-8));
          const hashed = hexes.join("");
          resolve(hashed);
        })
        .catch((e) => {
          reject(e);
        });
    });
  }
}
