import React from "react";

const ApprovedIcon = () => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="16"
        cy="16"
        r="12"
        stroke="#1A1A1A"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.3536 12.0463C22.5488 12.2416 22.5488 12.5582 22.3536 12.7535L14.3536 20.7535C14.2598 20.8472 14.1326 20.8999 14 20.8999C13.8674 20.8999 13.7402 20.8472 13.6464 20.7535L9.64645 16.7535C9.45118 16.5582 9.45118 16.2416 9.64645 16.0463C9.84171 15.8511 10.1583 15.8511 10.3536 16.0463L14 19.6928L21.6464 12.0463C21.8417 11.8511 22.1583 11.8511 22.3536 12.0463Z"
        fill="#1A1A1A"
      />
    </svg>
  );
};

export default ApprovedIcon;
