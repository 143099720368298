import React from 'react';
import styled from "styled-components";
import { IAppContext } from "../../interfaces";
import { useDropzone } from "react-dropzone";
import Description from "../typography/Description";
import { Trans } from "react-i18next";
import classNames from "classnames";

interface DropZoneProps {
  onDrop: (files: File[]) => void;
  multiple?: boolean;
  backgroundColor?: "white" | "grey" | "purple";
  disabled?: boolean;
  showIcon?: any;
}

const StyledDropZone = styled.div<any>`
  width: 100%;
  cursor: pointer;
  outline: none;
  transition: all 300ms ease-in-out;
  border-radius: 6px;
  padding: 15px;

  &.drag-active {
    border: 1px dashed ${(p: IAppContext) => p.theme.skye.colors.primary};
    background-color: transparent;
  }

  &.white {
    border: 1px dashed ${(p: IAppContext) => p.theme.skye.colors.silverChalice};
    background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  }

  &.grey {
    border: 1px dashed ${(p: IAppContext) => p.theme.skye.colors.background};
    background-color: ${(p: IAppContext) => p.theme.skye.colors.whiteSmoke};
  }

  &.purple {
    border: 1px dashed ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    background-color: ${(p: IAppContext) => p.theme.skye.colors.lavender};
  }
`;

const DropZoneContent = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 100%;
  justify-content: center;
`;

const DropZoneText = styled(Description)`
  text-align: center;

  &.label {
    margin-top: ${(p: IAppContext) => p.theme.skye.sizes.small};
  }

  strong {
    color: ${(p: IAppContext) => p.theme.skye.colors.electricViolet};
    text-decoration: underline;
    font-weight: inherit;
  }
`;
const FileUploadIcon = styled.img`
  height: 80px;
  margin-bottom: ${(p: IAppContext) => p.theme.skye.sizes.average};
`;

const getLabel = (isDragActive: boolean, multiple: boolean): string => {
  if (isDragActive) {
    return multiple ? "dropFilesHere" : "dropFileHere";
  }
  return multiple ? "dragAndDropOrSelectFiles" : "dragAndDropOrSelectFile";
};

const DropZone = ({
  onDrop,
  multiple = true,
  backgroundColor = "white",
  disabled,
  showIcon = true,
}: DropZoneProps) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    multiple,
    disabled,
  });

  return (
    <StyledDropZone
      {...getRootProps()}
      className={classNames([
        "drop-zone",
        backgroundColor,
        { "drag-active": isDragActive },
      ])}
    >
      <input {...getInputProps()} />
      <DropZoneContent className="drop-zone-content">
        {showIcon && <FileUploadIcon src="/images/upload_video.svg" />}
        <DropZoneText>
          <Trans>{getLabel(isDragActive, multiple)}</Trans>
        </DropZoneText>
      </DropZoneContent>
    </StyledDropZone>
  );
};

export default DropZone;