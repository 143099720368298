import React, { createContext, useContext, useRef } from "react";

export enum ViewerEventName {
  Rotate = "rotate",
  TexturesLoaded = "texturesLoaded",
}

// Create a Context
const GlobalEventEmitterContext = createContext({
  subscribe: (eventName: ViewerEventName, callback: () => void) => {},
  trigger: (eventName: ViewerEventName, eventData: any) => {},
});

// Create a Provider Component
export const ViewerEventEmitterProvider = ({ children }) => {
  const listenersRef = useRef({});

  const subscribe = (eventName, callback) => {
    listenersRef.current[eventName] = [
      ...(listenersRef.current[eventName] || []),
      callback,
    ];
    return () => {
      listenersRef.current[eventName] = listenersRef.current[eventName].filter(
        (cb) => cb !== callback
      );
    };
  };

  const trigger = (eventName, eventData) => {
    if (listenersRef.current[eventName]) {
      listenersRef.current[eventName].forEach((callback) => {
        callback(eventData);
      });
    }
  };

  return (
    <GlobalEventEmitterContext.Provider value={{ subscribe, trigger }}>
      {children}
    </GlobalEventEmitterContext.Provider>
  );
};

// Create a Custom Hook
export const useViewerEventEmitter = () => {
  const ctx = useContext(GlobalEventEmitterContext);
  if (!ctx) {
    throw new Error(
      "useViewerEventEmitter must be used within a ViewerEventEmitterProvider"
    );
  }

  return ctx;
};
