import React from 'react'

interface IVolumeIconPropTypes {
  
}

const VolumeIcon = ({
  
}: IVolumeIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path fillRule="evenodd" clipRule="evenodd" d="M2.8335 3.33334C2.8335 3.0572 3.05735 2.83334 3.3335 2.83334H6.00016C6.2763 2.83334 6.50016 3.0572 6.50016 3.33334V4.16668H25.5002V3.33334C25.5002 3.0572 25.724 2.83334 26.0002 2.83334H28.6668C28.943 2.83334 29.1668 3.0572 29.1668 3.33334V6.00001C29.1668 6.27615 28.943 6.50001 28.6668 6.50001H27.8335V25.5H28.6668C28.943 25.5 29.1668 25.7239 29.1668 26V28.6667C29.1668 28.9428 28.943 29.1667 28.6668 29.1667H26.0002C25.724 29.1667 25.5002 28.9428 25.5002 28.6667V27.8333H6.50016V28.6667C6.50016 28.9428 6.2763 29.1667 6.00016 29.1667H3.3335C3.05735 29.1667 2.8335 28.9428 2.8335 28.6667V26C2.8335 25.7239 3.05735 25.5 3.3335 25.5H4.16683V6.50001H3.3335C3.05735 6.50001 2.8335 6.27615 2.8335 6.00001V3.33334ZM5.16683 6.50001V25.5H6.00016C6.2763 25.5 6.50016 25.7239 6.50016 26V26.8333H25.5002V26C25.5002 25.7239 25.724 25.5 26.0002 25.5H26.8335V6.50001H26.0002C25.724 6.50001 25.5002 6.27615 25.5002 6.00001V5.16668H6.50016V6.00001C6.50016 6.27615 6.2763 6.50001 6.00016 6.50001H5.16683ZM3.8335 3.83334V5.50001H4.66683H5.50016V4.66668V3.83334H3.8335ZM26.5002 3.83334V4.66668V5.50001H27.3335H28.1668V3.83334H26.5002ZM3.8335 26.5V28.1667H5.50016V27.3333V26.5H4.66683H3.8335ZM26.5002 26.5V27.3333V28.1667H28.1668V26.5H27.3335H26.5002Z" fill="black"/>
    </svg>
  )
}

export default VolumeIcon;
