import React from "react";

interface INoVideoIconPropTypes {}

const NoVideoIcon = ({}: INoVideoIconPropTypes) => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.5 10.7803C4.5 9.35459 5.6566 8.19141 7.08008 8.19141H27.5381C28.9616 8.19141 30.1182 9.35459 30.1182 10.7803V15.4371L33.059 13.8684C33.0596 13.868 33.0603 13.8677 33.0609 13.8673C33.302 13.7366 33.5711 13.6699 33.8428 13.6699C34.2452 13.6699 34.6266 13.8183 34.9249 14.0752L34.9251 14.0754C35.2956 14.3948 35.5 14.8638 35.5 15.3535V24.5879C35.5 25.0929 35.2815 25.5699 34.8936 25.8904L34.8882 25.8948L34.8882 25.8948C34.594 26.131 34.2235 26.2656 33.8428 26.2656C33.5285 26.2656 33.2208 26.1772 32.952 26.005L30.1211 24.1991V29.2197C30.1211 30.6454 28.9645 31.8086 27.541 31.8086H7.08008C5.6566 31.8086 4.5 30.6454 4.5 29.2197V10.7803ZM7.08008 9.19141C6.21254 9.19141 5.5 9.90322 5.5 10.7803V29.2197C5.5 30.0968 6.21254 30.8086 7.08008 30.8086H27.541C28.4086 30.8086 29.1211 30.0968 29.1211 29.2197V23.2871V22.3751L29.89 22.8656L33.4906 25.1625L33.4914 25.163C33.5975 25.231 33.7176 25.2656 33.8428 25.2656C33.9883 25.2656 34.1381 25.2136 34.2594 25.1172C34.409 24.9923 34.5 24.8023 34.5 24.5879V15.3535C34.5 15.1402 34.4115 14.9531 34.2724 14.833C34.1488 14.7266 33.997 14.6699 33.8428 14.6699C33.7343 14.6699 33.6291 14.6966 33.5367 14.7469L33.5332 14.7488L33.5332 14.7488L29.8535 16.7117L29.1182 17.1039V16.2705V10.7803C29.1182 9.90322 28.4056 9.19141 27.5381 9.19141H7.08008Z"
        fill="#2E2E2E"
      />
      <line
        x1="2.85355"
        y1="3.39645"
        x2="36.6035"
        y2="37.1464"
        stroke="#2E2E2E"
      />
    </svg>
  );
};

export default NoVideoIcon;
