import { createContext, useState } from "react";

export const ViewerPerspectiveContext = createContext({
  perspectiveChanged: false,
  setPerspectiveChanged: (perspectiveChanged: boolean) => {},
});

export const ViewerPerspectiveProvider = (props) => {
  const [perspectiveChanged, _setPerspectiveChanged] = useState(false);

  const setPerspectiveChanged = (perspectiveChanged: boolean) => {
    _setPerspectiveChanged(perspectiveChanged);
  };

  return (
    <ViewerPerspectiveContext.Provider
      value={{ perspectiveChanged, setPerspectiveChanged }}
    >
      {props.children}
    </ViewerPerspectiveContext.Provider>
  );
};
