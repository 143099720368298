import { memo, useState } from "react";
import InfoBar from "../../../../viewer/components/InfoBar";

const GpsLogsNotAvailableInfoBar = () => {
  const [closed, setClosed] = useState(false);

  if (closed) return null;
  return (
    <div className="w-full h-full hidden md:block">
      <InfoBar
        showCloseIcon
        onClose={() => setClosed(true)}
        translation="gpsLogsNotAvailable"
      />
    </div>
  );
};

export default memo(GpsLogsNotAvailableInfoBar);
