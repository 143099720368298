import { IModelInput, IPublicModel, Model } from "models/Model";
import { inject, observer } from "mobx-react";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import ModelService from "views/models/services/ModelService";
import { MessageService } from "services/MessageService";
import { IVideoUploadStore, UploadQueueStatus } from "stores/VideoUploadStore";
import DropZone from "views/shared/components/DropZone";
import FileUploadIndicator, {
  UploadStatus,
} from "views/shared/components/FileUploadIndicator";

/**
 * Types of container which defines a container logic
 *
 * If container is set to ModelVideo it'll update video for model
 * For ModelPart will upload video for specified part - dedicated part should be
 *  also passed as a props
 * In case of NewModelPart - it'll create a new part and upload video for this dedicated part
 */
export enum EmergencyUploadType {
  ModelVideo = "ModelVideo",
  ModelPart = "ModelPart",
  NewModelPart = "NewModelPart",
}

interface IEmergencyUploadContainerPropTypes {
  model: IPublicModel;
  input?: IModelInput;
  showIcon?: boolean;
  VideoUploadStore?: IVideoUploadStore;
  uploadType: EmergencyUploadType;
  onUpdate?: () => void;
}

const EmergencyUploadContainer = ({
  model,
  input,
  uploadType,
  VideoUploadStore,
  showIcon = true,
  onUpdate = () => {},
}: IEmergencyUploadContainerPropTypes) => {
  const { t } = useTranslation();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const videoUpload = VideoUploadStore.items[selectedFile?.name];
  const existsInUploadStore = !!videoUpload;

  // Local video upload to be able to match correct upload from the store
  // Reference to current upload taken from the store
  // TODO: Implent
  const fileUploaded = false;

  const isVideoUploaded = (): boolean => {
    if (
      (uploadType === EmergencyUploadType.ModelVideo && input.vidUploaded) ||
      (uploadType === EmergencyUploadType.ModelPart &&
        input &&
        input.vidUploaded) ||
      fileUploaded
    ) {
      return true;
    }
    return false;
  };

  const onDrop = (files: File[]) => {
    const file = files[0];

    if (!file) {
      MessageService.error(t("noFileSelected"));
      return;
    }

    setSelectedFile(file);
  };

  const handleError = (err: any) => {
    if (err && err.status) {
      if (err.status === 409) {
        return MessageService.error(t("error_file_already_uploaded"));
      }
    }

    MessageService.error(t("requestFailed"));
  };

  const getCurrentUploadProgressLabel = (): string => {
    return videoUpload && videoUpload.status === UploadQueueStatus.IN_PROGRESS
      ? `Progress: ${videoUpload.progress.toFixed(2)}%`
      : "";
  };

  const addToQueue = (modelId: string, modelInputId: string) => {
    VideoUploadStore.addToQueue(
      selectedFile,
      selectedFile.name,
      modelId,
      modelInputId
    );
  };

  const createPartAndUploadVideo = async () => {
    await ModelService.createPart(
      model.id,
      selectedFile.lastModified,
      selectedFile.size,
      selectedFile.name
    );
    const updatedModel = (await ModelService.getModelDetails(model.id))?.data;
    const lastInput = updatedModel.inputs[updatedModel.inputs.length - 1];

    addToQueue(updatedModel.id, lastInput.id);
  };

  const uploadFile = () => {
    switch (uploadType) {
      case EmergencyUploadType.ModelPart:
      case EmergencyUploadType.ModelVideo:
        addToQueue(model.id, String(input.id));
        break;
      case EmergencyUploadType.NewModelPart:
        createPartAndUploadVideo();
        break;
      default:
        break;
    }
  };

  const getFileName = (): string => {
    if (input && input.fileName) {
      return input.fileName;
    }

    if (selectedFile) {
      return selectedFile.name;
    }

    return "Unknown file";
  };

  const getStatus = (): UploadStatus => {
    if (!existsInUploadStore) return null;

    switch (videoUpload.status) {
      case UploadQueueStatus.IN_PROGRESS:
        return UploadStatus.Uploading;
      case UploadQueueStatus.FAILED:
        return UploadStatus.Failed;
      case UploadQueueStatus.SUCCESS:
        return UploadStatus.Uploaded;
      case UploadQueueStatus.CANCELED:
        return UploadStatus.Failed;
      case UploadQueueStatus.IN_QUEUE:
      default:
        return UploadStatus.InQueue;
    }
  };

  const getWarningMessageTranslation = (): string => {
    if (uploadType === EmergencyUploadType.ModelVideo) {
      return "emergencyUploadConfirmationDialogContent";
    }
    return "emergencyUploadConfirmationPartDialogContent";
  };

  return (
    <>
      {selectedFile || existsInUploadStore ? (
        <FileUploadIndicator
          fileName={getFileName()}
          showWarning={true}
          warningMessage={getWarningMessageTranslation()}
          status={getStatus()}
          loadingProgressLabel={getCurrentUploadProgressLabel()}
          onUploadStart={uploadFile}
          onDelete={() => setSelectedFile(null)}
          onRefresh={uploadFile}
        />
      ) : (
        <DropZone
          onDrop={(files) => onDrop(files)}
          multiple={false}
          showIcon={showIcon}
        ></DropZone>
      )}
    </>
  );
};

export default inject("VideoUploadStore")(observer(EmergencyUploadContainer));
