import { ReactNode } from "react";
import styles from "./PaymentOption.module.css";

interface IPaymentOptionPropTypes {
  featured?: boolean;
  featuredLabel?: ReactNode;
  description?: ReactNode;
  periodLabel?: ReactNode;
  priceLabel?: ReactNode;
  icon?: ReactNode;
  switchPlacement?: ReactNode;
  buttonsPlacement?: ReactNode;
}

const PaymentOption = ({
  featured,
  featuredLabel,
  icon,
  periodLabel,
  priceLabel,
  description,
  switchPlacement,
  buttonsPlacement,
}: IPaymentOptionPropTypes) => {
  return (
    <div className="payment-option w-full h-full relative">
      <div className="payment-option-wrapper relative z-10 w-full h-full bg-white p-4 border border-solid text-center rounded-md flex flex-col justify-center">
        {icon && (
          <div className="icon-wrapper flex justify-center items-center mb-4">
            {icon}
          </div>
        )}
        {!!periodLabel && <div className="period text-base">{periodLabel}</div>}
        {!!priceLabel && (
          <div className="price font-medium text-2xl mt-2">{priceLabel}</div>
        )}
        {!!switchPlacement && (
          <div className="switch-wrapper mt-4 mb-2 flex justify-center">
            {switchPlacement}
          </div>
        )}
        {!!description && (
          <div className="description text-sm text-gray-600 mt-2">
            {description}
          </div>
        )}
        <div className="mt-4">
          {!!buttonsPlacement && (
            <div className="buttons-wrapper f-full">{buttonsPlacement}</div>
          )}
        </div>
      </div>
      {featured && (
        <div className={styles["featured-wrapper"]}>
          <div className={styles["featured-label"]}>
            <div>{featuredLabel}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentOption;
