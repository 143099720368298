import { makeAutoObservable } from "mobx";
import { IStream, StreamService } from "../services/StreamService";

export interface ISessionStreamStore {
  streamPanelOpen: boolean;
  streams: IStreamsData;
  streamsCount: string;
  initView: (sessionAddress: string) => Promise<boolean>;
  setStreamPanelOpen: (open: boolean) => void;
  resetStore: () => void;
}

export type IStreamsData = Map<string, IStream>;

class SessionStreamStore {
  streams: IStreamsData;
  streamPanelOpen: boolean;

  constructor() {
    this.streams = new Map();
    makeAutoObservable(this);
  }

  async initView(sessionAddress: string) {
    const streams = await StreamService.getStreamsBySessionAddress(
      sessionAddress
    );
    this.setStreams(streams);
  }

  resetStore(): void {
    this.streams = new Map();
    this.streamPanelOpen = false;
  }

  setStreamPanelOpen(open: boolean): void {
    this.streamPanelOpen = open;
  }

  setStreams(streams: IStream[]) {
    const newStreamData = new Map();
    streams.forEach((stream) => {
      newStreamData[stream.userId] = stream;
    });

    this.streams = newStreamData;
  }
}

export default new SessionStreamStore();
