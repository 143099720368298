import { STORE_NAMES } from "const/global";
import { inject, observer } from "mobx-react";
import React from "react";
import { ISessionViewerPageStore } from "stores/SessionViewerPageStore";
import styled from "styled-components";
import { IAppContext } from "views/interfaces";
import { BoxAlignedCenter } from "views/shared/components/Box";
import ConnectionStatusInfo from "./components/ConnectionStatusInfo";
import StreamStatusInfo from "./components/StreamStatusInfo";

interface ISessionViewerStatusTopBarContainerPropTypes {
  SessionViewerPageStore?: ISessionViewerPageStore;
}

const TopBar = styled(BoxAlignedCenter)`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};
  height: 36px;
  padding: 0 ${(p: IAppContext) => p.theme.skye.sizes.small};
`;
const Separator = styled.div`
  height: ${(p: IAppContext) => p.theme.skye.sizes.average};
  width: 1px;
  background-color: ${(p: IAppContext) => p.theme.skye.colors.textDisabled};
  content: "";
  margin: 0 ${(p: IAppContext) => p.theme.skye.sizes.average};
`;

const SessionViewerStatusTopBarContainer = ({
  SessionViewerPageStore,
}: ISessionViewerStatusTopBarContainerPropTypes) => {
  const subscribed = SessionViewerPageStore.subscribed;

  return (
    <TopBar>
      <ConnectionStatusInfo subscribed={subscribed} />
      <Separator />
      <StreamStatusInfo />
    </TopBar>
  );
};

export default inject(STORE_NAMES.SessionViewerPageStore)(
  observer(SessionViewerStatusTopBarContainer)
);
