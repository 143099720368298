import { makeAutoObservable } from "mobx";
import { CommentDTO, CommentService } from "services/CommentService";

export interface IModelDetailsPageStore {
  commentService: CommentService;
  comments: CommentDTO[];
  commentsCount: number;
  commentsOpen: boolean;
  commentsLoaded: boolean;
  loadComments: (modelId: string) => Promise<void>;
  openCommentsPanel: () => void;
  closeCommentsPanel: () => void;
  resetStore: () => void;
}

class ModelDetailsPageStore implements IModelDetailsPageStore {
  commentService: CommentService;

  commentsLoaded = false;
  commentsOpen = false;
  comments: CommentDTO[] = [];
  commentsCount: number = 0;

  constructor() {
    makeAutoObservable(this);
    this.commentService = new CommentService();
  }

  async loadComments(modelId: string): Promise<void> {
    const comments = await this.commentService.getModelComments(modelId);
    this.comments = comments.sort((a, b) => {
      return new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime();
    });
    this.commentsCount = comments.length;
    this.commentsLoaded = true;
  }

  resetStore(): void {
    this.commentsOpen = false;
    this.comments = [];
    this.commentsCount = 0;
    this.commentsLoaded = false;
  }

  openCommentsPanel(): void {
    this.commentsOpen = true;
  }

  closeCommentsPanel(): void {
    this.commentsOpen = false;
  }
}

export default new ModelDetailsPageStore();
