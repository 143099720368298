import { useEffect } from "react";
import { withRouter } from "react-router-dom";

/**
 * Hook used to scrool to top on every route change
 */
function ScrollToTopHook({ history }) {
  useEffect(() => {
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    };
  }, []);

  return null;
}

export default withRouter(ScrollToTopHook);
