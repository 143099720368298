import { useTranslation } from "react-i18next";
import BasePage from "../../../shared/layouts/BasePage";
import Title from "../../../shared/typography/Title";
import { useEffect } from "react";
import { WindowUtils } from "../../../../utils/windowUtils";
import useCurrentUser from "../../../account/hooks/useCurrentUser";
import { SubscriptionType } from "../../../payments/payments.model";
import { useSubscriptionPaymentDialog } from "../../../models/payments/useSubscriptionPaymentDialog";
import SubscriptionInformationRow from "../../../shared/components/SubscriptionInformationRow/SubscriptionInformationRow";
import Button from "../../../shared/buttons/Button";

const SCRIPT_CLASS = "gradio-app-script";

const InteriorAiPage = () => {
  const { t } = useTranslation();
  const { user } = useCurrentUser();
  const subscription = useSubscriptionPaymentDialog();
  const pricingDialog = subscription.content;

  const showInterior = [
    SubscriptionType.Lite,
    SubscriptionType.Premium,
  ].includes(user?.organization?.subscription?.type?.value);

  useEffect(() => {
    WindowUtils.loadScript(
      "https://gradio.s3-us-west-2.amazonaws.com/4.26.0/gradio.js",
      "module",
      "gradio-app-script"
    );

    return () => {
      const script = document.querySelector(`script.${SCRIPT_CLASS}`);
      if (script) {
        script.remove();
      }
    };
  }, []);

  return (
    <BasePage backgroundColor="white">
      <Title>{t("interiorAi.pageTitle")}</Title>
      {showInterior ? (
        <div className="mt-8 w-full h-full">
          <gradio-app src="https://app.skybrowse.co/" />
        </div>
      ) : (
        <div className="w-full">
          {pricingDialog}
          <SubscriptionInformationRow
            color="primary"
            iconColor="primary"
            icon="crown"
            primaryText="Get access to Interior AI"
            secondaryText="Redesign your interior space in seconds. Subscribe to Lite to access Interior AI."
            button={
              <Button
                className="w-max"
                color="primary"
                size="small"
                onClick={subscription.openDialog}
              >
                {t("subscription.subscribeNowBtn")}
              </Button>
            }
          ></SubscriptionInformationRow>
        </div>
      )}
      <div className="video-wrapper flex justify-center mt-8 lg:mt-12 mb-12">
        <iframe
          width="560"
          height="315"
          src="https://www.youtube.com/embed/kxW6n8NZOq8?si=WEm5hQKCOqi9IEDW&autoplay=1&loop=1&&rel=0&playlist=kxW6n8NZOq8"
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerPolicy="strict-origin-when-cross-origin"
          allowFullScreen
        ></iframe>
      </div>
    </BasePage>
  );
};

export default InteriorAiPage;
