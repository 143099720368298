import React, { memo, useState } from "react";
import styled from "styled-components";
import classNames from "classnames";
import MiniPlayer from "./mini-player/MiniPlayer";
import { IStream } from "../../../../services/StreamService";
import { IAppContext } from "../../../interfaces";

interface ISingleStreamWindowContainerPropTypes {
  stream: IStream;
}

export const SMALL_STREAM_PREVIEW_HEIGHT = 146;
export const SMALL_STREAM_PREVIEW_WIDTH = 266;

const PlayerWrapper = styled.div`
  width: 100%;
  display: none;
  background-color: ${(p: IAppContext) =>
    p.theme.skye.colors.backgroundThumbnail};
  border-radius: ${(p: IAppContext) => p.theme.skye.sizes.radius};

  &.playing {
    display: block;
  }
`;

const SingleStreamWindowContainer = ({
  stream,
}: ISingleStreamWindowContainerPropTypes) => {
  const [playing, setPlaying] = useState(true);
  const [initialLoadig, setInitialLoading] = useState(true);

  return (
    <PlayerWrapper
      style={{
        width: SMALL_STREAM_PREVIEW_WIDTH,
        height: SMALL_STREAM_PREVIEW_HEIGHT,
      }}
      className={classNames(["single-stream-container", { playing }])}
    >
      <MiniPlayer
        streamUrl={stream.playbackUrl}
        transition
        onPlayingChange={setPlaying}
        onLoad={() => setInitialLoading(false)}
      />
    </PlayerWrapper>
  );
};

export default memo(SingleStreamWindowContainer);
