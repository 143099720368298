import React, { memo, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { FormGroup } from "../../../../shared/form/FormGroup";
import InputGroup from "../../../../shared/form/InputGroup";
import Title from "../../../../shared/typography/Title";
import { InputCallback } from "../interface";

interface IModelNameInputGroupPropTypes {
  value: string;
  onChange: InputCallback;
}

const Wrapper = styled(FormGroup)``;

const ModelNameInputGroup = ({
  value,
  onChange,
}: IModelNameInputGroupPropTypes) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Title>{t("modelName")}</Title>
      <InputGroup
        bordered
        inputProps={{
          placeholder: "modelNamePlaceholder",
          value,
          onChange: (e: any) => onChange(e.target.value),
        }}
      />
    </Wrapper>
  );
};

export default memo(ModelNameInputGroup);
