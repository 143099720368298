import { ReactNode, memo } from "react";
import NewToolIndicator from "./NewToolIndicator";

interface INewToolIndicatorWrapperPropTypes {
  children: ReactNode;
}

const NewToolIndicatorWrapper = ({
  children,
}: INewToolIndicatorWrapperPropTypes) => {
  return (
    <div className="new-tool-indicator-wrapper relative">
      {children}
      <div className="absolute top-0 right-0">
        <NewToolIndicator />
      </div>
    </div>
  );
};

export default memo(NewToolIndicatorWrapper);
