import { Model, ModelHelpers } from "models/Model";
import React from "react";
import styled from "styled-components";
import { IAppContext } from "views/interfaces";

interface IModelVideoDurationPropTypes {
  videoDuration: number;
}

const VideoDuration = styled.div`
  background-color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
  color: ${(p: IAppContext) => p.theme.skye.colors.backgroundCard};
  padding: 2px 8px;
  font-size: 12px;
  font-weight: 900;
  border-radius: 4px;
  z-index: 100;
`;

const ModelVideoDuration = ({
  videoDuration,
}: IModelVideoDurationPropTypes) => {
  return (
    <VideoDuration className="video-duration">
      {ModelHelpers.videoDurationToTimeLabel(videoDuration)}
    </VideoDuration>
  );
};

export default ModelVideoDuration;
