import { computed, makeAutoObservable } from "mobx";

export interface UploadQueueItem {
  file: File;
  id: string;
  modelId: string;
  modelInputId: string;
  status: UploadQueueStatus;
  progress: number;
}

export enum UploadQueueStatus {
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  CANCELED = "CANCELED",
  IN_QUEUE = "IN_QUEUE",
  IN_PROGRESS = "IN_PROGRESS",
  PAUSED = "PAUSED",
}

export type UploadQueueStore = Record<string, UploadQueueItem>;

export interface IVideoUploadStore {
  items: UploadQueueStore;
  getQueued: UploadQueueItem[];
  inProgressArray: UploadQueueItem[];
  itemExist: (id: string) => boolean;
  addToQueue: (
    file: File,
    name: string,
    modelId: string,
    modelInputId: string
  ) => void;
  changeStatus: (id: string, status: UploadQueueStatus) => UploadQueueItem;
  updateProgress: (id: string, progress: number) => UploadQueueItem;
}

class VideoUploadStore implements IVideoUploadStore {
  items = {};

  constructor() {
    makeAutoObservable(this);
  }

  @computed get itemsList(): UploadQueueItem[] {
    return Object.values(this.items);
  }

  @computed get getQueued(): UploadQueueItem[] {
    return this.itemsList.filter(
      (item) => item.status === UploadQueueStatus.IN_QUEUE
    );
  }

  @computed get inProgressArray(): UploadQueueItem[] {
    return this.itemsList.filter(
      (item) => item.status === UploadQueueStatus.IN_PROGRESS
    );
  }

  @computed itemExist(id: string) {
    return !!this.items[id];
  }

  /**
   * Creating request to upload video to AWS
   * @param awsSignedUrl
   * @param file
   */
  addToQueue(file: File, id: string, modelId: string, modelInputId: string) {
    this.items[id] = {
      file,
      id,
      modelId,
      modelInputId,
      status: UploadQueueStatus.IN_QUEUE,
      progress: 0,
    };
  }

  changeStatus(id: string, status: UploadQueueStatus) {
    const updated = { ...this.items[id], status };
    this.items[id] = updated;
    return updated;
  }

  updateProgress(id: string, progress: number) {
    const updated = { ...this.items[id], progress };
    this.items[id] = updated;
    return updated;
  }
}

export default new VideoUploadStore();
