import React from 'react'

interface IUploadIconPropTypes {
  
}

const UploadIcon = ({
  
}: IUploadIconPropTypes) => {
  return (
    <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M7.78497 13.514C4.84968 14.2121 2.6665 16.8511 2.6665 20C2.6665 23.6819 5.65127 26.6667 9.33317 26.6667C9.96477 26.6667 10.5758 26.5789 11.1548 26.4147" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24.0364 13.514C26.9717 14.2121 29.1548 16.8511 29.1548 20C29.1548 23.6819 26.1701 26.6667 22.4882 26.6667C21.8566 26.6667 21.2455 26.5789 20.6665 26.4147" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M24 13.3333C24 8.91508 20.4183 5.33334 16 5.33334C11.5817 5.33334 8 8.91508 8 13.3333" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.377 18.5872L16 13.949L20.7546 18.6666" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M16 25.3333V16.3078" stroke="#1A1A1A" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}

export default UploadIcon;
