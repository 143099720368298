import React, { DOMAttributes } from 'react'
import styled from 'styled-components';
import classNames from 'classnames';
import { IAppContext } from "../../interfaces";

interface ILabelPropTypes extends DOMAttributes<HTMLHeadingElement> {
  className?: string,
  color?: 'danger' | 'success',
}

const StyledLabel = styled.h4`
  color: ${(p: IAppContext) => p.theme.skye.colors.textPrimary};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  padding-bottom: ${(p: IAppContext) => p.theme.skye.sizes.radius};

  &.danger {
    color: ${(p: IAppContext) => p.theme.skye.colors.danger};
  }

  &.success {
    color: ${(p: IAppContext) => p.theme.skye.colors.success};
  }
`;

const Label = ({
  className,
  color,
  ...otherProps
}: ILabelPropTypes) => {
  return (
    <StyledLabel
      className={classNames(['label', color, className])}
      {...otherProps}
    />
  );
}

export default Label;
