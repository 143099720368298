import React from "react";

interface LocationToolIconProps {}

const LocationToolIcon = ({}: LocationToolIconProps) => {
  return (
    <svg
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_4479_5045"
        maskUnits="userSpaceOnUse"
        x="3"
        y="3"
        width="26"
        height="26"
      >
        <path d="M29 3H3V29H29V3Z" fill="white" />
      </mask>
      <g mask="url(#mask0_4479_5045)">
        <mask
          id="mask1_4479_5045"
          maskUnits="userSpaceOnUse"
          x="3"
          y="3"
          width="26"
          height="26"
        >
          <path d="M3 3H29V29H3V3Z" fill="white" />
        </mask>
        <g mask="url(#mask1_4479_5045)">
          <path
            d="M14.7389 17.07L11.279 16.4902C11.0011 16.4436 10.9509 16.0656 11.207 15.9481L19.5292 12.1286C19.7743 12.0162 20.0246 12.2741 19.9049 12.5156L15.9009 20.593C15.7747 20.8475 15.3948 20.7824 15.3607 20.5003L14.9756 17.3178C14.9604 17.1919 14.864 17.091 14.7389 17.07Z"
            stroke="black"
            stroke-width="1.2"
            stroke-miterlimit="10"
          />
          <path
            d="M16 28.2433C9.24096 28.2433 3.76172 22.764 3.76172 16.005C3.76172 9.24598 9.24096 3.76673 16 3.76673C22.759 3.76673 28.2383 9.24598 28.2383 16.005C28.2383 22.764 22.759 28.2433 16 28.2433Z"
            stroke="black"
            stroke-width="1.2"
            stroke-miterlimit="10"
          />
        </g>
      </g>
    </svg>
  );
};

export default LocationToolIcon;
