import React from "react";
import { inject, observer } from "mobx-react";
import { STORE_NAMES } from "const/global";
import { ISessionViewerPageStore } from "stores/SessionViewerPageStore";
import SessionViewerLocatorComponent from "../components/SessionLocator/SessionViewerLocatorComponent";
import SessionMapLocatorComponent from "../components/SessionLocator/SessionMapLocatorComponent";
import { Model } from "models/Model";
import { TacbrowseEntity } from "../../../models/SessionData";

interface ISessionViewerLocatorsContainerPropTypes {
  SessionViewerPageStore?: ISessionViewerPageStore;
  type: "viewer" | "map";
}

const getRelativeAltitude = (altitude: number, model?: Model) => {
  if (model?.operatorAltitude) {
    return altitude - model.operatorAltitude;
  }
  return 0;
};

const SessionFindingViewerLocatorsContainer = ({
  SessionViewerPageStore,
  type,
}: ISessionViewerLocatorsContainerPropTypes) => {
  const sessionDataStore = SessionViewerPageStore.sessionFindingsData;
  const sessionModel = SessionViewerPageStore.sessionModel;

  const clickHandler = () => {};

  return (
    <>
      {Object.keys(sessionDataStore).map((key: string) => {
        const sessionData = sessionDataStore[key];
        const findingType = sessionData?.detectedObject?.toLocaleLowerCase();

        if (type === "viewer") {
          return (
            <SessionViewerLocatorComponent
              key={key}
              active={false}
              userId={sessionData.deviceId}
              label={findingType}
              longitude={sessionData.lng}
              latitude={sessionData.lat}
              altitude={getRelativeAltitude(sessionData.alt, sessionModel)}
              findingType={findingType}
              senderType={TacbrowseEntity.Finding}
            />
          );
        }

        return (
          <SessionMapLocatorComponent
            key={key}
            label={findingType}
            longitude={sessionData.lng}
            latitude={sessionData.lat}
            active={false}
            onClick={clickHandler}
            findingType={findingType}
            senderType={TacbrowseEntity.Finding}
          ></SessionMapLocatorComponent>
        );
      })}
    </>
  );
};

export default inject(STORE_NAMES.SessionViewerPageStore)(
  observer(SessionFindingViewerLocatorsContainer)
);
